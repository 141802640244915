import * as React from 'react';
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { CostingCalculatorConfig } from '../CostingCreateModule/CostingEstimation/CostingCalculatorConfig';
import { ScenarioCalculator } from '../CostingCreateModule/CostingEstimation/ScenarioCalculator';
import { useDispatch } from 'react-redux';
import { saveInput } from '../../Redux/Actions/userInput.action';


export interface IScenarioCalculatorModelProps {
  isOpen: any;
  onCloseModal: any;
  calculatorData?:any;
  costDetailsData?:any
  scenario?:any;
  HierarchyAllData?:any;
  vault?:any;
  getCostDetailsData?:any;
  costEstimationId?:any;
  topVault?:any;
  getScenarioTreeData?:any
  parentId?:any
  revision_id?:any
  assumption?:any;
  category_type?:any
  decimalPoints?:any
  PermissionDataa?:any
  userProfile?:any
  reDirectToMainCosting?:any;
  CostingType?:any;
  with_link?:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70vw",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

export const userPermission = React.createContext<any>(null);
export const withLink = React.createContext<any>(null);
export const costingDetails = React.createContext<any>({});

export default function ScenarioCalculatorModel(props: IScenarioCalculatorModelProps) {
  const { isOpen, onCloseModal, calculatorData, costDetailsData, scenario,HierarchyAllData, vault,topVault, getCostDetailsData, costEstimationId, getScenarioTreeData, parentId, revision_id, assumption, category_type, decimalPoints, PermissionDataa, userProfile, reDirectToMainCosting, CostingType,with_link} = props;

  const [PermissionData, setPermissionData] = React.useState<any>(null);

  React.useEffect(() => {

    getCostDetailsData();
  }, [vault]);

  const dispatch = useDispatch();


  React.useEffect(() => {
    dispatch(saveInput(HierarchyAllData));
  }, [HierarchyAllData])




  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Scenario Cost Model
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
           <userPermission.Provider
                                    value={PermissionDataa && PermissionDataa}
                                  >
                                    <costingDetails.Provider
                                      value={costDetailsData}
                                    >
                                      <withLink.Provider value={with_link && with_link}>
                                      <ScenarioCalculator
                                        passingObj={{}}
                                        calculatorData={calculatorData}
                                        costDetailsData={costDetailsData}
                                        tableId={HierarchyAllData?.object_id}
                                        costEstimationId={costEstimationId}
                                        vault={vault}
                                        HierarchyAllData={HierarchyAllData}
                                        calctype={HierarchyAllData?.calculator?.category}
                                        condition={0}
                                        scenarioId={scenario?.id}
                                        calcultorId={HierarchyAllData?.calculator?.id}
                                        conditionPopup={0}
                                        getScenarioTreeData={getScenarioTreeData}
                                        onCloseModal={onCloseModal}
                                        parentId={parentId}
                                        revision_id={revision_id}
                                        assumption={assumption}
                                        category_type={category_type}
                                        decimalPoints={decimalPoints}
                                        PermissionDataa={PermissionDataa}
                                        userProfile={userProfile}
                                        reDirectToMainCosting={reDirectToMainCosting}
                                        CostingType={CostingType}
                                      />
                                      </withLink.Provider>
                                    </costingDetails.Provider>
                                  </userPermission.Provider>
        </Box>
      </Modal>
    </div>
  );
}
