import { Box, Button, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { CostingImageSection } from "./CostingImageSection";
import { CostingTableSection } from "./CostingTableSection";
import CostViewChart from "./CostViewChart";

export interface ICostingGraphDataProps {
  permissionModule: any
  setProductLevelLoading?: any,
  count?: any
}

export function CostingGraphData(props: ICostingGraphDataProps) {
  const { permissionModule, count } = props;
  const [StackGraph, setSatckGraph] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<any>(false);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { projectId, vault, topVault, treeabbr, scenarioId } = useRouteParams<any>();
  const [imageData, setgImageData] = React.useState<any>({});
  const [bomData, setBomData] = React.useState<any>({});
  const toggleTopvault = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault_id: vault };
    }
  };

  React.useEffect(() => {
    setSatckGraph([]);
    setLoader(true);
    API.get(`/customer/costing_module/`, { ...toggleTopvault(), get_graph_data: true, scenario: url.includes("scenario") ? scenarioId : undefined }, 0)
      .then((res: any) => {
        if (res.data?.length > 0) {
          setLoader(false);
          setSatckGraph(res.data[0]);
        } else {
          setLoader(false);
          setSatckGraph([]);
        }
      })
      .catch((err: any) => {
        setLoader(false);
      });
  }, [vault, topVault, count]);

  React.useEffect(() => {
    if (vault != 0) {
      API.get("/customer/costing_module/", { bom: "true", vault: vault }, 0)
        .then((res: any) => {
          if (Object.keys(res.data)?.length > 0) {
            setgImageData(res.data?.image);
            setBomData(res.data?.numeric);
          } else {
            setgImageData({});
            setBomData({});
          }
        })
        .catch((res: any) => { });
    } else {
      API.get("/customer/costing_module/", { bom: "true", top_vault: topVault }, 0)
        .then((res: any) => {
          if (Object.keys(res.data)?.length > 0) {
            setgImageData(res.data?.image);
            setBomData(res.data?.numeric);
          } else {
            setgImageData({});
            setBomData({});
          }
        })
        .catch((res: any) => { });
    }
  }, [vault, topVault]);
  return (
    <div>
      {!loader ? (
        <Box sx={{ height: { lg: "82vh", xl: '86vh' }, padding: "0 0.5rem", }}>
          <Box
            sx={{ height: { lg: '82vh !important', xl: "86vh !important", },paddingRight: '0.5rem',marginRight: '-0.5rem' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {permissionModule && permissionModule?.includes("graph") ? <CostViewChart costEstimation={StackGraph} /> : <Typography align="center">Graph Not available</Typography>}
            {permissionModule && permissionModule?.includes("table-images") ? <CostingImageSection imageData={imageData && imageData} /> : ""}
            {permissionModule && permissionModule?.includes("table-images") ? <CostingTableSection bomData={bomData && bomData} /> : ""}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: '0 0.5rem',
              marginTop: "0rem"
            }}>
            <Skeleton sx={{ width: "37vw", height: "16rem" }} />
            <Box
              sx={{
                display: "flex",
                columnGap: "4rem",
                flexDirection: "row",
                height: "8rem",
                marginTop: '0rem',
                padding: '0 0.5rem'
              }}
            >
              <Skeleton sx={{ width: "10vw", height: "10rem" }} />
              <Skeleton sx={{ width: "10vw", height: "10rem" }} />
              <Skeleton sx={{ width: "10vw", height: "10rem" }} />
            </Box>

            <Box sx={{ padding: '0 0.5rem', marginTop: '1rem', }}>
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} />
              <Skeleton sx={{ width: "37vw", height: "3rem" }} /></Box>
          </Box>
        </>
      )}
    </div>
  );
}
