import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Backdrop,
  Chip,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddMachineModal.module.scss";

import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";

import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { API } from "../../../api-services";
import { memo, useState } from "react";
import MachineFlagS3Upload from "./MachineFlagS3Upload";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import MachineFlagS3UploadFetch from "./MachineFlagS3UploadFetch";
import MachineSpecificFlagS3Upload from "./MachineSpecificFlagS3Upload";
import MachineSpecificFlagS3UploadFetch from "./MachineSpecificFlagS3UploadFetch";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "50vw",
  maxWidth: "80vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  capital_cost: yup.string().required("Please enter Capital Cost"),
  commodity: yup.string().required("Please enter Commodity"),
  consumption: yup.mixed().required("Please enter Consumble Cost"),
  description: yup.string().required("Please enter Description"),
  effectiveness: yup.mixed().required("Please enter Effectiveness"),

  installation_cost: yup.mixed().required("Please enter Installation Cost"),
  life: yup.mixed().required("Please enter Life"),
  machine_code: yup.string().required("Please enter Machine code"),
  maintanance: yup.mixed().required("Please enter Maintanance"),
  name: yup.string().required("Please enter Machine Name"),
  operation: yup.string().required("Please enter Operation"),
  peak_power_factor: yup.mixed().required("Please enter Peak Power Factor"),
  power_consumption: yup.mixed().required("Please enter Power Consumption"),
  reference: yup.string().required("Please enter reference"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  type?: any;
  getRevisionData?: any;
  setCounter: any;
  swalalterHandlerAdd: any;
}

function AddMachineModal(props: IWatermarkModelProps) {
  const {
    isOpen,
    onCloseModal,
    actions,
    getRevisionData,
    type,
    setCounter,
    swalalterHandlerAdd,
  } = props;
  const { databaseName, typeId, revisionId } = useRouteParams<any>();

  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [isSpecLoadingsave, setIsSpecLoadingSave] = React.useState<any>(false);

  const [flagFile, setFlagFile] = useState<any>(null);
  const [specflagFile, setSpecFlagFile] = useState<any>(null);

  const inputElRef = React.useRef<any>();
  const specinputElRef = React.useRef<any>();
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");
  // ----------------------------------------------

  var ErrorModalFiles = useBaseModal();
  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };
  const specbrowseFiles = () => {
    return specinputElRef.current?.click?.();
  };
  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setFlagFile(e.target.files[0]);
    }
  };
  const handleImgSpecific = (e: any) => {
    if (e.target.files[0]) {
      setSpecFlagFile(e.target.files[0]);
    }
  };
  const swalMsgalterHandlerAdd = (type: any, msgtype: any, msg: any) => {
    if (type == true) {
      swalalterHandlerAdd(type, msgtype);
    } else {
      ErrorModalFiles.open();
      setErrorModalFileMsg(msg);
    }
  };
  React.useEffect(() => {
    API.get("/api/db/cost_databases/", {
      database_type: "machine",
    })
      .then((res: any) => {
        setCategoryList(res?.data);
      })
      .catch((err: any) => { });
  }, []);

  React.useEffect(() => {
    if (type == "edit") {
      if (actions?.currency_code) {
        setSelectedCurrencyCodes(actions?.currency_code || "");
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("brand_name", actions?.brand_name);
      formik.setFieldValue("capital_cost", actions?.capital_cost);
      formik.setFieldValue("commodity", actions?.commodity);
      formik.setFieldValue("consumption", actions?.consumption);
      formik.setFieldValue("description", actions?.description);
      formik.setFieldValue("machine_bed_area", actions?.machine_bed_area);
      formik.setFieldValue("effectiveness", actions?.effectiveness);
      formik.setFieldValue("installation_cost", actions?.installation_cost);
      formik.setFieldValue("life", actions?.life);
      formik.setFieldValue("machine_code", actions?.machine_code);
      formik.setFieldValue("maintanance", actions?.maintanance);
      formik.setFieldValue("name", actions?.name);
      formik.setFieldValue("operation", actions?.operation);
      formik.setFieldValue("peak_power_factor", actions?.peak_power_factor);
      formik.setFieldValue("power_consumption", actions?.power_consumption);
      formik.setFieldValue("reference", actions?.reference);
      formik.setFieldValue("unit", actions.unit);
      formik.setFieldValue("currency_id", actions.currency_id);
      formik.setFieldValue("currency_code", actions.currency_code);
    }
  }, [actions]);

  const formik = useFormik({
    initialValues: {
      brand_name: "",
      capital_cost: "",
      commodity: "",
      consumption: "",
      description: "",
      effectiveness: "",
      id: 0,
      installation_cost: "",
      life: "",
      machine_code: "",
      maintanance: "",
      name: "",
      operation: "",
      peak_power_factor: "",
      power_consumption: "",
      machine_bed_area: "",
      reference: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.currency_id = String(values.currency_id);
      const { currency_code, ...apiPayload } = values; // remove currency_code from apiPayload  as it is not needed in the api
      if (type == "edit") {
        if (flagFile != null || flagFile != undefined) {
          MachineFlagS3Upload(
            [flagFile],
            values,
            setIsLoadingSave,
            setCounter,
            "",
            setFlagFile,
            actions?.id,
            "Edit",
            swalMsgalterHandlerAdd,
            onCloseModal
          );
        } else {
          setIsLoadingSave(true);
          API.put(`/api/db/machine/${actions?.id}/`, values, {}, 0)
            .then((res: any) => {
              setIsLoadingSave(false);
              setFlagFile(null);
              onCloseModal();
              swalMsgalterHandlerAdd(true, "edit", "");
              setCounter((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles.open();
              setErrorModalFileMsg(data[0]);
              setIsLoadingSave(false);
            });
        }
        // specific flag upload
        if (specflagFile != null || specflagFile != undefined) {
          MachineSpecificFlagS3Upload(
            [specflagFile],
            values,
            setIsSpecLoadingSave,
            setCounter,
            "",
            setSpecFlagFile,
            actions?.id,
            "Edit",
            swalMsgalterHandlerAdd,
            onCloseModal
          );
        } else {
          setIsSpecLoadingSave(true);
          API.put(`/api/db/machine/${actions?.id}/`, values, {}, 0)
            .then((res: any) => {
              setIsSpecLoadingSave(false);
              setSpecFlagFile(null);
              onCloseModal();
              swalMsgalterHandlerAdd(true, "edit", "");
              setCounter((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles.open();
              setErrorModalFileMsg(data[0]);
              setIsSpecLoadingSave(false);
            });
        }
      } else {
        if (flagFile != null || flagFile != undefined) {
          if (!databaseName?.includes("DB")) {
            MachineFlagS3UploadFetch(
              [flagFile],
              values,
              setIsLoadingSave,
              setCounter,
              formik,
              setFlagFile,
              null,
              "Create",
              swalMsgalterHandlerAdd,
              onCloseModal,
              typeId,
              revisionId
            );
          } else {
            MachineFlagS3Upload(
              [flagFile],
              values,
              setIsLoadingSave,
              setCounter,
              formik,
              setFlagFile,
              null,
              "Create",
              swalMsgalterHandlerAdd,
              onCloseModal
            );
          }
        } else {
          setIsLoadingSave(true);
          API.post(`/api/db/machine/`, values, {}, 0)
            .then((res: any) => {
              if (!databaseName?.includes("DB")) {
                API.post("/api/db/machine_cost/", {
                  commodity: typeId,

                  machines: [res.data.id],

                  revision: revisionId,
                })
                  .then((res: any) => {
                    setIsLoadingSave(false);
                    onCloseModal();
                    // swalMsgalterHandlerAdd(true, "create", "");
                    setFlagFile(null);
                    setCounter((prev: any) => prev + 1);
                    formik.resetForm();
                  })
                  .catch((err: any) => { });
              } else {
                setIsLoadingSave(false);
                onCloseModal();
                swalMsgalterHandlerAdd(true, "create", "");
                setFlagFile(null);
                setCounter((prev: any) => prev + 1);
                formik.resetForm();
              }
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles.open();
              setErrorModalFileMsg(data[0]);
              setIsLoadingSave(false);
            });
        }
        // Specific flag create
        if (specflagFile != null || specflagFile != undefined) {
          if (!databaseName?.includes("DB")) {
            MachineSpecificFlagS3UploadFetch(
              [specflagFile],
              values,
              setIsSpecLoadingSave,
              setCounter,
              formik,
              setSpecFlagFile,
              null,
              "Create",
              swalMsgalterHandlerAdd,
              onCloseModal,
              typeId,
              revisionId
            );
          } else {
            MachineSpecificFlagS3Upload(
              [specflagFile],
              values,
              setIsSpecLoadingSave,
              setCounter,
              formik,
              setSpecFlagFile,
              null,
              "Create",
              swalMsgalterHandlerAdd,
              onCloseModal
            );
          }
        } else {
          setIsSpecLoadingSave(true);
          API.post(`/api/db/machine/`, values, {}, 0)
            .then((res: any) => {
              if (!databaseName?.includes("DB")) {
                API.post("/api/db/machine_cost/", {
                  commodity: typeId,

                  machines: [res.data.id],

                  revision: revisionId,
                })
                  .then((res: any) => {
                    setIsSpecLoadingSave(false);
                    onCloseModal();
                    // swalMsgalterHandlerAdd(true, "create", "");
                    setSpecFlagFile(null);
                    setCounter((prev: any) => prev + 1);
                    formik.resetForm();
                  })
                  .catch((err: any) => { });
              } else {
                setIsSpecLoadingSave(false);
                onCloseModal();
                swalMsgalterHandlerAdd(true, "create", "");
                setSpecFlagFile(null);
                setCounter((prev: any) => prev + 1);
                formik.resetForm();
              }
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles.open();
              setErrorModalFileMsg(data[0]);
              setIsSpecLoadingSave(false);
            });
        }
      }
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database: true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});











  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        keepMounted
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {type == "edit" ? "Edit" : "Add"} Machine
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "0 2rem 1rem",
              textAlign: "center",
              maxHeight: "90vh",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    label={
                      <span>
                        Machine Name <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="name"
                    name="name"
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    //@ts-ignore
                    helperText={formik.touched.name && formik.errors.name}
                    onChange={formik.handleChange}
                    value={formik.values.name}
                    multiline
                  />
                  {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Machine code <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="machine_code"
                    name="machine_code"
                    onChange={formik.handleChange}
                    value={formik.values.machine_code}
                    multiline
                    error={
                      formik.touched.machine_code &&
                      Boolean(formik.errors.machine_code)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.machine_code && formik.errors.machine_code
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Brand Name"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="brand_name"
                    name="brand_name"
                    onChange={formik.handleChange}
                    value={formik.values.brand_name}
                    multiline
                    error={
                      formik.touched.brand_name &&
                      Boolean(formik.errors.brand_name)
                    }
                    helperText={
                      formik.touched.brand_name && formik.errors.brand_name
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Operation <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="operation"
                    name="operation"
                    onChange={formik.handleChange}
                    value={formik.values.operation}
                    multiline
                    error={
                      formik.touched.operation &&
                      Boolean(formik.errors.operation)
                    }
                    helperText={
                      formik.touched.operation && formik.errors.operation
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Description <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                  />
                </FormControl>

                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Commodity<sup style={{ color: "red" }}>*</sup>
                  </InputLabel>

                  <Select
                    id="commodity"
                    name="commodity"
                    disabled={type == "edit" ? true : false}
                    //   className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                        textAlign: "left",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.commodity &&
                      Boolean(formik.errors.commodity)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.commodity && formik.errors.commodity
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.commodity || ""}
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {CategoryList &&
                      CategoryList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.commodity &&
                      Boolean(formik.errors.commodity) && (
                        <p>{formik.errors.commodity}</p>
                      )}
                  </div>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Capital Cost (INR) <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="capital_cost"
                    name="capital_cost"
                    onChange={formik.handleChange}
                    value={formik.values.capital_cost}
                    error={
                      formik.touched.capital_cost &&
                      Boolean(formik.errors.capital_cost)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.capital_cost && formik.errors.capital_cost
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Installation Cost (INR){" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="installation_cost"
                    name="installation_cost"
                    onChange={formik.handleChange}
                    value={formik.values.installation_cost}
                    error={
                      formik.touched.installation_cost &&
                      Boolean(formik.errors.installation_cost)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.installation_cost &&
                      formik.errors.installation_cost
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Life (Years) <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="life"
                    name="life"
                    onChange={formik.handleChange}
                    value={formik.values.life}
                    error={formik.touched.life && Boolean(formik.errors.life)}
                    //   @ts-ignore
                    helperText={formik.touched.life && formik.errors.life}
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        maintenance (%/Year/Shift){" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="maintanance"
                    name="maintanance"
                    onChange={formik.handleChange}
                    value={formik.values.maintanance}
                    error={
                      formik.touched.maintanance &&
                      Boolean(formik.errors.maintanance)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.maintanance && formik.errors.maintanance
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    type="number"
                    label={
                      <span>
                        Effectiveness (%) <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="effectiveness"
                    name="effectiveness"
                    onChange={formik.handleChange}
                    value={formik.values.effectiveness}
                    error={
                      formik.touched.effectiveness &&
                      Boolean(formik.errors.effectiveness)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.effectiveness &&
                      formik.errors.effectiveness
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    type="number"
                    variant="standard"
                    label={
                      <span>
                        Average Power Consumption(kWh){" "}
                        <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}

                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="power_consumption"
                    name="power_consumption"
                    onChange={formik.handleChange}
                    value={formik.values.power_consumption}
                    error={
                      formik.touched.power_consumption &&
                      Boolean(formik.errors.power_consumption)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.power_consumption &&
                      formik.errors.power_consumption
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    type="number"
                    label={
                      <span>
                        Consumable Cost(%) <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="consumption"
                    name="consumption"
                    onChange={formik.handleChange}
                    value={formik.values.consumption}
                    error={
                      formik.touched.consumption &&
                      Boolean(formik.errors.consumption)
                    }
                    helperText={
                      formik.touched.consumption && formik.errors.consumption
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    type="number"
                    label={
                      <span>
                        Peak Power Factor <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="peak_power_factor"
                    name="peak_power_factor"
                    onChange={formik.handleChange}
                    value={formik.values.peak_power_factor}
                    error={
                      formik.touched.peak_power_factor &&
                      Boolean(formik.errors.peak_power_factor)
                    }
                    //   @ts-ignore
                    helperText={
                      formik.touched.peak_power_factor &&
                      formik.errors.peak_power_factor
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Reference <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="reference"
                    name="reference"
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                    multiline
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Machine Bed Area in m2
                        <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="machine_bed_area"
                    name="machine_bed_area"
                    onChange={formik.handleChange}
                    value={formik.values.machine_bed_area}
                    error={
                      formik.touched.machine_bed_area &&
                      Boolean(formik.errors.machine_bed_area)
                    }
                    helperText={
                      formik.touched.machine_bed_area &&
                      formik.errors.machine_bed_area
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <input
                    type="file"
                    // accept=".png, .jpg, .jpeg"
                    id="photo"
                    style={{ display: "none" }}
                    onChange={handleImg}
                    ref={inputElRef}
                  //  disabled={ type === "to_user" ? true : false }
                  />
                  <Button onClick={browseFiles}>
                    {flagFile == null && "Choose Cost Flag"}{" "}
                    {flagFile == null ? "" : flagFile?.name}
                  </Button>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <input
                    type="file"
                    // accept=".png, .jpg, .jpeg"
                    id="photo"
                    style={{ display: "none" }}
                    onChange={handleImgSpecific}
                    ref={specinputElRef}
                  //  disabled={ type === "to_user" ? true : false }  
                  />
                  <Button onClick={specbrowseFiles}>
                    {specflagFile == null && "Choose Specification Flag"}{" "}
                    {specflagFile == null ? "" : specflagFile?.name}
                  </Button>
                </FormControl>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);


                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));

                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                        inputProps: {
                          pattern: "^[^0-9]*$", // This regex pattern disallows any numerical input
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={(e) => {
                        formik.handleChange(e);
                        // if (!/[0-9]/.test(e.target.value)) {


                        // }  
                      }}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  variant="contained"
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1,
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  type="submit"
                  loading={isLoadingsave}
                >
                  {type == "edit" ? "Save" : "Add"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddMachineModal;
