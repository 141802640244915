import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import successIcon from "../../../Assets/images/success.png";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";

import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InfoIcon from "@mui/icons-material/Info";
import FlagIcon from "@mui/icons-material/Flag";

import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import AddMachineModal from "./AddMachineModal";
import UploadIcon from "@mui/icons-material/Upload";
import GetAppIcon from "@mui/icons-material/GetApp";
import Swal from "sweetalert2";
import MHRmoveModal from "./MHRmoveModal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import move from "../../../Assets/images/right move.svg";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { MHRDatabaseInfoModal } from "./MHRDatabaseInfoModal";
import openFolder from '../../../Assets/images/svgs/openfolderblue.svg';
import FolderIcon from '@mui/icons-material/Folder';
import { AUTH } from "../../../Redux/Services/auth.service";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


export interface IRawMaterialRevisionTableProps {
  setRecyclebinCounter: any;
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Machine Database CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{
      width: '100%',
      height: '2.3rem',
      justifyContent: 'flex-end',
      paddingRight: '1rem',
      marginTop: '-2.4rem',
    }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

export default function MachineDatabase(props: IRawMaterialRevisionTableProps) {
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();
  const { setRecyclebinCounter } = props;
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);
  // const userProfilePic = useSelector(userSelectors.selectAll);
  const [headerData, setHeaderData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);
  const [statusColor, setStatusColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const history = useHistory<any>();
  const machinemodal = useBaseModal();
  const [RowsItem, setRowsItem] = useState<any>();
  const Editmachinemodal = useBaseModal();
  const [Counter, setCounter] = useState<any>(0);
  const [tableLoader, settableLoder] = useState<any>(false);
  const infomodal = useBaseModal();
  const flagmodal = useBaseModal();
  const inputElRefPdf = useRef<any>();
  const MachineMove = useBaseModal();
  const [rowItems, setRowItems] = useState<any>({});
  const [useraction, setuseraction] = useState<any>(null)
  const { height, width } = useWindowDimensions();
  const [tabValue, setTabValue] = useState<any>("");
  const userProfilePic = useSelector(userSelectors.selectAll);
  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     if (newExpanded == true) {
  //       history.push(
  //         `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machineDB/${panel}/${status}/0`
  //       );
  //     }
  //   };

  const handleChange = (categoryName: any) => {
    // getMaterialData(categoryName);
    setTabValue(categoryName);
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machineDB/${categoryName}/${status}/0/${lockCounter}`);
  };

  const handleShareWith: any = (item: any) => {
    setRowItems(item);
    MachineMove.open();
    // toolMaterialShare.open(idGet,shareItemCodeGet,listCommodityGet,shareItemNameGet)
  };
  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };
  const InfoHandler: any = (item: any) => {
    setRowItems(item);
    infomodal.open();
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  React.useEffect(() => {
    setTabValue(categoryName);
  }, [categoryName]);


  const DeleteHandler = (item: any) => {
    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/machine/${item?.id}/`)
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            setRecyclebinCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => { });
      }
    });
  };

  React.useEffect(() => {
    settableLoder(true);

    API.get("/api/db/machine/", {
      commodity: categoryName,
    })
      .then((res: any) => {
        settableLoder(false);
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false);
        console.log("Sever EEror");
      });
  }, [categoryName, Counter]);

  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  const EditHandler = (row: any) => {
    Editmachinemodal.open();
    setRowsItem(row);
  };

  const handleDBChange = (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? item : false);
  }

  const LockHandler = (item: any, type: any) => {
    Swal.fire({
      title: item?.lock == true ? "Unlock" : "Lock",
      text: item?.lock == true == true
        ? "Are you sure you want to Unlock this parameter?"
        : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        // setLoader(true);
        API.put(`/api/db/machine/${item?.id}/`, {
          lock: type, revision: revisionId,
          category: categoryName
        }).then((res: any) => {
          setCounter((prev: any) => prev + 1);
        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  }


  React.useEffect(() => {
    if (databaseName == 'rmDB') {
      setExpanded('rmDB');
    } else if (databaseName == 'bopDB') {
      setExpanded('bopDB');
    } else if (databaseName == 'machineDB') {
      setExpanded('machineDB');
    } else if (databaseName == 'laborDB') {
      setExpanded('laborDB');
    } else if (databaseName == 'toolDB') {
      setExpanded('toolDB');
    } else if (databaseName == 'operationsDB') {
      setExpanded('operationsDB');
    } else if (databaseName == 'currencyDB') {
      setExpanded('currencyDB');
    }
  }, [databaseName]);

  const redirectDBHandler = (item: any) => {
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${item}/${0}/${status}/${search}/${lockCounter}`);
  }

  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: width > 1380 ? 200 : 160,
      renderCell: (cellvalues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              width: "100%",
            }}
          >
            {cellvalues?.row?.lock === true ? (
              // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
              (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                <LockIcon
                  sx={{ cursor: 'pointer', color: 'green' }}
                  onClick={() => LockHandler(cellvalues?.row, false)}
                />
              ) : (
                // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                <LockIcon sx={{ opacity: 0.5 }} />
              )
            ) : (
              // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
              (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                <LockOpenIcon
                  sx={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => LockHandler(cellvalues?.row, true)}
                />
              )
            )}

            {cellvalues?.row?.mapped_status ? (
              <IconButton
                title="Machine Info"
                sx={{ cursor: "pointer", color: "primary.main", padding: "0" }}
                onClick={() => InfoHandler(cellvalues?.row)}
              >
                {" "}
                <InfoIcon />
              </IconButton>
            ) : (
              useraction && useraction?.includes("D") && (
                <IconButton
                  title="Delete"
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                    padding: "0",
                    opacity: cellvalues?.row?.lock == true ? 0.3 : 1
                  }}
                  onClick={() => {
                    if (cellvalues?.row?.lock != true) { DeleteHandler(cellvalues?.row) }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              )
            )}
            {cellvalues.row.flag == null ? (
              <IconButton
                title="Machine Cost"
                sx={{ color: "primary.main", padding: "0" }}
              >
                <FlagOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                title="Machine Cost"
                sx={{ cursor: "pointer", color: "primary.main", padding: "0" }}
                onClick={() =>
                  {
                    API.get("/api/db/machine/",{
                      download_flag:true,
                      flag:true,
                      id:cellvalues?.row?.id,
                    },0).then((res:any)=>{
                      window.open(res.data, "_blank")
                    }).catch((err:any)=>{})
                  }
                }
              >
                <FlagIcon sx={{ color: "green" }} />
              </IconButton>
            )}
            {cellvalues.row.specification == null ? (
              <IconButton
                title="Machine Specification"
                sx={{ color: "primary.main", padding: "0" }}
              >
                <FlagOutlinedIcon />
              </IconButton>
            ) : (
              <IconButton
                title="Machine Specification"
                sx={{ cursor: "pointer", color: "primary.main", padding: "0" }}
                onClick={() =>
                  {
                    API.get("/api/db/machine/",{
                      download_flag:true,
                      specification:true,
                      id:cellvalues?.row?.id,
                    },0).then((res:any)=>{
                      window.open(res.data, "_blank")
                    }).catch((err:any)=>{})
                  }
                }
              >
                <FlagIcon sx={{ color: "green" }} />
              </IconButton>
            )}
            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Move"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                  opacity: cellvalues?.row?.lock == true ? 0.3 : 1
                }}
                onClick={() => {
                  if (cellvalues?.row?.lock != true) { handleShareWith(cellvalues?.row) }
                }}
              >
                <img
                  src={move}
                  style={{ height: "1.3rem", cursor: "pointer",  }}
                />
              </IconButton>
            )}
            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Edit"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                  opacity: cellvalues?.row?.lock == true ? 0.3 : 1
                }}
                onClick={() => {
                  if (cellvalues?.row?.lock != true) { EditHandler(cellvalues?.row) }
                }}
              >
                <EditIcon />
              </IconButton>
            )}
          </Box>
        );
      },
    },
    { field: "Ids", headerName: "Sl.No", width: 60 },
    {
      field: "machine_code",
      headerName: "Machine Code",
      width: 150,
    },
    {
      field: "name",
      headerName: "Machine Name",
      width: 150,
    },

    {
      field: "brand_name",
      headerName: "Brand Name",
      width: 150,
    },
    {
      field: "operation",
      headerName: "Operation",
      width: 150,
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 200 : 150,
    },
    {
      field: "machine_bed_area",
      headerName: "Machine Bed Area in m2",
      width: width > 1400 ? 200 : 150,
    },
    {
      field: "capital_cost",
      headerName: "Capital Cost",
      align: "right",
      width: 120,
    },
    {
      field: "installation_cost",
      headerName: "Installation Cost ",
      align: "right",
      width: 150,
    },

    {
      field: "life",
      headerName: "Life (Years)",
      width: width > 1400 ? 100 : 80,
      align: "right",
    },
    {
      field: "maintanance",
      headerName: "Maintanance (%/Year/Shift)",
      width: 150,
      align: "right",
    },

    {
      field: "consumption",
      headerName: "Consumble Cost (%)",
      align: "right",
      width: 150,
    },
    {
      field: "power_consumption",
      headerName: "Avg Pwr Cons(kWh)",
      width: 150,
      align: "right",
    },
    {
      field: "effectiveness",
      headerName: "Effectiveness (%)",
      width: 120,
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },
    {
      field: "peak_power_factor",
      headerName: "Peak Power Factor",
      width: 130,
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 80,
      renderCell: (cellvalue: any) => {
        var link = ["https", "http", "www"];
        var likData = String(cellvalue?.row?.reference);

        if (cellvalue?.row?.reference.includes("/")) {
          return (
            <>
              {isUrl(cellvalue?.row?.reference) ? (
                <Typography
                  sx={{
                    textDecoration: "1px solid",
                    cursor: "pointer",
                    borderBottom: "1px solid",
                  }}
                  onClick={() => window.open(cellvalue?.row?.reference)}
                >
                  Link
                </Typography>
              ) : (
                <Typography
                  sx={{
                    textDecoration: "1px solid",
                    cursor: "pointer",
                    // borderBottom: "1px solid",
                  }}
                // onClick={() => window.open(cellvalue?.row?.reference)}
                >
                  {cellvalue?.row?.reference}
                </Typography>
              )}
            </>
          );
        }
        return <>{cellvalue?.row?.reference}</>;
      },
    },
  ];

  const Rows: any = () =>
    TableData &&
    TableData?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));

  const AddmachineHanlder = (e: any) => {
    e?.stopPropagation();
    machinemodal.open();
  };

  React.useEffect(() => {
    setCommodityLoader(true);
    API.get("/api/db/cost_databases/", {
      database_type: "machine",
    })
      .then((res: any) => {
        setCommodityLoader(false);
        setCategoryList(res?.data);
        if (categoryName == 0) {
          setTabValue(res?.data[0]?.id);
          history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/machineDB/${res?.data[0]?.id}/${status}/0/${lockCounter}`);
        }
      })
      .catch((err: any) => {
        setCommodityLoader(false);
      });
  }, [revisionId, Counter]);

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_machine/", formData, {})
      .then((res: any) => {
        // setParentsCounter((prev: any) => prev + 1);
        setCounter((prev: any) => prev + 1);
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">"${res?.data}"</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        setReverseUpload(false);
      });
  };

  const DownLoadFile = (e: any) => {
    e?.stopPropagation();
    setLoader(true);
    API.get("/api/db/database/download/machine/")
      .then((res: any) => {
        window.open(res?.data, "_self", " noopener");
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Sever Error");
      });
  };

  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }
    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }
    }
  }, []);


  const swalalterHandler = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,
      });
    } else {
      Swal.fire({
        html: `<div>
      <p style="color:red;">Server Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);

  return (
    <Box
      sx={{ width: "100%", }}
    // height: { lg: "87vh", xl: "90vh" }
    // adl-scrollbar="true"
    // adl-scrollbar-width="0.3"
    >
      <input
        id="PDF Upload"
        accept=".csv,.xlsx"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        onClick={handleInputClick}
        ref={inputElRefPdf}
      />
      {machinemodal.isOpen && (
        <AddMachineModal
          swalalterHandlerAdd={swalalterHandlerAdd}
          setCounter={setCounter}
          onCloseModal={machinemodal.close}
          isOpen={machinemodal.isOpen}
        />
      )}

      {infomodal.isOpen && (
        <MHRDatabaseInfoModal
          onCloseModal={infomodal?.close}
          isOpen={infomodal?.isOpen}
          Item={rowItems}
        />
      )}

      {MachineMove.isOpen && (
        <MHRmoveModal
          type={"library"}
          isOpen={MachineMove.open}
          onCloseModal={MachineMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandler}
        />
      )}
      {Editmachinemodal.isOpen && (
        <AddMachineModal
          type={"edit"}
          swalalterHandlerAdd={swalalterHandlerAdd}
          actions={RowsItem}
          setCounter={setCounter}
          onCloseModal={Editmachinemodal.close}
          isOpen={Editmachinemodal.isOpen}
        />
      )}
      <Box sx={{ display: 'grid', gridTemplateColumns: '20rem 1fr', width: '100%' }}>
        <Box sx={{ width: '100%', height: { lg: '87vh', xl: '89vh' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'rmDB'} onClick={() => redirectDBHandler('rmDB')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
              expandIcon={
                expanded === 'rmDB' ? (
                  <img
                    src={openFolder}
                    style={{ height: '1.2rem' }}
                  />
                ) : (
                  <FolderIcon
                    sx={{
                      fontSize: '1.5rem',
                      color: 'primary.main',
                    }}
                  />
                )
              } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Raw Material</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'bopDB'} onClick={() => redirectDBHandler('bopDB')} >
            <AccordionSummary aria-controls="bopDB-content" id="bopDB-header" expandIcon={
              expanded === 'bopDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Bought-out Parts</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'machineDB'} onChange={handleDBChange('machineDB')}>
            <AccordionSummary aria-controls="machineDB-content" id="machineDB-header" expandIcon={
              expanded === 'machineDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Machine Hour Rates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  columnGap: "0.5rem",
                  width: "94%",
                  borderLeft: "1px solid",
                  marginLeft: '1rem',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.2"
              >{CommodityLoader ? (
                <Box sx={{ padding: "0 1rem", width: "100%" }}>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />

                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem" }}
                  />
                </Box>
              ) : (
                <>
                  {CategoryList &&
                    CategoryList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{
                              color: "primary.main",
                              // height: "2rem",
                              // minHeight: "2.1rem",
                              maxWidth: "100% !important",
                              padding: "0.1rem 0",
                              fontSize: "1rem",
                              width: "100%",
                              backgroundColor:
                                item?.id == tabValue
                                  ? "primary.main"
                                  : "",
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                            }}
                          ><Typography sx={{
                            whiteSpace: "nowrap", color:
                              item?.id == tabValue
                                ? "white"
                                : "primary.main",
                          }}>--</Typography>
                            <Typography
                              sx={{
                                color:
                                  item?.id == tabValue
                                    ? "white"
                                    : "primary.main",
                                // height: "2rem",
                                // minHeight: "2.1rem",
                                maxWidth: "100% !important",
                                padding: "0.1rem 0rem",
                                fontSize: "1rem",
                                width: "100%",
                                textAlign: "center",
                                display: "flex",
                                whiteSpace: "nowrap",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                textTransform: "capitalize",
                              }}
                              onClick={() => handleChange(item?.id)}
                            >
                              {item?.name.toLowerCase()} ({item?.count})
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                </>)}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'laborDB'} onClick={() => redirectDBHandler('laborDB')}>
            <AccordionSummary aria-controls="laborDB-content" id="laborDB-header" expandIcon={
              expanded === 'laborDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Labour Hour Rates</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'toolsDB'} onClick={() => redirectDBHandler('toolsDB')} >
            <AccordionSummary aria-controls="toolsDB-content" id="toolsDB-header" expandIcon={
              expanded === 'toolsDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Tools</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'operationsDB'} onClick={() => redirectDBHandler('operationsDB')} >
            <AccordionSummary aria-controls="operationsDB-content" id="operationsDB-header" expandIcon={
              expanded === 'operationsDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Operation Cost</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'currencyDB'} onClick={() => redirectDBHandler('currencyDB')}>
            <AccordionSummary aria-controls="currencyDB-content" id="currencyDB-header" expandIcon={
              expanded === 'currencyDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Currency</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          {CommodityLoader ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
          ) : (
            <>
              {/* <Box sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '0.5rem',
                width: '100%',
                borderBottom: '1px solid',
              }} adl-scrollbar="true"
                adl-scrollbar-width="0.2">
                {CategoryList &&
                  CategoryList?.map((item: any, index: any) => {
                    return (
                      <>
                        <Box
                          key={index}
                          sx={{
                            color: '#007fff',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0',
                            fontSize: '1rem',
                            width: '100%',
                            backgroundColor:
                              item?.id == tabValue
                                ? 'primary.main'
                                : 'primary.light',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                          }}>
                          <Typography
                            sx={{
                              color:
                                item?.id == tabValue ? 'white' : '#007fff',
                              height: '2rem',
                              minHeight: '2.1rem',
                              maxWidth: '100% !important',
                              padding: '0 0.5rem',
                              fontSize: '1rem',
                              width: '100%',
                              textAlign: 'center',
                              display: 'flex',
                              alignItems: 'center',
                              whiteSpace: 'nowrap',
                              justifyContent: 'center',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => handleChange(item?.id)}>
                            {item?.name.toLowerCase()} ({item?.count})
                          </Typography>
                        </Box>                        
                      </>
                    );
                  })}
              </Box> */}
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'flex-start',
                marginLeft: "0.5rem",
                columnGap: "0.5rem",
              }}>
                {(
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "0.5rem",
                      columnGap: "0.5rem",
                    }}
                  >
                    {(useraction && useraction
                      ?.includes("U") ||
                      useraction && useraction
                        ?.includes("C")) && (
                        <LoadingButton
                          loading={resverUpload}
                          title="Upload"
                          onClick={() => ReverseUpload()}
                          sx={{
                            zIndex: 1,
                            padding: '0.2rem 1rem',
                            cursor: "pointer",
                            fontSize: { lg: "1.8rem", xl: "1.6rem" },
                            minWidth: "1rem",
                            ".MuiCircularProgress-root": {
                              color: "primary.main",
                            },
                          }}
                          startIcon={
                            <UploadIcon sx={{ marginRight: "-1rem" }} />
                          }
                        />
                      )}
                    <LoadingButton
                      title="Download"
                      sx={{
                        zIndex: 1,
                        padding: '0.2rem 1rem',
                        color: statusColor ? "red" : "primary.main",
                        fontSize: { lg: "1.8rem", xl: "1.6rem" },
                        minWidth: "1rem",
                        ".MuiCircularProgress-root": {
                          color: "primary.main",
                        },
                      }}
                      loading={Loader}
                      onClick={(e: any) => DownLoadFile(e)}
                      startIcon={
                        <GetAppIcon sx={{ marginRight: "-1rem" }} />
                      }
                    />
                    {useraction && useraction
                      ?.includes("C") && (
                        <Button
                          size="small"
                          sx={{ width: "10rem", zIndex: 1, }}
                          onClick={(e: any) => AddmachineHanlder(e)}
                          startIcon={
                            <AddIcon sx={{ marginRight: "-0.5rem" }} />
                          }
                        >
                          ADD Machine
                        </Button>
                      )}
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  height: { lg: '83vh', xl: '85vh' },
                  width: "100%",
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                    textAlign: "center !important",
                  },
                }}
              >
                {Rows() && Rows() && (
                  <DataGrid
                    loading={tableLoader}
                    headerHeight={42}
                    rowHeight={36}
                    rows={Rows()}
                    columns={columns}
                    disableSelectionOnClick={true}
                    disableColumnSelector={true}
                    components={{
                      Toolbar: () => {
                        return CustomToolbar();
                      },
                    }}
                    sx={{
                      "& ::-webkit-scrollbar": {
                        width: "0.3rem",
                        height: "0.3rem",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        backgroundImage:
                          "linear-gradient(#09C6F9, #045DE9)",
                        borderRadius: "10px",
                      },
                      "& ::- webkit-scrollbar-thumb: hover ": {
                        backgroundColor: "#045DE9 !important",
                      },
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1f3 !important",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                  />
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
