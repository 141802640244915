import * as React from "react";
import Box from "@mui/material/Box";

import {
  Backdrop,
  Button,
  ButtonProps,
  Divider,
  Modal,
  Skeleton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridFooterContainer,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useHistory } from "react-router-dom";
import PermissionSelect from "./PermissionSelect";
import { GlobalShareScenarioModal } from "./GlobalShareScenarioModal";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { ADMIN } from "../../Redux/Services/admin.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  //   width: { lg: '50vw', xl: "40vw" },
  width: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

// const CustomExportButton = (props: ButtonProps) => (
//   <GridToolbarExportContainer {...props}>
//     <GridCsvExportMenuItem options={csvOptions} />
//   </GridToolbarExportContainer>
// );

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      {/* <CustomExportButton /> */}
    </span>
  </GridToolbarContainer>
);

interface IFeatureInfoProps {
  isOpen: any;
  onCloseModal: any;
  item?: any;
  shareScenario: any;
  getTableData: any;
  finalCostData: any;
  vault: any;
}

export const PermissionModal = (props: IFeatureInfoProps) => {
  const history = useHistory();
  const {
    isOpen,
    onCloseModal,
    item,
    shareScenario,
    getTableData,
    finalCostData,
    vault,
  } = props;
  const { projectId, type, categoryId,topVault } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [pageNumber, setPageNumber] = React.useState<any>(0);
  const rowsPerPage = 20;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };

  const createAction = (scenarioId:any,user:any,roles:any) => {
    API.post(
      "/cost/scenario_share/",
      {
        action: roles,
        users: user,
        scenario:scenarioId,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Permission added successfully");
      })
      .catch((err: any) => {});
  };

  const editAction = (id:any,value:any) => {
    API.put(
      `/cost/scenario_share/${id}/`,
      {
        action: value,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Permission updated successfully");
      })
      .catch((err: any) => {});
  };

  const CustomToolbarIncludingPagination = ({ count }: any) => (
    <GridToolbarContainer style={{ height: "2rem" }}>
      <div
        style={{
          position: "absolute",
          right: "0rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[rowsPerPage]}
          component="div"
          rowsPerPage={rowsPerPage}
          page={pageNumber}
          count={count}
          onPageChange={handleChangePage}
          // You can adjust other TablePagination props here
          sx={{ display: "flex", justifyContent: "flex-start" }}
        />
        <GridToolbarColumnsButton />
        <GridFooterContainer></GridFooterContainer>

        {/* <CustomExportButton /> */}
      </div>
    </GridToolbarContainer>
  );
  //   const getSelectedName = () => {
  //     switch (type) {
  //       case 0:
  //         return "Feature";
  //       case 1:
  //         return "Specification";
  //       case 2:
  //         return "Information";
  //       default:
  //         return "Feature";
  //     }
  //   };

  const redirectToCostingPage = (item: any) => {
    window.open(
      `/#/createcostingpart/${projectId}/${
        item.vault__top_vault__project__name
      }/${item.vault__top_vault__name}/${item.vault__top_vault}/${
        item?.vault__id
      }/${item?.vault__abbreviation ?? "none"}/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const getPermissionInfo = () => {
    setInfoData([]);
    setIsLoading(true);
    API.get("/cost/scenario_share/", {
      get_all_user_permissions: true,
      vault_id: vault,
    })
      .then((res: any) => {
        setIsLoading(false);
        // let rows: any = [];
        // const dataGridDataGenerator: any = res.data?.map(
        //   (ideaItem: any, index: any) => {
        //     let objShared: any = {
        //       id: index + 1,
        //       ...ideaItem,
        //     };
        //     rows.push(objShared);
        //   }
        // );
        setInfoData(res?.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
    // setInfoData([
    //   {
    //     id: 14946,
    //     users: "User 1",
    //     "scenario 1": {rid:"1",roles:[]},
    //     "scenario 2": {rid:"2",roles:["R"]},
    //     "scenario 3": {rid:"2",roles:["R"]},
    //     "scenario 4": {rid:"3",roles:["R","U"]},
    //     "scenario 5": {rid:"4",roles:["U"]},
    //   },
    // ]);
  };

  React.useEffect(() => {
    getPermissionInfo();
  }, []);

  //   var Columns: any = [
  //     // {
  //     //   field: "id", headerName: 'Name', hide: true,
  //     //   minWidth: 50,
  //     //   flex: 1,
  //     // },
  //     {
  //       field: "vault__top_vault__name",
  //       headerName: "Users",
  //       minWidth: 120,
  //       flex: 1,
  //     },
  //     {
  //       field: "vault__name",
  //       headerName: "Name",
  //       minWidth: 160,
  //       flex: 1,
  //       renderCell: (cellValues: any) => {
  //         return (
  //           <>
  //             <Tooltip
  //               sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
  //               title={cellValues?.row?.vault__name}>
  //               <p
  //                 style={{cursor: 'pointer' }}
  //                 >
  //                 {cellValues?.row?.vault__name}
  //               </p>
  //             </Tooltip>
  //           </>
  //         );
  //       },
  //     },
  //     {
  //       field: "vault__part_no",
  //       headerName: "Part No",
  //       minWidth: 160,
  //       flex: 1,
  //       renderCell: (cellValues: any) => {
  //         return (
  //           <>
  //             <Box
  //               onClick={()=>redirectToCostingPage(cellValues?.row)}
  //               sx={{ textDecoration:'underline', color: "primary.main" }}>
  //               <p
  //                 style={{cursor: 'pointer' }}
  //                 >
  //                 {cellValues?.row?.vault__part_no}
  //               </p>
  //             </Box>
  //           </>
  //         );
  //       },
  //     },
  //   ];

  const generateColumns = (data: any[]) => {
    if (!data.length) return [];

    
    

    // Extract keys from the first row to define columns
    return Object.keys(data[0])
      .filter((key) => key !== "id")
      .map((key) => ({
        field: key,
        headerName: key.replace(/_/g, " ").toUpperCase(),
        minWidth: 150,
        flex: 1,
        // renderHeader: (params: any) => {
        //   console.log(params, "permissiondata");
          
        //   return (
        //     <Tooltip title={params.colDef.headerName || ""}>
             
        //      <span>{params.colDef.headerName}</span>
        //     </Tooltip>
        //   )
        // },
        renderHeader: (params: any) => {
          console.log(params, "permissiondata");
          return (
            <Tooltip title={params?.colDef?.headerName || ""}>
              <span
                style={{
                  display: "inline-block",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: (params?.field === "users" || params?.field === "user_category") ? "inherit" :  "90%", // Ensures the header respects column width
                }}
              >
                {params?.colDef?.headerName}
              </span>
            </Tooltip>
          )
        },
        renderCell: (cellValues: any) => {
          
          //   if (key === "users") {
          //     // Example: Add tooltip for specific fields
          //     return (
          //       <Tooltip
          //         title={cellValues?.row?.[key] || ""}
          //         sx={{ backgroundColor: "primary.light", color: "primary.main" }}
          //       >
          //         <p style={{ cursor: "pointer" }}>{cellValues?.row?.[key]}</p>
          //       </Tooltip>
          //     );
          //   }
            if (key === "user_category") {
              // Example: Add tooltip for specific fields
              return (
                <Tooltip
                  title={cellValues?.row?.[key] || ""}
                  sx={{ backgroundColor: "primary.light", color: "primary.main" }}
                >
                  <p style={{ cursor: "pointer",
                    // color: cellValues?.row?.user_category == "External" ? "#FF0000" : "inherit"
                     }}>{cellValues?.row?.user_category}</p>
                </Tooltip>
              );
            }
          if (key !== "users") {
            // Example: Add clickable functionality for specific fields
            return (
              <Box
                // onClick={() => redirectToCostingPage(cellValues?.row)}
                sx={{
                  display: "flex",
                  //   textDecoration: "underline",
                  color: "primary.main",
                  width: "100%",
                }}
              >
                {/* {cellValues?.row?.[key]?.map((ele: any) => (
                  <p style={{ cursor: "pointer" }}>{ele}</p>
                ))} */}
                <PermissionSelect
                  createAction={createAction}
                  editAction={editAction}
                  user={cellValues?.row?.id}
                  values={cellValues?.row?.[key]}
                />
              </Box>
            );
          }
          // Default rendering for all other fields
          return <span style={{color: cellValues?.row?.user_category == "External" ? "#FF0000" : "inherit" }}>{cellValues?.row?.[key]}</span>;
        },
      }));
  };

  const Columns = generateColumns(infoData);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          {shareScenario.isOpen && (
            <GlobalShareScenarioModal
              isOpen={shareScenario.isOpen}
              onCloseModal={shareScenario.close}
              data={shareScenario.propsId}
              getTableData={getTableData}
              finalCostData={finalCostData?.scenarios}
              getPermissionInfo={getPermissionInfo}
            />
          )}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
              paddingBottom: 1,
            }}
          >
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Share Scenarios with Users
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider />
          <Box
            sx={{ height: "600px", width: "100%", overflowY: "auto" }}
            // adl-scrollbar="true"
            // adl-scrollbar-width="0.4"
          >
            {!isLoading && infoData ? (
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={
                  pageNumber * rowsPerPage > infoData?.length
                    ? infoData?.slice(
                        pageNumber * rowsPerPage,
                        (pageNumber + 1) * rowsPerPage
                      )
                    : infoData?.slice(pageNumber * rowsPerPage, infoData.length)
                }
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 1,
                          paddingY: infoData?.length > rowsPerPage ? 0 : 1,
                        }}
                      >
                        <Box>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => shareScenario.open()}
                            // disabled={getDisableStatus()}
                            //   onClick={() => permissionModalHandler()}
                            sx={{
                              padding: "0.4rem 0.7rem",
                              minWidth: "1.5rem",
                              height: "fit-content",
                              marginRight: 1,
                              lineHeight: 1,
                              "&:hover": {
                                // transform: "Scale(1.05)",
                                transition: "transform 0.5s ease",
                              },
                            }}
                          >
                            Add Permission
                          </Button>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "This action will remove all permissions for this product.", 
                                icon: "error",
                                showCancelButton: true,
                                confirmButtonColor: "#007FFF",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, remove it!",
                                customClass: {
                                  container: "swal2Container",
                                },
                              }).then((result) => {
                                onCloseModal();
                                // Swal.fire(
                                //   {
                                //     title: "Removed!",
                                //     text: "Permissions have been removed successfully.",
                                //     customClass: {
                                //       container: "swal2Container",
                                //     },

                                //   }
                                //   //   "Removed!",
                                //   //   "Permissions have been removed successfully.",
                                //   //   "success",
                                // );
                                if (result.isConfirmed) {

                                  API.delete("/cost/scenario_share/",{
                                    remove_all_permission:true,
                                    top_vault:topVault
                                  },0).then((res:any)=>{

                                    Swal.fire({
                                      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                                                    <br />
                                                    <p style="color:#007fff;"> Permissions have been removed successfully.
                                                    </p>   
                                                    </div>`,
                                      customClass: {
                                        container: "swal2Container",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        
                                      }
                                    });
                                  }).catch((err:any)=>{})
                                 
                                }

                                // if (result.isConfirmed) {
                                //   API.delete(
                                //     `/cost/scenario/${TableData?.id}/`,
                                //     {},
                                //     0
                                //   )
                                //     .then((res: any) => {
                                //       getTableData();
                                //       // getCompareGraphData();
                                //       // getTotalCompareGraphData();
                                //       Swal.fire(
                                //         "Deleted!",
                                //         "Scenario has been deleted.",
                                //         "success"
                                //       );
                                //     })
                                //     .catch((err: any) => {});
                                // }
                              });
                            }}
                            sx={{
                              padding: "0.4rem 0.7rem",
                              minWidth: "1.5rem",
                              height: "fit-content",
                              lineHeight: 1,
                              backgroundColor: "red",
                              "&:hover": {
                                // transform: "Scale(1.05)",
                                transition: "transform 0.5s ease",
                                backgroundColor: "#bd0404",
                              },
                            }}
                          >
                            Remove all Permissions
                          </Button>
                        </Box>

                        {infoData?.length > rowsPerPage ? (
                          <TablePagination
                            rowsPerPageOptions={[rowsPerPage]}
                            component="div"
                            rowsPerPage={rowsPerPage}
                            page={pageNumber}
                            count={infoData?.length}
                            onPageChange={handleChangePage}
                            // You can adjust other TablePagination props here
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          />
                        ) : (
                          <CustomToolbar />
                        )}
                      </Box>
                    );
                  },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                pageSize={rowsPerPage}
                disableSelectionOnClick
                pagination
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            ) : (
              <Box>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
