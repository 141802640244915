import * as React from 'react';
import { Box, IconButton, Popover, Skeleton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LaunchIcon from '@mui/icons-material/Launch';
import { useShareWithDb } from './useShareWithDb';
import ShareWithPopUp from './ShareWithPopUp';
import { useHistory } from 'react-router-dom';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import { useBomCategoryUse } from '../BOM Categories/useBomCategoryUse';
import ToolsRevisionUseList from './ToolsRevisionUseList';
import move from '../../Assets/images/right move.svg';
import ToolEditModal from './ToolEditModal';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../Redux/Reducers/user.reducer';
import Swal from 'sweetalert2';
import successIcon from "../../Assets/images/success.png"
import LockStatusInfoModal from './LockStatusInfoModal';
import CreateIcon from '@mui/icons-material/Create';
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import ToolsActionComponents from './ActionComponents/ToolsActionComponents';
import MenuIcon from '@mui/icons-material/Menu';


type Anchor = "top" | "left" | "bottom" | "right";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Database Tool Revision (Costing-DB) In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export interface IToolRevisionSearchProps {
  setRecyclebinCounter: any;
}

export function ToolRevisionSearch(props: IToolRevisionSearchProps) {
  const { setRecyclebinCounter } = props;
  const { tab, year,month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
  useRouteParams<any>();
  const [searchData, setSearchData] = useState<any>();
  const [searchDataDataLoader, setSearchDataLoader] = useState<boolean>(true);
  const [apiCallsearchData, setApiCallSearchData] = useState<boolean>(true);

  const listToolRevisionUse = useBomCategoryUse();
  const toolMaterialShare = useShareWithDb();
  const { width } = useWindowDimensions();
  const history = useHistory();
  const [useraction,setuseraction]=React.useState<any>(null)
  const [RowItem, setRowItem] = useState<any>(null)
  const [Counter,setCounter]=useState<any>(0)
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile = useSelector(userSelectors.selectAll);
  const EditModal = useBaseModal()
  const lockmodal = useBaseModal();

  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  useEffect(() => {
    setSearchDataLoader(true);
    API.get('/api/db/costDB_search/', { tools_revision: true, revision: revisionId, search_key: search }, 0).then((res: any) => {
      setSearchData(res?.data);
      setSearchDataLoader(false);
    })
  }, [categoryName, search, apiCallsearchData,Counter])

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  }

  const handleShareWith: any = (idGet: any, shareItemCodeGet: any, shareItemNameGet: any, shareItemCategoryGet: any) => {
    toolMaterialShare.open(idGet, shareItemCodeGet, shareItemNameGet, shareItemCategoryGet)
  }

  const handleListToolRevisionUse = (id: any) => {
    listToolRevisionUse.open(id)
  }
  const EditHandler = (row: any) => {
    setRowItem(row)
    EditModal.open()
  }
  const isUrl = (url:any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id:revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);
  const handleDelete = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Tool Code`,
          value: `deleteToolCode`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteToolCode":
          API.delete("/api/db/tool_cost/" + id + "/")
            .then((res: any) => {
              setRecyclebinCounter((prev: any) => prev + 1);
              setApiCallSearchData(!apiCallsearchData);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  const LockHandler = (item: any, type: any) => {
    if (type==false){
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {
        API.put(`/api/db/tool_cost/${item?.Ids}/`, {
          lock: type, revision: revisionId,
          commodity: categoryName,
  
        }).then((res: any) => {
          setSearchDataLoader(true);
      API.get('/api/db/costDB_search/', { tools_revision: true, revision: revisionId, search_key: search }, 0).then((res: any) => {
        setSearchData(res?.data);
        setSearchDataLoader(false);
      })
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    } if (type==true){
      API.put(`/api/db/tool_cost/${item?.Ids}/`, {
        lock: type, revision: revisionId,
        commodity: categoryName,

      }).then((res: any) => {
        setSearchDataLoader(true);
    API.get('/api/db/costDB_search/', { tools_revision: true, revision: revisionId, search_key: search }, 0).then((res: any) => {
      setSearchData(res?.data);
      setSearchDataLoader(false);
    })
      }).catch((err: any) => {
        console.log("Server error")
      })
    }

 


  }

  const swalalterHandler = React.useCallback(() => {

    Swal.fire({
      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;">Tools Revision Updated successfully!</p>   
     </div>`,
    });

  }, []);
  let columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 135 : 130,
      renderCell: (cellValues: any) => {
        return (<>
         <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
            <Box>
              {cellValues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                <LockIcon />
              </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                <LockOpenIcon />
              </IconButton>}
            </Box>
            <Box>
              {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) &&
                <IconButton title='Edit' sx={{ color: '#007fff', padding: '0rem' }} onClick={() => EditHandler(cellValues?.row)}>  <CreateIcon /> </IconButton>}
            </Box>
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellValues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: 'primary.main', }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              sx={{
                '.MuiPaper-root.MuiPopover-paper': {
                  boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                }
              }}
            >
              <ToolsActionComponents
                cellValues={cellValues.row}
                rowId={rowId}
                history={history}
                tab={tab}
                year={year}
                month={month}
                revisionId={revisionId}
                typeId={typeId}
                categoryName={categoryName}
                status={status}
                search={search}
                handleListToolRevisionUse={handleListToolRevisionUse}
                handleDelete={handleDelete}
                handleShareWith={handleShareWith}
                useraction={useraction}
                setAnchorEl={setAnchorEl}
              />
            </Popover>

          </Box>
          {/* {cellValues?.row?.mapped_status ? <IconButton title='Info' sx={{ color: '#007fff', padding: '0rem' }} onClick={() => handleListToolRevisionUse(cellValues?.row?.idGet)}> <InfoIcon /> </IconButton> :   (cellValues?.row?.lock_status==false && useraction && useraction?.includes("D")) &&<IconButton title='Delete' sx={{ color: '#007fff', padding: '0rem' }} onClick={(e: any) => handleDelete(e, cellValues?.row?.idGet)}> <DeleteIcon /> </IconButton>}
          {
            cellValues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
              <LockIcon />
            </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
              <LockOpenIcon />
            </IconButton>
          }
           { (cellValues?.row?.lock_status==false &&  useraction && useraction?.includes("U") )&&
            <IconButton title='Edit' sx={{ color: '#007fff', padding: '0rem' }} onClick={() => EditHandler(cellValues?.row)}>  <CreateIcon /> </IconButton>}
          <IconButton title='Redirect' sx={{ color: '#007fff', padding: '0rem' }} onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/toolsDB/0/${status}/0/0`)}> <LaunchIcon /> </IconButton>
          {cellValues?.row?.flag == null ? <IconButton sx={{ color: '#007fff', padding: '0rem', }} >  <FlagOutlinedIcon /> </IconButton> :
            <IconButton title='Flag' sx={{ color: '#007fff', padding: '0rem', cursor: 'pointer' }} onClick={() => cellValues?.row?.flag != null && window.open(cellValues?.row?.flag)}> <FlagIcon sx={{ color: 'green' }} /></IconButton>}
          {   (cellValues?.row?.lock_status==false && useraction && useraction?.includes("U")) &&<IconButton title='Move' sx={{ color: '#007fff', padding: '0rem', cursor: 'pointer' }} onClick={() => handleShareWith(cellValues?.row?.id, cellValues?.row?.toolCode, cellValues?.row?.toolName, cellValues?.row?.commodity)}> <img src={move} style={{ height: '1.3rem' }} /> </IconButton>} */}
        </>)
      }
    },
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "toolCode", headerName: "Tool Code", width: width > 1370 ? 120 : 100, },
    { field: "commodity", headerName: "Commodity", width: width > 1370 ? 120 : 100, },
    {
      field: "toolName",
      headerName: "Tool Name",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 100 : width < 900 ? 120 : 200,
      flex: 1,
    },
    {
      field: "specification",
      headerName: "Specification",
      width: width > 1370 ? 120 : 100,
      //   flex: 1,
    },
    {
      field: "category",
      headerName: "Category",
      minWidth: width > 1370 ? 120 : 80,
    },
    { field: "supplier", headerName: "Supplier", width: width > 1370 ? 100 : 80, },
    { field: "material", headerName: "Material", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "other", headerName: "Other", minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
    { field: "life", headerName: "Life (No. of Parts)", minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120, flex: 1 },
    {
			field: 'currency_code',
			headerName: 'Currency/Unit',
			sortable: false,
			minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
			renderCell: (cellValues: any) => {
				
				
				return (
					<Box sx={{display:'flex',alignItems:'center',justifyContent:'flex-end',columnGap:'1rem',}}> 
					{`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`} 
					 </Box>
				);
			},
		},
    { field: "toolCost", cellClassName: "super-app-theme--cell", headerName: "Tool Cost ", minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120, flex: 1 },
    { field: "toolCostPerPart", cellClassName: "super-app-theme--cell", headerName: "Tool Cost/Part (Curr/Part)", minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120, flex: 1 },
    {
      field: "reference", headerName: "Reference", minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120, renderCell: (cellValues: any) => {
        return (<>
          {isUrl(cellValues?.row?.reference) ? <span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                handleOpenLinkReference(cellValues?.row?.reference)
              }
            >
              Link
            </span>:
             <span
            //  style={{ textDecoration: "underline", cursor: "pointer" }}
            //  onClick={() =>
            //    handleOpenLinkReference(cellValues?.row?.reference)
            //  }
           >
             {cellValues?.row?.reference}
           </span>}
        </>)
      }
    },

  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = searchData && searchData?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.["tools"]?.id,
      Ids:ideaItem?.id,
      sno: index + 1,
      toolCode: ideaItem?.["tools"]?.code,
      toolName: ideaItem?.["tools"]?.name,
      specification: ideaItem?.["tools"]?.specification,
      category: ideaItem?.["tools"]?.category,
      supplier: ideaItem?.["tools"]?.supplier,
      material: ideaItem?.["tools"]?.material,
      other: ideaItem?.["tools"]?.other,
      life: ideaItem?.shots,
      toolCost: ideaItem?.price,
      toolCostPerPart: (ideaItem?.["tools"]?.price / (ideaItem?.["tools"]?.shots == 0 ? 1 : ideaItem?.["tools"]?.shots)).toFixed(2),
      reference: ideaItem?.details,
      flag: ideaItem?.["tools"]?.flag,
      commodity: ideaItem?.["tools"]?.commodity_name,
      idGet: ideaItem?.id,
      mapped_status: ideaItem?.mapped_status,
      lock_status
      : ideaItem?.lock_status,
      currency_id: ideaItem?.currency_id ? ideaItem?.currency_id : ideaItem?.currency,
      currency_code: ideaItem?.currency_code,
      unit: ideaItem?.unit,

    locked_on
      :
      ideaItem?.locked_on,
    locked_user
      :
      ideaItem?.locked_user,
    locked_user_email
      :
      ideaItem?.locked_user_email,
    locked_user_name
      :
      ideaItem?.locked_user_name,
    locked_user_phone
      :
      ideaItem?.locked_user_phone,
    };
    return objShared
  });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

 
  return (
    <div style={{ width: "100%", height: '90vh' }}>
      {listToolRevisionUse.isOpen &&
        <ToolsRevisionUseList
          isOpen={listToolRevisionUse.isOpen}
          onCloseModal={listToolRevisionUse.close}
          id={listToolRevisionUse.id}
        />}
          {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}
      {toolMaterialShare.isOpen &&
        <ShareWithPopUp
          isOpen={toolMaterialShare.open}
          onCloseModal={toolMaterialShare.close}
          idIncoming={toolMaterialShare.id}
          shareItemName={toolMaterialShare.shareItemName}
          shareItemCode={toolMaterialShare.shareItemCode}
          shareItemCategory={toolMaterialShare.shareItemCategory}
          apiCallAccordianInsideData={apiCallsearchData}
          setApiCallAccordianInsideData={setApiCallSearchData}
        />}
        {EditModal.isOpen &&
        <ToolEditModal
          isOpen={EditModal.isOpen}
          onCloseModal={EditModal.close}
          Item={RowItem}
          setCounter={setCounter}
          swalalterHandler={swalalterHandler}
        />
      }

      <Box sx={{  height: {lg:'78vh',xl:'83vh'}, width: "100%" }}>
        {!searchDataDataLoader ?
          searchData && searchData?.length > 0 ? <DataGrid
            headerHeight={48}
            rowHeight={36}
            {...dataStructureDataGrid}
            disableSelectionOnClick={true}
            components={{
              Toolbar: () => {
                return CustomToolbar();
              },
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: 'none',
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
              },
            }}
            density={"compact"}
            pageSize={100}
            rowsPerPageOptions={[100]}
          /> : <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <h2>No Search Result Found..</h2>
          </div> : <>
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
            <Skeleton width="100%" height="10%" />
          </>}
      </Box>
    </div>
  );
}
