import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import successIcon from "../../../Assets/images/success.png";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useRef, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";

import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InfoIcon from "@mui/icons-material/Info";

import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";

import UploadIcon from "@mui/icons-material/Upload";
import GetAppIcon from "@mui/icons-material/GetApp";
import Swal from "sweetalert2";
import swal from "sweetalert";

import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import AddMachineModal from "../MHRDataBase/AddMachineModal";
import MHRmoveModal from "../MHRDataBase/MHRmoveModal";
import AddLabourModal from "./AddLabourModal";
import LabourmoveModal from "./LabourmoveModal";
import move from "../../../Assets/images/right move.svg";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { LHRDatabaseInfoModal } from "./LHRDatabaseInfoModal";
export interface IRawMaterialRevisionTableProps {
  setRecyclebinCounter: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "Labour Hours Rates CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridToolbarColumnsButton />
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span
      style={{
        position: "absolute",
        right: "0rem",
        fontSize: "1rem",
        paddingRight: "1rem",
      }}
    >
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function SearchLHRLibraryPage(
  props: IRawMaterialRevisionTableProps
) {
  const {
    revisionId,
    typeId,
    databaseName,
    categoryName,
    status,
    search,
    lockCounter,
  } = useRouteParams<any>();
  const { setRecyclebinCounter } = props;
  const infomodal = useBaseModal();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);

  const [TableData, setTableData] = useState<any>([]);

  const [statusColor, setStatusColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const history = useHistory<any>();
  const labourModal = useBaseModal();
  const [RowsItem, setRowsItem] = useState<any>();
  const Editmachinemodal = useBaseModal();
  const [Counter, setCounter] = useState<any>(0);
  const [tableLoader, settableLoder] = useState<any>(false);
  const [reverseLoader, setreverseLoader] = useState<any>(false);

  const inputElRefPdf = useRef<any>();
  const LabourMove = useBaseModal();
  const [rowItems, setRowItems] = useState<any>({});
  const { height, width } = useWindowDimensions();
  const [useraction, setuseraction] = useState<any>(null);

  const handleShareWith: any = (item: any) => {
    setRowItems(item);
    LabourMove.open();
    // toolMaterialShare.open(idGet,shareItemCodeGet,listCommodityGet,shareItemNameGet)
  };
  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };

  const DeleteHandler = (item: any) => {
    swal({
      text: "Are you sure, you want to delete?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/labour/${item?.id}/`)
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            setRecyclebinCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {});
      }
    });
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing",
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action);
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, []);
  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }
    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }
    }
  }, []);

  React.useEffect(() => {
    settableLoder(true);

    API.get("/api/db/costDB_search/", {
      labour: true,

      search_key: search,
    })
      .then((res: any) => {
        settableLoder(false);
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false);
        console.log("Sever EEror");
      });
  }, [categoryName, Counter, search]);

  const EditHandler = (row: any, e: any) => {
    Editmachinemodal.open();
    setRowsItem(row);
  };
  const InfoHandler: any = (item: any) => {
    setRowItems(item);
    infomodal.open();
  };
  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: width > 1400 ? 200 : 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues?.row?.mapped_status ? (
              <IconButton
                sx={{ padding: "0", color: "primary.main" }}
                title="Info"
                onClick={() => InfoHandler(cellvalues?.row)}
              >
                <InfoIcon style={{ cursor: "pointer" }} />
              </IconButton>
            ) : (
              useraction &&
              useraction?.includes("D") && (
                <IconButton
                  title="Delete"
                  onClick={() => DeleteHandler(cellvalues?.row)}
                >
                  <DeleteIcon style={{ cursor: "pointer" }} />
                </IconButton>
              )
            )}
            {cellvalues.row.flag !== null ? (
              <IconButton
                title="Flag"
                sx={{ padding: "0", color: "primary.main", cursor: "pointer" }}
              >
                <FlagIcon
                  onClick={() =>
                   {
                    API.get("/api/db/labour/",{
                      download_flag:true,
                      id:cellvalues?.row?.id
                    },0).then((res:any)=>{
                      window.open(res.data, "_blank")
                    }).catch((err:any)=>{})
                   }
                  }
                  style={{
                    cursor:
                      cellvalues.row.flag == null ? "not-allowed" : "pointer",
                    color: "green",
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                title="Flag"
                sx={{ padding: "0", color: "primary.main" }}
              >
                <FlagOutlinedIcon />
              </IconButton>
            )}
            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Move"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                }}
                onClick={() => handleShareWith(cellvalues?.row)}
              >
                <img
                  src={move}
                  style={{ height: "1.3rem", cursor: "pointer" }}
                />
              </IconButton>
            )}
            {useraction && useraction?.includes("U") && (
              <IconButton
                title="Edit"
                sx={{
                  color: "primary.main",
                  padding: "0rem",
                  cursor: "pointer",
                }}
              >
                <EditIcon
                  style={{ cursor: "pointer" }}
                  onClick={(e: any) => EditHandler(cellvalues?.row, e)}
                />
              </IconButton>
            )}
          </>
        );
      },
    },
    { field: "Ids", headerName: "Sl.No", width: 90 },

    {
      field: "labour_code",
      headerName: "Labour Codeee",
      width: 150,
    },

    {
      field: "labour_type",
      headerName: "Labour Type",
      width: 150,
    },
    {
      field: "currency_code",
      headerName: "Currency/Unit",
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
            }}
          >
            {`${
              cellValues?.row?.currency_code
                ? cellValues?.row?.currency_code
                : "--"
            }/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 800 : 650,
    },
  ];

  const Rows: any = () =>
    TableData &&
    TableData?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_labour_newui/", formData, {})
      .then((res: any) => {
        // setParentsCounter((prev: any) => prev + 1);
        setCounter((prev: any) => prev + 1);
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">${res?.data}</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        setReverseUpload(false);

        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:red;">Server Error</p>   
           </div>`,
        });
      });
  };

  const swalalterHandler = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,
      });
    } else {
      Swal.fire({
        html: `<div>
  
    
  
      <p style="color:red;">Server Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {((useraction && useraction?.includes("U")) ||
        (useraction && useraction?.includes("C"))) && (
        <input
          id="PDF Upload"
          accept=".csv,.xlsx"
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => UploadFile(e)}
          onClick={handleInputClick}
          ref={inputElRefPdf}
        />
      )}
      {labourModal.isOpen && (
        <AddLabourModal
          swalalterHandlerAdd={swalalterHandlerAdd}
          setCounter={setCounter}
          onCloseModal={labourModal.close}
          isOpen={labourModal.isOpen}
        />
      )}
      {infomodal.isOpen && (
        <LHRDatabaseInfoModal
          onCloseModal={infomodal?.close}
          isOpen={infomodal?.isOpen}
          Item={rowItems}
        />
      )}
      {LabourMove.isOpen && (
        <LabourmoveModal
          type={"library"}
          isOpen={LabourMove.open}
          onCloseModal={LabourMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandler}
        />
      )}
      {Editmachinemodal.isOpen && (
        <AddLabourModal
          type={"edit"}
          swalalterHandlerAdd={swalalterHandlerAdd}
          actions={RowsItem}
          setCounter={setCounter}
          onCloseModal={Editmachinemodal.close}
          isOpen={Editmachinemodal.isOpen}
        />
      )}

      {CommodityLoader ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          <>
            <Box
              sx={{
                height: { lg: "88vh", xl: "90vh" },
                width: "100%",
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                  textAlign: "center !important",
                },
              }}
            >
              {Rows() && Rows() && (
                <DataGrid
                  loading={tableLoader}
                  headerHeight={48}
                  rowHeight={40}
                  rows={Rows()}
                  columns={columns}
                  disableSelectionOnClick={true}
                  disableColumnSelector={true}
                  density="compact"
                  components={{
                    Toolbar: () => {
                      return CustomToolbar();
                    },
                  }}
                  sx={{
                    "& ::-webkit-scrollbar": {
                      width: "0.3rem",
                      height: "0.3rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                    "&.MuiDataGrid-root": {
                      border: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                />
              )}
            </Box>
          </>
        </>
      )}
    </Box>
  );
}
