import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";

import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import InfoIcon from '@mui/icons-material/Info';
import Flag from "../../../Assets/images/flag.png"
import FlagIcon from '@mui/icons-material/Flag';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import LaunchIcon from '@mui/icons-material/Launch';
import AddMachineListModal from "../MHRDataBase/FetchMachineListModal";
import MachineFlagModal from "../MHRDataBase/MachineFlagModal";
import EditLHRModal from "./EditLHRModal";
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import successIcon from "../../../Assets/images/success.png"
import FetchLabourListModal from "./FetchLabourListModal";
import LabourmoveModal from "./LabourmoveModal";
import LabourGraph from "./LabourGraph";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import move from "../../../Assets/images/right move.svg";
import { LHRRevisionInfoModal } from "./LHRRevisionInfoModal";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import LockStatusInfoModal from "../LockStatusInfoModal";
import LockIcon from '@mui/icons-material/Lock';
import AddLabourModal from "./AddLabourModal";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Add } from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Popover from '@mui/material/Popover';
import LabourHourRateActionComponents from "../ActionComponents/LabourHourRateActionComponents";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import FormulalistModal from "../../../ComponentUI/CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/FormulalistModal";



export interface IRawMaterialRevisionTableProps {

  setRecyclebinCounter: any;
  getSubRevisionData: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const csvOptions: GridCsvExportOptions = {
  fileName: "Labour Hours Rates CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridToolbarColumnsButton />
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '100%', xl: '100%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.6rem',
  }}>
    <span>
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function LabourHourRateTable(props: IRawMaterialRevisionTableProps) {

  const { setRecyclebinCounter, getSubRevisionData } = props
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();
    const formulamodal:any = useBaseParamsModal();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false)

  const [headerData, setHeaderData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);

  const [HourValue, setHourValue] = useState<any>();
  const [dayValues, setdayValues] = useState<any>();
  const labourAddFetchModal = useBaseModal();

  const [statusColor, setStatusColor] = useState<any>(false)
  const [Loader, setLoader] = useState<any>(false)
  const history = useHistory<any>()
  const machinemodal = useBaseModal()
  const [Counter, setCounter] = useState<any>(0)
  const [CounterEdit, setCounterEdit] = useState<any>(0)
  const [tableLoader, settableLoder] = useState<any>(false)
  const [RowItem, setRowItem] = useState<any>()
  const [SingleData, setSingleData] = useState<any>([])
  const [rowItems, setRowItems] = useState<any>({})
  const [headerCounter,setHeaderCounter] = useState<any>(0)
  const LabourMove = useBaseModal();
  const Labourgraph = useBaseModal()
  const Editmodal = useBaseModal()
  const { height, width } = useWindowDimensions();
  const infomodal = useBaseModal()
  const [useraction, setuseraction] = useState<any>(null)
  const [tabValue, setTabVale] = useState<any>(categoryName);
  const lockmodal = useBaseModal()
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile = useSelector(userSelectors.selectAll);
  const [selectedTab, setSelectedTab] = React.useState('');


  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  // const AccordianhandleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     if (newExpanded == true) {
  //       history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/labor/${panel}/${status}/0`)
  //     }
  //   };

  const AccordianhandleChange = (categoryName: any) => {
    // getMaterialData(categoryName);
    setTabVale(categoryName);
    setSelectedTab(categoryName)
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/labor/${categoryName}/${status}/0/${lockCounter}`)
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId, lockCounter]);
  const handleShareWith: any = (item: any) => {

    setRowItems(item)
    LabourMove.open()

  }

  const InfoHandler: any = (item: any) => {
    setRowItems(item)
    infomodal.open()
  }
  const GraphHandler: any = (item: any) => {

    setRowItems(item)
    Labourgraph.open()

  }

  React.useEffect(() => {
    settableLoder(true)
    API.get("/api/db/labour_cost/", {
      revision: revisionId,
      commodity: categoryName,
    })
      .then((res: any) => {
        settableLoder(false)
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false)
        console.log("Sever EEror");
      });


  }, [categoryName, Counter, CounterEdit, lockCounter])

  React.useEffect(() => {
    if (categoryName !== '0') {
      setSelectedTab(categoryName);
      // getCategoryList();
    }
  }, [categoryName, lockCounter]);


  React.useEffect(() => {
    if (headerData && headerData?.length > 0) {
      setHourValue(headerData && headerData[0]?.hours_per_day);
      setdayValues(headerData && headerData[0]?.days_per_year);


    }
  }, [headerData, lockCounter]);

  const redirectionHandler = (item: any) => {

    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/laborDB/${categoryName}/true/0/${lockCounter}`);

  }
  const LockHandler = (item: any, type: any) => {

    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {
        API.put(`/api/db/labour_cost/${item?.id}/`, {
          lock: type, revision: revisionId,
          commodity: categoryName,

        }).then((res: any) => {
          settableLoder(true)
          API.get("/api/db/labour_cost/", {
            revision: revisionId,
            commodity: categoryName,
          })
            .then((res: any) => {
              settableLoder(false)
              setTableData(res?.data);
            })
            .catch((err: any) => {
              settableLoder(false)
              console.log("Sever EEror");
            });
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    }
    if (type == true) {
      API.put(`/api/db/labour_cost/${item?.id}/`, {
        lock: type, revision: revisionId,
        commodity: categoryName,

      }).then((res: any) => {
        settableLoder(true)
        API.get("/api/db/labour_cost/", {
          revision: revisionId,
          commodity: categoryName,
        })
          .then((res: any) => {
            settableLoder(false)
            setTableData(res?.data);
          })
          .catch((err: any) => {
            settableLoder(false)
            console.log("Sever EEror");
          });
      }).catch((err: any) => {
        console.log("Server error")
      })
    }



  }


  //for Initail load
  // React.useEffect(() => {
  // 	if (CategoryList && CategoryList.length > 0) {
  // 	  // Set the selected tab to the first item in categoryList when the component loads
  // 	  setSelectedTab(CategoryList[0].id);
  // 	  history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${CategoryList[0].id}/${status}/${search}`);
  // 	//   getMaterialData(categoryList[0].category);
  // 	}
  //   }, [CategoryList]);

  const DeleteHandler = (item: any) => {
    setAnchorEl(null);
    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/labour_cost/${item?.id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1)
          setRecyclebinCounter((prev: any) => prev + 1)

        }).catch((err: any) => {

        })
      }
    });
  }

  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {

      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }

    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }

    }
  }, []);

 

  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: width > 1400 ? 130 : 120,
      renderCell: (cellvalues: any) => {
        return (
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
            <Box>
              {cellvalues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellvalues?.row, false)}>
                <LockIcon />
              </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellvalues?.row, true)}>
                <LockOpenIcon />
              </IconButton>}
            </Box>
            <Box>
              {(cellvalues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <IconButton
                title="Edit"
                sx={{ color: "#007fff", padding: "0rem", cursor: "pointer" }}
                onClick={(e: any) => EditHandler(cellvalues?.row, e)} >
                <EditIcon /></IconButton>}
            </Box>
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellvalues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: 'primary.main', }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              elevation={1}
            >
              <LabourHourRateActionComponents
                cellValues={cellvalues.row}
                rowId={rowId}
                InfoHandler={InfoHandler}
                DeleteHandler={DeleteHandler}
                GraphHandler={GraphHandler}
                redirectionHandler={redirectionHandler}
                handleShareWith={handleShareWith}
                useraction={useraction}
                setAnchorEl={setAnchorEl}
              />
            </Popover>
          </Box>
        )
      }
    },
    { field: "Ids", headerName: "Sl.No", width: 60 },
    {
      field: "labour_code",
      headerName: "Labour Code",
      width: 150,
    },
    {
      field: "labour_type",
      headerName: "Labour Type",
      width: width > 1400 ? 120 : 100,
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 110 : 150,
      renderCell: (cellValues: any) => {

        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 250 : 150,
    },
    {
      field: "wage_per_day",
      headerName: "Min.wage(Curr/D)",
      width: width > 1400 ? 130 : 110,
      align: 'right',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography sx={{ fontSize: '1rem' }}>
              {cellvalue?.row?.wage_per_day?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },
    {
      field: "dearness_allowance",
      headerName: "D.A (Curr/Month) ",
      width: width > 1400 ? 120 : 100,
      align: 'right',
    },

    {
      field: "perks",
      headerName: "Perks (%) ",
      width: width > 1400 ? 90 : 80,
      align: 'right',
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 100,
      renderCell: (cellvalue: any) => {
        if (cellvalue?.row?.reference !== null) {
          if (cellvalue?.row?.reference.includes("/")) {
            return (
              <>
                <Typography
                  sx={{ fontSize: '1rem', textDecoration: "underline", cursor: "pointer", }}
                  onClick={() => window.open(cellvalue?.row?.reference)}>
                  Link
                </Typography>
              </>
            );
          }
        }
        else {
          return <>{cellvalue?.row?.reference}</>;
        }
      },
    },
    {
      field: "wage_per_month",
      headerName: "Min.wage(Curr/M)",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 140 : 110,
      align: 'right',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography title={`Min.wage(Curr/M) : ${cellvalue?.row?.wage_per_month}`} sx={{ fontSize: '1rem' }}>
              {cellvalue?.row?.wage_per_month?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },

    {
      field: "cost_per_hour",
      headerName: width > 1400 ? "LHR" : "LHR",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 120 : 90,
      align: 'right',
      headerAlign: 'center',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography title={`Min.wage(Curr/M) : ${cellvalue?.row?.cost_per_hour}`} sx={{ fontSize: '1rem', textDecoration:"underline",cursor:"pointer" }} onClick={()=> {
                          formulamodal.open({
                            revision_id: revisionId,
                            labour_id:cellvalue?.row?.rowId,
                            lhr_details:true
                          })

                          
                          
                        }}>
              {cellvalue?.row?.cost_per_hour?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },
  ];
  const Rows: any = () => {


    return (

      TableData &&
      TableData?.map((item: any, index: any) => {
        return {
          Ids: index + 1,
          id: item?.id,
          labour_code: item?.labour?.labour_code,
          labour_type: item?.labour?.labour_type,
          description: item?.labour?.description,
          status: item?.mapped_status,
          wage_per_day: item?.wage_per_day,
          perks: item?.perks,
          wage_per_month: item?.wage_per_month,
          rowId: item?.labour?.id,
          reference: item?.reference,
          flag: item?.labour?.flag,
          cost_per_hour: item?.cost_per_hour,
          dearness_allowance: item?.dearness_allowance,
          lock_status: item?.lock_status,
          locked_on: item?.locked_on,
          currency_id: item?.currency_id,
          currency_code: item?.currency_code,
          unit: item?.unit,
          locked_user: item?.locked_user,
          locked_user_email: item?.locked_user_email,
          locked_user_name: item?.locked_user_name,
          locked_user_phone: item?.locked_user_phone,

        };
      })
    );
  };

  const swalalterHandler = useCallback(() => {

    Swal.fire({
      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;">Labour Revision Updated successfully!</p>   
     </div>`,
    });

  }, []);
  const swalalterHandlerMove = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br/>
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,

      });
    } else {
      Swal.fire({
        html: `<div>
      <p style="color:red;">Server Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);




  const SaveHandler = () => {

    setLoader(true)
    API.put(`/api/db/labour_detail/${headerData[0]?.id}/`, {

      hours_per_day: Number(HourValue),
      days_per_year: Number(dayValues),


    }, 0).then((res: any) => {
      setStatusColor(false)
      getSubRevisionData()
      setHourValue(res && res?.hours_per_day);
      setdayValues(res && res?.days_per_year);
      setHeaderCounter((prev:any)=>prev+1)

      setLoader(false)
    }).catch((err: any) => {
      setStatusColor(false)
      setLoader(false)
    })
  };

  const AddLabourHanlder = (e: any, Ids?: any) => {

    e?.stopPropagation();
    e?.preventDefault();

    machinemodal.open()
  }

  const AddAndFetchLabourHandler = (e: any) => {
    e?.stopPropagation();
    labourAddFetchModal.open()
  }

  const handlechange = (e: any, type: any) => {

    setStatusColor(true)
    if (type == "hours") {

      setHourValue(e?.target?.value);
    }

    if (type == "days") {
      setdayValues(e?.target?.value);
    }



  };

  const EditHandler = (item: any, e: any) => {
    e.stopPropagation()
    setRowItem(item)
    Editmodal.open()
  }
  React.useEffect(() => {
    API.get("/api/db/labour_detail/", {
      revision: revisionId,
    }).then((res: any) => {
      setHeaderData(res?.data);
    });
  }, [revisionId, lockCounter,headerCounter]);


  const LockLabour = (item: any, type: any) => {

    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {
        API.put(`/api/db/labour_detail/${item?.id}/`, {
          lock: type, revision: revisionId,


        }).then((res: any) => {

          API.get("/api/db/labour_detail/", {
            revision: revisionId,

          })
            .then((res: any) => {

              setHeaderData(res?.data);
            })
            .catch((err: any) => {

              console.log("Sever EEror");
            });
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }

    }

    if (type == true) {
      API.put(`/api/db/labour_detail/${item?.id}/`, {
        lock: type, revision: revisionId,


      }).then((res: any) => {

        API.get("/api/db/labour_detail/", {
          revision: revisionId,

        })
          .then((res: any) => {

            setHeaderData(res?.data);
          })
          .catch((err: any) => {

            console.log("Sever EEror");
          });
      }).catch((err: any) => {
        console.log("Server error")
      })
    }


  }



  React.useEffect(() => {
    setCommodityLoader(true)
    API.get("/api/db/revision_database/", {
      revision_id: revisionId,
      category_type: "labourdetail",
    }).then((res: any) => {
      setCommodityLoader(false)
      setCategoryList(res?.data);
      if (categoryName == 0) {
        setTabVale(res?.data[0]?.id);
        setSelectedTab(res?.data[0]?.id)
        history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/labor/${res?.data[0]?.id}/${status}/0/${lockCounter}`);
      }
      setSingleData(res?.data[0])

    }).catch((err: any) => {
      setCommodityLoader(false)
    })
  }, [revisionId, Counter, lockCounter]);

  return (
    <Box sx={{ width: "100%", }}>
       {formulamodal.isOpen && (
    <FormulalistModal revisionId={formulamodal.propsId?.revision_id} isOpen={formulamodal.isOpen} onClose={()=> formulamodal.close()} machine_id={formulamodal.propsId?.machine_id} mhr_details={formulamodal.propsId?.mhr_details} labour_id={formulamodal.propsId?.labour_id} lhr_details={formulamodal.propsId?.lhr_details} />
  )}
      {machinemodal.isOpen &&
        <FetchLabourListModal
          expanded={SingleData?.id}
          onCloseModal={machinemodal.close}
          isOpen={machinemodal.isOpen}
          setCounter={setCounter}


        />
      }
      {infomodal.isOpen &&
        <LHRRevisionInfoModal
          onCloseModal={infomodal.close}
          isOpen={infomodal.isOpen}
          Item={rowItems}

        />

      }
      {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}
      {
        Labourgraph.isOpen &&
        <LabourGraph
          isOpen={Labourgraph.open}
          onCloseModal={Labourgraph.close}
          rowItems={rowItems}

        />
      }
      {
        labourAddFetchModal.isOpen &&
        <AddLabourModal
          isOpen={labourAddFetchModal.isOpen}
          onCloseModal={labourAddFetchModal.close}
          setCounter={setCounter}
          swalalterHandlerAdd={swalalterHandlerAdd}
        />
      }
      {LabourMove.isOpen && (
        <LabourmoveModal
          isOpen={LabourMove.open}
          onCloseModal={LabourMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandlerMove}
        />
      )}
      {Editmodal.isOpen &&
        <EditLHRModal
          swalalterHandler={swalalterHandler}
          rowItems={RowItem}
          onCloseModal={Editmodal.close}
          isOpen={Editmodal.isOpen}
          setCounter={setCounterEdit}

        />
      }


      {CommodityLoader ?
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
        :
        <>
          <Box sx={{ width: "100%", }}>
            <Table>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>
                  <TextField
                    variant="filled"
                    size="small"
                    label="Hours/Shift"
                    disabled={headerData[0]?.lock_status}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={HourValue}
                    onChange={(e: any) => handlechange(e, "hours")}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>
                  <TextField
                    variant="filled"
                    size="small"
                    label="Working Days/Year"
                    disabled={headerData[0]?.lock_status}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    // type="number"
                    value={dayValues}
                    onChange={(e: any) => handlechange(e, "days")}
                  />

                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>
                  <TextField
                    variant="filled"
                    size="small"
                    label="Working Hours/Year"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      width: '100%',
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      ".MuiInputBase-root.MuiFilledInput-root": {
                        backgroundColor: 'primary.light',
                        fontSize: '1rem !important',
                      },
                      ".MuiInputBase-root.MuiFilledInput-root.Mui-disabled": {
                        backgroundColor: 'rgba(0, 0, 0, 0.12)',
                        color: 'rgba(0, 0, 0, 0.38)',
                      },
                      '& .MuiFilledInput-underline:before': { borderBottomColor: 'primary.main' },
                      '& .MuiFilledInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiFormLabel-root.MuiInputLabel-root.Mui-disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                      },
                    }}
                    disabled={true}
                    // type="number"
                    value={headerData && headerData[0]?.yearly_workhours}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    width: '3rem',
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                  }}>
                  <Box sx={{ display: "flex" }}>
                    {
                      headerData[0]?.lock_status ? <IconButton title="lock" sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockLabour(headerData[0], false)}>
                        <LockIcon />
                      </IconButton> : <IconButton title="Lock" sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockLabour(headerData[0], true)}>
                        <LockOpenIcon />
                      </IconButton>
                    }

                    {headerData[0]?.lock_status == false && <>
                      {(useraction && useraction?.includes("U") || useraction && useraction?.includes("C")) && <LoadingButton sx={{ minWidth: '2rem', color: statusColor ? "#d32f2f" : "primary.main" }} loading={Loader} onClick={() => SaveHandler()}
                        startIcon={<SaveIcon sx={{ marginRight: '-1rem' }} />}
                      />}
                    </>}
                  </Box>
                </TableCell>
              </TableRow>
            </Table>
          </Box>
          <Box>
            {/* <Box
						  sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}} adl-scrollbar="true"
            adl-scrollbar-width="0.2">
            {CategoryList &&
              CategoryList?.map((item: any, index: any) => {
                return (
                  <>
                   <Box
										key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
													item?.id == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
														item?.id == selectedTab ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
                          whiteSpace: 'nowrap',
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => AccordianhandleChange(item?.id)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>                    
                    </>
                  );
                })}
            </Box> */}
            <Box sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: 'flex-start',
              margin: "0.3rem",
              columnGap: "0.5rem",
            }}>
              {useraction && useraction?.includes("C") &&
                <>
                  <Box sx={{ width: '12rem' }}>
                    <Button size="small" sx={{
                      padding: '0.2rem 1rem', zIndex: 1, '&:hover': {
                        backgroundColor: "#007fff", color: "#fff",
                      }
                    }} onClick={(e: any) => AddLabourHanlder(e, tabValue)} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>Fetch Labour</Button>

                  </Box>
                  <Box sx={{ width: '18rem' }}>
                    <Button size="small" sx={{
                      padding: '0.2rem 1rem', zIndex: 1, '&:hover': {
                        backgroundColor: "#007fff", color: "#fff",
                      }
                    }} onClick={(e: any) => AddAndFetchLabourHandler(e)} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>Add & Fetch Labour</Button>

                  </Box>
                </>}
            </Box>
            <Box sx={{
              height: { lg: '75vh', xl: '80vh' },
              width: "100%", "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
            }}>
              {Rows() && Rows() && (
                <DataGrid
                  loading={tableLoader}
                  headerHeight={48}
                  rowHeight={36}
                  rows={Rows()}
                  columns={columns}
                  components={{
                    Toolbar: () => {
                      return CustomToolbar();
                    },
                  }}
                  disableSelectionOnClick={true}
                  disableColumnSelector={true}
                  sx={{
                    "& ::-webkit-scrollbar": {
                      width: "0.3rem",
                      height: "0.3rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      // background: $thumb-color;
                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      // width: "4px",
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                    "&.MuiDataGrid-root": {
                      border: "none",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                />
              )}
            </Box>
            {CategoryList?.length == 0 && useraction && useraction?.includes("C") &&
              <Box sx={{
                textAlign: 'center', display: "flex", flexDirection: "row",
                justifyContent: "end"
              }}>
                <Button size="small" onClick={(e: any) => AddLabourHanlder(e)} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>Fetch Labour</Button>
              </Box>}
          </Box>
        </>}
    </Box>
  );
}
