import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { ScenarioColors } from "./ScenarioColors.constants";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import PartCountDetailsModal from "./PartCountDetailsModal";
import { AddAssumptionScenarioDropdown } from "./AddAssumptionScenarioDropdown";
import ScenarioCloseAlertModal from "./ScenarioCloseAlertModal";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";

export interface IAddScenarioModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setScenarioCount?: any;
  getTableData?: any;
  setAddCount?: any;
  hasScenarios?:any
  title?: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "86vw", sm: "35vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

export function AddScenarioModal(props: IAddScenarioModalProps) {
  const {
    isOpen,
    onCloseModal,
    setCount,
    setScenarioCount,
    getTableData,
    setAddCount,
    hasScenarios,
    title
  } = props;
  const [selectedScenario, setSelectedScenario] = React.useState<any>({});
  const [loader, setLoader] = React.useState<any>(false);
  const [colorList, setColorList] = React.useState<any>([]);
  const [tableData, setTableData] = React.useState<any>([]);
  const [filteredTableData, setFilteredTableData] = React.useState<any>([]);
  const [assumptionsList, setAssumptionsList] = React.useState<any>([]);
  const [selectedAssumption, setSelectedAssumption] = React.useState<any>({});
  const [selectedColor, setSelectedColor] = React.useState<any>("");
  const [dbLink, setDBLink] = React.useState<any>(true);

  const [age, setAge] = React.useState("");
  const [assumptionTableData, setAssumptionTableData] = React.useState<any>([]);
  const [errMsg, setErrMsg] = React.useState<any>(null);
  const [savedScenarioId, setSavedScenarioId] = React.useState<any>("");
  const [assumptionTableDataLoading, setAssumptionTableDataLoading] =
    React.useState<any>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = React.useState<boolean>(true);
  const [isShowTable, setIsShowTable] = React.useState<boolean>(false);
  const [scenarioName, setScenarioName] = React.useState<any>("");
  const [assumptionsCardCount, setAssumptionCardCount] =
    React.useState<any>(null);
  const [loadingCardCount, setLoadingCardCount] = React.useState<boolean>(true);
  const [scenariodescription, setScenarioDescription] = React.useState<any>("");

  //   const handleChange = (event: SelectChangeEvent) => {
  //     setAge(event.target.value as string);
  //   };

  const [assumptionLoader, setAssumptionLoader] = React.useState<any>();
  const [selectedOption, setSelectedOption] = React.useState<number>(1); // Initialize state with defaultValue

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedOption(Number(event.target.value)); // Update state with selected value
  };

  function generateLightColor(): string {
    // Generate random RGB values in the range of 128–255 to ensure light colors
    const r = Math.floor(128 + Math.random() * 128);
    const g = Math.floor(128 + Math.random() * 128);
    const b = Math.floor(128 + Math.random() * 128);
  
    // Convert RGB values to a hexadecimal color string
    const hex = `#${r.toString(16).padStart(2, "0")}${g.toString(16).padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;
  
    return hex;
  }
  const { projectId, projectName, topVault, vault, scenarioId, treeabbr } =
    useRouteParams<any>();
  const partCountDetails = useBaseParamsModal();
  const totalAssumptionCardCounts = () => {
    setLoadingCardCount(true);
    API.get(
      "cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: 2,
      },
      0
    )
      .then((res: any) => {
        setAssumptionCardCount(res?.data?.length);
        setLoadingCardCount(false);
      })
      .catch((err: any) => {
        setLoadingCardCount(false);
      });
    // https://api-stage2.xcpep.com/cost/assumption_card/?top_vault=49&assumption_type=2
  };

  const handleChange = (event: SelectChangeEvent, itemMain?: any) => {
    const { name, value } = event.target;
    setErrMsg(null);
    if (name === "scenario") {
      setSelectedColor(generateLightColor());
      setSelectedScenario(value);
    } else if (name === "assumption") {
      setSelectedAssumption(value);
      if (itemMain?.id) {
        API.put(
          `/cost/scenario_assumption/${itemMain?.id}/`,
          {
            target_assumption: value,
          },
          {},
          0
        )
          .then((res: any) => {
            getAssumptionDataTableWol(savedScenarioId);
          })
          .catch((err: any) => {});
      } else {
        API.post(
          "/cost/scenario_assumption/",
          {
            target_assumption: value,
            base_assumption: itemMain?.base_assumption,
            cost_scenario: savedScenarioId,
          },
          {},
          0
        )
          .then((res: any) => {
            getAssumptionDataTableWol(savedScenarioId);
          })
          .catch((err: any) => {});
      }
    } else if (name === "scenarioname") {
      setScenarioName(value);
    } else if (name === "dblink") {
      setDBLink((prev: any) => !dbLink);
    }  else if (name === "scenariodescription") {
      setScenarioDescription(value);
    }
     else if (name === "withoutdblink") {
      setDBLink((prev: any) => !dbLink);
    }
  };

  const ScenarioCloseAlert = useBaseParamsModal();
  const ScenarioCloseModalHandler = () => {
    if (isShowTable && savedScenarioId) {
      ScenarioCloseAlert.open(savedScenarioId);
    } else {
      onCloseModal();
    }
  };

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getColor = () => {
    API.get(
      "/cost/scenario_assumption/",
      {
        get_scenario_color: true,
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setColorList(res?.data);
      })
      .catch((err: any) => {});
  };

  const getDropDownOptions = () => {
    setLoader(true);
    API.get("/cost/scenario_table/", { ...getObj() }, 0)
      .then((res: any) => {
        setTableData(res?.data);
        const filteredList = res?.data?.filter((item:any) => {
          
          
          return item?.scenario_list?.includes(item?.id)
        });
        setFilteredTableData(filteredList);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };



  const getAssumptionsDropdownList = () => {
    setAssumptionLoader(true);
    API.get("/cost/assumption_card/", {
      top_vault: topVault,
      assumption_type: 2,
    })
      .then((res: any) => {
        setAssumptionsList(res?.data);
        setAssumptionLoader(false);
      })
      .catch((err: any) => {
        setAssumptionLoader(false);
      });
  };

  const getAssumptionDataTableWol = (iddd: any) => {
    // setAssumptionTableDataLoading(true);
    API.get("/cost/scenario_assumption/", {
      ...getObj(),
      selected_costing:
        selectedScenario?.scenario_creation === "Main"
          ? 0
          : selectedScenario?.id,
      scenario: iddd,
    })
      .then((res: any) => {
        setAssumptionTableData(res?.data);
        // setAssumptionTableDataLoading(false);
      })
      .catch((err: any) => {
        console.log("Sever Error");
        // setAssumptionTableDataLoading(false);
      });
  };
  const getAssumptionDataTable = (iddd: any) => {
    setAssumptionTableDataLoading(true);
    API.get("/cost/scenario_assumption/", {
      ...getObj(),
      selected_costing:
        selectedScenario?.scenario_creation === "Main"
          ? 0
          : selectedScenario?.id,
      scenario: iddd,
    })
      .then((res: any) => {
        setAssumptionTableData(res?.data);
        setAssumptionTableDataLoading(false);
      })
      .catch((err: any) => {
        console.log("Sever Error");
        setAssumptionTableDataLoading(false);
      });
  };

  //   React.useEffect(() => {
  //     if (Object.keys(selectedScenario).length > 0) {
  //       getAssumptionDataTable();
  //     }
  //   }, [selectedScenario]);

  React.useEffect(() => {
    getDropDownOptions();
    getAssumptionsDropdownList();
    getColor();
    totalAssumptionCardCounts();
  }, []);
  //   const [disableStatus,setDisableStatus]=React.useState<boolean>(true);
  const getLoadingStatus = () => {
    let status = assumptionTableData.every((object: any) => {
      return (
        object.hasOwnProperty("target_assumption") &&
        object.target_assumption !== null
      );
    });
    return status;
  };

  const checkIfInputsFilled = () => {
    const isFilled =
      selectedScenario &&
      Object.keys(selectedScenario).length > 0 &&
      selectedColor.trim() !== "" &&
      scenarioName.trim() !== "" &&
      scenariodescription.trim() !== "";
    setIsSubmitDisabled(!isFilled);
  };

  // useEffect to watch for changes in the input states
  React.useEffect(() => {
    checkIfInputsFilled();
  }, [selectedScenario, scenarioName,scenariodescription]);



// const dropdownList = tableData?.length > 1 ? filteredTableData : tableData;



  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        {loadingCardCount ? (
          <Box sx={style}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Add Scenario [ {title} ]<br />
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={ScenarioCloseModalHandler}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            
           
            <Box sx={{ padding: "1rem" }}>
              <Skeleton
                variant="rounded"
                sx={{ height: "2rem", width: "100%", marginBottom: "0.5rem" }}
              />
              <Skeleton
                variant="rounded"
                sx={{ height: "2rem", width: "100%", marginBottom: "0.5rem" }}
              />
              <Skeleton
                variant="rounded"
                sx={{ height: "2rem", width: "100%", marginBottom: "0.5rem" }}
              />
            </Box>
          </Box>
        ) : assumptionsCardCount && assumptionsCardCount > 0 ? (
          <Box sx={style}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Add Scenario [ {title} ] <br />
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={ScenarioCloseModalHandler}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
    <Box sx={{ padding: "1rem" }}>
              <FormControl>
                <RadioGroup
                  row={false}
                  aria-labelledby="demo-form-control-label-placement"
                  name="position"
                  defaultValue={1}
                  onChange={handleChangeType}
          
                >
                  <FormControlLabel
                    value={1}
                    control={<Radio />}
                    label="Linked ( Select only if you have access to edit RM, MHR, and LHR values. )"
                    // labelPlacement="bottom"
                  />
                  <FormControlLabel
                    value={2}
                    control={<Radio />}
                    label="Delinked ( Select to quickly create a scenario )"
                  />
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ padding: "0 1rem 1rem" }}>
              <Box sx={{ marginBottom: "2rem" }}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    sx={{ color: "primary.main" }}
                    id="demo-simple-select-label"
                  >
                    Selected Base Scenario <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    disabled={isShowTable}
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="scenario"
                    value={selectedScenario}
                    onChange={(ed: any) => handleChange(ed, {})}
                    sx={{
                      width: "100%",
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                          paddingLeft: "0.5rem",
                          paddingBottom: "0rem",
                          paddingTop: "0.2rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {(!loader && tableData) ? (
                      tableData?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "1rem" }}
                            key={index}
                            value={item}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                  </Select>
                </FormControl>
                <Typography
                  sx={{ fontSize: "1rem", width: "100%", textAlign: "right" }}
                >
                  {selectedScenario?.cost}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  columnGap: "1rem",
                  //   justifyContent: "",
                  //   height: "2.5rem",
                }}
              >
                <FormControl variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    sx={{ color: "#007fff" }}
                    id="demo-simple-select-label"
                  >
                    Enter Scenario Name <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <TextField
                    //   label="Enter Scenario Name"
                    // defaultValue="Enter Scenario Name"
                    //   placeholder="Enter Scenario Name"
                    variant="standard"
                    name="scenarioname"
                    disabled={isShowTable}
                    fullWidth
                    size="small"
                    value={scenarioName}
                    onChange={(ed: any) => handleChange(ed, {})}
                    sx={{
                      marginTop: "1rem",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    error={errMsg}
                    helperText={errMsg}
                    inputProps={{
                      maxLength: 10, // Restrict input to a maximum of 10 characters
                    }}
                  />
                </FormControl>
                {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              > */}
                <FormControl fullWidth variant="standard">
                <InputLabel
                    shrink
                    sx={{ color: "#007fff" }}
                    id="demo-simple-select-label"
                  >
                    Enter Scenario Description <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      marginTop: "1rem",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    
                    id="scenariodescription"
                   
                    name="scenariodescription"
                    value={scenariodescription}
                    onChange={(ed: any) => handleChange(ed, {})}
                    multiline
                    
                  />
                </FormControl>
              {/* </Box> */}

                {/* <FormControl variant="standard" fullWidth>
                  <InputLabel
                    shrink
                    sx={{ color: "#007fff" }}
                    id="demo-simple-select-label"
                  >
                    Select Scenario Color <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    size="small"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="color"
                    disabled={isShowTable}
                    value={selectedColor}
                    label="Color"
                    onChange={(ed: any) => handleChange(ed, {})}
                    sx={{
                      width: "100%",
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                          paddingLeft: "0.5rem",
                          paddingBottom: "0rem",
                          paddingTop: "0.2rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                  >
                    {colorList?.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          value={item}
                          sx={{ padding: "0.1rem 0.5rem" }}
                          key={index}
                        >
                          <Box
                            sx={{
                              width: "100%",
                              height: "1rem",
                              backgroundColor: item,
                            }}
                          ></Box>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </Box>

              {/* <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  columnGap: "1rem",
                  //   justifyContent: "",
                  //   height: "2.5rem",
                  marginTop: "1rem",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!dbLink}
                      name="withoutdblink"
                      disabled={isShowTable}
                      onChange={(ed: any) => handleChange(ed, {})}
                    />
                  }
                  label="Without BOM & Database Link"
                  sx={{
                    "& .MuiFormControlLabel-label": { fontSize: "0.9rem" }, // Adjust font size here
                  }}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={dbLink}
                      name="dblink"
                      disabled={isShowTable}
                      onChange={(ed: any) => handleChange(ed, {})}
                    />
                  }
                  label="With BOM & Database Link"
                  sx={{
                    "& .MuiFormControlLabel-label": { fontSize: "0.9rem" }, // Adjust font size here
                  }}
                />
              </Box> */}

              <Box sx={{ width: "100%", textAlign: "right" }}>
                {/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
                <LoadingButton
                  size="small"
                  // loading={isLoadingsave}

                  variant="contained"
                  disabled={isShowTable || isSubmitDisabled}
                  type="submit"
                  sx={{ marginTop: "1rem" }}
                  onClick={() => {
                    API.post(
                      "cost/scenario_assumption/",
                      {
                        create_new_scenario: true,
                        ...getObj(),
                        name: scenarioName,
                        color: selectedColor,
                        description: scenariodescription,
                        without_bom_db_link: selectedOption === 1?true:false,
                        scenario:
                          selectedScenario?.scenario_creation === "Main"
                            ? 0
                            : selectedScenario?.id,
                      },
                      {},
                      0
                    )
                      .then((res: any) => {
                        if(selectedOption === 1){
                        if (dbLink) {
                          setIsShowTable(true);
                          setSavedScenarioId(res?.data?.id);
                          getAssumptionsDropdownList();
                          getAssumptionDataTable(res.data?.id);
                        } else {
                          onCloseModal();
                          setScenarioCount((prev: any) => prev + 1);
                          setAddCount((prev: any) => prev + 1);
                        }
                      }else{
                        getAssumptionsDropdownList();
                        setCount((prev: any) => prev + 1);
                        setScenarioCount((prev: any) => prev + 1);
                        setAddCount((prev: any) => prev + 1);
                        getTableData();
                        Swal.fire({
                          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
      
                          <br />
                    
                          <p style="color:#007fff;">Your scenario cost data is being created in the background. You will be notified once it is ready.!</p>   
                    
                           </div>`,
                          // icon: "success",
                          // title: "Success!",
                          // text: "Your scenario cost data will be made available as soon as it's created in the background.",
                          showConfirmButton: true,
                        });
                        onCloseModal();
                      }
                      })
                      .catch((err: any) => {
                        setErrMsg(err?.response?.data[0]);
                      });
                  }}
                >
                  {(dbLink === true && selectedOption === 1) ? "Next" : "Add Scenario"}
                </LoadingButton>
              </Box>

              {(isShowTable &&selectedOption !== 2 ) && (
                <>
                  <Box
                    sx={{ maxHeight: "40vh", marginTop: "3rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Table>
                      <TableHead sx={{ backgroundColor: "primary.light" }}>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Used assumption card
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Parts
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            New assumption card
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {!assumptionTableDataLoading && assumptionTableData ? (
                          assumptionTableData?.map(
                            (itemMain: any, index: any) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {itemMain?.base_assumption_name}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {itemMain?.base_assumption_location}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      if (itemMain?.part_count > 0) {
                                        partCountDetails.open({
                                          id: itemMain?.base_assumption,
                                          scenario: savedScenarioId,
                                        });
                                      }
                                    }}
                                  >
                                    {itemMain?.part_count}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <AddAssumptionScenarioDropdown
                                      assumptionLoader={assumptionLoader}
                                      assumptionsList={assumptionsList}
                                      handleChange={handleChange}
                                      itemMain={itemMain}
                                      selectedAssumption={selectedAssumption}
                                      setSelectedAssumption={
                                        setSelectedAssumption
                                      }
                                      key={index}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          )
                        ) : (
                          <TableRow>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell>
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box sx={{ width: "100%", textAlign: "right" }}>
                    {/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
                    <LoadingButton
                      size="small"
                      variant="contained"
                      disabled={!getLoadingStatus() || false}
                      type="submit"
                      sx={{ marginTop: "1rem" }}
                      onClick={() => {
                        API.post(
                          "cost/scenario_assumption/",
                          {
                            create_scenario: true,
                            scenario_id: savedScenarioId,
                          },
                          {},
                          0
                        )
                          .then((res: any) => {
                            setIsShowTable(true);
                            setSavedScenarioId(res?.data?.id);
                            getAssumptionsDropdownList();
                            setCount((prev: any) => prev + 1);
                            setScenarioCount((prev: any) => prev + 1);
                            setAddCount((prev: any) => prev + 1);
                            getTableData();
                            Swal.fire({
                              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          
                              <br />
                        
                              <p style="color:#007fff;">Your scenario cost data is being created in the background. You will be notified once it is ready.!</p>   
                        
                               </div>`,
                              // icon: "success",
                              // title: "Success!",
                              // text: "Your scenario cost data will be made available as soon as it's created in the background.",
                              showConfirmButton: true,
                            });
                            onCloseModal();
                          })
                          .catch((err: any) => {});
                      }}
                    >
                      Proceed
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                <br />
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
         
            {!loadingCardCount && (
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  color: "red",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                  marginBottom: "2rem",
                }}
              >
                Create Scenario Assumption card first{" "}
                <sup style={{ color: "red" }}>*</sup>
              </Typography>
            )}
          </Box>
        )}
      </Modal>
      {partCountDetails.isOpen && (
        <PartCountDetailsModal
          isOpen={partCountDetails.isOpen}
          onCloseModal={partCountDetails.close}
          assumption={partCountDetails.propsId}
        />
      )}

      {ScenarioCloseAlert.isOpen && (
        <ScenarioCloseAlertModal
          isOpen={ScenarioCloseAlert.isOpen}
          onCloseModal={ScenarioCloseAlert.close}
          parentCloseModal={onCloseModal}
          scenarioIdd={ScenarioCloseAlert.propsId}
          setCount={setCount}
        />
      )}
    </div>
  );
}
