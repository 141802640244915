// // react
// import React, { useMemo } from 'react';

import {
    Box,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
  } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import CancelIcon from "@mui/icons-material/Cancel";
  
  
  
  // ----------------------------------------------------------------------------------
  
  interface SelectedDatabaseRecordInfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    data:any
  }
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "30vw",
    maxWidth: "80vw",
    // height: '80vh',
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  
  };
  
  const WorkFlowOtherInfoModal: React.FC<
    SelectedDatabaseRecordInfoModalProps
  > = (props) => {
    const { isOpen, onClose,data } = props;
  
   console.log("KJHKHKJHKJ",data)
   
  
    return (
      <Modal open={isOpen} onClose={onClose}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
                Other Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ maxHeight: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
          
              <Box>
                <Table>
                  <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sticky",
                      top: 0,
                      zIndex: 10,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          width: "15rem",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Value
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                        {
                           data &&  Object.keys(data)?.map((key,index)=>{
                                return <TableRow>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                 {key}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {data[key]}
                                </TableCell>
                              </TableRow>
                            })
                        }
                  </TableBody>
                </Table>
              </Box>
          </Box>
        </Box>
      </Modal>
    );
  };
  
  // ----------------------------------------------------------------------------------
  
  export default WorkFlowOtherInfoModal;
  