import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import styles from "./CreateCostingPart.module.scss";
import { CostEstimation } from "./CostEstimation";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InfoIcon from "@mui/icons-material/Info";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../../Assets/images/loading-screen.gif";
import RViewerJS from "viewerjs-react";
import { AssumptionModal } from "../../../ViewModule/CostingView/AssumptionModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { BOMDrawerTable } from "./BOMDrawerTable";
import { CostigngCalculatorSection } from "./CostigngCalculatorSection";
import Draggable from "react-draggable";
import { ID } from "../../../utlis/commonInterfaces";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import Swal from "sweetalert2";
import { useEffect } from "react";
import OtherAssumptionModal from "./OtherAssumptionModal";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { UserContext } from "../../../Configuration/BOM/BomConfigurationLanding";
import { CreateCalculatorWithCategoryModal } from "./CreateCalculatorWithCategoryModal";

export interface ICreateCostingPartProps {
  setCostData: any;
  count?: any;
  Cost_status?: any;
  getHierarchyData?: any;
  treeCount?: any;
  handleChangeCostingStatus?: any;
  ValidationLoader?:any;
}

type Anchor = "top" | "left" | "bottom" | "right";


export const AssumptionIdContext = React.createContext<ID>(0);
export const OtherAssumptionIdContext = React.createContext<any>([]);
export const AssumptionIdSelectedContext = React.createContext<any>([]);

export function CreateCostingPart(props: ICreateCostingPartProps) {
  const { setCostData, count, Cost_status, getHierarchyData, treeCount, handleChangeCostingStatus,ValidationLoader } =
    props;
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    condition,
    calctype,
    calcultorId,
    tableCounter,
    scenarioId,
    newUI
  } = useRouteParams<any>();
  const assumptionModal = useBaseModal();
  const openCategoryCosting = useBaseModal();
  const history = useHistory();
  const [costDetailsData, setCostDetailsData] = React.useState<any>({});
  const [stepLoader, setStepLoader] = React.useState<any>(null);
  const [costDetailsDataLoading, setCostDetailsDataLoading] =
    React.useState<any>(false);
  const [assumptionList, setAssumptionList] = React.useState<any>([]);
  const [selectedAssumption, setSelectedAssumption] = React.useState<any>("");
  const [commodityList, setCommodityList] = React.useState<any>([]);
  const [commodityListLoader, setCommodityListLoader] =
    React.useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>();
  const [unvalidatedCategory, setUnvalidatedCategory] = React.useState<any>();
  const [oldSelectedCommodity, setOldSelectedCommodity] = React.useState<any>(
    []
  );
  const [categoryLoading, setCategoryLoading] = React.useState<any>(false);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [Tabstatus, setTabstatus] = React.useState<any>("Parameters");
  const [passingObj, setPassingObj] = React.useState<any>({});
  const [updateCount, setUpdateCount] = React.useState<any>(1);
  const [SelectedmulIds, setSelectedmulIds] = React.useState<any>([]);
  const [Selectedmulassumption, setselectedmulassumption] = React.useState<any>(
    []
  );
  const [commodityPermission, setCommodityPermission] = React.useState<any>(false);
  const [isShowWizard, setIsShowWizard] = React.useState<boolean>(false);
  const [categoryLoader, setCategoryLoader] = React.useState<any>(false);
  const [countDel, setCount] = React.useState<any>(0);


  const [TablelistData, setTablelistData] = React.useState<any>([]);
  const [ParameterData, setParameterDaa] = React.useState<any>([]);
  const [imageData, setImageData] = React.useState<any>([]);
  const [TableLoader, setTableLoader] = React.useState<any>(false);
  const [imagesLoader, setImagesLoader] = React.useState<any>(false);

  const getWizardpermissionData = () => {
    API.get("/cost/show_commodity/", {
      project_id: projectId
    }, 0).then((res: any) => {
      const { show_commodity, config_exist } = res.data;
      setIsShowWizard(config_exist);
      setCommodityPermission(show_commodity);
    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    if (projectId != 0) {
      getWizardpermissionData();
    }
  }, [projectId])
  const [isShowCost, setIsShowCost] = React.useState<boolean>(true);
  const [MultipleAssumptionList, setMultipleAssumptionList] =
    React.useState<any>([]);
  const [counter, setCounter] = React.useState<any>(0);
  function arraysAreEqual(arr1: object[], arr2: object[]): boolean {
    // Check if arrays have the same length
    if (arr1.length !== arr2.length) {
      return false;
    }

    // Iterate through each object in both arrays
    for (let i = 0; i < arr1.length; i++) {
      const obj1 = arr1[i];
      const obj2 = arr2[i];

      // Check if the current objects are not equal
      if (!objectsAreEqual(obj1, obj2)) {
        return false;
      }
    }

    // If all objects are equal, return true
    return true;
  }

  const getParameterData = () => {
    setTableLoader(true);
    API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
      .then((res: any) => {
        setParameterDaa(res.data);
        setTableLoader(false);
      })
      .catch((err: any) => {
        setTableLoader(false);
      });
  }

  const getTableDataa = () => {
    setTableLoader(true);
    API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
      .then((res: any) => {
        setTableLoader(false);
        setTablelistData(res.data);
      })
      .catch((err: any) => {
        setTableLoader(false);
      });
  }

  const getImageData = () => {
    setImagesLoader(true);
    API.get("/xcpep/cost_bom_data/", { image: true, vault: vault }, 0)
      .then((res: any) => {
        setImagesLoader(false);
        setImageData(res.data?.image_details);
      })
      .catch((err: any) => {
        setImagesLoader(false);
      });
  }

  React.useEffect(() => {
    getParameterData();
    getTableDataa();
    getImageData();
  }, [vault]);



  function objectsAreEqual(obj1: any, obj2: any) {
    // Get the keys of the objects
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if objects have the same number of keys
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if the values of each key are equal
    for (let key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If all keys and values are equal, return true
    return true;
  }
  const otherassumptionModal = useBaseModal();
  const handleChange = (event: SelectChangeEvent) => {
    setSelectedAssumption(event.target.value);
    API.put(
      `/cost/update_estimation/${costDetailsData?.cost_estimation}/`,
      {
        assumption: event.target.value,
      },
      {},
      0
    )
      .then((res: any) => {
        getHierarchyData();
        getCostDetailsData();
        getPreSelectedCommodity();
      })
      .catch((err: any) => { });
  };
  const getCommodityList = () => {
    setCommodityListLoader(true);
    API.get("/api/db/commodity/", {}, 0)
      .then((res: any) => {
        setCommodityList(
          res.data?.map((item: any) => ({
            id: item.id,
            name: item.name,
          }))
        );
        setCommodityListLoader(false);
      })
      .catch((err: any) => {
        setCommodityListLoader(false);
      });
  };

  const getAssumptionList = () => {
    API.get(
      "/cost/assumption/",
      {
        top_vault: topVault,
        vault: vault,
        details: true,
        scenario: true,
        assumption_type: scenarioId ? 2 : 1,
      },
      0
    )
      .then((res: any) => {
        setAssumptionList(res.data);
        setMultipleAssumptionList(res.data);
      })
      .catch((err: any) => { });
  };
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  React.useEffect(() => {
    if (newUI == "false") {
      getCommodityList();
    }
   
    getAssumptionList();
  }, [vault, newUI]);

  const getCostDetailsData = () => {
    setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {

        setCostDetailsData(res.data[0]);
        setStepLoader(false);
        setSelectedCategory(res.data[0]?.costing_category);
        setUnvalidatedCategory(res.data[0]?.costing_category_name)
        setIsShowCost(true);
        setCostData(res.data[0]);
        setSelectedAssumption(res.data[0]?.assumption);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        if (err?.response && err?.response?.data) {
          setIsShowCost(false);

          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${err?.response?.data[0]}</p>   
           </div>`,
          });
        }
        setCostDetailsDataLoading(false);
      });
  };

  const getCostDetailsDataWOL = () => {
    // setCostDetailsDataLoading(true);
    setCostDetailsData({});

    API.get(
      "/cost/costing_view/",
      {
        ...getObj(),
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setCostDetailsData(res.data[0]);
        setStepLoader(false);
        setCostData(res.data[0]);
        setSelectedCategory(res.data[0]?.costing_category);
        setUnvalidatedCategory(res.data[0]?.costing_category_name)
        setSelectedAssumption(res.data[0]?.assumption);
        setCostDetailsDataLoading(false);
      })
      .catch((err: any) => {
        setCostDetailsDataLoading(false);
      });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width:
          anchor === "top" || anchor === "bottom"
            ? "auto"
            : { lg: 600, xl: 700 },
        backgroundColor: "aliceblue",
        display: "flex",
      }}
      role="presentation"
    >
      <Box className={styles.bomBodySection}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1px",
          }}
        >
          {ParameterData?.part_details?.length > 0 && (
            <Box
              onClick={() => TabHandler("Parameters")}
              className={
                Tabstatus == "Parameters" ? styles.bomactiveTab : styles.bomTab
              }
              sx={{
                width:
                  TablelistData?.length > 0 && imageData?.length > 0
                    ? "33.2% !important"
                    : TablelistData?.length === 0 && imageData?.length === 0
                      ? "100% !important"
                      : "49.5% !important",
              }}
            >
              Parameters
            </Box>
          )}
          {TablelistData?.length > 0 && (
            <Box
              onClick={() => TabHandler("Table")}
              className={
                Tabstatus == "Table" ? styles.bomactiveTab : styles.bomTab
              }
              sx={{
                width:
                  ParameterData?.part_details?.length > 0 &&
                    imageData?.length > 0
                    ? "33.2% !important"
                    : ParameterData?.part_details?.length === 0 &&
                      imageData?.length === 0
                      ? "100% !important"
                      : "49.5% !important",
              }}
            >
              Table
            </Box>
          )}
          {imageData?.length > 0 && (
            <Box
              onClick={() => TabHandler("Images")}
              className={
                Tabstatus == "Images" ? styles.bomactiveTab : styles.bomTab
              }
              sx={{
                width:
                  ParameterData?.part_details?.length > 0 &&
                    TablelistData?.length > 0
                    ? "33.2% !important"
                    : ParameterData?.part_details?.length === 0 &&
                      TablelistData?.length === 0
                      ? "100% !important"
                      : "49.5% !important",
              }}
            >
              Images
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr",
          }}
        >
          <BOMDrawerTable
            TabStatus={Tabstatus}
            imageData={imageData}
            TablelistData={TablelistData}
            ParameterData={ParameterData}
            setParameterDaa={setParameterDaa}
            setTablelistData={setTablelistData}
          />
        </Box>
      </Box>
    </Box>
  );


  const getPreSelectedCommodity = () => {
    API.get(
      "/cost/costing_commodity/",
      {
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setSelectedCommodity(res.data[0]?.commodity_data);
        setOldSelectedCommodity(res.data[0]?.commodity_data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };



  const getValidatedCategoryList = () => {
    setCategoryLoader(true);
    if (costDetailsData?.costing_type != null || costDetailsData?.costing_type != undefined) {
      API.get(
        "/cost/get_all_validated_configuration_cat/",
        { project_id: projectId, costing_type: costDetailsData?.costing_type, vault_id: vault },
        0
      )
        .then((res: any) => {
          setCategoryList(res.data);
          setCategoryLoader(false);
        })
        .catch((err: any) => {
          setCategoryLoader(false);
        });
    }
  };

  React.useEffect(() => {
    getValidatedCategoryList();
  }, [projectId, vault]);



  React.useEffect(() => {
    if (treeCount != 0) {
      getCostDetailsDataWOL();
    }
  }, [treeCount]);

  React.useEffect(() => {
    // setTimeout(() => {
    //   getCostDetailsData();
    // }, 1000);

    getPreSelectedCommodity();
    getCostDetailsData();
  }, [vault, , updateCount, count, countDel]);

  React.useEffect(() => {
    if (condition != 0) {
      getCostDetailsData();
    }
  }, [condition, tableCounter, countDel]);

  useEffect(() => {
    if (MultipleAssumptionList?.length)
      API.get(`/cost/costing_assumption/`, {
        vault: vault,
        scenario: scenarioId,
      }).then((res: any) => {
        let assumptionIds = MultipleAssumptionList?.filter((item: any) =>
          res?.data?.assumption_list?.includes(item?.id)
        );
        setSelectedmulIds(res?.data?.assumption_list);
        setselectedmulassumption(assumptionIds);
      });
  }, [vault, counter, MultipleAssumptionList]);

  const ExactDebounceCommodity = React.useRef(
    debounce(async (value: any, vaultId: any) => {
      API.put(
        `/cost/costing_commodity/${vaultId}/`,
        {
          commodity: value,
        },
        {},
        0
      )
        .then((res: any) => {
          setSelectedCommodity(value);
          setOldSelectedCommodity(value);
          getHierarchyData();
          API.get(
            "/cost/costing_commodity/",
            {
              vault: vault,
            },
            0
          )
            .then((res: any) => {
              getPreSelectedCommodity();
            })
            .catch((err: any) => {
              console.log(err);
            });

          setCostDetailsData({});

          API.get(
            "/cost/costing_view/",
            {
              ...getObj(),
              level: "cost_detail",
              scenario: scenarioId ? scenarioId : undefined,
            },
            0
          )
            .then((res: any) => {
              setCostDetailsData(res.data[0]);
              setStepLoader(false);
              setCostData(res.data[0]);
              setSelectedCategory(res.data[0]?.costing_category);
              setUnvalidatedCategory(res.data[0]?.costing_category_name)
              setSelectedAssumption(res.data[0]?.assumption);
              setCostDetailsDataLoading(false);
            })
            .catch((err: any) => {
              setCostDetailsDataLoading(false);
            });

          // setUpdateCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
          });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      //@ts-ignore
      ExactDebounceCommodity.cancel(); // Cancel the debounced function
    };
  }, []);

  const handleSelectChangParams = (event: any, newSystemValue: any) => {
    setSelectedCommodity(newSystemValue);
  };

  const getOptionDisabled = (option: any) => {
    return selectedCommodity.some(
      (selectedOption: any) => selectedOption.id === option.id
    );
  };

  const [isActive, setIsActive] = React.useState(false);
  const BomdataDrawer = () => {
    // toggle
    setIsActive((current) => !current);

    // or set to true
    // setIsActive(true);
  };

  const [isExpand, setIsExpand] = React.useState(true);
  const partexpandHandler = () => {
    // toggle
    setIsExpand((current) => !current);

    // or set to true
    // setIsActive(true);
  };

  const TabHandler = (tab: any) => {
    if (tab == "Parameters") {
      setTabstatus("Parameters");
    } else if (tab == "Table") {
      setTabstatus("Table");
    } else {
      setTabstatus("Images");
    }
  };
  const calculateTextColor = (backgroundColor: any) => {
    // Calculate the text color based on the brightness of the background color.
    const hex = backgroundColor?.replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness < 200 ? "#000000" : "#007fff"; // Adjust the threshold as needed
  };

  let multipleDataSet =
    MultipleAssumptionList &&
    MultipleAssumptionList?.filter((item: any) => {
      return item?.id !== selectedAssumption;
    });
  let selectedIds =
    MultipleAssumptionList &&
    MultipleAssumptionList?.filter((item: any) => {
      return item?.id == selectedAssumption;
    });
  const OpenHandlerAssumption = () => {
    otherassumptionModal.open();
  };

  const handleSelectChangAssumtionParams = (
    event: any,
    newSystemValue: any
  ) => {
    setselectedmulassumption(newSystemValue);

    ExactDebounceMultipleAssumption(
      newSystemValue?.map((item: any) => item.id),
      vault
    );
  };
  const ExactDebounceMultipleAssumption = React.useRef(
    debounce(async (value: any, vaultId: any) => {
      API.post(
        `/cost/costing_assumption/`,
        {
          assumption_list: value,
          vault: vaultId,
          scenario: scenarioId,
        },
        {},
        0
      )
        .then((res: any) => {
          setCounter((prev: any) => prev + 1);
          ADMIN.toast.info("Assumptions updated successfully");

          // setUpdateCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
          });
        });
    }, 1000)
  ).current;
  React.useEffect(() => {
    if (costDetailsData && Object.keys(costDetailsData).length > 0) {
      if (
        costDetailsData?.costing_category ||
        costDetailsData?.costing_status === "completed" ||
        costDetailsData?.costing_status === "validated"
      ) {
      } else {
        if (!commodityPermission) {
          isShowWizard && openCategoryCosting.open();
        }
      }
    }
  }, [selectedCategory, costDetailsData]);

  const [CostType, setCostType] = React.useState('');

  React.useEffect(() => {
    if (costDetailsData?.costing_type) {
      setCostType(costDetailsData.costing_type);
    }
  }, [costDetailsData]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });


  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setState({ ...state, left: false });
    }
  };

  <Box className={styles.bomBodySection}>
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        columnGap: "1px",
      }}
    >
      {ParameterData?.part_details?.length > 0 && (
        <Box
          onClick={() => TabHandler("Parameters")}
          className={
            Tabstatus == "Parameters" ? styles.bomactiveTab : styles.bomTab
          }
          sx={{
            width:
              TablelistData?.length > 0 && imageData?.length > 0
                ? "33.2% !important"
                : TablelistData?.length === 0 && imageData?.length === 0
                  ? "100% !important"
                  : "49.5% !important",
          }}
        >
          Parameters
        </Box>
      )}
      {TablelistData?.length > 0 && (
        <Box
          onClick={() => TabHandler("Table")}
          className={
            Tabstatus == "Table" ? styles.bomactiveTab : styles.bomTab
          }
          sx={{
            width:
              ParameterData?.part_details?.length > 0 &&
                imageData?.length > 0
                ? "33.2% !important"
                : ParameterData?.part_details?.length === 0 &&
                  imageData?.length === 0
                  ? "100% !important"
                  : "49.5% !important",
          }}
        >
          Table
        </Box>
      )}
      {imageData?.length > 0 && (
        <Box
          onClick={() => TabHandler("Images")}
          className={
            Tabstatus == "Images" ? styles.bomactiveTab : styles.bomTab
          }
          sx={{
            width:
              ParameterData?.part_details?.length > 0 &&
                TablelistData?.length > 0
                ? "33.2% !important"
                : ParameterData?.part_details?.length === 0 &&
                  TablelistData?.length === 0
                  ? "100% !important"
                  : "49.5% !important",
          }}
        >
          Images
        </Box>
      )}
    </Box>
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
      }}
    >
      <BOMDrawerTable
        TabStatus={Tabstatus}
        imageData={imageData}
        TablelistData={TablelistData}
        ParameterData={ParameterData}
        setParameterDaa={setParameterDaa}
        setTablelistData={setTablelistData}
      />
    </Box>
  </Box>




  return (
    newUI == "true" ?

      <AssumptionIdContext.Provider value={selectedAssumption || 0}>
        <AssumptionIdSelectedContext.Provider value={selectedIds || []}>
          <OtherAssumptionIdContext.Provider
            value={Selectedmulassumption || []}
          >

            <CreateCalculatorWithCategoryModal
              assumptionList={assumptionList && assumptionList}
              isShowCost={isShowCost}
              selectedAssumption={selectedAssumption}
              costDetailsData={costDetailsData}
              stepLoader={stepLoader}
              handleChange={handleChange}
              selectedCategory={selectedCategory}
              setSelectedCategory={setSelectedCategory}
              unvalidatedCategory={unvalidatedCategory}
              getCostDetailsDataWOL={getCostDetailsDataWOL}
              categoryList={categoryList}
              handleSelectChangParams={handleSelectChangParams}
              commodityList={commodityList}
              getOptionDisabled={getOptionDisabled}
              selectedCommodity={selectedCommodity}
              arraysAreEqual={arraysAreEqual}
              oldSelectedCommodity={oldSelectedCommodity}
              ExactDebounceCommodity={ExactDebounceCommodity}
              handleSelectChangAssumtionParams={
                handleSelectChangAssumtionParams
              }
              SelectedmulIds={SelectedmulIds}
              multipleDataSet={multipleDataSet}
              Selectedmulassumption={Selectedmulassumption}
              handleChangeCostingStatus={handleChangeCostingStatus}
              categoryLoader={categoryLoader}
              setCategoryLoader={setCategoryLoader}
              setCategoryList={setCategoryList}
              CostType={CostType}
              setCostType={setCostType}
              setCount={setCount}
              ValidationLoader={ValidationLoader}
              getCostDetailsData={getCostDetailsData}
            />
          </OtherAssumptionIdContext.Provider>
        </AssumptionIdSelectedContext.Provider>
      </AssumptionIdContext.Provider>
      : <div>
        {otherassumptionModal.isOpen && (
          <OtherAssumptionModal
            onCloseModal={otherassumptionModal.close}
            isOpen={otherassumptionModal.isOpen}
            Item={vault}
            scenario={scenarioId}
            costDetailsData={costDetailsData}
            setselectedmulassumption={setselectedmulassumption}
            assumptionList={multipleDataSet}
            setCounter={setCounter}
            Selectedmulassumption={Selectedmulassumption}
          />
        )}

        <AssumptionIdContext.Provider value={selectedAssumption || 0}>
          <AssumptionIdSelectedContext.Provider value={selectedIds || []}>
            <OtherAssumptionIdContext.Provider
              value={Selectedmulassumption || []}
            >
              {!costDetailsDataLoading ? (
                <Box className={styles.containerWrapper}>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        maxWidth: { lg: "40vw", xl: "35vw" },
                      }}
                    >
                      {
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            padding: "0.2rem 0.5rem",
                            width: "100%",
                            marginBottom: "0.2rem",
                            backgroundColor: costDetailsData?.scenario_color,
                            color: costDetailsData?.scenario_color
                              ? calculateTextColor(
                                costDetailsData?.scenario_color
                              )
                              : "#007fff",
                            borderRadius: "5px",
                          }}
                        >
                          <IconButton
                            title="Previous"
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              color: "primary.main",
                              backgroundColor: "#007fff14",
                              borderRadius: "50%",
                              width: "1.7rem",
                              height: "1.7rem",
                            }}
                            disabled={costDetailsData?.prev_vault ? false : true}
                            onClick={() =>
                              history.push(
                                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${costDetailsData?.prev_vault}/${abbrebation}/0/0/0/0/0/0/0/${scenarioId}/0/${newUI}`
                              )
                            }
                          >
                            <ChevronLeftIcon
                              titleAccess="Previous"
                              sx={{
                                fontSize: "1.8rem",
                                color: costDetailsData?.scenario_color
                                  ? calculateTextColor(
                                    costDetailsData?.scenario_color
                                  )
                                  : "#007fff",
                              }}
                            />
                          </IconButton>
                          {/* <Typography sx={{ fontSize: "1rem" }}>
                          {costDetailsData?.part_no} ( {costDetailsData?.name} ){" "}
                          {(isShowWizard) && (
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                cursor: "pointer",
                                marginLeft: "1rem",
                                backgroundColor: "orange",
                                color: "white",
                              }}
                              onClick={() => {
                                history.push(
                                  `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/0/false/true`
                                );
                                setTimeout(() => {
                                  openCategoryCosting.open();
                                }, 500);
                              }}
                            >
                              Wizard
                            </Button>
                          )}
                        </Typography> */}
                          <IconButton
                            title="Next"
                            sx={{
                              cursor: "pointer",
                              display: "flex",
                              alignItems: "center",
                              color: "primary.main",
                              backgroundColor: "#007fff14",
                              borderRadius: "50%",
                              width: "1.7rem",
                              height: "1.7rem",
                            }}
                            disabled={costDetailsData?.next_vault ? false : true}
                            onClick={() =>
                              history.push(
                                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${costDetailsData?.next_vault}/${abbrebation}/0/0/0/0/0/0/0/${scenarioId}/0/0/${newUI}`
                              )
                            }
                          >
                            <ChevronRightIcon
                              titleAccess="Next"
                              sx={{
                                fontSize: "1.8rem",
                                color: costDetailsData?.scenario_color
                                  ? calculateTextColor(
                                    costDetailsData?.scenario_color
                                  )
                                  : "#007fff",
                              }}
                            />
                          </IconButton>
                        </Box>
                      }
                    </Box>
                    <Box
                      className={
                        isExpand
                          ? styles.CostPartDetails
                          : styles.CostPartDetailsCollapse
                      }
                    >
                      <Box>
                        <Table
                          sx={{
                            border: "1px solid",
                            borderColor: "primary.light",
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell className={styles.partInfoTitle}>
                                Qty
                              </TableCell>
                              <TableCell className={styles.partInfo}>
                                {costDetailsData?.quantity}
                              </TableCell>
                              <TableCell className={styles.partInfoTitle}>
                                Level
                              </TableCell>
                              <TableCell className={styles.partInfo}>
                                {costDetailsData?.level}
                              </TableCell>
                              <TableCell className={styles.partInfoTitle}>
                                Weight
                              </TableCell>
                              <TableCell className={styles.partInfo}>
                                {costDetailsData?.weight?.toFixed(2)}g
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <Table
                          sx={{
                            border: "1px solid",
                            borderColor: "primary.light",
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell className={styles.partlabel}>
                                Total Cost
                              </TableCell>
                              <TableCell className={styles.partdetails}>
                                ₹ {costDetailsData?.total_cost?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={styles.partlabel}>
                                Cost/Kg
                              </TableCell>
                              <TableCell className={styles.partdetails}>
                                ₹ {costDetailsData?.cost_per_kg?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell className={styles.partlabel}>
                                % Share in BOM
                              </TableCell>
                              <TableCell className={styles.partdetails}>
                                {costDetailsData?.bom_share?.toFixed(2)}%
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#c4efb3" }}>
                              <TableCell className={styles.partlabel}>
                                Material (
                                {costDetailsData?.material_share?.toFixed(2)}%)
                              </TableCell>
                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  padding: "0.2rem 0.5rem",
                                }}
                                align="right"
                              >
                                ₹ {costDetailsData?.raw_material?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#fffb90" }}>
                              <TableCell className={styles.partlabel}>
                                Process ({costDetailsData?.process_share}%)
                              </TableCell>

                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  padding: "0.2rem 0.5rem",
                                }}
                                align="right"
                              >
                                ₹ {costDetailsData?.process?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "#f7e1f4" }}>
                              <TableCell className={styles.partlabel}>
                                Overheads ({costDetailsData?.overhead_share}%)
                              </TableCell>

                              <TableCell
                                sx={{
                                  borderBottom: "none",
                                  padding: "0.2rem 0.5rem",
                                }}
                                align="right"
                              >
                                ₹ {costDetailsData?.overhead?.toFixed(2)}
                              </TableCell>
                            </TableRow>
                            {!costDetailsData?.is_leaf && (
                              <TableRow sx={{ backgroundColor: "#d6f1f4" }}>
                                <TableCell className={styles.partlabel}>
                                  Child Parts (
                                  {costDetailsData?.child_share?.toFixed(2)}%)
                                </TableCell>
                                <TableCell
                                  sx={{
                                    borderBottom: "none",
                                    padding: "0.2rem 0.5rem",
                                  }}
                                  align="right"
                                >
                                  ₹ {costDetailsData?.child_cost?.toFixed(2)}
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </Box>

                      <Box sx={{ display: "flex", padding: "0 0.5rem" }}>
                        <Box className={styles.prodImage}>
                          {/* <img src={dummy} style={{ height: "8rem", opacity: 0.3 }} /> */}
                          {/* @ts-ignore */}
                          <RViewerJS>
                            {costDetailsData?.images?.map(
                              (item: any, index: number) => {
                                return (
                                  <Image
                                    placeholderImg={LoadingImage}
                                    src={item?.image}
                                    style={
                                      index === 0
                                        ? {
                                          height: "100%",
                                          width: "100%",
                                          maxHeight: "15.3rem",
                                          maxWidth: "23rem",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                          borderRadius: "5px",
                                        }
                                        : {
                                          height: "100%",
                                          width: "100%",
                                          maxHeight: "15.3rem",
                                          maxWidth: "23rem",
                                          objectFit: "contain",
                                          cursor: "pointer",
                                          display: "none",
                                          borderRadius: "5px",
                                        }
                                    }
                                  />
                                );
                              }
                            )}
                          </RViewerJS>
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      className={styles.collapser}
                      onClick={() => partexpandHandler()}
                    >
                      {isExpand ? <span className={styles.arrowup}></span> : ""}
                      {isExpand ? "Collapse" : "Expand"} Cost Summary
                      {isExpand ? "" : <span className={styles.arrowdown}></span>}
                    </Box>
                    <Box sx={{ width: { lg: "40vw", xl: "35vw" } }}>
                      <Box
                        sx={{
                          display: "flex",
                          columnGap: "1rem",
                          alignItems: "center",
                          marginBottom: "3px",
                          width: "100%",
                        }}
                      >
                        {" "}
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            marginLeft: "0.5rem",
                            fontSize: "0.75rem",
                            width: "100%",
                          }}
                        >
                          Select Main Assumption :
                        </span>
                        {/* {selectedAssumption !== null && (
                        <span
                          style={{
                            whiteSpace: "nowrap",
                            marginLeft: "2rem",
                            fontSize: "0.75rem",
                            width: "100%",
                          }}
                        >
                          Select Commodity :
                        </span>
                      )} */}
                        {selectedAssumption !== null && (
                          <span
                            style={{
                              whiteSpace: "nowrap",
                              marginLeft: "2rem",
                              fontSize: "0.75rem",
                              width: "100%",
                            }}
                          >
                            Select Category :
                          </span>

                        )}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "1rem",
                          alignItems: "flex-end",
                          marginBottom: "3px",
                          width: "100%",
                          marginTop: "-0.5rem",
                        }}
                      >
                        {isShowCost && (
                          <FormControl fullWidth>
                            <Select
                              variant="standard"
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder="Select Assumption card"
                              value={selectedAssumption}
                              displayEmpty
                              renderValue={(selectedAssumption) => {
                                if (!selectedAssumption) {
                                  return (
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        opacity: "0.5",
                                        marginTop: "0.5rem",
                                      }}
                                    >
                                      Select Assumption card
                                    </Typography>
                                  );
                                } else {
                                  return (
                                    assumptionList &&
                                    assumptionList?.filter(
                                      (item: any) => item.id == selectedAssumption
                                    )[0]?.name
                                  );
                                }
                              }}
                              label="Select Assumption card"
                              disabled={
                                costDetailsData &&
                                  costDetailsData?.permission?.includes("U")
                                  ? false
                                  : true
                              }
                              onChange={handleChange}
                              sx={{
                                width: "100%",
                                "&:before": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                {
                                  fontSize: "1rem",
                                  paddingLeft: "0.5rem",
                                },
                                ".MuiSvgIcon-root.MuiSelect-icon": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              {assumptionList &&
                                assumptionList?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      sx={{
                                        fontSize: "1rem",
                                        backgroundColor:
                                          assumptionList?.is_mapped === true
                                            ? "#b3f7b3"
                                            : "white",
                                        color:
                                          assumptionList?.is_mapped === true
                                            ? "green"
                                            : "primary.main",
                                      }}
                                      value={item?.id}
                                    >
                                      {item?.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        )}

                        {selectedAssumption !== null && (
                          <>
                            {" "}
                            <InfoIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => assumptionModal.open()}
                            />
                          </>
                        )}
                        {selectedAssumption !== null && (
                          <>
                            {selectedAssumption !== null && (
                              <FormControl fullWidth variant="standard" sx={{ display: 'flex', alignItems: 'center', gap: '1.1rem', width: '100%', flexDirection: 'row' }}>
                                {<Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  value={selectedCategory}
                                  onChange={(e: any) => {
                                    const { value } = e.target;
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Changing the configuration will reset the costing data. Do you want to continue?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, continue!",
                                      customClass: {
                                        container: "swal2Container",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setCategoryLoading(true)
                                        API.put(
                                          `/cost/get_all_validated_configuration_cat/${vault}/`,
                                          {
                                            costing_category: value,
                                          },
                                          {},
                                          0
                                        )
                                          .then((res: any) => {
                                            setSelectedCategory(value);
                                            setCategoryLoading(false)
                                            getCostDetailsDataWOL();
                                          })
                                          .catch((err: any) => {
                                            setCategoryLoading(false)
                                          });
                                      }
                                    });
                                  }}
                                  sx={{ fontSize: "1rem", width: '96%' }}
                                >
                                  {categoryList &&
                                    categoryList?.map((item: any, index: any) => {
                                      return (
                                        <MenuItem key={index} value={item.id}>
                                          {item.name}
                                        </MenuItem>
                                      );
                                    })}
                                </Select>}
                                {categoryLoading && <CircularProgress size={21} sx={{}} />}
                              </FormControl>
                            )}
                          </>
                        )}
                      </Box>
                      {assumptionList && assumptionList.length > 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                            }}
                          >
                            {/* <span style={{ whiteSpace: "nowrap",marginLeft:'0.5rem',fontSize:'1rem' }}>Select Other Assumptions :</span> */}

                            <div
                              style={{
                                display: "flex",
                                marginLeft: "0.5rem",
                                gap: "0.3rem",
                                width: "100%",
                                /* white-space: noWrap; */
                              }}
                            >
                              {selectedAssumption !== null && commodityPermission && (
                                <>
                                  {isShowCost && commodityList && (
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        multiple
                                        disableClearable
                                        disabled={
                                          costDetailsData &&
                                            costDetailsData?.permission?.includes(
                                              "U"
                                            )
                                            ? false
                                            : true
                                        }
                                        disableCloseOnSelect
                                        onChange={(
                                          event: any,
                                          newSystemValue: any
                                        ) => {
                                          handleSelectChangParams(
                                            event,
                                            newSystemValue
                                          );
                                        }}
                                        id="tags-standard"
                                        limitTags={1}
                                        options={commodityList}
                                        getOptionDisabled={getOptionDisabled}
                                        value={
                                          selectedCommodity && selectedCommodity
                                        }
                                        getOptionLabel={(option: any) =>
                                          option.name
                                        }
                                        // defaultValue={[top100Films[3]]}
                                        sx={{
                                          ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                          {
                                            color: "primary.main",
                                          },
                                          ".MuiButtonBase-root.MuiChip-root": {
                                            // background-color: #ecffec;
                                            // color: selectedCommodity?.is_mapped == "true" ? "green" : 'primary.main',
                                            height: "26px",
                                            // maxWidth:{lg:'6rem',xl:'10rem'},
                                          },
                                        }}
                                        onBlur={() => {
                                          if (
                                            selectedCommodity &&
                                            arraysAreEqual(
                                              selectedCommodity,
                                              oldSelectedCommodity
                                            ) !== true
                                          ) {
                                            ExactDebounceCommodity(
                                              selectedCommodity?.map(
                                                (item: any) => item.id
                                              ),
                                              vault
                                            );
                                          }
                                        }}
                                        renderTags={(
                                          value: any[],
                                          getTagProps: any
                                        ) =>
                                          value.map((option, index) => {
                                            return (
                                              <Chip
                                                key={index}
                                                label={option.name}
                                                {...getTagProps({ index })}
                                                title={option.name} // Set title attribute with full text content
                                                sx={{
                                                  backgroundColor:
                                                    option?.is_mapped === true
                                                      ? "#b3f7b3"
                                                      : "primary.light",
                                                  color:
                                                    option?.is_mapped === true
                                                      ? "green"
                                                      : "primary.main",
                                                }} // Update chip styles as needed
                                              />
                                            );
                                          })
                                        }
                                        renderOption={(props, option) => (
                                          <li
                                            {...props}
                                            style={{ fontSize: "1rem" }}
                                          >
                                            {option.name}
                                          </li>
                                        )}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder="Select Commodity"
                                            sx={{
                                              ".MuiInputBase-root.MuiInput-root":
                                              {
                                                fontSize: "1rem !important",
                                              },
                                              "& .MuiInput-underline:before": {
                                                borderBottomColor: "primary.main",
                                              },
                                              "& .MuiInput-underline:after": {
                                                borderBottomColor: "primary.main",
                                              },
                                            }}
                                          />
                                        )}
                                      />
                                    </FormControl>
                                  )}
                                </>
                              )}
                              {isShowCost && (
                                <FormControl fullWidth>
                                  <Autocomplete
                                    multiple
                                    disableClearable
                                    disabled={
                                      costDetailsData &&
                                        costDetailsData?.permission?.includes("U")
                                        ? false
                                        : true
                                    }
                                    filterSelectedOptions
                                    disableCloseOnSelect
                                    onChange={(
                                      event: any,
                                      newSystemValue: any
                                    ) => {
                                      handleSelectChangAssumtionParams(
                                        event,
                                        newSystemValue
                                      );
                                    }}
                                    id="tags-standard"
                                    limitTags={1}
                                    options={multipleDataSet && multipleDataSet}
                                    // getOptionDisabled={getOptionDisabled}/
                                    value={
                                      Selectedmulassumption &&
                                      Selectedmulassumption
                                    }
                                    getOptionLabel={(option: any) => option.name}
                                    // defaultValue={[top100Films[3]]}
                                    sx={{
                                      ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                      {
                                        color: "primary.main",
                                      },
                                      ".MuiButtonBase-root.MuiChip-root": {
                                        // backgroundColor: "primary.light",
                                        height: "26px",
                                      },
                                    }}
                                    // onBlur={() => {
                                    //   if (Selectedmulassumption) {
                                    //     ExactDebounceMultipleAssumption(
                                    //       Selectedmulassumption?.map(
                                    //         (item: any) => item.id
                                    //       ),
                                    //       vault

                                    //     );
                                    //   }
                                    // }}
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        style={{
                                          fontSize: "1rem",
                                          backgroundColor:
                                            option?.is_mapped === true
                                              ? "#b3f7b3"
                                              : "primary.light",
                                          color:
                                            option?.is_mapped === true
                                              ? "green"
                                              : "primary.main",
                                        }}
                                      >
                                        {option.name}
                                      </li>
                                    )}
                                    renderTags={(
                                      value: any[],
                                      getTagProps: any
                                    ) =>
                                      value.map((option, index) => {
                                        return (
                                          <Chip
                                            key={index}
                                            label={option.name}
                                            {...getTagProps({ index })}
                                            title={option.name} // Set title attribute with full text content
                                            sx={{
                                              backgroundColor:
                                                option?.is_mapped === true
                                                  ? "#b3f7b3"
                                                  : "primary.light",
                                              color:
                                                option?.is_mapped === true
                                                  ? "green"
                                                  : "primary.main",
                                            }} // Update chip styles as needed
                                          />
                                        );
                                      })
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        variant="standard"
                                        placeholder="Select Other Assumptions"
                                        sx={{
                                          ".MuiInputBase-root.MuiInput-root": {
                                            fontSize: "1rem !important",
                                          },
                                          "& .MuiInput-underline:before": {
                                            borderBottomColor: "primary.main",
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "primary.main",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              )}

                            </div>
                          </Box>

                        </Box>
                      )}
                      {isShowCost &&
                        selectedAssumption !== null &&
                        selectedCommodity.length > 0 && (
                          <CostEstimation
                            costDetailsData={costDetailsData && costDetailsData}
                            isExpand={isExpand}
                            setPassingObj={setPassingObj}
                            SelectedmulIds={SelectedmulIds}
                            getCostDetailsData={getCostDetailsData}
                            getHierarchyData={getHierarchyData}
                          />
                        )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      position: "absolute",
                      left: "0.6rem",
                      top: "11rem",
                    }}
                  >
                    {(["left"] as const).map((anchor) => (
                      <React.Fragment key={anchor}>
                        <IconButton
                          title="BOM Data"
                          sx={{
                            backgroundColor: "primary.light",
                            color: "primary.main",
                          }}
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <ChevronRightIcon sx={{ fontSize: "1.5rem" }} />
                        </IconButton>
                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          // hideBackdrop={true} // Hides the backdrop
                          ModalProps={{
                            keepMounted: true, // Keeps drawer in DOM for performance
                            disableEnforceFocus: true, // Allows interaction outside the drawer
                            disableScrollLock: true, // Prevents locking page scroll
                          }}
                          sx={{ width: "fit-content" }}
                          PaperProps={{
                            sx: {
                              backgroundColor: "aliceblue",
                            },
                          }}
                        >
                          {list(anchor)}
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </Box>
                  {Cost_status !== "Not Started" && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      width: { lg: "58vw", xl: "64vw" },
                    }}
                  >
                    <Box
                      sx={{
                        width: { lg: "58vw", xl: "64vw" },
                        zIndex: "0",
                        position: "relative",
                      }}
                    >
                      {isShowCost && tableId !== 0 && (
                        <CostigngCalculatorSection passingObj={passingObj} />
                      )}
                    </Box>
                    <Box className={styles.BomDrawer}>
                      <Box className={styles.drabtnSec}>
                        <Draggable
                          axis="y"
                        // defaultPosition={{x: 200, y: 0}}
                        // bounds={{left: 0, right: width > 1400 ? 1200 : 800,}}
                        >
                          <Box
                            className={styles.bombutton}
                            onClick={BomdataDrawer}
                          >
                            <Typography
                              className={styles.text}
                              sx={{
                                fontSize: "1rem",
                                color: "white",
                                padding: "0.5rem 0.2rem",
                              }}
                            >
                              BOM Data
                            </Typography>
                          </Box>
                        </Draggable>
                      </Box>
                      {/* <Box
                        className={
                          isActive
                            ? styles.bomTableActive
                            : styles.bomTableInactive
                        }
                      >
                        <Box className={styles.bomBodySection}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1px",
                            }}
                          >
                            <Box
                              onClick={() => TabHandler("Parameters")}
                              className={
                                Tabstatus == "Parameters"
                                  ? styles.bomactiveTab
                                  : styles.bomTab
                              }
                              sx={{width:"33.2% !important"}}
                            >
                              Parameters
                            </Box>
                            <Box
                              onClick={() => TabHandler("Table")}
                              className={
                                Tabstatus == "Table"
                                  ? styles.bomactiveTab
                                  : styles.bomTab
                              }
                              sx={{width:"33.2% !important"}}
                            >
                              Table
                            </Box>
                            <Box
                              onClick={() => TabHandler("Images")}
                              className={
                                Tabstatus == "Images"
                                  ? styles.bomactiveTab
                                  : styles.bomTab
                              }
                              sx={{width:"33.2% !important"}}
                            >
                              Images
                            </Box>
                          </Box>
                          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 3rem' }}>
                          
                            <BOMDrawerTable TabStatus={Tabstatus} imageData={imageData} TablelistData={TablelistData} ParameterData={ParameterData} setParameterDaa={setParameterDaa} setTablelistData={setTablelistData} />
                            <Box onClick={BomdataDrawer} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'primary.main', color: 'white', cursor: 'pointer' }}><Typography sx={{ transform: 'rotateZ(270deg)', whiteSpace: 'nowrap' }}>Close BOM Data</Typography></Box>
                          </Box>
                        </Box>
                      </Box> */}
                    </Box>
                  </Box>
                )}
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "flex-start",
                    columnGap: "1rem",
                  }}
                >
                  <Box sx={{ width: { lg: "40%", xl: "35%" } }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        columnGap: "1rem",
                        padding: "0 0.5rem",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        height="18rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        variant="rectangular"
                        height="18rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                    </Box>
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                  </Box>
                  <Box
                    sx={{ width: { lg: "60%", xl: "65%" }, padding: "0 1rem" }}
                  >
                    <Skeleton
                      variant="rectangular"
                      height="89vh"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                  </Box>
                </Box>
              )}
              {assumptionModal.isOpen && (
                <AssumptionModal
                  isOpen={assumptionModal.isOpen}
                  onClose={assumptionModal.close}
                />
              )}
            </OtherAssumptionIdContext.Provider>
          </AssumptionIdSelectedContext.Provider>
        </AssumptionIdContext.Provider>
      </div>
  );
}
