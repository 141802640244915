import {
    Box,
    FormControlLabel,
    Skeleton,
    Switch,
    Typography,
  } from "@mui/material";
  import * as React from "react";
  import styles from "./CostingCalculatorSection.module.scss";
  import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
  import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
  
  import {
    getRawMaterialCardCosts,
    resetRawMaterialCard,
  } from "../../../Redux/Actions/rawMaterialCard.actions";
  import { API } from "../../../api-services";
  import CalculatorCardConfig from "./CalculatorCard/CalculatorCardConfig";
  import RawMaterialCardConfig from "./RawMaterialCard/RawMaterialCardConfig";
  import { useHistory } from "react-router-dom";
  import ProcessCardConfig from "./RawMaterialCard/ProcessCardConfig";
  import OverheadCardConfig from "./RawMaterialCard/OverheadCardConfig";
import ScenarioCalculatorCardConfig from "./CalculatorCard/ScenarioCalculatorCardConfig";
import ScenarioRawMaterialCardConfig from "./RawMaterialCard/ScenarioRawMaterialCardConfig";
import ScenarioProcessCardConfig from "./RawMaterialCard/ScenarioProcessCardConfig";
import ScenarioOverheadCardConfig from "./RawMaterialCard/ScenarioOverheadCardConfig";
import { userPermission } from "../../NewScenariosCostingCreate/ScenarioCalculatorModel";
  export interface ICostigngCalculatorSectionProps {
    passingObj?: any;
    calculatorData?: any;
    costDetailsData: any;
    tableId?:any
    costEstimationId?:any
    vault?:any;
    HierarchyAllData?:any
    calctype?:any;
    condition?:any;
    calcultorId?:any;
    scenarioId?:any;
    conditionPopup?:any;
    getScenarioTreeData?:any
    onCloseModal?:any
    parentId?:any
    revision_id?:any;
    assumption?:any;
    category_type?:any;
    decimalPoints?:any;
    userProfile?:any;
    PermissionDataa?:any
    reDirectToMainCosting?:any;
    CostingType?:any
  }
  
  type CalculatorItem = {
    id: number;
    calculator_id: number;
    sequence_id: string;
    type: string;
    ancestors: string[];
    parent_id: any;
    category: any;
    
    
  };
  
  export const isShowUserInputVal = React.createContext<any>(false);
  export function ScenarioCalculator(
    props: ICostigngCalculatorSectionProps
  ) {
    const {
      
      topVault,
      projectId,
      projectname,
      productId,
      abbrebation,
      cardtype,
     
      
      // condition,
      tableCounter,
      
      // calcultorId,
      // scenarioId,
      // conditionPopup,
      newUI
    } = useRouteParams<any>();
    const permission = React.useContext(userPermission);
    const history = useHistory();
    const { passingObj, calculatorData, costDetailsData, tableId, costEstimationId, 
      vault, HierarchyAllData,calctype, condition, calcultorId, scenarioId, conditionPopup, 
      getScenarioTreeData, onCloseModal, parentId, revision_id, assumption, category_type, 
      decimalPoints, PermissionDataa, userProfile, reDirectToMainCosting, CostingType } = props;
    const handleDelete = () => {
      console.info("You clicked the delete icon.");
    };
    const [cost, setCost] = React.useState<any>(null);
    const [PermissionData, setPermissionData] = React.useState<any>(null);
    const [showUserInput, setShowUserInput] = React.useState<any>(false);
    const [calcDataRedirect, setCalcDataRedirect] = React.useState<any>([]);
    const [costType, setCostType] = React.useState<any>((calctype == 1 || calctype == 2) ? "/cost/material/" : (calctype == 3 || calctype == 4 || calctype == 5 || calctype == 6)  ? "/cost/process" : "cost/overhead");
    const [nextCalc, setNextCal] = React.useState<any>(null);
    const [prevCalc, setPrevCal] = React.useState<any>(null);
    const [currenctCalc, setCurrentCalc] = React.useState<any>(null);
  console.log("permissionpermissionpermission",permission)
    const costsFetched = useQueryDispatch({
      // @ts-ignore
      query: {
        action: getRawMaterialCardCosts,
        arg: { costEstimationId, tableId: parentId },
      },
      cleanup: { action: resetRawMaterialCard, args: [] },
  
      dependency: [parentId, vault, condition, calcultorId],
    });

  



    const getRMCostData = () => {
      API.get(
        costType,
        {
          cost_estimation: costEstimationId,
          id:parentId
        },
        0
      )
        .then((res: any) => {
          console.log(res?.data?.[0], "calctype costs");
          setCost(res?.data?.[0]);
        })
        .catch((err: any) => {
          console.log("Srver Error");
        });
    }

    
  
   
  
    React.useEffect(() => {
      API.get(
        "/auth/calibration_user_permission/",
        {
          action: true,
          costing_create: true,
          vault: vault,
          scenario: scenarioId ? scenarioId : undefined,
        },
        0
      )
        .then((res: any) => {
          setPermissionData(res?.data);
        })
        .catch((err: any) => {
          console.log("Srver Error");
        });
    }, [vault]);

    React.useEffect(()=> {
        getRMCostData();
    },[])

   
   

    
    
  
    const getComponent = () => {
     
    
     
      switch (calctype) {
        
        
        case 1:
          
         return <ScenarioRawMaterialCardConfig costDetailsData={costDetailsData} tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
        case 2:
          return <ScenarioRawMaterialCardConfig costDetailsData={costDetailsData} tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
        case 3:
          return <ScenarioProcessCardConfig tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
        case 4:
          return <ScenarioProcessCardConfig tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
        case 5:
          return <ScenarioProcessCardConfig tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
        case 6:
          return <ScenarioProcessCardConfig tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
  
        default:
          return <ScenarioOverheadCardConfig tableId={parentId} costEstimationId={costEstimationId} calctype={calctype} condition={condition} decimalPoints={decimalPoints} CostingType={CostingType} />;
      }
    };
    {
      /* <RawMaterialCard />
                            <ProcessCard/> */
    }

    // console.log(PermissionDataa,userProfile, "permissionn");
    
    return (
      <div>
        <Box sx={{ height: "100%", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row ",
              gap: "0.2rem",
              justifyContent: "space-between",
              ml: 1,
              mr: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%", justifyContent: "space-between" }}>
              <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                {currenctCalc && currenctCalc?.calculator_name}
              </Typography>
              {/* <Button
              size="small"
              sx={{ float: "right", mb: 1 }}
              variant={showUserInput ? "contained" : "outlined"}
              onClick={() => setShowUserInput((prev: any) => !prev)}
            >
              User Inputs
            </Button> */}
              {/* <FormControlLabel
                control={
                  <Switch
                    checked={conditionPopup === "true" ? true : false}
                    onChange={() => {
                      // /createcostingpart/:projectId/:projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId/:scenarioId/:conditionPopup
                      // history.push(`/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${calcultorId}/${scenarioId}/${!showUserInput}/${newUI}`);
                      setShowUserInput((prev: boolean) => !prev);
                      onCloseModal();
                    }}
                    sx={{
                      "& .MuiSwitch-switchBase": {
                        color: "gray", // Switch color when off
                      },
                      "& .Mui-checked": {
                        color: "#007fff", // Switch color when on
                      },
                      "& .MuiSwitch-track": {
                        backgroundColor: conditionPopup === "true" ? "#00887A" : "#bfbfbf", // Track color
                      },
                      "& .MuiSwitch-thumb": {
                        boxShadow: conditionPopup === "true" ? "0 0 10px #00887A" : "none", // Creative glow effect when on
                      },
                    }}
                  />
                }
                label={
                  conditionPopup ? "User Inputs" : "User Inputs"
                }
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  color: conditionPopup === "true" ? "#007fff" : "gray",
                  fontWeight: "bold",
                  // mb: 1,
                }}
              /> */}
            </Box>
  
          </Box>
          {costsFetched?.match("FETCHING") ? (
            <>
            <Skeleton
              variant="rectangular"
              height="3vh"
              width="100%"
              sx={{ marginBottom: "1rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="3vh"
              width="100%"
              sx={{ marginBottom: "0.25rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="3vh"
              width="100%"
              sx={{ marginBottom: "0.25rem" }}
            />
            <Skeleton
              variant="rectangular"
              height="3vh"
              width="100%"
              sx={{ marginBottom: "0.25rem" }}
            />
            </>
          ) : (
            <>
              <Box>
                {costsFetched?.match("FETCHING") ? (
                   <>
                   <Skeleton
                     variant="rectangular"
                     height="3vh"
                     width="100%"
                     sx={{ marginBottom: "1rem" }}
                   />
                   <Skeleton
                     variant="rectangular"
                     height="3vh"
                     width="100%"
                     sx={{ marginBottom: "0.25rem" }}
                   />
                   <Skeleton
                     variant="rectangular"
                     height="3vh"
                     width="100%"
                     sx={{ marginBottom: "0.25rem" }}
                   />
                   <Skeleton
                     variant="rectangular"
                     height="3vh"
                     width="100%"
                     sx={{ marginBottom: "0.25rem" }}
                   />
                   </>
                ) : (
                  <>
                    <Box sx={{ height: "8vh", width: "100%" }}>
                    {tableId && getComponent()}
                    </Box>
                    <Box
                      className={styles.calculatorTabSection}
                    // sx={{ height: "78vh !important" }}
                    >
                      <isShowUserInputVal.Provider value={showUserInput}>
                        <ScenarioCalculatorCardConfig
                        tableId={tableId}
                          setCurrentCalc={setCurrentCalc}
                          costEstimationId={costEstimationId}
                          condition={condition}
                          calctype={calctype}
                          calcultorId={calcultorId}
                          scenarioId={scenarioId}
                          HierarchyAllData={HierarchyAllData}
                          onCloseModal={onCloseModal}
                          parentId={parentId}
                          revision_id={revision_id}
                          assumption={assumption}
                          getScenarioTreeData={getScenarioTreeData}
                          category_type={category_type}
                          costData={cost}
                          PermissionDataa={PermissionDataa}
                          userProfile={userProfile}
                          reDirectToMainCosting={reDirectToMainCosting}
                          CostingType={CostingType}
                          
                        />
                      </isShowUserInputVal.Provider>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )}
        </Box>
      </div>
    );
  }
  