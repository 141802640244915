
export const SAVE = 'SAVE';


export const saveInput =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"SAVE",payload:data});
}







