import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../Redux/Services/admin.service";
import FetchCostModelName from "./FetchCostModelName";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton for the loader
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  BOPCategories,
  materialcategories,
} from "../../utlis/Category.constant";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

interface IWatermarkModelProps {
  isOpen: boolean;
  onCloseModal: () => void;
  setParentCount: any;
  fetchType: any;
  parentTreeRefresh: any;
  categoryList:any,
  setAddFectchCounter?:any
  getCategoryInfo?:any
}

const FetchCostModelGroup: React.FC<IWatermarkModelProps> = ({
  isOpen,
  onCloseModal,
  fetchType,
  setParentCount,
  parentTreeRefresh,
  categoryList,
  setAddFectchCounter,
  getCategoryInfo
}) => {
  const { categoryId } = useRouteParams<any>();
  const [rows, setRows] = React.useState<any>([
    { name: "", id: -1, cmCount: 0, material_category: "" ,sequence:""},
  ]);
  const fetchCostModel = useBaseParamsModal();
  const [loading, setLoading] = React.useState<boolean>(true); // Loading state
  const [errorMessages, setErrorMessages] = React.useState<string[]>([]);
  const [apiRowsData, setAPIRowsData] = React.useState<any>([]);

  const getOptions = () => {
    switch (fetchType) {
      case "RM":
        return materialcategories;
      case "BOP":
        return BOPCategories;
      default:
        return [];
    }
  };

  const getRowsdata = () => {
    setLoading(true); // Set loading to true before fetching
    API.get(
      "/config/add_configuration_estimation_group/",
      { category: getCategoryType(), costing_category: categoryId },
      0
    )
      .then((res: any) => {
        setRows(
          res.data.map((row: any) => ({
            name: row.name,
            id: row.id,
            cmCount: row.cmCount,
            material_category: row.material_category,
            sequence:row.sequence
          }))
        );
        setAPIRowsData(
          res.data.map((row: any) => ({
            name: row.name,
            id: row.id,
            cmCount: row.cmCount,
            material_category: row.material_category,
          }))
        );        
      })
      .catch((err: any) => {
        // Handle error if needed
      })
      .finally(() => {
        setLoading(false); // Set loading to false after fetching
      });
  };
  const getRowsdataWOL = () => {
    API.get(
      "/config/add_configuration_estimation_group/",
      { category: getCategoryType(), costing_category: categoryId },
      0
    )
      .then((res: any) => {
        setRows(
          res.data.map((row: any) => ({
            name: row.name,
            id: row.id,
            cmCount: row.cmCount,
            material_category: row.material_category,
            sequence:row.sequence
          }))
        );
        setAPIRowsData(
          res.data.map((row: any) => ({
            name: row.name,
            id: row.id,
            cmCount: row.cmCount,
            material_category: row.material_category,
          }))
        );    
      })
      .catch((err: any) => {
        // Handle error if needed
      })
      .finally(() => {
      });
  };


  React.useEffect(() => {
    getRowsdata();
  }, []);

  const handleAddRow = () => {
    const errors: string[] = [];
    rows.forEach((row: any, index: number) => {
      if (!row.name) {
        errors[index] = `Row ${index + 1}: Name is required`;
      }
      // Only check material_category if fetchType is RM or BOP
      if (
        (fetchType === "RM" || fetchType === "BOP") &&
        !row.material_category
      ) {
        errors[index] = `Row ${index + 1}: Material category is required`;
      }
    });

    if (errors.length > 0) {
      setErrorMessages(errors);
    } else {
      setRows([...rows, { name: "", id: -1, cmCount: 0,sequence:'' }]); // Use Date.now() for unique ID
      setErrorMessages([]);
    }
  };

  const getCategoryType = () => {
    switch (fetchType) {
      case "RM":
        return 1;
      case "BOP":
        return 2;
      case "OH":
        return 0;
      default:
        return 0;
    }
  };

  const getCategoryFullName = () => {
    switch (fetchType) {
      case "RM":
        return "rm_material";
      case "BOP":
        return "bop_material";
      case "OH":
        return "overhead";
      default:
        return "overhead";
    }
  };

  const getHeading = () => {
    switch (fetchType) {
      case "RM":
        return "Raw Material";
      case "BOP":
        return "BOP Material";
      case "OH":
        return "Overhead";
      default:
        return "Overhead";
    }
  };

  const handleInputChange = (id: number, value: string) => {
    setRows(
      rows.map((row: any) => (row.id === id ? { ...row, name: value } : row))
    );
  };
  const handleInputChangeSequence = (id: number, value: any) => {
    setRows(
      rows.map((row: any) => (row.id === id ? { ...row, sequence: value } : row))
    );
  };
  const handleInputChangeCategory = (id: number, value: string) => {
    setRows(
      rows.map((row: any) =>
        row.id === id ? { ...row, material_category: value } : row
      )
    );
  };

  const handleCmAdd = (id: number) => {
    fetchCostModel.open(id);
  };

  const handleDeleteRow = (id: number, name: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this row",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      customClass: {
        container: "swal2Container",
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (typeof name === "string" && name?.length > 0) {
          API.delete(`/config/add_configuration_estimation_group/${id}/`, {}, 0)
            .then((res: any) => {
              getRowsdata();
            })
            .catch((err: any) => {
              if (err && err.response && err.response.data) {
                ADMIN.toast.error(err.response.data[0]);
              }
            });
        } else {
          getRowsdata();
        }
      }
    });
  };


  // 
  

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h2" id="modal-title">
            Add {getHeading()}
          </Typography>
          <IconButton
            onClick={() => {
              parentTreeRefresh();
              setParentCount((prev: any) => prev + 1);
              setAddFectchCounter((prev:any)=>prev+1)
              onCloseModal();
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box sx={{ overflowY: "auto", maxHeight: "60vh" }}>
          {loading // Show skeleton loader while loading
            ? Array.from(Array(3)).map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mb: 2,
                  padding: "0.5rem",
                  backgroundColor: "whitesmoke",
                  borderRadius: "8px",
                }}
              >
                <Skeleton variant="text" width={50} />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="rectangular" width={100} height={36} />
                <Skeleton variant="text" width={50} />
                <Skeleton variant="circular" width={40} height={40} />
              </Box>
            ))
            : rows.map((row: any, index: number) => (
              <Box
                key={row.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  mb: 2,
                  padding: "0.5rem",
                  backgroundColor: "whitesmoke",
                  borderRadius: "8px",
                  width: "100%",
                }}
              >
                {/* <Typography sx={{ width: "3%" }}>{index + 1}</Typography> */}
                <TextField
                  type="number"
                  variant="standard" // Use 'standard' variant for underline
                  placeholder="sequence"
                  value={row.sequence}
                  disabled={row.id === -1}
                  onChange={(e) =>
                    +e.target.value > 0 &&
                    handleInputChangeSequence(row.id,+e.target.value)
                  }
                  InputProps={{
                    disableUnderline: false, // Keep underline enabled
                    //@ts-ignore
                    min: 1, // Minimum value
                    sx: {
                      padding: "0px", // Remove extra padding
                      margin: "0px", // Remove extra margin
                      fontSize: "0.875rem", // Smaller font size
                      minHeight: "1.5rem", // Minimal height for compact design
                    },
                  }}
                  onBlur={(e) => {
                    if (
                      e.target.value !==
                      apiRowsData[index]?.material_category
                    ) {
                      API.put(
                        `/config/add_configuration_estimation_group/${row.id}/`,
                        {
                          sequence: e.target.value,
                        },
                        {},
                        0
                      )
                        .then((res: any) => {
                          getRowsdataWOL();                          
                        })
                        .catch((err: any) => {
                          if (err && err.response && err.response.data) {
                            ADMIN.toast.error(err.response.data[0]);
                          }
                        });
                    }
                  }}
                  autoFocus={true}
                  sx={{
                    width: "6%", // Adjust width as needed
                    marginLeft: "1rem",
                    "& .MuiInput-root": {
                      padding: 0, // Remove internal padding
                      height: "1.5rem", // Minimal height
                    },
                    "& .MuiInput-underline:before": {
                      borderBottom: "1px solid #e0efff", // Custom underline color before focus
                    },
                    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #e0efff", // Custom underline color on hover
                    },
                    "& .MuiInput-underline:after": {
                      borderBottom: "1px solid #e0efff", // Custom underline color on focus
                    },
                  }}
                />


                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter name"
                  value={row.name}
                  onChange={(e) => handleInputChange(row.id, e.target.value)}
                  onBlur={(e) => {
                    if (apiRowsData[index]?.name?.length > 0) {
                      if (e.target.value !== apiRowsData[index]?.name) {
                        API.put(
                          `/config/add_configuration_estimation_group/${row.id}/`,
                          {
                            name: e.target.value,
                            // category: getCategoryType(),
                            // costing_category: categoryId,
                          },
                          {},
                          0
                        )
                          .then((res: any) => {
                            getRowsdataWOL();
                          })
                          .catch((err: any) => {
                            if (err && err.response && err.response.data) {
                              ADMIN.toast.error(err.response.data[0]);
                            }
                          });
                      }
                    } else {
                      if (e.target.value) {
                        API.post(
                          "/config/add_configuration_estimation_group/",
                          {
                            name: e.target.value,
                            category: getCategoryType(),
                            costing_category: categoryId,
                          },
                          {},
                          0
                        )
                          .then((res: any) => {
                            getRowsdataWOL();
                          })
                          .catch((err: any) => {
                            if (err && err.response && err.response.data) {
                              ADMIN.toast.error(err.response.data[0]);
                            }
                          });
                      }
                    }
                  }}
                  sx={{ flex: 1, width: "31%" }}
                  error={
                    !!errorMessages[index] &&
                    errorMessages[index].includes(
                      `Row ${index + 1}: Name is required`
                    )
                  }
                  helperText={
                    errorMessages[index] &&
                      errorMessages[index].includes(
                        `Row ${index + 1}: Name is required`
                      )
                      ? errorMessages[index]
                      : ""
                  }
                />

                {(fetchType === "RM" || fetchType === "BOP") && (
                  <FormControl
                    sx={{ width: "31%" }}

                  >
                    {/* <InputLabel>
                        Select Category
                      </InputLabel> */}
                    {/* <Typography>Select Category</Typography> */}
                    <Select
                      fullWidth
                      disabled={rows[index]?.name?.length === 0 || row.id === -1 || categoryList.find((category:any) => category.id === categoryId)?.mapped_status && row.cmCount>0}
                      labelId={`select-category-label-${index}`}
                      value={row.material_category || ""}
                      onChange={(e) =>
                        handleInputChangeCategory(row.id, e.target.value)
                      }
                      displayEmpty
                      error={!!errorMessages[index] && errorMessages[index].includes(`Row ${index + 1}: Material category is required`)}
                      onBlur={(e) => {
                        if (
                          e.target.value !==
                          apiRowsData[index]?.material_category
                        ) {
                          API.put(
                            `/config/add_configuration_estimation_group/${row.id}/`,
                            {
                              material_category: e.target.value,
                            },
                            {},
                            0
                          )
                            .then((res: any) => {                              
                              getRowsdataWOL();                             
                            })
                            .catch((err: any) => {
                              if (err && err.response && err.response.data) {
                                ADMIN.toast.error(err.response.data[0]);
                              }
                            });
                        }
                      }}
                    >
                      <MenuItem value={""} disabled>
                        Select Category
                      </MenuItem>
                      {getOptions()?.map((item: any, idx: any) => (
                        <MenuItem key={idx} value={item?.category}>
                          {item?.category}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}

                {(fetchType === "RM" || fetchType === "BOP")?<Button
                  variant="contained"
                  sx={{ color: "white", whiteSpace: "nowrap" }}
                  disabled={!row.name || row.id === -1 || !row.material_category}
                  onClick={() => handleCmAdd(row.id)}
                >
                  Add CM
                  </Button>:  <Button
                  variant="contained"
                  sx={{ color: "white", whiteSpace: "nowrap" }}
                  disabled={!row.name || row.id === -1}
                  onClick={() => handleCmAdd(row.id)}
                >
                  Add CM
                </Button>}

                <Typography
                  variant="body2"
                  sx={{
                    minWidth: "4rem",
                    textAlign: "center",
                  }}
                >
                  {row.cmCount} CM
                </Typography>

                <IconButton
                  color="error"
                  onClick={() => handleDeleteRow(row.id, row.name)}
                  disabled={categoryList.find((category:any) => category.id === categoryId)?.mapped_status && row.cmCount>0}
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            width: "100%",
          }}
        >
          <Divider sx={{ color: "black", width: "40%" }} />
          <Button
            variant="outlined"
            startIcon={<AddCircleIcon />}   
            onClick={handleAddRow}
            sx={{ width: "20%" }}
          >
            Add Row
          </Button>
          <Divider sx={{ color: "black", width: "40%" }} />
        </Box>

        {fetchCostModel.isOpen && (
          <FetchCostModelName
            isOpen={fetchCostModel.isOpen}
            onCloseModal={fetchCostModel.close}
            setParentCount={setParentCount}
            parentTreeRefresh={parentTreeRefresh}
            fetchType={getCategoryFullName()}
            setAddFectchCounter={setAddFectchCounter}
            row_id={fetchCostModel.propsId}
            getRowsdata={getRowsdata}
            getHeading={getHeading}
            getCategoryInfo={getCategoryInfo}
          />
        )}
      </Box>
    </Modal>
  );
};

export default FetchCostModelGroup;
