import * as React from "react";
import styles from "./ScenarioCostingCreateDrawer.module.scss";
import { Box, Checkbox, Divider, Skeleton, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useState } from "react";

import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CostingScenarioTree from "../CostingScenarios/CostingScenarioTree";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { userPermissionAction } from "../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { debounce } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { Padding } from "@mui/icons-material";


export interface IScenarioCostingCreateDrawerProps {
  count: any;
  setStatus: any;
  syncColor?: any;
  setSyncColor?: any;
  scenarioDashboardOpen: any;
  setScenarioDashboardOpen: any;
  anchorComing: any;
  toggleDrawer: any;
  productList: any;
  setProductList: any;
  selectedProduct: any;
  setSelectedProduct: any;
  setLocatorCount?: any;
  locatorCount?: any;
  isOpen?: any;
  childRef: any;
}

export function ScenarioCostingCreateDrawer(
  props: IScenarioCostingCreateDrawerProps
) {
  const {
    setStatus,
    setScenarioDashboardOpen,
    setLocatorCount,
    isOpen,
    locatorCount,
  } = props;

  const {
    anchorComing,
  } = props;
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    scenarioType,
  } = useRouteParams<any>();
  const childRef = React.useRef<any>(null)
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>({});
  const history = useHistory();
  const [treeData, setTreeData] = React.useState<any>([]);
  const [treeDataLoading, setTreeDataLoading] = React.useState<boolean>(false);
  const [selectedNodeCheck, setSelectedNodeCheck] =
    React.useState<boolean>(false);
  const { width } = useWindowDimensions();
  const descRef = React.useRef<any>();
  const OuterRef = React.useRef<any>();

  // Scenario Costing Section


  const [selectedValue, setSelectedValue] = React.useState<any>(null);
  const [SearchValue, setSearchValue] = React.useState<any>([]);
  const inputRef = React.useRef<any>(null);

  const handleOnChange = async (event: any) => {
    setSelectedValue(event.target.value);
    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    } else {
      inputRef.current.style.display = "none";
      setSearchValue([]);
    }
    // setSearchValue
  };

  React.useEffect(()=>{
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a) || [];
    if(val && val?.length>0 && val?.includes(topVault?.toString())){

    }else{
      sessionStorage.setItem("nodeCostingScenario",JSON.stringify([topVault?.toString()]))
    }
  },[topVault,isOpen])

  const fetchSequentially = async () => {
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a) || [];

    // Check if vault is not 0 and val contains topVault
    if (vault != 0 && val?.includes(topVault?.toString())) {
      await childRef.current?.fetchChildData(topVault, "PRODUCT");
      // Sequentially fetch data for each item in val
      for (let nodeId of val) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(nodeId, HierarchyAllData?.abbreviation);
          } catch (error) {
            console.error(`Failed to fetch child data for nodeCostingScenario ${nodeId}:`, error);
          }
        }
      }
    }
  };
 


  const fetchFirstLevelData=()=>{
    if(childRef.current && vault ==0){
      childRef.current?.fetchChildData(topVault, "PRODUCT");
    }
  }

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("/xcpep/vault/search/", {
        search: criteria,
        top_vault: topVault,
        type: scenarioType == 1 ? null : 2,
      }).then((res: any) => {
        setSearchValue(res.data);
      });
    }, 500)
  ).current;

  const getTreeData = () => {
    setTreeDataLoading(true);
    API.get(
      "/cost/scenario_hierachy/",
      {
        top_vault: topVault,
        type: scenarioType == 1 ? null : 2,
      },
      0
    )
      .then((res: any) => {
        setTreeData(res.data);
        setHierarchyAllData(res.data.data);
        // let a: any = sessionStorage.getItem("nodeCostingScenario");
        // let val: any = JSON.parse(a) || [];
        // if (val) {
        // } else {
          // sessionStorage.setItem(
          //   "nodeCostingScenario",
          //   JSON.stringify([topVault])
          // );
          // sessionStorage.removeItem(
          //   "nodeCostingScenario"
          // );
        // }
      setTimeout(()=>{
        if (childRef.current) {
          if (vault != 0) {
            fetchSequentially();
          } else {
            fetchFirstLevelData();
          }
        }
      },0)
        setTreeDataLoading(false);
      })
      .catch(() => {
        setTreeDataLoading(false);
      });
  };
  React.useEffect(() => {
    if (sessionStorage.getItem("isCategory") == undefined) {
      sessionStorage.setItem(
        "isCategory",
        JSON.stringify([treeabbr, false, topVault])
      );
    }
    getTreeData();
  }, [topVault]);

  const onSelect = (
    value: any,
    abbreviation: any,
    topv: any,
    statuss: any,
    isCategory: any
  ) => {
    setScenarioDashboardOpen(false);
    setStatus(statuss);
    sessionStorage.removeItem("isCategory");
    // below toggle state code do not remove as it is used to call the api for category as change. It help to call api in costingDashboard category.
    setSelectedNodeCheck(!selectedNodeCheck);
    sessionStorage.setItem(
      "isCategory",
      JSON.stringify([abbreviation, isCategory, value])
    );
    if (abbreviation == "PRODUCT") {
      sessionStorage.setItem("addScenarioStatus", "3");
      !isCategory &&
        history.push(
          `/costingscenario/${projectId}/${projectName}/${topVault}/${0}/${scenarioType}/${"PRODUCT"}/0`
        );
    } else {
      sessionStorage.setItem("addScenarioStatus", "3");
      !isCategory &&
        history.push(
          `/costingscenario/${projectId}/${projectName}/${topVault}/${value}/${scenarioType}/${
            abbreviation ? abbreviation : "none"
          }/0`
        );
    }
  };

  const changeScenarioHandler = (typeScenario: any) => {
    sessionStorage.setItem("nodeCostingScenario",JSON.stringify([topVault?.toString()]));
    // sessionStorage.setItem("nodeCostingScenario", JSON.stringify([topVault]));
    sessionStorage.removeItem("isCategory");
    sessionStorage.setItem(
      "isCategory",
      JSON.stringify([treeabbr, false, topVault])
    );
    getTreeData()
    if (typeScenario == "BOM") {
      history.push(
        `/costingscenario/${projectId}/${projectName}/${topVault}/0/1/PRODUCT/0`
      );
    } else {
      setScenarioDashboardOpen(false);
      history.push(
        `/costingscenario/${projectId}/${projectName}/${topVault}/0/2/PRODUCT/0`
      );
    }
  };

  // React.useEffect(()=> {
  //   console.log("locatorCount",locatorCount)
  //   if(locatorCount && locatorCount?.ancestors){
  //   sessionStorage.setItem(
  //     "nodeCostingScenario",
  //     JSON.stringify(locatorCount?.ancestors)
  //   );
  //   }
  // },[locatorCount])

  return (
    <Box
      sx={{
        minWidth: { lg: 350, xl: 400 },
        maxWidth: { lg: 350, xl: 400 },

        width:
          anchorComing === "top" || anchorComing === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            borderBottom: "1px solid",
            borderBottomColor: "primary.light",
          }}
        >
          <Box
            sx={
              scenarioType == 1
                ? {
                    backgroundColor: "#007fff",
                    color: "white",
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "center",
                    borderRight: "1px solid",
                    borderRightColor: "#ddefff",
                    padding: "0.3rem",
                  }
                : {
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                    borderRight: "1px solid",
                    borderRightColor: "#ddefff",
                    padding: "0.3rem",
                  }
            }
            onClick={() => changeScenarioHandler("BOM")}
          >
            BOM
          </Box>
          <Box
            sx={
              scenarioType == 2
                ? {
                    backgroundColor: "#007fff",
                    color: "white",
                    cursor: "pointer",
                    width: "100%",
                    textAlign: "center",
                    borderRight: "1px solid",
                    borderRightColor: "#ddefff",
                    padding: "0.3rem",
                  }
                : {
                    width: "100%",
                    textAlign: "center",
                    cursor: "pointer",
                    borderRight: "1px solid",
                    borderRightColor: "#ddefff",
                    padding: "0.3rem",
                  }
            }
            onClick={() => changeScenarioHandler("Category")}
          >
            Category
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            borderBottom: "1px solid",
            borderBottomColor: "primary.light",
            height: "2.5rem",
            margin: "0 0.5rem",
            overflow: "hidden",
          }}
        >
          <Box className={styles.inputbox} ref={OuterRef}>
            {/* {
              <input
                type="search"
                placeholder="Search Part Name / Number"
                value={selectedValue}
                className={styles.searchInput}
                autoFocus={false}
                style={{ color: "#007fff" }}
                // @ts-expect-
                ref={descRef}
                onKeyPress={(event: any) => handleOnChange(event)}
                onChange={(event: any) => handleOnChange(event)}
                // onFocus={(e) => FocusHandler(e)}
              />
            } */}

            {/* <Box
              className={styles.AutoSearchBox}
              ref={inputRef}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              <>
             
              </>
            </Box> */}
          </Box>
          <Divider
            orientation="vertical"
            sx={{ borderColor: "primary.light" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "4rem",
              justifyContent: "space-evenly",
            }}
          >
            {/* <Typography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                fontWeight: "500",
                color: "green",
                width: "2rem",
              }}
            >
              I
            </Typography>
            <Typography
              sx={{
                fontSize: "1rem",
                textAlign: "center",
                fontWeight: "500",
                color: "green",
                width: "2rem",
              }}
            >
              D
            </Typography> */}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            borderRight: "1px solid #ddefff",
            height: { lg: "89vh", xl: "90vh" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {!treeDataLoading && treeData ? (
            <CostingScenarioTree
              HierarchyData={treeData && treeData} // hierarchyData={HierarchyData && HierarchyData}
              onSelectCallback={onSelect}
              locatorCount={locatorCount}
              setLocatorCount={setLocatorCount}
              scenarioType={scenarioType}
              childRef={childRef}
              isOpenn={isOpen} // isOpenn={isOpen}
            />
          ) : (
            <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
              <Skeleton sx={{ height: "2rem", width: "30%" }} />
              <Skeleton sx={{ height: "2rem", width: "45%" }} />
              <Skeleton sx={{ height: "2rem", width: "50%" }} />
              <Skeleton sx={{ height: "2rem", width: "65%" }} />
              <Skeleton sx={{ height: "2rem", width: "70%" }} />
              <Skeleton sx={{ height: "2rem", width: "60%" }} />
              <Skeleton sx={{ height: "2rem", width: "55%" }} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
