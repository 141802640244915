import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, Skeleton, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import CostingTreeTemplateConfig from "./CostingTreeTemplateConfig";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../Redux/Services/admin.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

interface IFetchCostModelProps {
  isOpen: any;
  onCloseModal: any;
  setParentCount: any;
  fetchType?: any;
  parentTreeRefresh?: any;
  setCounterApiCall?: any;
   type?:any;
   getHeirarchyData?:any
   setHierarchyCount?:any;
   getCategoryInfo?:any
}

function FetchCostModel(props: IFetchCostModelProps) {
  const { isOpen, onCloseModal, setParentCount, fetchType, parentTreeRefresh, setCounterApiCall, type, getHeirarchyData , setHierarchyCount, getCategoryInfo} = props;
  const [loading, setLoading] = React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [counterApi, setCounterApi] = React.useState<any>(1);
  const [checkedNodes, setCheckedNodes] = React.useState<any[]>([]);
  const [isFetchingData, setIsFetchingData] = React.useState<any>(false);

  const getHeading = () => {
    if(fetchType === "rm_material"){
      return "Material";
    }else if(fetchType === "overhead"){
      return "Overhead";
    }
    else if(fetchType?.fetchType === "bop_material"){
      return "BOP Material";
    }
    else{
      return "";
    }
  };

  const { projectId, projectname, categoryId, } = useRouteParams<any>();

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => { };
  const getCalcData = (data: any) => {
    setSelectedTreeData(data);
  };

  const getTreeData = () => {
    setIsLoading(true);
    API.get(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        project: +projectId,
        get_fetch_hierarchy: +categoryId,
        type_filter: fetchType || ""

      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getTreeData();
  }, [counterApi]);

  const fetchingData = () => {
    setIsFetchingData(true);
    API.post(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        category: categoryId,
        multi_list: checkedNodes,
      },
      {},
      0
    )
      .then((res: any) => {
        // setIsFetchingData(false);
        // setParentCount((prev: any) => prev + 1);
        // setHierarchyCount((prev: any) => prev + 1);
        // setCheckedNodes([]);
        // parentTreeRefresh();
        //  setCounterApiCall((prev: any) => prev + 1);
        // // if (getHeirarchyData) {
        // //   if (type === "process") {
        // //     getHeirarchyData(fetchType);
        // //   }
        // // }
        
        // onCloseModal();
        // //ADMIN.toast.success( "Calculators fetched successfully" );



        setIsFetchingData(false);
        setParentCount((prev: any) => prev + 1);
        setCheckedNodes([]);
        parentTreeRefresh();
        setCounterApiCall((prev: any) => prev + 1);
        getCategoryInfo();
        onCloseModal();
        ADMIN.toast.success( "Calculators fetched successfully" );
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, `Could not fetch calculators`);
        setIsFetchingData(false);
      });
  };

  React.useEffect(() => {
    if (categoryId != 0) {
      setCheckedNodes([]);
    }
  }, [categoryId]);







  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Fetch Cost Models {fetchType && <span>
                {getHeading()?.length >0 ?`(${getHeading()})`:''}
              </span>}
            </Typography>
            <LoadingButton
              disabled={checkedNodes?.length > 0 ? false : true}
              sx={{ float: "right", marginLeft: '1rem' }}
              variant="contained"
              loading={isFetchingData}
              loadingIndicator="Loading..."
              onClick={() => fetchingData()}
            >
              Fetch
            </LoadingButton>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                height: { lg: "71vh", xl: "72vh" },
                marginLeft: "5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!isLoading && AllHierarchy ? (
                AllHierarchy?.map((item: any) => {
                  return (
                    <CostingTreeTemplateConfig
                      HierarchyData={item && item}
                      onSelectCallback={onSelect}
                      setSelectedTreeData={getCalcData}
                      setCounterApiCall={setCounterApiCall}
                      useraction={"CRUD"}
                      setCheckedNodes={setCheckedNodes}
                      checkedNodes={checkedNodes}
                    />
                  );
                })
              ) : (
                <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FetchCostModel;
