import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, Skeleton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import CostingTreeTemplateConfig from "./CostingTreeTemplateConfig";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../Redux/Services/admin.service";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

interface IFetchCostModelNameProps {
  isOpen?: any;
  onCloseModal?: any;
  setParentCount?: any;
  fetchType?: any;
  parentTreeRefresh?: any;
  row_id?: any;
  getRowsdata?: any;
  getHeading?: any;
  setAddFectchCounter?: any;
  getCategoryInfo?:any
}

function FetchCostModelName(props: IFetchCostModelNameProps) {
  const { isOpen, onCloseModal, setParentCount, fetchType, parentTreeRefresh, row_id, getRowsdata, getHeading, setAddFectchCounter, getCategoryInfo } = props;
  const [loading, setLoading] = React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [counterApiCall, setCounterApiCall] = React.useState<any>(1);
  const [checkedNodes, setCheckedNodes] = React.useState<any[]>([]);
  const [isFetchingData, setIsFetchingData] = React.useState<any>(false);

  const {
    projectId,
    projectname,
    categoryId,
  } = useRouteParams<any>();

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => { };
  const getCalcData = (data: any) => {
    setSelectedTreeData(data);
  };

  const getTreeData = () => {
    setIsLoading(true);
    API.get(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        project: +projectId,
        get_fetch_hierarchy: +categoryId,
        type_filter: (typeof fetchType === "object" ? fetchType?.fetchType : fetchType) || "",
        estimation_id: typeof fetchType === "object" ? fetchType?.estimation_id : row_id

      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };

  React.useEffect(() => {
    getTreeData();
  }, [counterApiCall]);

  const fetchingData = () => {
    
    setIsFetchingData(true);
    API.post(
      "/config/get_costing_configuration_based_on_costing_category/",
      {
        category: categoryId,
        multi_list: checkedNodes,
        row_id: typeof fetchType === "object" ? fetchType?.estimation_id : row_id
      },
      {},
      0
    ).then((res: any) => {      

        setIsFetchingData(false);
        setCheckedNodes([]);
        if(fetchType?.fetchType !== "bop_material"){
        getRowsdata();
        }
        ADMIN.toast.success( "Calculators fetched successfully" );
        setAddFectchCounter((prev:any)=>prev+1); 
        parentTreeRefresh();
        getCategoryInfo();
        
              
        onCloseModal();
      })
      .catch((err: any) => {
        if(err && err.response && err.response.data ){
        ADMIN.toast.throwError(err, `Could not fetch calculators`);
        }
        setIsFetchingData(false);
      });
  };

  React.useEffect(() => {
    if (categoryId != 0) {
      setCheckedNodes([]);
    }
  }, [categoryId]);

 


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Fetch Cost Models {getHeading && <span>
                {`(${getHeading()})`}
              </span>}
            </Typography>
            <LoadingButton
              disabled={checkedNodes?.length > 0 ? false : true}
              sx={{ float: "right", marginLeft: '1rem' }}
              variant="contained"
              loading={isFetchingData}
              loadingIndicator="Loading..."
              onClick={() => fetchingData()}
            >
              Fetch
            </LoadingButton>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={()=>{                
                onCloseModal();
              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                height: { lg: "71vh", xl: "72vh" },
                marginLeft: "5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!isLoading && AllHierarchy ? (
                AllHierarchy?.map((item: any) => {
                  return (
                    <CostingTreeTemplateConfig
                      HierarchyData={item && item}
                      onSelectCallback={onSelect}
                      setSelectedTreeData={getCalcData}
                      setCounterApiCall={setCounterApiCall}
                      useraction={"CRUD"}
                      setCheckedNodes={setCheckedNodes}
                      checkedNodes={checkedNodes}
                    />
                  );
                })
              ) : (
                <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "65%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "60%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "30%" }} />
                  <Skeleton sx={{ height: "2rem", width: "45%" }} />
                  <Skeleton sx={{ height: "2rem", width: "50%" }} />
                  <Skeleton sx={{ height: "2rem", width: "35%" }} />
                  <Skeleton sx={{ height: "2rem", width: "70%" }} />
                  <Skeleton sx={{ height: "2rem", width: "55%" }} />
                  <Skeleton sx={{ height: "2rem", width: "80%" }} />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FetchCostModelName;
