import React, { useEffect, useRef, useState } from "react";

import { BorderColor, Category, Search } from "@mui/icons-material";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import { Box, Button, ButtonProps, IconButton, InputBase, TextField, Typography, styled } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddFeatureInformationModal from "./AddFeatureInformationModal";
import UploadIcon from "@mui/icons-material/Upload";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import successIcon from "../../Assets/images/success.png";
import Swal from "sweetalert2";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,

  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const csvOptions: GridCsvExportOptions = {
  fileName: "Subsystem Name list CSV",
  delimiter: ",",
  utf8WithBom: true,
  allColumns: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{ height: "2.5rem", paddingRight: "1rem" }}>
    <GridToolbarColumnsButton />
    <CustomExportButton />
  </GridToolbarContainer>
);

interface SystemAndNamesTableProps {
  setParentsCounter: any;
  setRecyclebinCounter: any;
  useraction: any;
}

const FeatureNamesTable = (props: SystemAndNamesTableProps) => {
  const { categoryId, subsystemId, search, Featuretype } = useParams<any>();
  // console.log(Featuretype, "Featuretype")
  const inputElRefPdf = useRef<any>();
  const { setParentsCounter, setRecyclebinCounter, useraction } = props;
  const [TableData, setTableData] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false);
  const [Counter, setCounter] = useState<any>(0);
  const AddPartNameModal = useBaseModal();
  const [GetSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const AddPartNameModalEdit = useBaseModal();
  const [searchvalue, setsearchvalue] = useState<any>(null);
  const userProfilePic = useSelector(userSelectors.selectAll);
  const [searchkey, setSearchkey] = useState<any>(null);
  const [RowItems, setRowItems] = useState<any>();
  const history = useHistory<any>();
  // const [expanded, setExpanded] = React.useState<string | false>(String(Featuretype));

  // const handleChangeAccordian =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     history.push(`/data-base/feature-names-database/${categoryId}/${subsystemId}/${search}/${panel}`)
  //   };


  const SingleDeleteHanlder = (Id: any) => {
    swal({
      text: "Are you sure you want to delete ?",
      buttons: ["No", "Yes"],
    }).then((confirm) => {
      if (confirm) {
        setLoader(true);
        API.delete(`/api/db/feature_name/${Id}/`, 0)
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            setRecyclebinCounter((prev: any) => prev + 1);
            setParentsCounter((prev: any) => prev + 1);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }
    });
  };

  const MultipleDeleteHandler = () => {
    swal({
      text: "Are you sure you want to delete ?",
      buttons: ["No", "Yes"],
    }).then((confirm) => {
      if (confirm) {
        setLoader(true);
        API.delete(
          `/api/db/subsys_delete_all_names/`,
          { id_list: GetSelectedIds, part_name: true },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            setParentsCounter((prev: any) => prev + 1);
            setRecyclebinCounter((prev: any) => prev + 1);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      }
    });
  };

  const lockHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        API.put(`/api/db/feature_name/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          // setParentsCounter((prev: any) => prev + 1);
          setCounter((prev: any) => prev + 1);
          // setEditCounter((prev: any) => prev + 1);
          setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
    });
  };


  useEffect(() => {
    setsearchvalue(search == "0" || search == 0 ? null : search);
  }, [search, subsystemId, categoryId]);

  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same video can be uploaded again

    (e.target as HTMLInputElement).value = "";
  };
  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_feature_name/", formData, {
      subsystem: subsystemId,
      vehicle_type: categoryId,
    })
      .then((res: any) => {
        setParentsCounter((prev: any) => prev + 1);
        setCounter((prev: any) => prev + 1);
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">"${res?.data}"</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        setReverseUpload(false);
      });
  };
  let columnsDataGrid: any =
    search !== "0"
      ? [
        { field: "id", hide: true, disableExport: true },
        { field: "squenceNo", headerName: "Sl No." },
        {
          field: "name",
          headerName: "Name",
          minWidth: 300,
          flex: 1
        },
        {
          field: "subsystem_name",
          headerName: "System Name",
          minWidth: 300,
          flex: 1
        },
        {
          field: "feature_type",
          headerName: "Type",
          minWidth: 300,
          flex: 1,
          renderCell: (cellvalues: any) => {


            return (<>

              {cellvalues?.row?.feature_type == 0 ? "Feature" : cellvalues?.row?.feature_type == 1 ? "Specification" : "Information"}
            </>)
          }
        },

        {
          field: "actions",
          headerName: "Action",
          disableExport: true,
          renderCell: (cellvalues: any) => {
            return (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  {useraction && useraction?.includes("U") && cellvalues?.row?.lock != true ? <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  /> : <EditIcon sx={{ opacity: 0.5 }} />}

                  {useraction && useraction?.includes("D") && <DeleteIcon
                    onClick={() => {
                      if (cellvalues?.row?.lock != true) {
                        SingleDeleteHanlder(cellvalues?.row?.id)
                      }
                    }}
                    titleAccess="Delete"
                    sx={{ cursor: "pointer", opacity: cellvalues?.row?.lock == true ? 0.5 : 1 }}
                  />}

                  {cellvalues?.row?.lock === true ? (
                    // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                    (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                      <LockIcon
                        sx={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => lockHandler(cellvalues?.row)}
                      />
                    ) : (
                      // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                      <LockIcon sx={{ opacity: 0.5 }} />
                    )
                  ) : (
                    // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                    (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                      <LockOpenIcon
                        sx={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => lockHandler(cellvalues?.row)}
                      />
                    )
                  )}
                </Box>
              </>
            );
          },
          minWidth: 330,
          flex: 1
        },
      ]
      : [
        { field: "id", hide: true, disableExport: true },
        { field: "squenceNo", headerName: "Sl No.", },
        {
          field: "name",
          headerName: `${Featuretype == 0 ? "Feature Name" : Featuretype == 1 ? "Specification Name" : "Information Name"}`,
          minWidth: 300,
          flex: 1
        },
        {
          field: "subsystem_name",
          headerName: "System Name",
          hide: true,
          disableExport: false,
          minWidth: 300,
          flex: 1
        },
        {
          field: "actions",
          headerName: "Action",
          disableExport: true,
          renderCell: (cellvalues: any) => {
            return (
              <>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                  {useraction && useraction?.includes("U") && cellvalues?.row?.lock != true ? <EditIcon
                    titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => EditHandler(cellvalues?.row)}
                  /> : <EditIcon sx={{ opacity: 0.5 }} />}

                  {useraction && useraction?.includes("D") && <DeleteIcon
                    onClick={() => {
                      if (cellvalues?.row?.lock != true) {
                        SingleDeleteHanlder(cellvalues?.row?.id)
                      }
                    }}
                    titleAccess="Delete"
                    sx={{ cursor: "pointer", opacity: cellvalues?.row?.lock == true ? 0.5 : 1 }}
                  />}

                  {cellvalues?.row?.lock === true ? (
                    // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                    (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                      <LockIcon
                        sx={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => lockHandler(cellvalues?.row)}
                      />
                    ) : (
                      // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                      <LockIcon sx={{ opacity: 0.5 }} />
                    )
                  ) : (
                    // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                    (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                      <LockOpenIcon
                        sx={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => lockHandler(cellvalues?.row)}
                      />
                    )
                  )}
                </Box>
              </>
            );
          },
          minWidth: 330,
          flex: 1,
        },
      ];

  var rows =
    TableData &&
    TableData.map((item: any, index: any) => ({
      ...item,
      squenceNo: index + 1,
    }));

  const AddPartName = () => {
    AddPartNameModal.open();
  };

  var EditHandler = (rowItem: any) => {
    setRowItems(rowItem);

    AddPartNameModalEdit.open();
  };
  const handlechange = (event: any) => {
    setsearchvalue(event?.target.value);
    if (event?.target.value.length == 0) {
      setsearchvalue(null);
      setSearchkey(null);
      setLoader(true);
      history.push(
        `/data-base/feature-names-database/${categoryId}/${subsystemId}/${0}/0`
      );
    }
  };

  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      if (searchvalue !== null) {
        setSearchkey(searchvalue);
        history.push(
          `/data-base/feature-names-database/${categoryId}/${subsystemId}/${searchvalue}/0`
        );
        setCounter((prev: any) => prev + 1);
      }
    }
  };



  const SearchHandler = () => {
    {
      if (searchvalue?.length > 0) {
        setSearchkey(searchvalue);
        history.push(
          `/data-base/feature-names-database/${categoryId}/${subsystemId}/${searchvalue}/0`
        );
        setCounter((prev: any) => prev + 1);
      }
    }
  };

  // useEffect(() => {

  //   setExpanded(String(0))
  // }, [subsystemId])
  // console.log(searchvalue, "fdguydvdvdvdv")
  useEffect(() => {
    if (search !== "0") {
      if (categoryId !== 0 && subsystemId !== 0) {
        setLoader(true);
        setTableData([]);
        API.get(`/api/db/feature_name/`, {
          search: search?.trim(),
          v_type: categoryId,
        })
          .then((res: any) => {
            setTableData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Srver Error");
          });
      }
    } else {
      if (categoryId !== 0 && subsystemId !== 0 && Featuretype !== false) {
        setsearchvalue("");
        setLoader(true);
        setTableData([]);
        API.get(`/api/db/feature_name/`, {
          subsystem: subsystemId,
          vehicle_type: categoryId,
          feature_type: parseInt(Featuretype)

        })
          .then((res: any) => {
            setTableData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            console.log("Srver Error");
          });
      }
    }
  }, [categoryId, subsystemId, Counter, search, Featuretype]);

  return (
    <Box sx={{ width: '100%' }}>
      <input
        id="PDF Upload"
        accept=".csv,.xlsx"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        onClick={handleInputClick}
        ref={inputElRefPdf}
      />

      <Box sx={{ marginTop: '-1.5rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', justifyContent: 'flex-end', width: '80%', position: 'absolute', zIndex: 1, }}>
          <Box>
            <>
              {search && (
                <TextField
                  type="search"
                  placeholder="Search"
                  // size="small"
                  value={searchvalue}
                  style={{ color: "#007fff", fontSize: "0.9rem" }}
                  onKeyDown={(event: any) => handlechangeDown(event)}
                  onChange={(event: any) => handlechange(event)}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              )}
              <SearchIcon
                sx={{
                  color: "primary.main",
                  cursor: "pointer",
                  fontSize: { lg: "2.4rem", xl: "2.25rem" },
                  padding: '3px',
                  borderBottom: "1px solid",
                }}
                onClick={() => SearchHandler()}
              />
            </>
          </Box>
          {(search == 0 || search == "0") && (
            useraction && useraction?.includes("C") && <AddIcon
              onClick={() => AddPartName()}
              sx={{ fontSize: "2rem", cursor: "pointer" }}
              titleAccess="Add (Feature Name | Specification Name | Information Name)"
            />
          )}

          {(search == 0 || search == "0") &&
            useraction && useraction?.includes("C") && <LoadingButton sx={{
              minWidth: '1rem',
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }} loading={resverUpload} title="Upload" onClick={() => ReverseUpload()}>
              <UploadIcon />
            </LoadingButton>}
          <Button
            disabled={GetSelectedIds?.length == 0 ? true : false}
            onClick={() => MultipleDeleteHandler()}
            sx={{ cursor: "pointer", minWidth: '1rem' }}>
            {useraction && useraction?.includes("D") && <DeleteIcon
              onClick={() => MultipleDeleteHandler()}
              titleAccess="Delete"
              sx={{ cursor: "pointer" }}
            />}
          </Button>
        </Box>




        <Box sx={{ width: "100%", height: { lg: '86vh', xl: '89vh' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >

          {search != 0 ? <Box sx={{
            width: "100%", height: "86vh",
            "& .super-app-theme--lock": {
              bgcolor: "#c5c5c5",
            },
          }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {rows && (
              <DataGrid
                headerHeight={48}
                rowHeight={40}
                rows={rows && rows}
                columns={columnsDataGrid}
                density="compact"
                loading={Loader}
                // @ts-ignore
                disableSelectionOnClick={true}
                // autoHeight={true}
                sx={{
                  "& .MuiDataGrid-checkboxInput": {
                    color: "primary.main",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: 'none',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.lock === true
                    ? "lock"
                    : params.row.mapped_status === true
                      ? "true"
                      : "false"
                  }`
                }
                checkboxSelection
                hideFooterPagination={false}
                // @ts-ignore
                onSelectionModelChange={(ids) => {
                  const selectedIDs = new Set(ids);
                  setGetSelectedIds(ids);
                }}
              />
            )}
          </Box> :


            <>
              <Box sx={{
                width: "100%", height: "86vh",
                "& .super-app-theme--true": {
                  bgcolor: "#ecffec",
                },
                "& .super-app-theme--lock": {
                  bgcolor: "#c5c5c5",
                },
              }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {rows && (
                  <DataGrid
                    headerHeight={48}
                    rowHeight={40}
                    rows={rows && rows}
                    columns={columnsDataGrid}
                    density="compact"
                    loading={Loader}
                    // @ts-ignore
                    disableSelectionOnClick={true}
                    // autoHeight={true}
                    sx={{
                      "& .MuiDataGrid-checkboxInput": {
                        color: "primary.main",
                      },
                      "& ::-webkit-scrollbar": {
                        width: "0.3rem",
                        height: "0.3rem",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        // background: $thumb-color;
                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                        borderRadius: "10px",
                      },
                      "& ::- webkit-scrollbar-thumb: hover ": {
                        // width: "4px",
                        backgroundColor: "#045DE9 !important",
                      },
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1f3 !important",
                      },
                      "&.MuiDataGrid-root": {
                        border: 'none',
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                    components={{
                      Toolbar: () => {
                        return CustomToolbar();
                      },
                    }}
                    getRowClassName={(params) =>
                      `super-app-theme--${params.row.lock === true
                        ? "lock"
                        : params.row.mapped_status === true
                          ? "true"
                          : "false"
                      }`
                    }
                    checkboxSelection
                    hideFooterPagination={false}
                    // @ts-ignore
                    onSelectionModelChange={(ids) => {
                      const selectedIDs = new Set(ids);
                      setGetSelectedIds(ids);
                    }} />
                )}
              </Box>
              {/* <Accordion sx={{
                marginTop: "4rem !important",




              }} expanded={expanded === "0"} onChange={handleChangeAccordian("0")}>
                <AccordionSummary sx={{
                  minHeight: "37px !important"
                }} aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Feature</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: "100%", height: "45rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    {rows && (
                      <DataGrid
                        headerHeight={48}
                        rowHeight={40}
                        rows={rows && rows}
                        columns={columnsDataGrid}
                        density="compact"
                        loading={Loader}
                        // @ts-ignore
                        disableSelectionOnClick={true}
                        // autoHeight={true}
                        sx={{
                          "& .MuiDataGrid-checkboxInput": {
                            color: "primary.main",
                          },
                          "& ::-webkit-scrollbar": {
                            width: "0.3rem",
                            height: "0.3rem",
                          },
                          "& ::-webkit-scrollbar-thumb": {
                            // background: $thumb-color;
                            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                            borderRadius: "10px",
                          },
                          "& ::- webkit-scrollbar-thumb: hover ": {
                            // width: "4px",
                            backgroundColor: "#045DE9 !important",
                          },
                          "::-webkit-scrollbar-track": {
                            backgroundColor: "#e1e1f3 !important",
                          },
                          "&.MuiDataGrid-root": {
                            border: 'none',
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            borderBottomColor: "primary.light",
                          },
                        }}
                        components={{
                          Toolbar: () => {
                            return CustomToolbar();
                          },
                        }}
                        checkboxSelection
                        hideFooterPagination={false}
                        // @ts-ignore
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          setGetSelectedIds(ids);
                        }} />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion sx={{ marginTop: "0.5rem !important" }} expanded={expanded === "1"} onChange={handleChangeAccordian("1")}>
                <AccordionSummary

                  sx={{
                    minHeight: "37px !important"
                  }}
                  aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Specification</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: "100%", height: "45rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    {rows && (
                      <DataGrid
                        headerHeight={48}
                        rowHeight={40}
                        rows={rows && rows}
                        columns={columnsDataGrid}
                        density="compact"
                        loading={Loader}
                        // @ts-ignore
                        disableSelectionOnClick={true}
                        // autoHeight={true}
                        sx={{
                          "& .MuiDataGrid-checkboxInput": {
                            color: "primary.main",
                          },
                          "& ::-webkit-scrollbar": {
                            width: "0.3rem",
                            height: "0.3rem",
                          },
                          "& ::-webkit-scrollbar-thumb": {
                            // background: $thumb-color;
                            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                            borderRadius: "10px",
                          },
                          "& ::- webkit-scrollbar-thumb: hover ": {
                            // width: "4px",
                            backgroundColor: "#045DE9 !important",
                          },
                          "::-webkit-scrollbar-track": {
                            backgroundColor: "#e1e1f3 !important",
                          },
                          "&.MuiDataGrid-root": {
                            border: 'none',
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            borderBottomColor: "primary.light",
                          },
                        }}
                        components={{
                          Toolbar: () => {
                            return CustomToolbar();
                          },
                        }}
                        checkboxSelection
                        hideFooterPagination={false}
                        // @ts-ignore
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          setGetSelectedIds(ids);
                        }} />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion><Accordion sx={{ marginTop: "0.5rem !important" }} expanded={expanded === "2"} onChange={handleChangeAccordian("2")}>
                <AccordionSummary
                  sx={{
                    minHeight: "37px !important"
                  }}

                  aria-controls="panel1d-content" id="panel1d-header">
                  <Typography>Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: "100%", height: "45rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    {rows && (
                      <DataGrid
                        headerHeight={48}
                        rowHeight={40}
                        rows={rows && rows}
                        columns={columnsDataGrid}
                        density="compact"
                        loading={Loader}
                        // @ts-ignore
                        disableSelectionOnClick={true}
                        // autoHeight={true}
                        sx={{
                          "& .MuiDataGrid-checkboxInput": {
                            color: "primary.main",
                          },
                          "& ::-webkit-scrollbar": {
                            width: "0.3rem",
                            height: "0.3rem",
                          },
                          "& ::-webkit-scrollbar-thumb": {
                            // background: $thumb-color;
                            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                            borderRadius: "10px",
                          },
                          "& ::- webkit-scrollbar-thumb: hover ": {
                            // width: "4px",
                            backgroundColor: "#045DE9 !important",
                          },
                          "::-webkit-scrollbar-track": {
                            backgroundColor: "#e1e1f3 !important",
                          },
                          "&.MuiDataGrid-root": {
                            border: 'none',
                          },
                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                            borderBottomColor: "primary.light",
                          },
                        }}
                        components={{
                          Toolbar: () => {
                            return CustomToolbar();
                          },
                        }}
                        checkboxSelection
                        hideFooterPagination={false}
                        // @ts-ignore
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          setGetSelectedIds(ids);
                        }} />
                    )}
                  </Box>
                </AccordionDetails>
              </Accordion> */}
            </>
          }
        </Box>
      </Box>
      {AddPartNameModal.isOpen && (
        <AddFeatureInformationModal
          ModalType={"Add"}
          RowItem={"null"}
          isOpen={AddPartNameModal.isOpen}
          onCloseHanlder={AddPartNameModal.close}
          setCounter={setCounter}
        />
      )}
      {AddPartNameModalEdit.isOpen && (
        <AddFeatureInformationModal
          ModalType={"Edit"}
          RowItem={RowItems && RowItems}
          isOpen={AddPartNameModalEdit.isOpen}
          onCloseHanlder={AddPartNameModalEdit.close}
          setCounter={setCounter}
        />
      )}
    </Box>
  );
};

export default FeatureNamesTable;
