import React, { useEffect, useState } from 'react'
import Modal from "@mui/material/Modal";
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: " 50rem",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };

function DecimalModal({decimalPoints, setDecimalPoints, isOpen, onCloseModal, setCount, setScenarioCount}:any) {
    const [decimalPointss, setDecimalPointss] = useState<any>();

      React.useEffect(() => {
   
    if (decimalPoints) {
      setDecimalPointss(Number(decimalPoints));
    } else {
      setDecimalPointss(3);
    }
  }, [decimalPoints]);



    const handleSubmit = () => {
        
        setDecimalPoints(Number(decimalPointss))
        localStorage.setItem("decimalPoint", String(decimalPointss));
   
        onCloseModal();

        
    }
  return (
    <Modal
    open={isOpen}
    onClose={onCloseModal}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Set Decimal Precision
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{padding:"2rem 1rem"}}>
          <Typography variant="body2" mb={2}>
            Enter the number of digits you want to display after the decimal point for values.
          </Typography>

          <form onSubmit={(e) => e.preventDefault()}>
          <TextField
                autoFocus
                variant="standard"
                InputProps={{
                  style: { color: "#007fff", fontSize: "1rem" },
                }}
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                value={decimalPointss}
              onChange={(e) => setDecimalPointss(e.target.value)}
                sx={{
                    marginBottom:"2rem",
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                label="Decimal Points *"
                inputProps={{
                    min: 0, // Minimum value of 0 for decimal points
                    type: "number", // Ensure it's treated as a number input
                  }}
              />

           
            <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
              
              <Button onClick={handleSubmit} variant="contained" color="primary">
                Apply
              </Button>
            </Box>
          </form>
          </Box>
    </Box>
    </Modal>
  )
}

export default DecimalModal