import * as React from "react";
import Box from "@mui/material/Box";

import { Backdrop, ButtonProps, Divider, Modal, Skeleton, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  item:any;
}

function DatabaseAllInfo(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal ,item} = props;
  const { revisionId } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading,setIsLoading]=React.useState<any>(false);
  const getInfoData = () => {
    setIsLoading(true)
    API.get(
      "/api/db/check_variant/",
      {
        type: item?.type,
        id: item?.id
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false)
        let rows: any = [];
        const dataGridDataGenerator: any = res.data?.map(
          (infoItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              ...infoItem,
            };
            rows.push(objShared);
          }
        );
        setInfoData(rows);
      })
      .catch((err: any) => {
        setIsLoading(false)
      });
  };
  React.useEffect(() => {
    getInfoData();
  }, []);

  var Columns: any = [
    // {
    //   field: "id", headerName: 'Name', hide: true,
    //   minWidth: 50,
    //   flex: 1,
    // },
    {
      field: "part_name",
      headerName: "Part Name",
      minWidth: width > 1400 ? 250 : 220,
      flex: 1,
    },
    {
      field: "part_no",
      headerName: "Part No",
      minWidth: width > 1400 ? 250 : 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellValues?.row?.part_no}
            >
              <p>{cellValues?.row?.part_no}</p>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "project",
      headerName: "Project Name",
      minWidth: width > 1400 ? 250 : 200,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellValues?.row?.project}
            >
              <p>{cellValues?.row?.project}</p>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "costing_name",
      headerName: "Costing Type",
      minWidth: width > 1400 ? 200 : 120,      
      rendeCell: (cellvalues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellvalues?.row?.costing_name}
            >
              <p>{cellvalues?.row?.costing_name}</p>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
               Usage Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider />
          <Box
            sx={{ height: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.4"
          >
            {!isLoading && infoData ? (
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={infoData && infoData}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            ):<Box>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default DatabaseAllInfo;
