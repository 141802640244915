import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Modal,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { QRCode } from "react-qrcode-logo";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import IFrame from "./ImageIFrame/ImageIFrame";
import { fetchS3Url } from "../../../CustomHook/s3-uri.service";
import ErrorModalFilesMail from "../../ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { LoadingButton } from "@mui/lab";
import { useReactToPrint } from "react-to-print";
import { useStage } from "spritespin";
import { useHistory } from "react-router-dom";
import { debounce, set } from "lodash";
import { CheckBoxComponent } from "./CheckBoxComponent";
import { NumberInputBox } from "./NumberInputBox";
import { TextInputBox } from "./TextInputBox";
import { DescriptionInputBox } from "./DescriptionInputBox";
import { SingleSelectInputBox } from "./SingleSelectInputBox";
import { ADMIN } from "../../../Redux/Services/admin.service";
import Swal from "sweetalert2";
import { DatabaseInputBox } from "./DatabaseInputBox";
import { BooleanInputBox } from "./BooleanInputBox";
import { DateInputBox } from "./DateInputBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "35vw", xl: "30vw" },

  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IBOMEntryAddBOMPartModalProps {
  onCloseModal: any;
  isOpen: any;
  getData: any;
  setParentsCounter: any;
  AssemblyData: any;
  SucessAndErrorMsgVault: any;
  copypartmsg:any;
}

export default function BOMEntryAddBOMPartModal(
  props: IBOMEntryAddBOMPartModalProps
) {
  const {
    onCloseModal,
    isOpen,
    getData,
    setParentsCounter,
    AssemblyData,
    SucessAndErrorMsgVault,
    copypartmsg
  } = props;
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus
  } = useRouteParams<any>();
  const imageIframeRef = React.useRef<any>(null);

  const [PartAssemblyData, setPartAssemblyData] =
    React.useState<any>([]);
  const [contentRef, setContentRef] = React.useState<any>();
  const [print, setPrint] = React.useState<any>(0);
  const [quantity, setquantity] = React.useState<any>(1);
  const inputRef = React.useRef<any>(null);
  const [SelectedPart, setSelectedPart] = React.useState<any>({});
  const [UserManualEamil, setUserManualEamil] = React.useState<any>({});
  const [Loader, setLoader] = React.useState<any>(false);
  const ErrorModalFiles = useBaseModal();
  const [InfoData, setInfodata] = React.useState<any>([]);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [newVaultID, setNewVaultID] = React.useState<any>();
  const [PartError, SetPartError] = React.useState<any>(false);
  const [CategoryError, SetCategoryError] = React.useState<any>(false);
  const [SelectedCategory, setSelectedCategory] = React.useState<any>({});
  const [categoryList, setcategoryList] = React.useState<any>([]);
  const history = useHistory<any>();
  const [WorkStationList,setWorkStationList]=React.useState<any>([])
  const [BOMparameters, setBOMparameters] = React.useState<any>([]);
  const [details, setDetails] = React.useState<any>({});
  const [allValues, setAllValues] = React.useState<any>({});
  const [loadingWeightLock, setLoadingWeightLock] = React.useState<any>(false);
  const [partNameErrorMsg, setPartNameErrorMsg] = React.useState<any>("Please select Part/Assembly");
  const [isBOMparametersLoading, setIsBOMparametersLoading] =
    React.useState<boolean>(false);
  const handleSelectChangeAddBOMPart = (newSystemValue: any) => {
    
    
      setSelectedPart(newSystemValue);
      SetPartError(false);
    
    
  };

  const handleChange = (e: any) => {
    setquantity(e?.target?.value);
  };
  const handleSelectChangecategory = (event: any, newSystemValue: any) => {
    setSelectedCategory(newSystemValue);
    SetCategoryError(false);
  };
  const handlePrint = useReactToPrint({
    content: () => imageIframeRef.current,
  });

  const PrintHaandler = () => {
    if (
      Object?.keys(SelectedCategory)?.length > 0 &&
      Object.keys(SelectedPart)?.length > 0
    ) {
      setLoader(true);
      API.post(
        `xcpep/vault_info/`,
        {
          name: SelectedPart?.name,
          category: SelectedCategory?.category,
          quantity: +quantity,
          parent: getData?.id,
          top_vault: InfoData[0]?.top_vault,
        },
        {},
        0
      )
        .then((res: any) => {
          // @ts-ignore
          setLoader(false);

          setNewVaultID(res?.data?.id);
          // onCloseModal();

          setContentRef(res?.data?.qr_link);
          //  fetchS3Url().then(setContentRef)
        


          API.get(
            "/auth/workstation/",
            {
              top_vault: topVault,
              vault:  res?.data?.id ,
              workstation_list: true,
            },
            0 
          )
            .then((res1: any) => {
              let CreateSation= res1?.data && res1?.data.filter((item:any,index:any)=>{return item?.name=="CS"})
              if(CreateSation && CreateSation?.length==0 ){
                if (SelectedCategory?.category_leaf == false) {
                  var data = [...InfoData[0]?.ancestors, newVaultID];
      
                  // @ts-ignore
      
                  history.push(
                    `/bomentry/${projectId}/${projectName}/${topVault}/${
                      res?.data?.id == undefined ? vault : res?.data?.id
                    }/${treeabbr}/${workstation}/${isLeaf}/${copyStatus}`
                  );
                  //
                  setContentRef(null);
                  onCloseModal();
                  sessionStorage.setItem("BOMCreateNode", JSON.stringify(data));
                  sessionStorage.setItem("newvault", res?.data?.id);
      
                  setParentsCounter((prev: any) => prev + 1);
                  if(getData?.is_copy==true){
                    copypartmsg()
                  }else{
                     SucessAndErrorMsgVault();
                  }
                 
                }

                if (SelectedCategory?.category_leaf == true) {
                  handlePrint();
                }
              }
             
            })
            .catch((err: any) => {
          
            });
       
          

          // setContentRef
        })
        .catch((err: any) => {

        
          setLoader(false);
          const { data } = err?.response;
          ErrorModalFiles?.open();
          setErrorModalFileMsg(data);
        });
    }
    if (
      Object?.keys(SelectedCategory)?.length == 0 ||
      Object?.keys(SelectedCategory)?.length == null
    ) {
      SetCategoryError(true);
    }
    if (
      Object?.keys(SelectedPart)?.length == 0 ||
      Object?.keys(SelectedPart)?.length == null
    )
      SetPartError(true);
      setPartNameErrorMsg("*Please select Part/Assembly");
  };


  const Proceedhandler=()=>{
    
  }
  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      await API.get("api/db/part_name/", { search_key: criteria ,vault: getData?.id},0).then(
        (res: any) => {
          setPartAssemblyData(res.data);
        }
      );
    }, 700)
  ).current;
  const PartNameHandler = (e:any,item: any) => {
    setUserManualEamil(item);
    if (e?.type == "change") {
      if(item.includes("/")){
        setPartNameErrorMsg("*Please select PartName or Assembly without '/'");
        SetPartError(true);
        // e?.type == "blur"
      }
      else if (item !== "False" && item?.length > 0) {
        ExactDebounce(item);
      }
    }
  };

  const ManualEmailHandler = (e: any) => {
    if(e?.key == "/"){
      if(inputRef.current){
        inputRef.current.blur();
      }
    }
    else{

    
    var filterdata =
      PartAssemblyData &&
      PartAssemblyData.filter(
        (item: any, index: any) => item?.name == UserManualEamil
      );

    if (filterdata?.length == 0 ) {
      if (e.key == "Enter") {
        {
          API.post(`api/db/part_name/`, {
            name: UserManualEamil,

            vault: getData?.id,
          },{},0)
            .then((res1: any) => {
              API.get(`api/db/part_name/`, { search_key: UserManualEamil, vault: getData?.id},0)
                .then((res: any) => {
                  setPartAssemblyData(res?.data);
                })
                .catch((err: any) => {
                  console.log("Server Error");
                });
            })
            .catch((err: any) => {
              const { data } = err?.response;
              ErrorModalFiles?.open();
              setErrorModalFileMsg(data);
            });
        }
      }
    }
  }
  };

  const PasteEmailHandler = (data: any) => {
    var filterdata =
      PartAssemblyData &&
      PartAssemblyData.filter((item: any, index: any) => item?.name == data);

    if (filterdata?.length == 0) {
      API.post(
        `api/db/part_name/`,
        {
          name: data,

          vault: getData?.id,
        },
        {},
        0
      )
        .then((res1: any) => {
          API.get(`/api/db/part_name/`, { vault: getData?.id }, 0)
            .then((res: any) => {
              setPartAssemblyData(res?.data);
            })
            .catch((err: any) => {
              console.log("Server Error");
            });
        })
        .catch((err: any) => {
          const { data } = err?.response;
          ErrorModalFiles?.open();
          setErrorModalFileMsg(data);
        });
    }
  };

  React.useEffect(() => {
    API.get("/xcpep/vault_info/", { vault: getData?.id })
      .then((res1: any) => {
        setInfodata(res1?.data);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  }, [getData]);

  React.useEffect(() => {
    if (InfoData.length > 0) {
      API.get(
        "/config/template_config/",
        { top_vault: InfoData[0]?.top_vault, bom_create: true },
        0
      )
        .then((res: any) => {
          setcategoryList(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  }, [InfoData]);

  React.useEffect(() => {
    setPartAssemblyData(AssemblyData);
  }, [AssemblyData]);


React.useEffect(() => {
  if(newVaultID!==undefined){
    getBOMParametersData();
  }

}, [vault, WorkStationList, topVault,newVaultID]);


let CreateSation= WorkStationList && WorkStationList?.filter((item:any,index:any)=>{return item?.name=="CS"})


 const getBOMParametersData = () => {
  setIsBOMparametersLoading(true);
  API.get(
    "/xcpep/bom_create_view/",
    {
      ...obj(topVault, newVaultID),
      part_details: true,
      workstation: CreateSation && CreateSation[0]?.id,
    },
    0
  )
    .then((res: any) => {
      setIsBOMparametersLoading(false);
      setBOMparameters(res.data);
    })
    .catch((err: any) => {
      setIsBOMparametersLoading(false);
    });
};




  const handleClose = () => {
    if (
      Object?.keys(SelectedCategory)?.length > 0 &&
      Object.keys(SelectedPart)?.length > 0
    ) {
      var data = [...InfoData[0]?.ancestors];
  if(getData?.is_copy==true){
    copypartmsg()
  }
      // @ts-ignore

    
      history.push(
        `/bomentry/${projectId}/${projectName}/${topVault}/${
          newVaultID == undefined ? vault : newVaultID
        }/${treeabbr}/${ CreateSation && CreateSation?.length >0?CreateSation && CreateSation[0]?.id: workstation}/${isLeaf}/${copyStatus}`
      );
      // //
      setContentRef(null);
      
      sessionStorage.removeItem("BOMCreateNode");
      sessionStorage.setItem("BOMCreateNode", JSON.stringify(data));
      sessionStorage.setItem("newvault", newVaultID);

      setParentsCounter((prev: any) => prev + 1);
      document.getElementById(newVaultID)?.scrollIntoView({
        block: "nearest",
        inline: "start",
        behavior: "smooth",
      });
      window.location.reload();
      onCloseModal();
    } else {
      setContentRef(null);
      onCloseModal();
    }
  };
React.useEffect(()=>{
if(newVaultID!==undefined){
  API.get(
    "/auth/workstation/",
    {
      top_vault: topVault,
      vault: newVaultID != 0 ? newVaultID : undefined,
      workstation_list: true,
    },
    0 
  )
    .then((res: any) => {
   
      setWorkStationList(res.data);
     
    })
    .catch((err: any) => {
  
    });
}



},[newVaultID])






const obj = (tv: any, vlt: any) => {
  if (vlt == 0) {
    return { top_vault: tv };
  } else {
    return { vault: vlt };
  }
};

const getobj = (v: any, tv: any) => {
  if (v == 0) {
    return { top_vault: tv };
  } else {
    return { vault: v };
  }
};

const getVaultDetails = () => {
  setLoadingWeightLock(true);
  API.get(
    "/xcpep/vault_info/",
    {
      ...obj(topVault, newVaultID),
      module: 9,
      current_top_vault: topVault,
    },
    0
  )
    .then((res: any) => {
      setDetails(res.data[0]);
      // setBOMStatus(res.data[0]?.status)
      
      // setWeightLockStatus(res.data[0]?.weight_lock);
      // setLoadingWeightLock(false);
    })
    .catch((err: any) => {});
};


const getAllValues = () => {
  API.get("/xcpep/component_details/", { ...obj(topVault, newVaultID) }, 0)
    .then((res: any) => {
      setAllValues(res.data[0]?.details);
    })
    .catch((err: any) => {});
};

React.useEffect(() => {
  if(newVaultID!==undefined){
    getAllValues();
  }

}, [vault, newVaultID]);
React.useEffect(() => {

  if(newVaultID!==undefined){
    getVaultDetails();
  }

}, [vault, newVaultID]);



const BOMValidationStatus = () => {
  API.get(
    "/xcpep/bom_hirearchy_function/",
    {
      bom_status: true,
      ...obj(topVault, newVaultID),
    },
    0
  )
    .then((res: any) => {
      // setBOMStatus(res.data?.bom_status);
    })
    .catch((err: any) => {});
};




const ExactDebounceParameters = React.useRef(
  debounce(async (value: any, name: any, idd: any, tv) => {
    await updateDataVaultParams(name, value, idd, tv);
  }, 1000)
).current;



React.useEffect(() => {
  return () => {
    ExactDebounce.cancel(); // Cancel the debounced function
    ExactDebounceParameters.cancel(); // Cancel the debounced function
  };
}, []);




const updateDataVaultParams = async (
  name: any,
  value: any,
  vlt: any,
  tv: any
) => {
  API.put(
    `/xcpep/component_details/74699/`,
    {
      [name]: value,
    },
    {
      ...getobj(vlt, tv),
    },
    0
  )
    .then((res: any) => {
      // setSyncColor("red");
      // getBOMParametersData();
      BOMValidationStatus();
      ADMIN.toast.info("Updated Successfully");
    })
    .catch((err: any) => {
      const { data } = err.response;
      Swal.fire({
        title: "Error!",
        text: data[0],
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
};


const handleSelectSystems = (
  event: any,
  newSystemValue: any,
  key: any,
  v: any,
  tv: any
) => {
  ExactDebounceParameters(newSystemValue, key, v, tv);
};


 const renderInputs = (item: any, index: any) => {
  switch (item?.data_type) {
    case "number":
    case "float":
    case "nonzero-positive-int":
    case "nonzero-positive-float":
    case "positive-float":
    case "nonzero-float":
    case "percentage":
    case "positive-int":
    case "integer":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {!item?.exceptions?.includes(item?.value) ? (
              <NumberInputBox
                item={item}
                details={details && details}
                vaultIdd={newVaultID}
                vaultpropId={newVaultID}
                modalType={"modalform"}
               
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            ) : (
              item?.value
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );
    case "text":
    case "string":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {
              <TextInputBox
                item={item}
                details={details}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            }
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );

    case "textarea":
    case "longtext":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {
              <DescriptionInputBox
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                details={details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            }
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );
    case "dropdown":
    case "singleselect":
    case "country":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>

          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          > 
            <SingleSelectInputBox
              item={item}
              details={details}
              modalType={"modalform"}
              vaultpropId={newVaultID}
              BOMValidationStatus={BOMValidationStatus}
              allValues={allValues && allValues}
              getAllValues={getAllValues}
            />
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );

    case "multiselect":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                 vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <FormControl fullWidth>
              <Autocomplete
                multiple
                id="tags-standard"
                // disabled={
                //   (copyStatus == false || copyStatus == "false") &&
                //   (UserAction?.includes("U") || UserAction?.includes("C"))
                //     ? false
                //     : true
                // }
                limitTags={1}
                // onChange={handleOnChange}
                disableClearable
                disableCloseOnSelect
                sx={{
                  ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                    {
                      color: "primary.main",
                    },
                  ".MuiButtonBase-root.MuiChip-root": {
                    backgroundColor: "primary.light",
                    height: "26px",
                  },
                }}
                options={item?.allowed_values}
                getOptionLabel={(option: any) => option}
                defaultValue={item?.value || []}
                onChange={(event: any, newSystemValue: any) => {
                  handleSelectSystems(
                    event,
                    newSystemValue,
                    item?.key,
                    newVaultID,
                    topVault
                  );
                }}
                // getOptionDisabled={getOptionDisabled}
                renderOption={(props, option) => (
                  <li {...props} style={{ fontSize: "12px" }}>
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Option"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "transparent",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "12px !important",
                        backgroundColor: "rgba(255,255,255,0.3)",
                        paddingLeft: "0.3rem",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </FormControl>
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );
    case "boolean":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {!item?.exceptions?.includes(item?.value) ? (
              <BooleanInputBox
                item={item}
                details={details && details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            ) : (
              item?.value
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );

    case "date":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {!item?.exceptions?.includes(item?.value) ? (
              <DateInputBox
                item={item}
                details={details && details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            ) : (
              item?.value
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );

    case "database":
      return (
        <TableRow sx={{ backgroundColor: item?.color }}>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              lineHeight: 1,
            }}
          >
            {item?.paramter_name}{" "}
            {item?.required && <sup style={{ color: "red" }}>*</sup>}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.exceptions?.length > 0 && (
              <CheckBoxComponent
                check={item?.exceptions?.includes(item?.value)}
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                getBOMParametersData={getBOMParametersData}
                BOMValidationStatus={BOMValidationStatus}
              />
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {!item?.exceptions?.includes(item?.value) ? (
              <DatabaseInputBox
                item={item}
                modalType={"modalform"}
                vaultpropId={newVaultID}
                details={details && details}
                BOMValidationStatus={BOMValidationStatus}
                allValues={allValues && allValues}
                getAllValues={getAllValues}
              />
            ) : (
              "NA"
            )}
          </TableCell>
          <TableCell
            sx={{
              fontSize: "12px",
              padding: "0.1rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {item?.unit ? item?.unit : "-"}
          </TableCell>
        </TableRow>
      );
  }
};

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                whiteSpace: "nowrap",
                // marginRight: "-2rem",
              }}
            >
              Add Part/Assembly to {getData?.name} ({getData?.part_no})
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => handleClose()}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem 1rem" }}>
            {/* <FormControl fullWidth> */}
            {
              <Autocomplete
                id="User"
                value={SelectedPart}
                // disableCloseOnSelect
                // sx={{ width: { lg: '87.5vw', xl: "93vw" }, }}

                onInputChange={(event, newInputValue) => {
                  PartNameHandler(event,newInputValue);
                }}
                onChange={(event, newValue) => {
                  handleSelectChangeAddBOMPart(newValue);
                }}
                onPaste={(event: any) => {
                  PasteEmailHandler(event.clipboardData.getData("text"));
                }}
                options={PartAssemblyData && PartAssemblyData}
                selectOnFocus
                // @ts-ignore
                getOptionLabel={(option) =>
                  // @ts-ignore
                  option?.name == undefined ? "" : option?.name
                }
                onKeyDown={(e: any) => ManualEmailHandler(e)}
                renderOption={(props, option) => (
                  // @ts-ignore
                  <li {...props} style={{ fontSize: "1rem" }}>
                    {/* @ts-ignore */}
                    {option?.name}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Part Name / Assembly"
                    inputRef={inputRef}
                    sx={{
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "#007fff",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#007fff",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                      // @ts-ignore
                      // backgroundColor: `${params?.user_color}`,
                    }}
                    //   InputProps={{
                    //     // background: `${params?.user_color}`
                    //     // @ts-ignore
                    //     style: "background-color: ${params?.user_color",

                    //  }}
                  />
                )}
              />
            }
            {PartError ? (
              <span style={{ color: "red" }}>                
               {partNameErrorMsg}
              </span>
            ) : (
              ""
            )}

            {/* </FormControl> */}
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                columnGap: "1rem",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <FormControl fullWidth variant="standard">
                <Autocomplete
                  id="tags-standard"
                  // disableClearable
                  // disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={categoryList}
                  getOptionLabel={(option) =>
                    // @ts-ignore
                    option?.category_name == undefined
                      ? ""
                      : option?.category_name
                  }
                  value={SelectedCategory}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectChangecategory(event, newSystemValue);
                  }}
                  // getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {option?.category_name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Select Category"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />

                {CategoryError ? (
                  <span style={{ color: "red" }}> *Please Select Category</span>
                ) : (
                  ""
                )}
              </FormControl>
              <FormControl fullWidth>
                <TextField
                  value={quantity}
                  onChange={(e: any) => handleChange(e)}
                  variant="standard"
                  type="number"
                  inputProps={{
                    min:1
                  }}
                  InputLabelProps={{
                    shrink: true,
                    style:{
                    color:'#007fff'
                    }
                  }}
                  label="Quantity"
                  placeholder="Quantity"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              </FormControl>
            </Box>
           
            {contentRef && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  columnGap: "1rem",
                  width: "100%",

                  marginTop: "1rem",
                }}
              >
                <Box
                  sx={
                    {
                      // width: "220px",
                      // height: "220px",
                      // padding: "0.5rem",
                      // borderRadius: "10px",
                      // border: "1px solid",
                      // borderColor: "primary.light",
                      // textAlign: "center",
                    }
                  }
                >
                  { <>
                  { contentRef && (
                    <>
                      {/* <IFrame  Condition={contentRef}  style={{ width: '100%' }} > */}
                      <div
                        ref={imageIframeRef}                        
                        style={{ textAlign: "center" }}
                      >
                        <img src={contentRef} height={200} width={200} />
                      </div>
                      {/* </IFrame> */}
                    </>
                  )}
                  </>}
                  {/* <QRCode style={{ width: "100%", height: "100%" }} />
                <Typography sx={{fontSize:'1rem',textAlign:'center',width:'100%'}}> 2203-BA-021-D </Typography> */}
                </Box>
                <Box
                  sx={{
                    marginTop: "13rem",
                  }}
                >
                  {/* <Button
                  size="small"
                  variant="contained"
                  disabled
                  sx={{
                    width: "10rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  Create Part
                </Button> */}
                </Box>
              </Box>
            )}
 {contentRef && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <LoadingButton
                  loading={Loader}
                  size="small"
                  variant="contained"
                  onClick={() => handlePrint()}
                  sx={{
                    width: "10rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  Print QR
                </LoadingButton>
              </Box>
            )}
{CreateSation &&  CreateSation?.length >0 &&

<Table stickyHeader aria-label="sticky table" sx={{marginTop:"2rem !important"}}>
          <TableHead sx={{ backgroundColor: "primary.main" }}>
            <TableRow>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {xs:'12rem',sm:'12vw',md:'12vw', lg: "12vw", xl: "17vw" },
                }}
              >
                Parameters
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {xs:'3rem',sm:'3rem',md:'3rem', lg: "2rem", xl: "3rem" },
                }}
              ></TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {xs:"25rem",sm:"25vw",md:"25vw", lg:"25vw", xl:"25vw"},
                }}
              >
                Value
              </TableCell>
              <TableCell
                sx={{
                  fontSize: "12px",
                  padding: "0.1rem 0.5rem",
                  borderBottomColor: "primary.light",
                  backgroundColor: "primary.main",
                  color: "white",
                  width: {xs:"7rem",sm:"7rem",md:"7rem", lg:"7rem", xl:"7rem"},
                }}
              >
                Unit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isBOMparametersLoading && BOMparameters ? (
              BOMparameters?.map((item: any, index: any) => {
                return renderInputs(item, index);
              })
            ) : (
              <>
                <TableRow>
                  <TableCell
                    sx={{ padding: "1rem 0.5rem", borderBottom: "none" }}
                    colSpan={4}
                  >
                    <Box sx={{ width: "100%" }}>
                    
                    
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        height="2rem"
                        width="100%"
                        sx={{ marginBottom: "1rem" }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
}
        <Box
              sx={{
                display: "flex",
                marginTop: "1rem",
                justifyContent: "end",
              }}
            >
              {SelectedCategory &&
                Object?.keys(SelectedCategory)?.length > 0 && (
                  <>
                    {contentRef == null && (<>


{CreateSation &&  CreateSation?.length>0 ? <>
  
  
  

                      <LoadingButton
                        // loading={Loader}
                        size="small"
                        variant="contained"
                        onClick={() => handleClose()}
                        sx={{
                          width: "8rem",
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                       Proceed
                      </LoadingButton>
                      </>
                      
                      :
                      
                      <>
                      <LoadingButton
                      loading={Loader}
                      size="small"
                      variant="contained"
                      onClick={() => PrintHaandler()}
                      sx={{
                        width: "8rem",
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      {SelectedCategory?.category_leaf == true
                        ? "Get QR"
                        : "ADD"}
                    </LoadingButton>
                    </>
                      
                      
                      }
                      </> )}
                  </>
                )}
            </Box>
           
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
