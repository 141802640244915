import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/ViewComponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LaunchIcon from "@mui/icons-material/Launch";
import CalculateIcon from "@mui/icons-material/Calculate";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import CalculatorConfigDataModal from "../../Configuration/Costing/CalculatorConfigDataModal";
import InfoIcon from "@mui/icons-material/Info";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import WorkFlowOtherInfoModal from "../CostingCreateModule/CostingEstimation/CalculatorCard/Calculator/CalculatorRow/SelectedDatabaseRecordInfoModal/WorkFlowOtherInfoModal";
import InfoTreeModal from "../../Configuration/Costing/InfoTreeModal";
import { BomTemplateinfoModal } from "../../Configuration/BOM/BomTemplateinfoModal";
import BOMIcon from "../../Assets/images/hierarchy.png";
import DatabaseIcon from "../../Assets/images/database.svg";
import BucketIcon from "../../Assets/images/svgs/bucket.svg";
import CostModelIcon from "../../Assets/images/calculator.svg";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { BucketFetchedInfoModal } from "../../Configuration/BOM/BucketFetchedInfoModal";
import DatabaseAllInfo from "../../DataBaseModule/Costing/DatabaseAllInfo";
function TransitionComponent(props: TransitionProps) {
  /*  */
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
}
const HierarchyTree = (props: TreeViewComponentsProps) => {
  const { HierarchyData, onSelectCallback } = props;
  const otherInfo = useBaseParamsModal();
  const bucketFetched = useBaseParamsModal();
  const databaseInfo = useBaseParamsModal();
  const infoModal = useBaseParamsModal();
  const infoModalBOM = useBaseParamsModal();
  const { topVault, vault, projectId, projectname } = useParams<any>();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  // useEffect(()=>{
  //   sessionStorage.setItem(
  //     "workflowNode",
  //     JSON.stringify([Number(topVault)])
  //   );

  // },[])

  const renderTable = (tableData: any, name: any, data: any) => {
    if (name) {
      switch (name) {
        case "bom":
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Lock
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "8rem",
                      }}
                    >
                      Usage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData?.map((item: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.name || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.value || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.unit || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.lock ? (
                              <LockIcon style={{ color: "green" }} />
                            ) : (
                              <LockOpenIcon style={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              textDecoration: item?.usage_count > 0 ? "underline" : "none",
                              cursor: item?.usage_count > 0 ? "pointer" : "default",
                            }}
                            key={index}
                            onClick={()=>{
                              if(item?.usage_count > 0){
                                infoModalBOM.open(item)
                              }
                            }}
                          >
                            {/* <InfoIcon
                              sx={{ color: "primary.main", cursor: "pointer" }}
                              onClick={() => {
                                infoModalBOM.open(item);
                              }}
                            /> */}
                            {item?.usage_count || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          );
        case "bucket":
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Type
                    </TableCell>
                    {/* <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      backgroundColor: "primary.light",
                      width: "10%",
                    }}
                  >
                    Lock
                  </TableCell> */}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Usage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData?.map((item: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.name || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.value || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.unit || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.type || "--"}
                          </TableCell>
                          {/* <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                        key={index}
                      >
                        {item?.lock ? (
                          <LockIcon style={{ color: "green" }} />
                        ) : (
                          <LockOpenIcon style={{ color: "red" }} />
                        )}
                      </TableCell> */}
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              textDecoration:
                                item?.usage_count > 0 ? "underline" : "none",
                              cursor:
                                item?.usage_count > 0 ? "pointer" : "default",
                            }}
                            key={index}
                            onClick={() => {
                              if (item?.usage_count > 0) {
                                bucketFetched.open(item);
                              }
                            }}
                          >
                            {/* <InfoIcon sx={{ color: "primary.main",cursor:'pointer' }} onClick={()=>{
                          infoModalBOM.open(item)
                        }} /> */}
                            {item?.usage_count || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          );
        case "bct_pcb":
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Serial No.
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Description
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData?.map((item: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.serial_no || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.name || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.description || "--"}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          );
        case "revision":
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Lock
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Usage
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Info
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData?.map((item: any, index: any) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.name || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.value || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.unit || "--"}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            {item?.lock ? (
                              <LockIcon style={{ color: "green" }} />
                            ) : (
                              <LockOpenIcon style={{ color: "red" }} />
                            )}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              textDecoration:
                                item?.usage_count > 0 ? "underline" : "none",
                              cursor:
                                item?.usage_count > 0 ? "pointer" : "default",
                            }}
                            onClick={() => {
                              if (item?.usage_count > 0) {
                                databaseInfo.open(item);
                              }
                            }}
                            key={index}
                          >
                            {/* <InfoIcon
                              sx={{ color: "primary.main", cursor: "pointer" }}
                            /> */}
                            {item?.usage_count || 0}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                            key={index}
                          >
                            <InfoIcon
                              sx={{ color: "primary.main", cursor: "pointer" }}
                              onClick={() => {
                                otherInfo.open(item?.other_info);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          );
        case "volume":
          return <Box
          sx={{
            marginLeft: "1rem",
            marginTop: "10px",
            maxHeight: "15rem",
            overflow: "auto",
          }}
        >
          <Table sx={{ width: "100%" }} stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    backgroundColor: "primary.light",
                  }}
                >
                  Parameter
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    backgroundColor: "primary.light",
                    width: "15%",
                  }}
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    backgroundColor: "primary.light",
                    width: "10%",
                  }}
                >
                  Unit
                </TableCell>
             
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData?.map((item: any, index: any) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                      key={index}
                    >
                      {item?.name || "--"}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                      key={index}
                    >
                      {item?.value || "--"}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}
                      key={index}
                    >
                      {item?.unit || "--"}
                    </TableCell>
                   
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Box>
        case "costmodel":
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Lock
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Usage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.name || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.value || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.unit || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.lock ? (
                            <LockIcon style={{ color: "green" }} />
                          ) : (
                            <LockOpenIcon style={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            textDecoration:
                              item?.usage_count > 0 ? "underline" : "none",
                            cursor:
                              item?.usage_count > 0 ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if (item?.usage_count > 0) {
                              infoModal.open(item.id);
                            }
                          }}
                          key={index}
                        >
                          {item?.usage_count || 0}
                          {/* <InfoIcon sx={{ color: "primary.main",cursor:'pointer' }} /> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          );
        default:
          return (
            <Box
              sx={{
                marginLeft: "1rem",
                marginTop: "10px",
                maxHeight: "15rem",
                overflow: "auto",
              }}
            >
              <Table sx={{ width: "100%" }} stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "15%",
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Lock
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        width: "10%",
                      }}
                    >
                      Usage
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((item: any, index: any) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.name || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.value || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.unit || "--"}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {item?.lock ? (
                            <LockIcon style={{ color: "green" }} />
                          ) : (
                            <LockOpenIcon style={{ color: "red" }} />
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                          key={index}
                        >
                          {/* <InfoIcon sx={{ color: "primary.main",cursor:'pointer' }} /> */}
                          --
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          );
      }
    }
  };
  const calcModalOpen = useBaseParamsModal();
  const [expanded, setExpanded] = React.useState<any>([parseInt(topVault)]);
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>(parseInt(topVault));
  const openCostingConfiguration = (ed: any, data: any) => {
    ed.stopPropagation();
    ed.preventDefault();
    history.push(
      `/costingconfiguration-lock-flow/${projectId}/${projectname}/${topVault}/${vault}/${HierarchyAllData?.id}/calculator`
    );
    calcModalOpen.open(data);
  };
  React.useEffect(() => {
    let a: any = sessionStorage.getItem("workflowNode");
    let val: any = JSON.parse(a) || [];
    if (!val?.includes(parseInt(topVault))) {
      // sessionStorage.setItem(
      //   "workflowNode",
      //   JSON.stringify([parseInt(topVault)])
      // );
      // sessionStorage.setItem("node", JSON.stringify([parseInt(topVault)]));
    }
  }, [topVault]);

  const getIcons = (data: any) => {
    if (data?.button_group === "bom") {
      return <img src={BOMIcon} style={{ width: "1.2rem" }} />;
    } else if (data?.button_group === "database") {
      return <img src={DatabaseIcon} style={{ width: "1rem" }} />;
    } else if (data?.button_group === "bucket") {
      return <img src={BucketIcon} style={{ width: "1rem" }} />;
    } else if (data?.button_group === "costmodel") {
      return <img src={CostModelIcon} style={{ width: "1rem" }} />;
    } else if (data?.button_group === "costinput") {
      return <CurrencyRupeeIcon />;
    } else if (data?.button_group === "assumptioncard") {
      return <CreditCardIcon />;
    } else {
      return <FolderIcon />;
    }
  };

  // useEffect(()=>{return sessionStorage.setItem("node",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (
    e: any,
    nodeId: any,
    abbreviation: any,
    statusss: any
  ) => {
    let a: any = sessionStorage.getItem("workflowNode");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != nodeId;
      });
      sessionStorage.setItem("workflowNode", JSON.stringify(noDuplicate));
    } else {
      val?.push(nodeId);
      sessionStorage.setItem("workflowNode", JSON.stringify(val));
    }
    setActiveId(nodeId);
    onSelectCallback(nodeId, abbreviation, topVault, statusss);
    const index = expanded.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === 1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };
  const getBCColor = (lock: any) => {
    if (lock === true) {
      return "green";
    } else {
      return "red";
    }
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("workflowNode");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [topVault, sessionStorage.getItem("workflowNode")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  useEffect(() => {
    document.getElementById(vault)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, [vault]);

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={getIcons(HierarchyAllData)}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.expanded !== false &&
          getIcons(HierarchyAllData)
        }
        defaultExpandIcon={
          HierarchyAllData?.expanded !== false && getIcons(HierarchyAllData)
        }
        defaultEndIcon={
          !HierarchyAllData?.expanded
            ? getIcons(HierarchyAllData)
            : getIcons(HierarchyAllData)
        }
        onNodeSelect={(e: any) =>
          handleChange(
            e,
            HierarchyAllData?.id,
            HierarchyAllData?.abbreviation,
            HierarchyAllData?.status
          )
        }
        // selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          maxWidth: "100%",
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == vault
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          //   onMouseEnter={() =>
          //     hoverData(
          //       `${HierarchyAllData?.part_no} ${
          //         HierarchyAllData?.part_no ? "x" : ""
          //       } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,
          //       HierarchyAllData.id
          //     )
          //   }
          title={titleString && url.includes("costing") && titleString}
          label={
            <Box
              className={styles.treeViewData}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                color: getBCColor(HierarchyAllData?.lock),
              }}
            >
              <Box
                sx={{
                  width: "30%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor:
                    HierarchyAllData?.id === 1 ? "primary.light" : "white",
                  // textDecoration: HierarchyAllData?.table_type === "cost_model" ? "underline" : "none",
                }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    // color: HierarchyAllData?.colour,
                    lineHeight: "1.2",
                    padding: "0.2rem 0",
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                    width: "100%",
                  }}
                  className={styles.treeViewText}

                  // onContextMenu={(event: any) =>
                  //   handleClick(
                  //     event,
                  //     HierarchyAllData.id,
                  //     HierarchyAllData.expanded,
                  //     HierarchyAllData
                  //   )
                  // }stopPropagation
                >
                  {HierarchyAllData?.name}
                  <br /> {HierarchyAllData?.value ? "Value:" : ""}{" "}
                  {HierarchyAllData?.value
                    ? HierarchyAllData?.value?.toFixed(2)
                    : ""}
                  {/* <Box> */}
                  {/* </Box> */}
                </Typography>
                {HierarchyAllData?.table_type === "cost_model" && (
                  <CalculateIcon
                    fontSize="small"
                    sx={{
                      cursor: "pointer",
                      color: "primary.main",
                      marginRight: "1rem",
                    }}
                    onClick={(ed: any) => {
                      if (HierarchyAllData?.table_type === "cost_model") {
                        openCostingConfiguration(ed, HierarchyAllData);
                      }
                    }}
                  />
                )}
                {/* {HierarchyAllData?.redirection && (
                  <LaunchIcon
                    fontSize="small"
                    sx={{ cursor: "pointer", color: "primary.main" }}
                    onClick={() => {
                      if (HierarchyAllData?.name === "BOM") {
                        sessionStorage.setItem(
                          "BOMCreateNode",
                          JSON.stringify(HierarchyAllData?.ancestors)
                        );
                        // :projectId/:projectName/:topVault/:vault/:treeabbr/:workstation/:isLeaf/:copyStatus
                        window.open(
                          `/#/bomentry/${HierarchyAllData?.project}/${
                            HierarchyAllData?.project_name
                          }/${HierarchyAllData?.top_vault}/${
                            HierarchyAllData?.vault
                          }/${
                            HierarchyAllData?.abbreviation
                              ? HierarchyAllData?.abbreviation
                              : "none"
                          }/0/${HierarchyAllData?.is_leaf}/${
                            HierarchyAllData?.is_copy
                          }`
                        );
                      }
                    }}
                  />
                )} */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "70%",
                  gap: "0.5rem",
                  justifyContent: "flex-end",
                  color: "primary.main",
                  backgroundColor:
                    HierarchyAllData?.id === 1 ? "primary.light" : "white",
                  fontSize: HierarchyAllData?.id === 1 ? "1rem" : "0.9rem",
                }}
              >
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "Bucket"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.bucket_count || 0)}
                </Box>
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "CM"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.cost_model_result_count || 0)}
                </Box>
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "CI"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.cost_input_heads_count || 0)}
                </Box>
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "BOM"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.individual_bom_count || 0)}
                </Box>
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "AC"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.db_count +
                        HierarchyAllData?.volume_count ||
                        0)}
                </Box>
                <Box
                  sx={{
                    width: "6rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "DB"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      (HierarchyAllData?.db_count || 0)}
                </Box>
                <Box
                  sx={{
                    width: "10rem",
                    textAlign: "right",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "Type"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.type}
                </Box>
                <Box
                  sx={{
                    width: "17rem",
                    textAlign: "right",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "Commodity"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.commodity}
                </Box>
                <Box
                  sx={{
                    width: "17rem",
                    textAlign: "right",
                    padding: "0 0.5rem",
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "Process"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.process_name}
                </Box>
                <Box
                  sx={{
                    width: "8rem",
                    textAlign: "center",
                    padding: "0 0.5rem",
                    textDecoration:
                      HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.id !== 1
                        ? "underline"
                        : "none",
                  }}
                  onClick={(ed: any) => {
                    ed.stopPropagation();
                    ed.preventDefault();
                    if (
                      HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.id !== 1
                    ) {
                      infoModal.open(HierarchyAllData?.id);
                    }
                  }}
                >
                  {HierarchyAllData?.id === 1
                    ? "Usage"
                    : HierarchyAllData?.table_type === "cost_model" &&
                      HierarchyAllData?.usage_count &&
                      HierarchyAllData?.usage_count}
                </Box>
              </Box>
            </Box>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.children?.map((subsystemData: any) => {
              return (
                <>
                  <HierarchyTree
                    HierarchyData={subsystemData}
                    key={subsystemData?.id}
                    onSelectCallback={onSelectCallback}
                  />
                </>
              );
            })}
          {HierarchyAllData &&
            HierarchyAllData?.tableData &&
            HierarchyAllData?.table &&
            Array.isArray(HierarchyAllData?.tableData) &&
            HierarchyAllData?.tableData?.length > 0 && (
              <Box sx={{ width: { lg: "29.5rem", xl: "38rem" } }}>
                {renderTable(
                  HierarchyAllData?.tableData,
                  HierarchyAllData?.table_type,
                  HierarchyAllData
                )}
              </Box>
            )}
        </StyledTreeItem>
      </TreeView>

      {calcModalOpen.isOpen && (
        <CalculatorConfigDataModal
          isOpen={calcModalOpen.isOpen}
          onCloseModal={calcModalOpen.close}
          selectedTreeData={calcModalOpen.propsId}
        />
      )}
      {otherInfo.isOpen && (
        <WorkFlowOtherInfoModal
          isOpen={otherInfo.isOpen}
          onClose={otherInfo.close}
          data={otherInfo.propsId}
        />
      )}
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={infoModal.propsId}
        />
      )}

      {infoModalBOM.isOpen && (
        <BomTemplateinfoModal
          onCloseModal={infoModalBOM.close}
          isOpen={infoModalBOM.isOpen}
          Item={infoModalBOM.propsId}
        />
      )}
      {bucketFetched.isOpen && (
        <BucketFetchedInfoModal
          onCloseModal={bucketFetched.close}
          isOpen={bucketFetched.isOpen}
          Item={bucketFetched.propsId}
        />
      )}
      {databaseInfo.isOpen && (
        <DatabaseAllInfo
          isOpen={databaseInfo.isOpen}
          onCloseModal={databaseInfo.close}
          item={databaseInfo.propsId}
        />
      )}
    </>
  );
};

export default HierarchyTree;
