import * as React from "react";
import { Box, CircularProgress, IconButton, TableCell, Typography } from "@mui/material";
import moment from "moment";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import WallpaperIcon from "@mui/icons-material/Wallpaper";
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Swal from "sweetalert2";
import { API } from "../../api-services";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


interface ISupplierpageProps {
  item: any;
  index: any;
  getPlantIds: any;
  useraction: any;
  getContactDetail: any;
  editSupplierDatabase: any;
  handleDeleteSupplier: any;
  viewFile: any;
  singleSupplierShow: any;
  handleListSupplierUse: any;
  setParentsCounter: any;
}

function Supplierpage(props: ISupplierpageProps) {
  const { item, index, getPlantIds, getContactDetail, editSupplierDatabase, handleDeleteSupplier, viewFile, singleSupplierShow, handleListSupplierUse, useraction, setParentsCounter } = props;
  const [loader, setLoader] = React.useState<any>(false);
  const userProfilePic = useSelector(userSelectors.selectAll);
  const lockHandler = (rows: any) => {
    // console.log("rows", rows);
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        API.put(`/api/db/supplier/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          setParentsCounter((prev: any) => prev + 1);
          // setCounter((prev: any) => prev + 1);
          // setEditCounter((prev: any) => prev + 1);
          setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
    });
  };


  return (
    <>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{index}</TableCell>
      <TableCell
        sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", textDecoration: "underline", cursor: "pointer" }}
        onClick={() => {
          singleSupplierShow(
            item?.id,
            item?.name,
            item?.commodity,
            item?.vehicle_type,
            item?.subsystem,
            item?.supplier_details,
            item?.contact_info,
            item?.contact,
            item?.dialCode,
            item?.spoc_name,
            item?.spoc_email,
            item?.logo,
            item?.supplier_type,
            item?.certification,
            item?.plants,
            item?.created_user_name,
            moment(item?.last_updated).format("DD-MM-YYYY, h:mm a")
          );
        }}>
        <Typography sx={{ fontSize: "1rem", display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          {item?.name}{" "}
          {item?.logo && (
            <WallpaperIcon
              titleAccess="Logo"
              sx={{ cursor: "pointer" }}
              onClick={() => viewFile(item?.logo)}
            />
          )}
        </Typography>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.commodity?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
        <IconButton
          style={{ color: "#007fff", padding: "3px" }}
          title="Details"
          onClick={() => {
            getContactDetail(item?.supplier_details, item?.contact_info, item?.contact, item?.spoc_name, item?.spoc_email, item?.dialCode);
          }}>
          <InfoIcon />
        </IconButton>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.vehicle_type?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.subsystem?.join(", ")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
        <span
          onClick={() => getPlantIds(item?.id)}
          style={{ textDecoration: "underline", cursor: "pointer" }}>
          {item?.plants}
        </span>
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.supplier_type}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.certification}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{item?.created_user_name}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>{moment(item?.last_updated).format("DD-MM-YYYY, h:mm a")}</TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
          {useraction && useraction?.includes("U") && item?.lock != true ? <IconButton
            style={{ color: "#007fff", padding: "3px" }}
            title="Edit BOM Category">
            <EditIcon
              onClick={() =>
                editSupplierDatabase(
                  item?.id,
                  item?.name,
                  item?.commodity,
                  item?.vehicle_type,
                  item?.subsystem,
                  item?.supplier_details,
                  item?.contact_info,
                  item?.contact,
                  item?.dialCode,
                  item?.spoc_name,
                  item?.spoc_email,
                  item?.logo,
                  item?.supplier_type,
                  item?.certification
                )
              }
            />
          </IconButton> : <IconButton
            style={{ color: "#007fff", padding: "3px", opacity: 0.5 }}
            title="Edit BOM Category">
            <EditIcon />
          </IconButton>}
          {item?.mapped_status ? (
            <IconButton
              style={{ color: "#007fff", padding: "3px" }}
              onClick={() => handleListSupplierUse(item?.id)}
              title="Place Used (BOM Category)">
              <InfoIcon />
            </IconButton>
          ) : (
            useraction && useraction?.includes("D") && (
              <IconButton
                style={{ color: "#007fff", padding: "3px", opacity: item?.lock != true ? 1 : 0.5 }}
                onClick={(e: any) => {
                  if (item?.lock != true) {
                    handleDeleteSupplier(e, item?.id)
                  }
                }}
                title="Delete BOM Category">
                <DeleteIcon />
              </IconButton>
            )
          )}

          {loader ?
            <CircularProgress size={18} /> : item?.lock === true ? (
              // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
              (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                <LockIcon
                  sx={{ cursor: 'pointer', color: 'green' }}
                  onClick={() => lockHandler(item)}
                />
              ) : (
                // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                <LockIcon sx={{ opacity: 0.5 }} />
              )
            ) : (
              // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
              (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                <LockOpenIcon
                  sx={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => lockHandler(item)}
                />
              )
            )}
        </Box>
      </TableCell>
    </>
  );
}
export default Supplierpage;
