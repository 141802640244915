import { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import styles from "../BOMCreate/BOMEntry/BOMTreeViewCreate.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";

import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import React from "react";
import { Typography } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FolderIcon from "@mui/icons-material/Folder";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  checkboxvalue: any;
  setcheckboxvalue: any;
  setParentsCounter?: any;
  disabledNodes:any;
  setDisabledNodes:any;
}
const CostParametersHeirarchy = (props: TreeViewComponentsProps) => {
  const {
    HierarchyData,
    onSelectCallback,
    setParentsCounter,
    checkboxvalue,
    setcheckboxvalue,
    disabledNodes,
    setDisabledNodes
  } = props;
  const { topVault, vault } = useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ModalData, setModalData] = useState<any>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
  };

  // console.log("HierarchyData", HierarchyData);
  const [expanded, setExpanded] = React.useState<any>([]);
  const [selected, setSelected] = React.useState<any>();


  // [-1,"-2--Material--calculator_category","-5--Process--calculator_category","-6--Overheads--calculator_category"]


  const handleChange = (e: any, nodeId: any, abbreviation: any) => {
    let a: any = sessionStorage.getItem("ScenarioParamSelected");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != nodeId;
      });
      let b: any = sessionStorage.setItem(
        "ScenarioParamSelected",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(nodeId);
      let b: any = sessionStorage.setItem("ScenarioParamSelected", JSON.stringify(val));
    }

    // onSelectCallback(nodeId, abbreviation, HierarchyAllData, HierarchyAllData.id);
    const index = expanded.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("ScenarioParamSelected");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [HierarchyData, sessionStorage.getItem("ScenarioParamSelected")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);
  let CopyId = sessionStorage?.getItem("copyId");

  // @ts-ignore
  let matchData = JSON?.parse(CopyId);
  useEffect(() => {
    setTimeout(
      () =>
        document.getElementById(vault)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        }),
      1000
    );
    // @ts-ignore
  }, [vault]);

  // const getColor = (status: any) => {
  //   switch (status) {
  //     case 0:
  //       return "red";
  //     case 1:
  //       return "#007fff";
  //     case 2:
  //       return "#007fff";
  //     default:
  //       return "green";
  //   }
  // };

  //   const CheckboxHandler = (e: any, data: any) => {
  //     // setcheckboxvalue([])
  //     e?.stopPropagation();

  //     onSelectCallback(data);
  //   };

  const CheckboxHandler = (e: any, data: any) => {
    e?.stopPropagation();
  
    const toggleCheckbox = (
      node: any,
      isChecked: boolean,
      checkedList: any[],
      disabledList: any[],
      isRoot: boolean = true // Track whether the current node is the clicked node
    ) => {
      if (isChecked) {
        // Add the root node to checkedList
        if (isRoot && !checkedList.includes(node.id)) {
          checkedList.push(node.id);
        }
  
        // Add child nodes to disabledList (excluding the root node)
        if (!isRoot && !disabledList.includes(node.id)) {
          disabledList.push(node.id);
        }
  
        // Remove child nodes from checkedList
        if (!isRoot) {
          const childIndex = checkedList.indexOf(node.id);
          if (childIndex > -1) {
            checkedList.splice(childIndex, 1);
          }
        }
      } else {
        // Remove the node from checkedList
        const index = checkedList.indexOf(node.id);
        if (index > -1) {
          checkedList.splice(index, 1);
        }
  
        // Remove the node from disabledList
        const disabledIndex = disabledList.indexOf(node.id);
        if (disabledIndex > -1) {
          disabledList.splice(disabledIndex, 1);
        }
      }
  
      // Process children recursively
      if (node.data) {
        node.data.forEach((child: any) => {
          toggleCheckbox(child, isChecked, checkedList, disabledList, false); // Mark children as not root
        });
      }
    };
  
    const isChecked = !checkboxvalue.includes(data.id); // Determine if the checkbox is being checked or unchecked
    const updatedCheckboxValue = [...checkboxvalue]; // Clone the current checkbox state
    const updatedDisabledNodes = [...disabledNodes]; // Clone the current disabled nodes state
  
    toggleCheckbox(data, isChecked, updatedCheckboxValue, updatedDisabledNodes);
  
    setcheckboxvalue(updatedCheckboxValue); // Update the checkbox state
    setDisabledNodes(updatedDisabledNodes); // Update the disabledNodes state
    onSelectCallback(data); // Call the callback with the updated data
  };
  
  
  

  

  return (
    <>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultEndIcon={
          HierarchyAllData?.type == "calculator" && <Checkbox
          sx={{ padding: 0.5, margin: 1 }}
          checked={checkboxvalue?.includes(HierarchyAllData?.id)}
          disabled={disabledNodes?.includes(HierarchyAllData?.id)}
          onClick={(e: any) => CheckboxHandler(e, HierarchyAllData)}
        />
        }
        defaultParentIcon={
          (HierarchyAllData?.sequence_id === "1Parent" || HierarchyAllData?.type === "calculator_category") ? (
            <FolderIcon sx={{ color: HierarchyAllData?.color , margin: 1}} />
          ) : (
            <Checkbox
              sx={{ padding: 0.5, margin: 1 }}
              checked={checkboxvalue?.includes(HierarchyAllData?.id)}
              disabled={disabledNodes?.includes(HierarchyAllData?.id)}
              onClick={(e: any) => CheckboxHandler(e, HierarchyAllData)}
            />
          )
        }
        className={styles.treetemp}       
        onNodeSelect={(e: any) =>
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation)
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == vault
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          id={HierarchyAllData?.id}
        

          label={
            <Typography className={styles.treeViewData}>
              <Typography
                style={{
                  fontSize: "12px",
                  // color: getColor(HierarchyAllData?.status),
                  color: '#007fff',
                }}
                className={styles.treeViewText}
                onContextMenu={(event: any) =>
                  handleClick(event, HierarchyAllData.id, HierarchyAllData)
                }
              >
              {HierarchyAllData?.name}
              </Typography>              
            </Typography>
          }
        >
          {/* {console.log("jjj", HierarchyAllData)} */}
          {HierarchyAllData?.data?.map((subsystemData: any) => {
            // console.log("jjj2", subsystemData);

            return (
              <>
                <CostParametersHeirarchy
                  HierarchyData={subsystemData}
                  key={subsystemData?.id}
                  checkboxvalue={checkboxvalue}
                  setcheckboxvalue={setcheckboxvalue}
                  onSelectCallback={onSelectCallback}
                  setParentsCounter={setParentsCounter}
                  disabledNodes={disabledNodes}
                  setDisabledNodes={setDisabledNodes}
                />
              </>
            );
          })}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
};

export default CostParametersHeirarchy;
