import * as React from "react";
import Box from "@mui/material/Box";

import {
  Backdrop,
  ButtonProps,
  Divider,
  Modal,
  Skeleton,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import { darken, lighten } from "@mui/material/styles";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  Item?: any;
  type?: any;
  rightClickedId?: any;
}

function InfoTreeModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, rightClickedId, Item, type } = props;
  const { selectedIdTree } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const [infoDataMain, setInfoDataMain] = React.useState<any>([]);
  const [infoDataScenario, setInfoDataScenario] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getBackgroundColor = (color: string, mode: string) => {
    return mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
  };

  const getInfoData = () => {
    setIsLoading(true);
    API.get(
      "/api/db/check_variant/",
      {
        id: rightClickedId,
        type: "cost_estimation",
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false);
        let rows: any = [];
        let rowsScenario: any = [];
        const dataGridDataGenerator: any = res.data?.main?.map(
          (infoItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              ...infoItem,
            };
            rows.push(objShared);
          }
        );
        const dataGridDataGeneratorr: any = res.data?.scenario?.map(
          (infoItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              ...infoItem,
            };
            rowsScenario.push(objShared);
          }
        );
        setInfoDataMain(rows);
        setInfoDataScenario(rowsScenario);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getInfoData();
  }, [selectedIdTree]);

  var Columns: any = [
    // {
    //   field: "id", headerName: 'Name', hide: true,
    //   minWidth: 50,
    //   flex: 1,
    // },
    {
      field: "name",
      headerName: "Name",
      width: width > 1400 ? 250 : 220,
    },

    {
      field: "part_no",
      headerName: "Part Number",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellValues?.row?.part_no}
            >
              <p>{cellValues?.row?.part_no}</p>
            </Tooltip>
          </>
        );
      },
    },

    {
      field: "category",
      headerName: "Category",
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      rendeCell: (cellvalues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellvalues?.row?.category}
            >
              <p>{cellvalues?.row?.category}</p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "scenario_name",
      headerName: "Scenario Name",
      minWidth: width > 1400 ? 300 : 200,
      flex: 1,
      rendeCell: (cellvalues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellvalues?.row?.scenario_name}
            >
              <p>{cellvalues?.row?.scenario_name}</p>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Cost models usage info
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider />
          <Box sx={{ width: "100%", bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered >
              <Tab label="Main" sx={{color:'#007fff !important'}}/>
              <Tab label="Scenario" sx={{color:'#007fff !important'}}/>
            </Tabs>
          </Box>
          {type == "Lockstatus" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "14.5rem",
              }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Locked By </Typography>
                  <Typography>:</Typography>
                  <Typography>
                    {Item?.locked_user_name == null
                      ? "___"
                      : Item?.locked_user_name}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Email </Typography>
                  <Typography sx={{ marginLeft: "2.4rem" }}>:</Typography>
                  <Typography>
                    {Item?.locked_user_email == null
                      ? "___"
                      : Item?.locked_user_email}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Phone Number </Typography>
                  <Typography>:</Typography>
                  <Typography>
                    {Item?.locked_user_phone == null
                      ? "___"
                      : Item?.locked_user_phone}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "0.5rem",
                  }}
                >
                  <Typography>Locked Date </Typography>
                  <Typography sx={{ marginLeft: "1.3 rem" }}>:</Typography>

                  <Typography>
                    {Item?.locked_on == null ? "___" : Item?.locked_on}
                  </Typography>
                </Box>
              </Box>
            </Box>
          )}
         {value == 0? <Box
            sx={{
              height: "65vh",
              width: "100%",
            
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {!isLoading && infoDataMain ? (
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={infoDataMain && infoDataMain}
                columns={Columns}
                density="compact"
               
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "&.highlight-row": {
                    backgroundColor: "red",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            ) : (
              <Box>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            )}
          </Box>:
           <Box
           sx={{
             height: "65vh",
             width: "100%",
           
           }}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3"
         >
           {!isLoading && infoDataScenario ? (
             <DataGrid
               headerHeight={48}
               rowHeight={36}
               rows={infoDataScenario && infoDataScenario}
               columns={Columns}
               density="compact"
              
               // autoHeight={true}
               components={{
                 Toolbar: () => {
                   return CustomToolbar();
                 },
               }}
               hideFooter={true}
               hideFooterPagination={true}
               sx={{
                 "&.MuiDataGrid-root .MuiDataGrid-cell": {
                   borderBottomColor: "primary.light",
                 },
                 "&.highlight-row": {
                   backgroundColor: "red",
                 },
                 "& ::-webkit-scrollbar": {
                   width: "0.3rem",
                   height: "0.3rem",
                 },
                 "& ::-webkit-scrollbar-thumb": {
                   // background: $thumb-color;
                   backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                   borderRadius: "10px",
                 },
                 "& ::- webkit-scrollbar-thumb: hover ": {
                   // width: "4px",
                   backgroundColor: "#045DE9 !important",
                 },
                 "::-webkit-scrollbar-track": {
                   backgroundColor: "#e1e1f3 !important",
                 },
               }}
             />
           ) : (
             <Box>
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
               <Skeleton animation="wave" />
             </Box>
           )}
         </Box>}
        </Box>
      </Modal>
    </div>
  );
}
export default InfoTreeModal;
