// import React, { useRef, useState } from "react";
// import { debounce } from "lodash";
// import styles from "../../ComponentUI/Header/Header.module.scss";
// import SearchIcon from "@mui/icons-material/Search";
// import { API } from "../../api-services";
// import { Box, TextField } from "@mui/material";

// function ScenarioSearch({ viewSwitchInput }: any) {
//   const descRef = useRef<any>();
//   const inputRef = useRef<any>();
//   const [SearchValue, setSearchValue] = useState<any>([]);
//   var [countuerData, setCounterData] = useState<any>(1);
//   const [selectedValue, setSelectedValue] = React.useState<any>(null);

//   const ExactDebounce = React.useRef(
//     debounce(async (criteria: any) => {
//       await API.get("/cost/scneario_list_data_view/", {
//         search_query: criteria,
//         top_vault: !viewSwitchInput?.vault ? !viewSwitchInput?.top_vault : null,
//         vault: !viewSwitchInput?.vault ? null : viewSwitchInput?.vault,
//       })
//         .then((res: any) => {
//           setSearchValue(res.data);
//         })
//         .finally(() => {
//           // setSearchValue(["aaa", "sdc"]);
//         });
//     }, 500)
//   ).current;

//   const handleOnChange = async (event: any) => {
//     setSearchValue([]);
//     setSelectedValue(event.target.value);

//     var DataSplit = event?.target?.value?.split("(")?.shift();
//     //  inputRef.current.style.display = "block";
//     // if (event?.target?.value?.length > 0) {
//     //   ExactDebounce(DataSplit);
//     // }
//     ExactDebounce(DataSplit);
//   };

//   const FocusHandler = (e: any) => {
//     e.stopPropagation();
//     e.preventDefault();
//     // inputRef.current.style.display = "block";
//     var DataSplit = selectedValue?.split("(")?.shift();
//     if (DataSplit?.length > 0) {
//       ExactDebounce(DataSplit);
//     }
//   };

//   console.log(SearchValue);

//   return (
//     <Box sx={{ position: "relative" }}>
//       <TextField
//         id="standard-basic"
//         variant="standard"
//         type="search"
//         placeholder="Search Part"
//         value={selectedValue}
//         className={styles.searchInput}
//         autoFocus={false}
//         style={{
//           color: "#007fff",
//           fontSize: "0.9rem",
//           border: "none",
//           width: "12rem",
//         }}
//         // @ts-expect-
//         ref={descRef}
//         onKeyPress={(event: any) => handleOnChange(event)}
//         onChange={(event: any) => handleOnChange(event)}
//         onFocus={(e) => FocusHandler(e)}
//       />
//       {/* <input
//         type="search"
//         placeholder="Search"
//         value={selectedValue}
//         className={styles.searchInput}
//         autoFocus={false}
//         style={{ color: "#007fff", fontSize: "0.9rem", border:"none", borderBottom:"0.1px solid #797a7a", height:"2.5rem", width:"12rem" }}
//         // @ts-expect-
//         ref={descRef}
//         onKeyPress={(event: any) => handleOnChange(event)}
//         onChange={(event: any) => handleOnChange(event)}
//         onFocus={(e) => FocusHandler(e)}

//       /> */}

//       {SearchValue?.length > 0 && (
//         <div
//           className={styles.searchSubText}
//           style={{
//             position: "absolute",
//             zIndex: "20",
//             border: "0.1px solid #f2f2f2",
//             height: "30rem",
//           }}
//           adl-scrollbar="true"
//           adl-scrollbar-width="0.3"
//         >
//           {SearchValue.map((item: any, index: any) => {
//             return (
//               <div>
//                 <div
//                   key={index}
//                   //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
//                   className={styles.innserSearchSubText}
//                   //  onClick={() => {
//                   //    if(item?.original_model_ob==="ideastorage"){searchHandlerIdeaClick(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob,item?.original_model_ob)}else{
//                   //    handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob,item?.original_model_ob);
//                   //    }
//                   //  }}
//                 >
//                   <span
//                   //    onClick={() => {
//                   //      if(item?.original_model_ob==="ideastorage"){searchHandlerIdeaClick(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob,item?.original_model_ob)}else{
//                   //      handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob,item?.original_model_ob);
//                   //      }
//                   //    }}
//                   >
//                     {item?.model_ob == "topvault" ? (
//                       <span>{item?.topvault_name}</span>
//                     ) : (
//                       <>
//                         {/* <span>{item?.value} </span> */}
//                         <span>
//                           {item?.original_model_ob === "ideastorage"
//                             ? item?.value
//                             : item?.vault_name}
//                           <span>
//                             <span>(</span>
//                             {item?.part_no}
//                             <span>)</span>
//                           </span>
//                           | <span>{item?.topvault_name}</span>
//                         </span>
//                       </>
//                     )}
//                   </span>
//                   <span
//                     style={{
//                       // marginRight: "0.5rem",
//                       paddingTop: "5px",
//                     }}
//                   >
//                     <SearchIcon />
//                   </span>
//                 </div>
//               </div>
//             );
//           })}
//           {SearchValue && SearchValue?.length == 0 && (
//             <p
//               style={{
//                 paddingLeft: "1rem",
//                 fontSize: "0.9rem",
//               }}
//             >
//               No Data
//             </p>
//           )}
//         </div>
//       )}
//     </Box>
//   );
// }

// export default ScenarioSearch;













import React, { useRef, useState } from "react";
import { debounce } from "lodash";
import styles from "../../ComponentUI/Header/Header.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { API } from "../../api-services";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

function ScenarioSearch({ viewSwitchInput }: any) {
  const descRef = useRef<any>();
  const [searchResults, setSearchResults] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const {
    vault,
    topVault,
    projectId,
    projectname,
    projectName,
    productId,
    abbrebation,
    cardtype,
    tableCounter,
    condition,
    calctype,
    scenarioId,
    costEstimationId,
    treeabbr,
    newUI,
    scenarioType,
  } = useRouteParams<any>();
  const ExactDebounce = React.useRef(
    debounce(async (criteria: string) => {
      if (!criteria) {
        setSearchResults([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      const queryParams: any = { search_query: criteria };
      if (!viewSwitchInput?.vault) {
        // Add `top_vault` if `vault` is null
        queryParams.top_vault = viewSwitchInput?.top_vault;
      } else {
        // Add `vault` if `vault` is not null
        queryParams.vault = viewSwitchInput?.vault;
      }

      try {
        const response = await API.get("/cost/scneario_list_data_view/", queryParams);
        setSearchResults(response.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 500)
  ).current;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedValue(value);
    setSearchResults([]); // Clear previous results while typing
    ExactDebounce(value.split("(").shift() || "");
  };

  // console.log(searchResults);


  return (
    <Box sx={{ position: "relative", width: { lg: '13rem', xl: "18rem" } }}>
      <TextField
        variant="standard"
        type="search"
        placeholder="Search Part"
        value={selectedValue}
        onChange={handleOnChange}
        onFocus={() => {
          if (selectedValue) {
            ExactDebounce(selectedValue.split("(").shift() || "");
          }
        }}
        ref={descRef}
        sx={{
          width: "100%",
          fontSize: "0.9rem",
          "& .MuiInputBase-input": {
            color: "#007fff",
          },
          "& .MuiInputBase-input::placeholder": {
            fontSize: "1rem", // Reduce the placeholder font size
          },
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: '1rem !important'
          },
          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
          '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ color: 'primary.main', fontSize: "1.35rem", marginTop: "0.2rem" }} />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          position: "absolute",
          top: "100%",
          left: 0,
          right: 0,
          maxHeight: "20rem",
          overflowY: "auto",
          // border: "1px solid #f2f2f2",
          bgcolor: "#fff",
          zIndex: 10,
          width: "150%",
        }}
      >
        {!loading && searchResults?.length > 0 ? (
          searchResults.map((item: any, index: number) => (
            <Box
              key={index}
              className={styles.innserSearchSubText}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 1,

                cursor: "pointer",
                "&:hover": {
                  bgcolor: "#f9f9f9",
                },
              }}
              onClick={() => {
                window.open(
                  `/#/costingscenario/${projectId}/${projectName}/${item?.topvault_id}/${item?.id}/${scenarioType}/${item?.abbreviation ? item?.abbreviation : "none"}/${scenarioId}`
                );
              }}
            >
              <Typography variant="body2" sx={{ textDecoration: "underline" }}>
                {item?.name}
              </Typography>
              <SearchIcon />
            </Box>
          ))
        ) : (
          !loading && selectedValue && (
            <Typography variant="body2" sx={{ p: 2, textAlign: "center" }}>
              No Part Found
            </Typography>
          )
        )}
      </Box>
    </Box>
  );
}

export default ScenarioSearch;

