import * as React from 'react';
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box, Divider, IconButton, Popover, Skeleton, styled, TextField, Tooltip, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ScenarioMenuItems from './ScenarioMenuItems';
import Swal from 'sweetalert2';
import { API } from '../../api-services';


export interface IScenarioHeaderProps {
  item: any;
  getTableData?: any;
  userProfile?: any
  TableData?: any;
}


export default function ScenarioHeader(props: IScenarioHeaderProps) {
    const { item, getTableData, userProfile, TableData } = props;
    const [downloadLoader, setDownloadLoader] = React.useState(false);
    // Scenario Popover Menu Option
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;


  return (
    <>
      {/* { lg: '11rem', xl: '13rem' }, */}
      
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '0.2rem', width: '100%', borderLeft: '1px solid' }}>
      <Tooltip title={item?.description || ""} placement="top">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
            {/* <LockIcon sx={{ fontSize: '1.2rem', color: item?.lock ? 'green' : 'red' }} /> */}
            {item?.lock ? <LockIcon sx={{ fontSize: '1.2rem', color: 'green', cursor: "pointer" }}
              onClick={() => {
                if (item?.action?.includes("U")) {
                  API.put(
                    `/cost/scenario/${item?.id}/`,
                    {
                      lock: false,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      Swal.fire({
                        title: "Success",
                        text: "Scenario has been unlocked successfully",
                        icon: "success",
                        confirmButtonColor: "#007FFF",
                      });
                      getTableData();
                    })
                    .catch((err: any) => {
                      const { data } = err.response;
                      Swal.fire({
                        title: "Error",
                        text: data[0],
                        icon: "error",
                        confirmButtonColor: "#007FFF",
                      });
                    });
                }
              }} /> : <LockOpenIcon sx={{ fontSize: '1.2rem', color: 'red', cursor: "pointer" }} onClick={() => {
                if (item?.action?.includes("U")) {
                  API.put(
                    `/cost/scenario/${item?.id}/`,
                    {
                      lock: true,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      Swal.fire({
                        title: "Success",
                        text: "Scenario has been unlocked successfully",
                        icon: "success",
                        confirmButtonColor: "#007FFF",
                      });
                      getTableData();
                    })
                    .catch((err: any) => {
                      const { data } = err.response;
                      Swal.fire({
                        title: "Error",
                        text: data[0],
                        icon: "error",
                        confirmButtonColor: "#007FFF",
                      });
                    });
                }
              }} />}
            {item?.independent ? "(I)" : "(D)"}
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', overflow: 'hidden', width: TableData?.length <= 4 ? '100%' : TableData?.length <= 5 ? { lg: '7rem', xl: '9rem' } : { lg: '5rem', xl: '7rem' }, textOverflow: 'ellipsis' }}> {item?.name} </Typography>
          </Box>
          </Tooltip>
       
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem', padding: '0', cursor:item?.status == true ? "pointer" : item?.status == false ? "default !important" : "pointer"  }}>
          <IconButton aria-describedby={id} sx={{ padding: '0' }} onClick={(e)=> {
            if (item?.status == true) {
              handleClick(e);
            } if (item?.status == false) {
             
            } else {
              handleClick(e);
            }
          }
            
            }>
            <MenuIcon sx={{ fontSize: '1.5rem', color: item?.status == true ? 'primary.main' : item?.status == false ? "grey" : "primary.main" }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            elevation={1}
          >
            <ScenarioMenuItems TableData={item} getTableData={getTableData} userProfile={userProfile} downloadLoader={downloadLoader} setDownloadLoader={setDownloadLoader} />
          </Popover>
        </Box>
      </Box>
    </>
  );
}
