import * as React from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";

interface PermissionSelectProps {
  createAction: (scenarioId: any, user: any, roles: any) => any;
  editAction: (id: any, value: any) => any;
  user: any;
  values: any;
}

export default function Per({
  createAction,
  editAction,
  user,
  values,
}: PermissionSelectProps) {
  const { scenario_id, id, action } = values || {};
  const [roles, setRoles] = React.useState<string[]>(action); // Initialize with the prop values

  const handleChange = (event: SelectChangeEvent<typeof roles>) => {
    const {
      target: { value },
    } = event;

    const tempRoles = typeof value === "string" ? value.split(",") : value;
    setRoles(tempRoles);

    if (action?.length === 0 && tempRoles?.length > 0) {
      createAction(
        scenario_id,
        [user],
        tempRoles.filter((item: any) => item !== "-")
      );
      console.log("ddd", value, scenario_id, tempRoles);
    } else {
      console.log("eee", id, tempRoles);
      editAction(
        id,
        tempRoles.filter((item: any) => item !== "-")
      );
    }
  };

  return (
    <FormControl
      // size="small"
      sx={{
        "& fieldset": { border: "none", padding: 0, margin: 0 },
      }}
      fullWidth
    >
      <InputLabel id="demo-multiple-checkbox-label"></InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        value={
          roles?.length > 0 ? roles?.filter((item: any) => item !== "-") : ["-"]
        }
        onChange={handleChange}
        input={<OutlinedInput label="" fullWidth />}
        fullWidth
        renderValue={(selected) => selected.join(", ")}
        size="small"
        sx={{
          "& .MuiOutlinedInput-input": {
            outline: "none",
            padding: 0,
            width: "100%",
          },
        }}
      >
        {["R", "U"].map(
          (
            name // You should map over 'values' to generate the menu items
          ) => (
            <MenuItem key={name} value={name} sx={{ width: "100%" }}>
              <Checkbox checked={roles?.includes(name)} />
              <ListItemText primary={name} />
            </MenuItem>
          )
        )}
      </Select>
    </FormControl>
  );
}
