import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from '@mui/material/Divider';
import { useFormik } from 'formik';
import * as yup from "yup";
import { FormControl, Input, InputLabel, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api-services';

import Swal from 'sweetalert2';
import {

  MenuItem,

} from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

interface EditLHRModalProps {
  isOpen: any;
  onCloseModal: any;
  rowItems: any;
  type?: any;
  getRevisionData?: any;
  setCounter: any;
  swalalterHandler: any;

}

const validationSchema = yup.object({
  wage_per_day: yup.string().required("Please enter Wage Per Day"),
  perks: yup.string().required("Please enter Perks"),
  dearness_allowance: yup.string().required("Please enter D.A"),
  reference: yup.string().required("Please enter reference"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});

const EditLHRModal = (props: EditLHRModalProps) => {
  const { isOpen, onCloseModal, rowItems, getRevisionData, type, setCounter, swalalterHandler } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false)
  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] = React.useState<any>("");
  // ----------------------------------------------

  React.useEffect(() => {
    if (rowItems) {
      if (rowItems?.currency_code) {
        setSelectedCurrencyCodes([rowItems?.currency_code]);
      } else {
        setSelectedCurrencyCodes([]);
      }
      formik.setFieldValue("wage_per_day", rowItems?.wage_per_day);
      formik.setFieldValue("perks", rowItems?.perks);
      formik.setFieldValue("dearness_allowance", rowItems?.dearness_allowance);

      formik.setFieldValue("reference", rowItems?.reference);
      formik.setFieldValue("unit", rowItems?.unit);
      formik.setFieldValue("currency_id", rowItems?.currency_id);
      formik.setFieldValue("currency_code", rowItems?.currency_code);
    }
  }, [rowItems]);



  const formik = useFormik({
    initialValues: {
      wage_per_day: "",
      perks: "",
      reference: "",
      dearness_allowance: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoadingSave(true)
      values.currency_id = String(values.currency_id);
      API.put(`/api/db/labour_cost/${rowItems?.id}/`, values, {}, 0)
        .then((res: any) => {
          setIsLoadingSave(false)
          swalalterHandler()
          onCloseModal()
          setCounter((prev: any) => prev + 1)
        })
        .catch((err: any) => {
          setIsLoadingSave(false)
        });
    }
  })


  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database: true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});




  return (
    <div>

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit Labour Revision
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Minimum Wage(Currency/Day)"
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="wage_per_day"
                    name="wage_per_day"
                    onChange={formik.handleChange}
                    value={formik.values.wage_per_day}

                    error={formik.touched.wage_per_day && Boolean(formik.errors.wage_per_day)}
                    //   @ts-ignore
                    helperText={formik.touched.wage_per_day && formik.errors.wage_per_day}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Perks (%)"
                    type="number"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="perks"
                    name="perks"
                    onChange={formik.handleChange}
                    value={formik.values.perks}

                    error={formik.touched.perks && Boolean(formik.errors.perks)}
                    //   @ts-ignore
                    helperText={formik.touched.perks && formik.errors.perks}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="D.A (Currency/Month)"
                    type="number"
                    // autoFocus={autoFocusStatus}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', style: { color: "#007fff" } }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="dearness_allowance"
                    name="dearness_allowance"
                    onChange={formik.handleChange}
                    value={formik.values.dearness_allowance}

                    error={formik.touched.dearness_allowance && Boolean(formik.errors.dearness_allowance)}
                    //   @ts-ignore
                    helperText={formik.touched.dearness_allowance && formik.errors.dearness_allowance}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Reference
                  "
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                    }}
                    id="reference"
                    name="reference"
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                    multiline
                    error={formik.touched.reference && Boolean(formik.errors.reference)}
                    helperText={formik.touched.reference && formik.errors.reference}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "100%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);


                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));

                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_code &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_code && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: '100%', textAlign: 'right' }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1,
                    marginTop: "1rem",
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    },
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },
                  }}>
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal >
    </div >
  )
}

export default EditLHRModal;