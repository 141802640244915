import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { ScenarioColors } from "./ScenarioColors.constants";
import { tab } from "@testing-library/user-event/dist/tab";
import { EditNewAssumptionDropdown } from "./EditNewAssumptionDropdown";
import PartCountDetailsModal from "./PartCountDetailsModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { RoleListScenario, RoleListScenarioLock } from "../../Constants/projectspecificmodules.constants";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";

export interface IEditScenarioModalProps {
  isOpen: any;
  onCloseModal: any;
  data: any;
  getTableData: any;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "86vw", sm: "35vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function ShareScenarioModal(props: IEditScenarioModalProps) {
  const { isOpen, onCloseModal, data, getTableData } = props;
  const [users, setUsers] = React.useState<any>([]);
  const [userList, setUserList] = React.useState<any>([]);
  const [roles, setRoles] = React.useState<any>([]);

  //   const handleChange = (event: SelectChangeEvent) => {
  //     setAge(event.target.value as string);
  //   };st

  const getUserList = () => {
    API.get(
      "/cost/scenario_share/",
      {
        get_user_list: true,
        scenario: data.id,
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setUserList(res?.data);
      })
      .catch(() => {});
  };


  React.useEffect(() => {
    getUserList();
  }, []);

  const getData = () => {
    if (data.lock) {
      return RoleListScenarioLock;
    } else {
      return RoleListScenario;
    }
  };

  const [saveButtonLoader, setSaveButtonLoader] = React.useState<any>(false);
  const { projectId, projectName, topVault, vault, scenarioId, treeabbr } =
    useRouteParams<any>();

  const handleSelectChange = (ed: any) => {
    const { name, value } = ed.target;
    if (name === "users") {
      setUsers(value);
    } else {
      setRoles(value);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Share Scenarios with users
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ padding: "0 1rem 1rem" }}>
            {/* <Box sx={{ marginBottom: "2rem" }}></Box> */}

            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                columnGap: "1rem",
                //   justifyContent: "",
                //   height: "2.5rem",
              }}
            ></Box>

            {/* <Box sx={{ width: "100%", textAlign: "right" }}></Box> */}

            <Box>
              <FormControl
                sx={{ marginBottom: "0.5rem" }}
                fullWidth
                variant="standard"
              >
                <InputLabel
                  shrink
                  sx={{ color: "primary.main" }}
                  id="demo-simple-select-label"
                >
                  Select Users
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="users"
                  value={users && users}
                  multiple
                  multiline
                  label="User"
                  onChange={handleSelectChange}
                  size="small"
                  placeholder="Select Users"
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {userList
                        .filter((item: any) =>
                          selected?.includes(item?.user__id)
                        )
                        ?.map((value: any) => (
                          <Chip
                            size="small"
                            key={value.user__id}
                            label={`${value?.full_name}`}
                            onDelete={() =>
                              setUsers((prev: any) =>
                                prev?.filter(
                                  (item: any) => value.user__id !== item
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {userList &&
                    userList?.map((item: any, index: number) => {
                      return (
                        !users?.includes(item?.user__id) && (
                          <MenuItem key={index} value={item?.user__id}  sx={{color: item?.user_category == "External" ? "#FF0000" : "inherit" }}>
                            {item?.full_name}
                          </MenuItem>
                        )
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="standard">
                <InputLabel
                  shrink
                  sx={{ color: "primary.main" }}
                  id="demo-simple-select-label"
                >
                  Select roles
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={roles && roles}
                  multiple
                  multiline
                  name="roles"
                  label="Role"
                  placeholder="Select Role"
                  onChange={handleSelectChange}
                  size="small"
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {getData()
                        ?.filter((item: any) => selected?.includes(item?.value))
                        ?.map((value: any) => (
                          <Chip
                            size="small"
                            sx={{ height: "1.5rem" }}
                            key={value.value}
                            label={value.value}
                            onDelete={() =>
                              setRoles((prev: any) =>
                                prev?.filter(
                                  (item: any) => value.value !== item
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {getData() &&
                    getData()?.map((item: any, index: number) => {
                      return (
                        !roles?.includes(item.value) && (
                          <MenuItem key={index} value={item?.value}>
                            {item.text}
                          </MenuItem>
                        )
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: "100%", textAlign: "right" }}>
              {/* <Button onClick={()=>FeatureCreateUploadModal.open()}>Open create</Button> */}
              <LoadingButton
                size="small"
                variant="contained"
                loading={saveButtonLoader}
                type="submit"
                disabled={
                  users?.length === 0 || roles?.length === 0 ? true : false
                }
                sx={{ marginTop: "1rem" }}
                onClick={() => {
                  setSaveButtonLoader(true);
                  API.post(
                    "/cost/scenario_share/",
                    {
                      action: roles,
                      users: users,
                      scenario: data.id,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      getTableData();
                      onCloseModal();

                      setSaveButtonLoader(false);
                      Swal.fire({
                        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          
                <br />
          
                <p style="color:#007fff;">Scenario shared successfully!</p>   
          
                 </div>`,
                        // title: "Success",
                        // text: "Scenario shared successfully",
                        // icon: "success",
                        confirmButtonText: "Ok",
                      });
                    })
                    .catch((err: any) => {
                      setSaveButtonLoader(false);
                    });
                }}
              >
                Share
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
