import { Box, Divider, Grid, Modal, Skeleton, Typography } from "@mui/material";
import React from "react";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "fit-content",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
  paddingTop: 0,
  overflowY: "auto",
};

function AnalysisGraphModal({
  isOpen,
  onCloseModal,
  vault,
  topVault,
  title,
  datas,
  graphType,
  checkboxvalue,
  handleGraphRemove,
}: any) {
  const [Loader, setLoader] = React.useState(false);
  const [isActive, setIsActive] = React.useState(true);
  const [CompareGraphData, SetCompareGraphData] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [TotalCompareGraphData, setTotalCompareGraphData] = React.useState<any>(
    []
  );
  const [TotalLoader, serTotalLoader] = React.useState<any>(false);

  const getTotalCompareGraphData = () => {
    serTotalLoader(true);

    API.get(
      "/cost/scenario_graph/",
      { ...getObj(), total_cost: true, page: currentPage + 1 },
      0
    )
      .then((res: any) => {
        setTotalCompareGraphData(res?.data);
      })
      .catch((err: any) => {
        console.log("Server Error");
      })
      .finally(() => {
        serTotalLoader(false);
      });
  };

  const getCompareGraphData = () => {
    setLoader(true);
    API.get("/cost/scenario_graph/", { ...getObj(), page: currentPage + 1 }, 0)
      .then((res: any) => {
        setLoader(false);
        SetCompareGraphData(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server Error");
      });
  };

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  // console.log("graphTYpe", graphType, checkboxvalue);
  // console.log("datas", datas);

  React.useEffect(() => {
    getCompareGraphData();
    getTotalCompareGraphData();
  }, [topVault, vault, currentPage]);

  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={8} fill="#666">
          <tspan
            style={{ fill: "black", fontSize: "12" }}
            textAnchor="middle"
            x="0"
          >
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    const firstCurrency =
      payload && payload.length > 0 ? payload[0]?.payload?.currency : "";

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
            border: "1px solid",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "0.87rem",
              minWidth: "8rem",
              width: "100%",
              textAlign: "center",
            }}
          >{`${label}`}</Typography>
          <Typography
            sx={{
              fontSize: "0.87rem",
              width: "100%",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
              fontWeight: "bold",
            }}
          >
            <span style={{ width: "6rem", textTransform: "capitalize" }}>
              Total:
            </span>
            <span style={{ width: "7rem", textAlign: "right" }}>
              {payload
                .reduce(
                  (accumulator: number, currentItem: any) =>
                    accumulator + currentItem.value,
                  0
                )
                .toFixed(2)}{" "}
              {firstCurrency}
            </span>
          </Typography>

          {payload.map((item: any, index: any) => {
            return (
              <>
                {/* @ts-ignore */}
                {
                  <Typography
                    sx={{
                      fontSize: "0.87rem",
                      width: "100%",
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ width: "6rem", textTransform: "capitalize" }}
                    >
                      {item?.name}:
                    </span>
                    <span style={{ width: "7rem", textAlign: "right" }}>
                      {item?.value?.toFixed(2)} {item.payload?.currency}
                    </span>
                  </Typography>
                }
              </>
            );
          })}
        </div>
      );
    }
    return null;
  };

  const currency = sessionStorage.getItem("currency");

  // const colors = {
  //   Overhead_Cost: "#ffc658",
  //   Process_Cost: "#82ca9d",
  //   RM_Cost: "#8884d8",
  // };

  const colors = React.useMemo(() => {
    const map: Record<string, string> = {
      "-2--Material--calculator_category": "RM_Cost",
      "-5--Process--calculator_category": "Process_Cost",
      "-6--Overheads--calculator_category": "Overhead_Cost",
    };

    const allowedItems = [
      "-2--Material--calculator_category",
      "-5--Process--calculator_category",
      "-6--Overheads--calculator_category",
    ];

    const colorMap: Record<string, string> = {
      RM_Cost: "#8884d8",
      Process_Cost: "#82ca9d",
      Overhead_Cost: "#ffc658",
    };

    if (graphType === "split") {
      return checkboxvalue
        .filter((item: string) => allowedItems.includes(item)) // Filter by allowed items
        .reduce((acc: Record<string, string>, item: string) => {
          const key = map[item]; // Map item to its corresponding cost type
          if (key) acc[key] = colorMap[key]; // Map cost type to its color
          return acc;
        }, {});
    } else {
      return {}; // Return an empty object if not split
    }
  }, [graphType, checkboxvalue]);

   const generateDynamicTicks = (data: any) => {
    const max = Math.max(...data.map((d: any) => d.cost || 0));
    const bufferedMax = Math.ceil(max * 1.2 * 10) / 10; // Add 20% buffer and round to 1 decimal place
    const interval = bufferedMax / 2; // Divide into two intervals
    return [0, parseFloat(interval.toFixed(1)), parseFloat(bufferedMax.toFixed(1))]; // Return the ticks
  };


  return (
    <>
      {datas.map((data: any, index: any) => (
        <Grid item xs={12} sm={6} justifyItems={"center"}>
          <CloseIcon
            titleAccess="Remove Analysis Graph"
            onClick={() => handleGraphRemove(index)}
            sx={{
              cursor: "pointer",
              position: "fixed",
              right: "2rem",
              zIndex: "0",
              color: "red",
            }}
          />
          <ResponsiveContainer width="95%" height={400}>
            <BarChart
              data={data}
              barSize={80}
              margin={{ top: 20, right: 30, left: 35, bottom: 30 }}
            >
              <XAxis dataKey="name" tick={{ fill: "#007fff" }} />
              <YAxis
                label={{
                  value: `Currency (${currency})`,
                  angle: -90,
                  position: "insideLeft",
                  offset: -30,
                  style: { textAnchor: "middle", fill: "#007fff" },
                }}
                tick={{ fill: "#007fff" }}
                tickFormatter={(value) => value.toFixed(2)} // Format Y-axis ticks
                domain={[
                  () => 0,
                  () => Math.max(...data.map((d:any) => d.cost || 0)) * 1.15,
                ]}
                ticks={generateDynamicTicks(data)}
              />
              <Tooltip
                formatter={(value: number | string) =>
                  typeof value === "number"
                    ? `${currency} ${new Intl.NumberFormat("en-IN").format(value)}`
                    : `${currency} ${value}`
                }
              />
              <Legend />
              {graphType === "split" ? (
                Object.keys(colors).map((key: any) => (
                  <Bar
                    key={key}
                    dataKey={key}
                    name={key.replace(/_/g, " ")}
                    stackId="a"
                    fill={colors[key as keyof typeof colors]}
                  />
                ))
              ) : (
                <Bar
                  dataKey="cost"
                  name={data[0]?.parameter}
                  label={{
                    fill: "black",
                    position: "top",
                    formatter: (value: any) => value.toFixed(2),
                  }}
                />
              )}
            </BarChart>
          </ResponsiveContainer>
        </Grid>
      ))}
    </>
  );
}

export default AnalysisGraphModal;
