import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { Divider, Typography } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
//@ts-ignore
import { CSVLink } from "react-csv";
import CancelIcon from '@mui/icons-material/Cancel';

export interface IInformationModalProps {
  isOpen: boolean;
  onClose: () => void;
  tableData: any;
}

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "39rem",
    maxWidth: '80%',
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    padding:'0 0 1rem 0',
    // p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "10px",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function InformationModal(props: IInformationModalProps) {
  const { isOpen, onClose, tableData } = props;
  const classes = useStyles();
  // console.log("tableData", tableData);
  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        height: "2rem",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
        width: "100%",
      }}
    >
      <Typography style={{ color: "red", fontSize: "12px" }}>
        {/* *Only completed systems data is visible {" "} */}
      </Typography>
      {/* <CustomExportButton />      */}
      <CSVLink
        data={getRow()}
        filename={"product-details-cost.csv"}
        className="btn btn-primary"
        target="_blank"
        sx={{ textDecoration: 'none !important' }}
      >
        <Box
          sx={{
            color: "primary.main",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            // textDecoration: "none",
          }}
        >
          <SaveAltIcon sx={{ fontSize: "1.2rem" }} />
          EXPORT
          {/* <GetAppIcon /> */}
        </Box>
      </CSVLink>
      {/* <CSVDownload data={rows} target="_blank" /> */}
    </GridToolbarContainer>
  );
  const col: any = [{
    field: "name",
    headerName: "Name",
    minWidth: 180,
    flex: 1.5,
  },
  {
    field: "value",
    headerName: "Value",
    minWidth: 100,
    flex: 1.5,
  },

  ]

  const getRow = () => {
    let data: any = [];
    if (tableData?.length > 0) {
      const keys = Object.keys(tableData[0]);
      if(keys.includes('bop_reference') || keys.includes('bop_cost') || keys.includes("commodity_name")){
        switch (tableData[0].commodity_name) {
          case "Consumables" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Description", value: tableData[0].description})
            data.push({id: 2, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 3, name: "Unit", value: tableData[0].unit})
            data.push({id: 4, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 5, name: "Cost", value: tableData[0].bop_cost})
            break;
          
          case "Bearings" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Code", value: tableData[0].code})
            data.push({id: 2, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 3, name: "ID(mm)", value: tableData[0].item_id})
            data.push({id: 4, name: "OD(mm)", value: tableData[0].item_od})
            data.push({id: 5, name: "Height(mm)", value: tableData[0].height})
            data.push({id: 6, name: "Weight(g)", value: tableData[0].weight})
            data.push({id: 7, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 8, name: "Cost", value: tableData[0].bop_cost})
            break;

          case "Connectors" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "No. of pins", value: tableData[0].no_of_pins})
            
            data.push({id: 2, name: "Position Config(mxn)", value: tableData[0].postion_config})
            data.push({id: 3, name: "Shape", value: tableData[0].shape})
            data.push({id: 4, name: "Terminal pin pitch", value: tableData[0].pin_pitch})
            data.push({id: 5, name: "Connection Type", value: tableData[0].connection_type})
            data.push({id: 6, name: "Male/Female", value: tableData[0].gender})
            data.push({id: 7, name: "Fit Type", value: tableData[0].fit_type})
            data.push({id: 8, name: "Material", value: tableData[0].material})
            data.push({id: 9, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 10, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 11, name: "Cost", value: tableData[0].bop_cost})
            break;

          case "Fasteners" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Code", value: tableData[0].code})
            data.push({id: 2, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 3, name: "Type of Fasteners", value: tableData[0].type})
            data.push({id: 4, name: "Grade", value: tableData[0].grade})
            data.push({id: 5, name: "Material", value: tableData[0].material})
            data.push({id: 6, name: "M-Size", value: tableData[0].m_size})
            data.push({id: 7, name: "Length", value: tableData[0].length})
            data.push({id: 8, name: "Thread Length", value: tableData[0].thread_length})
            data.push({id: 9, name: "Weight", value: tableData[0].weight})
            data.push({id: 10, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 11, name: "Cost", value: tableData[0].bop_cost})
            break;

          case "Electronic Child Components" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Description", value: tableData[0].description})
            data.push({id: 2, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 3, name: "Length", value: tableData[0].length})
            data.push({id: 4, name: "Width", value: tableData[0].width})
            data.push({id: 5, name: "Component Type", value: tableData[0].component_type})
            data.push({id: 6, name: "Size Unit", value: tableData[0].unit})
            data.push({id: 7, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 8, name: "Cost", value: tableData[0].bop_cost})

            break;
          
          case "Mechanical Proprietory Parts" :
            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Code", value: tableData[0].code})
            data.push({id: 2, name: "Item Description", value: tableData[0].description})
            data.push({id: 3, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 4, name: "Length", value: tableData[0].length})
            data.push({id: 5, name: "Width", value: tableData[0].width})
            data.push({id: 6, name: "Height", value: tableData[0].height})
            data.push({id: 7, name: "Weight", value: tableData[0].weight})
            data.push({id: 8, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 9, name: "Cost", value: tableData[0].bop_cost})

            break;
          default:

            data.push({id: 0, name: "Item Name", value: tableData[0].name})
            data.push({id: 1, name: "Item Description", value: tableData[0].description})
            data.push({id: 2, name: "Commodity Name", value: tableData[0].commodity_name})
            data.push({id: 3, name: "Reference", value: tableData[0].bop_reference})
            data.push({id: 4, name: "Cost", value: tableData[0].bop_cost})
            break;

          
        }
      }
      else{
        Object.keys(tableData[0]).map((item: any, index: any) => {
          data.push({ id: index, name: item, value: tableData[0][item] })
        })
      }
      
    }
    return data
  }
  let dataStructureDataGrid: any = {
    columns: col,
    rows: getRow() && getRow(),
  };
  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                {/* Handover */}

              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "0.2rem",
              }}
            />
            {/* <Table>
        <TableHead sx={{backgroundColor:'primary.main',color:'common.white'}}>
          <TableRow>
            <TableCell  sx={{color:'common.white',fontSize:'12px',padding:'0.2rem 0.5rem'}}className={styles.tableHcell}>Parameter</TableCell>
            <TableCell  sx={{color:'common.white',fontSize:'12px',padding:'0.2rem 0.5rem',width:'30%'}}className={styles.tableHcell}>Value</TableCell>
            <TableCell  sx={{color:'common.white',fontSize:'12px',padding:'0.2rem 0.5rem',width:'10%',}}className={styles.tableHcell}>Unit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
         {bomData && Object.keys(bomData).map((item:any)=>{
            return<TableRow>
            <TableCell sx={{borderBottomColor:'primary.light',fontSize:'12px',padding:'0.2rem 0.5rem'}} className={styles.tableBcell}>{item}</TableCell>
            <TableCell sx={{borderBottomColor:'primary.light',fontSize:'12px', width:'30%', padding:'0.2rem 0.5rem'}} className={styles.tableBcell}>{bomData[item][0]?.val}</TableCell>           
            <TableCell sx={{borderBottomColor:'primary.light',fontSize:'12px', width:'10%', padding:'0.2rem 0.5rem'}} className={styles.tableBcell}>{bomData[item][0]?.unit}</TableCell>           
          </TableRow>
         }) }
        </TableBody>
      </Table> */}
            <Box sx={{ height: { lg: '40vh', xl: '44vh' },
              "& .MuiDataGrid-columnHeaderTitle": {
                color: '#fff !important',
              },        
              "& .MuiDataGrid-cell.MuiDataGrid-cell--textLeft": {
                // minHeight: '24px !important',
                // maxHeight: '24px !important',
              },
              "& .MuiDataGrid-root .MuiDataGrid-columnHeader--sortable": {
                backgroundColor: "primary.main",
                // minHeight: '24px !important',
                // maxHeight: '24px !important',
              },
              "& .MuiDataGrid-root .MuiDataGrid-row": {
                // minHeight: '24px !important',
                // maxHeight: '24px !important',
              },
              "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders":{
                backgroundColor: "primary.main",
                // minHeight: '34px !important',
                // maxHeight: '34px !important',
                // lineHeight: '34px !important'
              },
            }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">
              <DataGrid
              headerHeight={48}
              rowHeight={36}
                {...dataStructureDataGrid}
                //   initialState={{
                //     sorting: {
                //       sortModel: [{ field: "name", sortable: false }],
                //     },
                //   }}
                isRowSelectable={() => false}
                density="compact"
                //@ts-ignore
                hideFooter="true"
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                // onCellClick={(params, event) => {
                //   event.defaultMuiPrevented = true;
                //   if (params?.field == "name") {
                //     setRoute(
                //       params?.row?.id,
                //       params?.row?.abbreviation

                //     );
                //   }
                // }}
                // rowsPerPageOptions={[16]}
                sx={{
                  padding: '0 1rem',
                  fontSize: "12px",
                  boxShadow: 2,
                  border: 2,
                  cursor: "pointer",                  
                  ".MuiDataGrid-cell": {
                    borderBottomColor: 'primary.light',                   
                  },

                }}
              // getRowClassName={(params) =>
              //   `super-app-theme--${params.row.abbreviation}`
              // }
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}