


import { useContext } from "react";

import { ID } from "../../utlis/commonInterfaces";
import assumptionCardService from "../../Redux/Services/assumptionCard.service";
import useFetch from "../../Core/CustomHooks/useFetch";
import { AssumptionIdContext } from "./CostingEstimation/CreateCostingPart";

// ----------------------------------------------------------------------------------

export interface IAssumptionCard {
    id: any;
    project: ID;
    top_vault: ID;
    supplier: ID;
    name: string;
    location: ID;
    labour_revision: ID;
    machine_revision: ID;
    tools_revision:ID;
    material_revision: ID;
    bop_revision: ID;
    currency_revision: ID;
    operations_revision:ID;
    volume: number;
    new_volume?:any;
}
function useAssumptioCard () {
    const assumptioCardId = useContext( AssumptionIdContext );
    
    return useFetch<IAssumptionCard>( {
        method: assumptionCardService.getById,
        args: [{ id: assumptioCardId }],
        getZeroth: true
    } );
};

// ----------------------------------------------------------------------------------

export {
    useAssumptioCard
};