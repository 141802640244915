function toFixed ( num: number, decimalPoints?:any ) {
    
    const array = num?.toString()?.split( "." );
    if ( array[1]?.length > 2 ) return num.toFixed( decimalPoints ? decimalPoints : 3 );

    return num;
}

export {
    toFixed
};