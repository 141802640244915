// import React, { useState } from 'react';
// import { Switch, FormControlLabel, IconButton } from '@mui/material';
// import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// interface SwitchInputProps {
//   label: string;
//   checked?: boolean;
//   setChecked?:any
  
// }

// function({ label, setChecked, checked }:any){


//   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setChecked(event.target.checked);
//     console.log('Switch is now:', event.target.checked ? 'ON' : 'OFF');
//   };

//   return (
//     <>
//     <IconButton onClick={handleChange}>
//       <FormatListBulletedIcon
//         sx={{ color: checked ? "#007FFF" : "grey", cursor: "pointer" }}
//       />
//     </IconButton>
//     {/* <FormControlLabel
//       control={<Switch checked={checked}  />}
//       label={label}
//     /> */}
//     </>
//   );
// };

// export default SwitchInput;



import React from 'react'
import { Switch, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

function SwitchInput({ label, setChecked, checked }:any) {

    const handleChange = (event:any) => {
    setChecked(!checked);
    
  };
  return (
    <>
      <Tooltip title="List View">
           <IconButton onClick={handleChange}>
       <FormatListBulletedIcon
         sx={{ color: checked ? "#007FFF" : "grey", cursor: "pointer" }}
       />
     </IconButton>
     </Tooltip>
    </>
  )
}

export default SwitchInput