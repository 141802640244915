import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import {
  Backdrop,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "600px",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  counterApiCall?: any;
  setDataType?: any;
  setCategoryExpanded?: any;
}

function AddCostingCategoryModal(props: IWatermarkModelProps) {
  const history = useHistory();
  const { isOpen, onCloseModal, counterApiCall, setDataType, setCategoryExpanded } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [categories, setCategories] = React.useState<any[]>([]); // State to store category options
  const { projectId, projectname } = useRouteParams<any>();

  // Fetch categories from API
  

  // Formik setup
  const formik = useFormik({
    initialValues: {
      bom_category: "",
      description: "",
      is_bop: "DC", // Default selection is 'DC'
    },
    validationSchema: Yup.object({
      bom_category: Yup.string().required("Category is required"),
      description: Yup.string(),
      is_bop: Yup.string().required("Selection is required"), // Required validation for DC/BOP
    }),
    onSubmit: (values) => {
      setLoading(true);
      const requestData = {
        ...values,
        is_bop: values.is_bop === "BOP", // Convert to boolean for API
        project: projectId,
      };
      API.post("/api/db/costing_category/", requestData, {}, 0)
        .then((res: any) => {
          setLoading(false);
          counterApiCall((prev: any) => prev + 1);
          // setDataType('');
          // setCategoryExpanded(values.is_bop ? "BOP" : "DC");
          // const newUrl = `/costingconfiguration-template/${projectId}/${projectname}/${costData?.costing_category}/${costData?.costing_category_group}/false/0/0/0`;
          history.push(`/costingconfiguration-template/${projectId}/${projectname}/${res.data?.id || 0}/${values.is_bop == 'BOP' ? "bop" : "dc"}/false/0/0/0/1/0`);
          onCloseModal();
        }).catch((err: any) => {
          setLoading(false);
          if (err && err?.response && err?.response?.data[0]) {
            Swal.fire({
              icon: "error",
              customClass: {
                container: "swal2Container",
              },
              html: `<div>
              <br />
              <p style="color:red;">${err?.response?.data[0]}</p>   
              </div>`,
            });
          }
        });
    },
  });

  React.useEffect(() => {
    if (isOpen) {
      API.get(
        `/api/db/get_bom_category_for_costing_config/`,
        {
          project: projectId,
          is_bop: formik.values.is_bop === "BOP",
        },
        0
      )
        .then((res: any) => {
          setCategories(res.data); // Set fetched categories
        })
        .catch((err: any) => {
          console.error("Error fetching categories:", err);
        });
    }
  }, [isOpen, projectId,formik.values.is_bop]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3.5rem",
              p: 2,
              backgroundColor: "#007fff", // Same color as the button background
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                color: "#fff", // White text for contrast
              }}
            >
              Add Costing Category
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light" }} />

          <Box sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
            <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                <ToggleButtonGroup
                  value={formik.values.is_bop}
                  exclusive
                  onChange={(e, value) => {
                    formik.setFieldValue("is_bop", value);
                  }}
                  aria-label="DC/BOP selection"
                >
                  <ToggleButton
                    value="DC"
                    sx={{
                      width: "150px",
                      textTransform: "none",
                      backgroundColor:
                        formik.values.is_bop === "DC" ? "#007fff" : "#fff",
                      color: formik.values.is_bop === "DC" ? "#fff" : "#007fff",
                      border: "2px solid",
                      borderColor:
                        formik.values.is_bop === "DC" ? "#007fff" : "#b0b0b0",
                      boxShadow:
                        formik.values.is_bop === "DC"
                          ? "0 0 8px rgba(0, 127, 255, 0.5)"
                          : "none",
                      "&:hover": {
                        backgroundColor:
                          formik.values.is_bop === "DC" ? "#007fff" : "#e6f7ff",
                        borderColor:
                          formik.values.is_bop === "DC" ? "#005bb5" : "#007fff",
                      },
                      "&.Mui-selected": {
                        borderColor: "#007fff",
                      },
                    }}
                    aria-label="DC"
                  >
                    DC
                  </ToggleButton>
                  <ToggleButton
                    value="BOP"
                    sx={{
                      width: "150px",
                      textTransform: "none",
                      backgroundColor:
                        formik.values.is_bop === "BOP" ? "#007fff" : "#fff",
                      color:
                        formik.values.is_bop === "BOP" ? "#fff" : "#007fff",
                      border: "2px solid",
                      borderColor:
                        formik.values.is_bop === "BOP" ? "#007fff" : "#b0b0b0",
                      boxShadow:
                        formik.values.is_bop === "BOP"
                          ? "0 0 8px rgba(0, 127, 255, 0.5)"
                          : "none",
                      "&:hover": {
                        backgroundColor:
                          formik.values.is_bop === "BOP"
                            ? "#007fff"
                            : "#e6f7ff",
                        borderColor:
                          formik.values.is_bop === "BOP"
                            ? "#005bb5"
                            : "#007fff",
                      },
                      "&.Mui-selected": {
                        borderColor: "#007fff",
                      },
                    }}
                    aria-label="BOP"
                  >
                    BOP
                  </ToggleButton>
                </ToggleButtonGroup>
              </Box>
             {formik.values.is_bop &&  <Box sx={{ marginBottom: "1.5rem" }}>
                <FormControl fullWidth variant="outlined">
                  <Select
                    fullWidth
                    id="bom_category"
                    name="bom_category"                    
                    placeholder="Select Category"
                    value={formik.values.bom_category}
                    onChange={formik.handleChange}
                    error={formik.touched.bom_category && Boolean(formik.errors.bom_category)}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 300, // Limit dropdown height
                        },
                      },
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <span style={{ color: '#aaa' }}>Select Category</span>;
                      }
                      const selectedCategory = categories.find(
                        (category) => category.category__id === selected
                      );
                      return selectedCategory ? selectedCategory.category__name : '';
                    }}
                  >
                    {categories.map((category) => (
                      <MenuItem
                        key={category.category__id}
                        value={category.category__id}
                      >
                        {category.category__name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>}
             {formik.values.is_bop &&  <Box sx={{ marginBottom: "1.5rem" }}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  variant="outlined"
                />
              </Box>}
           
              <LoadingButton
                type="submit"
                fullWidth
                disabled={formik.values.bom_category == null || formik.values.bom_category == ""}
                variant="contained"
                loading={loading}
                sx={{ backgroundColor: "#007fff" }}
              >
                Add Category
              </LoadingButton>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default AddCostingCategoryModal;
