import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Checkbox, Chip, Skeleton, Typography } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { API } from "../../api-services";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import {
  Bar,
  BarChart,
  Tooltip,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useStage } from "spritespin";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { ParametersHierarchyModal } from "./ParametersHierarchyModal";
import AnalysisGraphModal from "./AnalysisGraphModal";

export interface IScenarioAnalysisModelProps {
  isOpen: any;
  onCloseModal: any;
  scenarioIdd: any;
  setCount: any;
  graphModal: any;
  finalCostData: any;
  vault: any;
  checkboxvalue: any;
  setcheckboxvalue: any;
  getAnalysis: any;
  selectedScenarios: any;
  setSelectedScenarios: any;
  disabledNodes: any;
  setDisabledNodes: any;
  title: any;
  graphType: any;
  datas: any;
  topVault: any;
  onCloseGraphModal: any;
  handleGraphRemove: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "90%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  borderRadius: "10px",
  paddingX: 1,
  overflowY: "auto",
};

export default function ScenarioAnalysisModel(
  props: IScenarioAnalysisModelProps
) {
  const {
    isOpen,
    onCloseModal,
    scenarioIdd,
    setCount,
    graphModal,
    finalCostData,
    vault,
    checkboxvalue,
    setcheckboxvalue,
    getAnalysis,
    selectedScenarios,
    setSelectedScenarios,
    disabledNodes,
    setDisabledNodes,
    title,
    datas,
    graphType,
    onCloseGraphModal,
    topVault,
    handleGraphRemove,
  } = props;
  
  const [parameterData, setParameterData] = React.useState<any>([
    { name: "Parameter 1", id: 123, scenario: 1 },
    { name: "Parameter 2", id: 234, scenario: 1 },
    { name: "Parameter 3", id: 453, scenario: 2 },
    { name: "Parameter 4", id: 745, scenario: 2 },
  ]);
  const [selectedParameters, setSelectedParameters] = React.useState<any>([]);
  const [commodityName, setCommodityName] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [finalDataGraph, setfinalDataGraph] = React.useState<any>([]);
  const ScenarioAnalysis = useBaseModal();


  const [Loader, setLoader] = React.useState(false);
  const [CompareGraphData, SetCompareGraphData] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(0);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getCompareGraphData = () => {
    setLoader(true);
    API.get("/cost/scenario_graph/", { ...getObj(), page: currentPage + 1 }, 0)
      .then((res: any) => {
        setLoader(false);
        SetCompareGraphData(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server Error");
      });
  };

React.useEffect(() => {
    getCompareGraphData();
    // getTotalCompareGraphData();
  }, [topVault, vault,  currentPage]);


  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={8} fill="#666">
          <tspan
            style={{ fill: "black", fontSize: "12" }}
            textAnchor="middle"
            x="0"
          >
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    const firstCurrency = payload && payload?.length > 0 ? payload[0]?.payload?.currency : '';

    if (active && payload && payload?.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
            border: "1px solid",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "0.87rem",
              minWidth: "8rem",
              width: "100%",
              textAlign: "center",
            }}
          >{`${label}`}</Typography>
          <Typography
            sx={{
              fontSize: "0.87rem",
              width: "100%",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
              fontWeight: "bold", 
            }}
          >
            <span style={{ width: "6rem", textTransform: "capitalize" }}>
              Total:
            </span>
            <span style={{ width: "7rem", textAlign: "right" }}>
            {payload.reduce((accumulator: number, currentItem: any) => accumulator + currentItem.value,0).toFixed(2) }{' '}
            {firstCurrency}        
            </span>
          </Typography>

          {payload?.map((item: any, index: any) => {
            return (
              <>
                {/* @ts-ignore */}
                {
                  <Typography
                    sx={{
                      fontSize: "0.87rem",
                      width: "100%",
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ width: "6rem", textTransform: "capitalize" }}
                    >
                      {item?.name}:
                    </span>
                    <span style={{ width: "7rem", textAlign: "right" }}>
                      {item?.value?.toFixed(2)} {item.payload?.currency}
                    </span>
                  </Typography>
                }
              </>
            );
          })}          
        </div>
      );
    }
    return null;
  };

  const AnalysisHandler = () => {
    // console.log("isopen2");
    ScenarioAnalysis.open();
  };

  const currency = sessionStorage.getItem("currency");

  // Effect to ensure selectedParameters are valid when selectedScenarios change
  React.useEffect(() => {
    const validParameterIds = parameterData
      ?.filter((param: any) => selectedScenarios.includes(param.scenario))
      ?.map((param: any) => param.id);
    setSelectedParameters((prev: any) =>
      prev?.filter((id: any) => validParameterIds.includes(id))
    );
  }, [selectedScenarios]);

  // Filter parameters based on selected scenarios
  const filteredParameters = parameterData?.filter((param: any) =>
    selectedScenarios.includes(param.scenario)
  );

 const getFinalDataGraph = () => {
    setLoading(true);
    API.get(
      "/cost/scenario_analysis/",
      {
        get_total_cost_graph: true,
        vault_id: vault
      },
      0
    )
      .then((res: any) => {
        setfinalDataGraph(res?.data);
      })
      .catch((err: any) => {
        console.log("Server Error");
      })
      .finally(() => {
        setLoading(false);
      });
  };


  React.useEffect(() => {
    getFinalDataGraph()
  }, [])

  const colors = {
    RM_Cost: "#8884d8",
    Process_Cost: "#82ca9d",
    Overhead_Cost: "#ffc658",
  };

  const data = [
    {
      name: "Realistic Costing",
      Overhead_Cost: 10000000,
      Process_Cost: 20000000,
      RM_Cost: 60000000,
    },
    {
      name: "Scenario1",
      Overhead_Cost: 10000000,
      Process_Cost: 20000000,
      RM_Cost: 60000000,
    },
    {
      name: "Scenario2",
      Overhead_Cost: 20000000,
      Process_Cost: 10000000,
      RM_Cost: 50000000,
    },
  ];

  const scenarioData = finalCostData?.scenarios?.map((scenario: any) => ({
    name: scenario.name,
    id: scenario.estimation_id,
  }));

  console.log(finalCostData, "scenarios", finalCostData);
  

  return (
    <div>
      {ScenarioAnalysis.isOpen && (
        <ParametersHierarchyModal
          isOpen={ScenarioAnalysis.isOpen}
          onCloseModal={ScenarioAnalysis.close}
          finalCostData={finalCostData}
          vault={vault}
          checkboxvalue={checkboxvalue}
          setcheckboxvalue={setcheckboxvalue}
          disabledNodes={disabledNodes}
          setDisabledNodes={setDisabledNodes}
          title={title}
          datas={datas}
          graphType={graphType}
          onCloseGraphModal={graphModal.close}
          topVault={topVault}
          graphModal={graphModal}
          selectedScenarios={selectedScenarios}
          getAnalysis={getAnalysis}
        />
      )}

      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              justifyContent: "center",
              position: "sticky",
              top: 0,
              backgroundColor: "white",
              zIndex: 10,
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "1rem",
                textAlign: "center",
                width: "100%",
                padding: 1,
              }}
            >
              Scenario Analysis
            </Typography>
            <CancelIcon
              onClick={onCloseModal}
              sx={{ fontSize: "1.5rem", cursor: "pointer" }}
            />
          </Box>

          <Box
            sx={{ display: "flex", justifyContent: "center", padding: "1rem" }}
          >
            <Box sx={{ width: { lg: "75vw", xl: "75vw" }, height: "38vh" }}>
              {Loader ? (
                <>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                  <Skeleton></Skeleton>
                </>
              ) : (
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    width={500}
                    height={300}
                    data={CompareGraphData}
                    margin={{ top: 10, right: 30, left: 35, bottom: 0 }}
                  >
                    <XAxis
                      dataKey="name"
                      interval={0}
                      style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                      }}
                      stroke="#007FFF"
                      tick={<CustomizedTick />}
                    />
                    <YAxis
                      //@ts-ignore
                      left={0}
                      stroke="#007FFF"
                      fill="#1a4faa"
                      fontSize="0.87rem"
                      // type="number"
                      // domain={[(dataMin:any) => (0 - Math.abs(dataMin)),( dataMax:any) => (dataMax * 2)]}
                      domain={[
                        () => 0,
                        (dataMax: number) =>
                          Math.round(dataMax + (dataMax * 5) / 100),
                      ]}
                      label={{
                        value: `Currency (${CompareGraphData && CompareGraphData[0]?.currency
                          })`,
                        angle: -90,
                        position: "middle",
                        dx: -47,
                        fill: "black",
                        fontSize: "12px",
                      }}
                    />
                    <Legend verticalAlign="bottom" align="center" />
                    <Tooltip
                      cursor={false}
                      content={<CustomizedCostTooltip />}
                    />
                    <Bar
                      maxBarSize={80}
                      dataKey="RM Cost"
                      stackId="1"
                      stroke="#8884d8"
                      fill="#8884d8"
                      id="aarea"                     
                    >

                    </Bar>
                    <Bar
                      maxBarSize={80}
                      dataKey="Process Cost"
                      stackId="1"
                      stroke="#82ca9d"
                      fill="#82ca9d"
                      id="barea"
                      cursor={"pointer"}
                    >

                    </Bar>
                    <Bar
                      maxBarSize={80}
                      cursor={"pointer"}
                      dataKey="Overhead Cost"
                      stackId="1"
                      stroke="#ffc658"
                      fill="#ffc658"
                      id="carea"
                      label={{
                        fill: "black",
                        position: "top", // Adjust position if needed
                        formatter: (value:any) => value.toFixed(2), // Format to 2 decimal points
                      }}
                    >

                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              )}
            </Box>           
          </Box>
          <AnalysisGraphModal
            // onCloseModal={}
            onCloseModal={graphModal.close}
            vault={vault}
            topVault={topVault}
            title={title}
            datas={datas}
            graphType={graphType}
            checkboxvalue={checkboxvalue}
            isOpen={graphModal.isOpen}
            handleGraphRemove={handleGraphRemove}
          />

          <Box sx={{ position: "sticky", bottom: 0, backgroundColor: "white" }}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                // columnGap: "1rem",
                justifyContent: "center",
                marginTop: 1,
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "1rem",
                  textAlign: "center",
                  width: "100%",
                  paddingTop: 1,
                }}
              >
                Select the scenario you want analyze
              </Typography>
            </Box>

            <Box
              sx={{
                padding: "1rem",
                paddingTop: 0,
                display: "flex",
                alignItems: "flex-end",
                gap: "1rem",
              }}
            >              

              {/* Autocomplete for Selecting Scenarios */}
              <Autocomplete
                multiple
                disableClearable
                id="Select Scenarios"
                limitTags={6}
                disableCloseOnSelect
                options={scenarioData}
                // Map selectedScenarios IDs back to scenario objects
                value={scenarioData?.filter((scenario: any) =>
                  selectedScenarios?.includes(scenario.id)
                )}
                onChange={(event, newValue) => {
                  console.log("xx", newValue);
                  // Update state with selected scenario IDs
                  const newIds = newValue?.map((scenario: any) => scenario.id);
                  setSelectedScenarios(newIds);
                }}
                sx={{ width: "100%" }}
                getOptionLabel={(option: any) => option.name}
                renderOption={(props, option, { selected }) => (
                  <li {...props} style={{ fontSize: "1rem" }}>
                    <Checkbox checked={selected} style={{padding:'3px', marginRight: 8 }} />
                    {option.name}
                  </li>
                )}
                renderTags={(tagValue, getTagProps) =>
                  tagValue?.map((option, index) => (
                    <Chip
                      size="small"
                      sx={{
                        backgroundColor: "#007fff14",
                        color: "primary.main",
                        fontSize: "1rem",
                      }}
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Select Scenarios"
                    sx={{
                      marginTop: "1rem",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />

             

              <Button
                variant="contained"
                size="small"
                // startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                title="Select Parameter"
                // disabled={getDisableStatus()}
                // onClick={() => AddScenarioHandler()}
                onClick={() => {
                  AnalysisHandler();
                }}
                disabled={selectedScenarios?.length < 2}
                sx={{
                  padding: "0.8rem 1rem",
                  minWidth: "fit-content",
                  lineHeight: 1,
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
              >
                Select Parameters
              </Button>
            </Box>           
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
