import React, { useEffect, useState } from "react";
import { API } from "../../api-services";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import InfoIcon from "@mui/icons-material/Info";
import ScenarioCostingListViewRow from "./ScenarioCostingListViewRow";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ScenarioCostingListViewRowItem from "./ScenarioListViewRowItem";

function ScenarioCostingPageListView({
  currentPage,
  setCurrentPage,
  setTotalcount,
  totalCount,
  costDetailsData,
  addCount,
  decimalPoints,
  setDecimalPoints,
  scenarioCostHeaderData,
  selectedScenarios,
  toggleHighlight,
  setListLoading,
  apiCallMain
}: any) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    scenarioId,
    treeabbr,
    scenarioType,
  } = useRouteParams<any>();

  const [Loader, setLoader] = React.useState(false);
  const [ListLoader, setListLoader] = React.useState(false);
  const [hierarchyLevel, setHierarchyLevel] = React.useState(1);
  const [TableData, setTableData] = React.useState<any>([]);
  const [listData, setListData] = React.useState<any>([]);
  const [costingTableData, setCostingTableData] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [sessionGetInto, setSessionGetInt] = useState<any>([]);
  const [loaderRecursiveId, setLoaderRecursivId] = React.useState<any>();

  const getObj = () => {
    if (scenarioCostHeaderData?.vault !== null) {
      return { vault: scenarioCostHeaderData?.vault };
    } else {
      return { top_vault: scenarioCostHeaderData?.top_vault };
    }
  };




  const getScenarioListData = () => {
    setListLoader(true);

    API.get(
      `/cost/scneario_list_data_view/`,
      {
        ...getObj(),
        list_view: Boolean(true),
        page: Number(currentPage + 1),
        selected_scenarios: selectedScenarios?.length > 0 ? selectedScenarios?.toString() : null
      },
      0
    )
      .then((res: any) => {
        setListData(res.data);
        sessionStorage.setItem(
          "hierarchyCostingScenarioList",
          JSON.stringify([vault])
        );
        setSessionGetInt([vault]);
      })
      .catch(() => { })
      .finally(() => {
        setListLoader(false);
      });
  };

  const loaderLoadingComponent = () => {
    let arrayLoopComponent = [];
    for (let i = 0; i < 20; i++) {
      arrayLoopComponent.push(
        <Box sx={{ display: "flex", width: "100%" }}>
          <Box
            sx={{
              padding: "0.5rem 0.5rem",
              borderBottomColor: "primary.light",
              width: "22rem",
              minWidth: '15rem',
            }}
          >
            <Skeleton variant="text" />

          </Box>

          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>
          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>
          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>
          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>
          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>

          <Box
            sx={{
              padding: " 0.5rem",
              borderBottomColor: "primary.light",
              width: "15rem",
              minWidth: '15rem',

            }}
          >
            <Skeleton variant="text" />
          </Box>

        </Box>
      );
    }
    return arrayLoopComponent;
  };

  const getTableData = () => {
    setLoader(true);
    API.get(
      "/cost/scneario_list_data_view/",
      { ...getObj(), page: currentPage + 1, header_data: true, selected_scenarios: selectedScenarios?.length > 0 ? selectedScenarios?.toString() : null },
      0
    )
      .then((res: any) => {
        // console.log(res?.data);
        setTableData(res?.data);

        setLoader(false);
      })
      .catch((err: any) => {
        console.log("Sever Error");
        setLoader(false);
      }).finally(() => {
        setListLoading(false);
      });
  };

  const getPageCount = () => {
    API.get(
      "/cost/scenario_data_header/",
      { ...getObj(), total_page_count: true, selected_scenarios: selectedScenarios?.length > 0 ? selectedScenarios?.toString() : null },
      0
    )
      .then((res: any) => {
        setTotalcount(res.data?.total_count);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  React.useEffect(() => {
    if (scenarioCostHeaderData) {
      setListLoading(true);
      getTableData();
      getPageCount();
      getScenarioListData();
    }
  }, [currentPage, scenarioCostHeaderData, apiCallMain, selectedScenarios]);

  useEffect(() => {
    setSessionGetInt(sessionStorage.getItem("hierarchyCostingScenarioList"));
  }, []);

  useEffect(() => {
    if (listData?.length > 0) {
      const dataa = {
        subsystem: [listData?.[0]?.subsystem],
      };
      setCostingTableData(dataa);
    }
  }, [listData]);


  useEffect(() => {
    console.log(apiCallMain, "apiCallMain");

    if (apiCallMain > 0) {
      getTableData();
    }
  }, [apiCallMain])

  // console.log(listData?.[0]?.subsystem, "listData");

  function replaceDataWithSameId(
    obj: any,
    targetId: any,
    newData: any,
    setLoaderRecursivId: any
  ) {
    if (obj.id === targetId) {
      // Replace data in the current object with the matching ID
      obj.subsystem = newData.subsystem;
      setLoaderRecursivId(-1);
      return;
      // console.log(obj.id,targetId,"targetId",obj.data,newData.data)
    }

    if (obj?.subsystem && obj?.subsystem.length > 0) {
      // Recursively traverse the children
      for (let i = 0; i < obj?.subsystem?.length; i++) {
        replaceDataWithSameId(
          obj.subsystem[i],
          targetId,
          newData,
          setLoaderRecursivId
        );
      }
    }
  }

  const handleOpenHierarchy = (
    idPush: any,
    abbrebation?: any,
    object_id?: any
  ) => {
    let a: any = sessionStorage.getItem("hierarchyCostingScenarioList");
    let val: any = JSON.parse(a);

    // console.log(val, idPush,listData?.[0]?.subsystem?.abbreviation,listData?.[0]?.subsystem?.object_id, "listdataa name");

    if (val?.includes(idPush)) {
      sessionStorage.setItem(
        "hierarchyCostingScenarioList",
        JSON.stringify(val?.filter((item: any) => item != idPush))
      );
      setSessionGetInt(val?.filter((item: any) => item != idPush));
    } else {
      sessionStorage.setItem(
        "hierarchyCostingScenarioList",
        JSON.stringify([...val, idPush])
      );
      setLoaderRecursivId(idPush);
      setSessionGetInt([...val, idPush]);
      API.get(
        `/cost/scneario_list_data_view/`,
        {
          // ...getObj(),
          top_vault: (abbrebation && abbrebation == "product") ? idPush : null,
          vault: (abbrebation && abbrebation == "product") ? null : idPush,
          list_view: Boolean(true),
          page: Number(currentPage + 1),
          abbreviation: listData?.[0]?.subsystem?.abbreviation,
          object_id: listData?.[0]?.subsystem?.object_id,
          selected_scenarios: selectedScenarios?.length > 0 ? selectedScenarios?.toString() : null
        },
        0
      ).then((res: any) => {
        replaceDataWithSameId(
          costingTableData,
          idPush,
          res?.data?.[0]?.subsystem,
          setLoaderRecursivId
        );
      });
    }
  };






  return (
    <div>
      <Box
        sx={{
          // display: "flex",
          // alignItems: "flex-start",
          width: "100%",
          border: "1px solid #ddefff",
          borderRadius: "10px",
          // overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: { width: "100%", padding: "0", lg: "87vh", xl: "89vh", overflowX: "scroll" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"

        >
          <Box sx={{ width: '100%', minWidth: 'fit-content' }}>
            <Box
              sx={{
                backgroundColor: "primary.light",
                position: "sticky",
                top: 0,
                zIndex: 1,
                width: "100%"
              }}
            >
              <Box sx={{ display: "flex", width: "100%" }}>
                <Box
                  sx={{
                    padding: "0.5rem 0.5rem",
                    borderBottomColor: "primary.light",
                    width: "22rem",
                    minWidth: '15rem',
                  }}
                >
                  Part No.
                </Box>

                <Box
                  sx={{
                    padding: " 0.5rem",
                    borderBottomColor: "primary.light",
                    width: "15rem",
                    minWidth: '15rem',

                  }}
                >
                  Part Name
                </Box>

                <Box sx={{ display: "flex", flex: 1 }}>
                  {TableData?.map((item: any, index: any) => {
                    return (

                      <Box
                        sx={{
                          display: "inline-block", // Ensures it behaves as a single line
                          overflow: "hidden", // Clips content that overflows the box
                          whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                          textOverflow: "ellipsis", // Adds ellipsis to clipped text
                          padding: "0.5rem",
                          minWidth: "8rem",
                          width: '100%',
                          textAlign: "right",
                          borderLeft:'1px solid',
                          borderColor: "#beddf8",
                        }}
                      >
                       
                        <Tooltip title={`(${item?.independent == true ? "Independent" : "Dependent"}) ${item?.name}(${item?.currency})`} placement="top">
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                            <span>{item?.independent == true ? "(I)" : "(D)"}</span>
                            <span style={{ width: '100%', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}> {item?.name} </span> <span>{` (${item?.currency})`} </span>
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}
                </Box>
              </Box>
            </Box>

            <Box>
              {ListLoader
                ? loaderLoadingComponent()
                : costingTableData?.subsystem?.map((item: any, index: any) => {
                  return (
                    <ScenarioCostingListViewRowItem
                      itemIndex={index}
                      firstItem={costingTableData?.subsystem?.[0]}
                      costingTableData={item}
                      hoveredIndex={hoveredIndex}
                      setHoveredIndex={setHoveredIndex}
                      sessionGetInto={sessionGetInto}
                      costingTableDataParent={sessionGetInto}
                      loaderRecursiveId={loaderRecursiveId}
                      handleOpenHierarchy={handleOpenHierarchy}
                      decimalPoints={decimalPoints}
                      toggleHighlight={toggleHighlight}
                      hierarchyLevel={hierarchyLevel} setHierarchyLevel={setHierarchyLevel}
                    />
                  );
                })}





            </Box>
          </Box>


        </Box>
      </Box>
    </div>
  );
}

export default ScenarioCostingPageListView;
