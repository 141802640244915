import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../Redux/Services/admin.service";
import FetchCostModelName from "./FetchCostModelName";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton"; // Import Skeleton for the loader
import { FormControl, MenuItem, Select } from "@mui/material";
import {
  BOPCategories,
  materialcategories,
} from "../../utlis/Category.constant";
import CreateCostCalculator from "./CreateCostCalculator";
import { Height } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  Height: "95vh",
  boxShadow: 24,
  p: 1,
};


// selectedTreeData={selectedTreeData && selectedTreeData}
//                 setCounterApiCall:any
//                 setIsSyncRecomended:any
//                 setValidationParent:any
//                 triggerSyncButton:any
//                 setTriggerSyncButton:any
//                 Counter:any
//                 setCounter
interface ICalculatorConfigDataModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  selectedTreeData: any;
  
}

const CalculatorConfigDataModal: React.FC<ICalculatorConfigDataModalProps> = ({
  isOpen,
  onCloseModal,
  selectedTreeData
}) => {
  
    const [counterApiCall, setCounterApiCall] = React.useState<any>(1);
     const [isSyncRecomended, setIsSyncRecomended] = React.useState<any>(false)
      const [validationParent, setValidationParent] = React.useState<any>(false)
      const [triggerSyncButton, setTriggerSyncButton] = React.useState<any>(0)
      const [Counter, setCunter] = React.useState<any>(0)
      
      const SyncClick = (itemId: any, HierarchyAllData: any) => {
        // setIsSyncRecomended(false)
        // setValidationParent(true)
        setTriggerSyncButton((prev: any) => prev + 1)
        // history.push(`/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`)
      }




  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "3vh",
          }}
        >
          
          <IconButton
          sx={{float: "right"}}
            onClick={() => {
              onCloseModal();
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>

        {/* <Divider sx={{ my: 2 }} /> */}

        <Box sx={{ overflowY: "auto", maxHeight: "90vh" }}>
        <CreateCostCalculator
                selectedTreeData={selectedTreeData && selectedTreeData}
                setCounterApiCall={setCounterApiCall}
                setIsSyncRecomended={setIsSyncRecomended}
                setValidationParent={setValidationParent}
                triggerSyncButton={triggerSyncButton}
                setTriggerSyncButton={setTriggerSyncButton}
                Counter={Counter}
                setCounter={setCunter}
              />
              
        </Box>

        
      </Box>
    </Modal>
  );
};

export default CalculatorConfigDataModal;
