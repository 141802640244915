import {
    SAVE

  } from "../Actions/userInput.action";
  
  const initialState = {
   userInput:{}

  };
  
  export default (state: any = initialState, action: any) => {
    switch (action.type) {
     


        case SAVE:
        return Object.assign({}, state, {
            userInput: action.payload,

        });
  

      default:
        return state;
    }
  };
  