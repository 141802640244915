import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./AddMaterialRevisionModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
// import { AssignCostModeltoAddedMaterialsModal } from './AssignCostModeltoAddedMaterialsModal';
import { useBaseModal } from "../../SearchPage/useBaseModal";
// import { AssignCostModeltoAddedBoughtOutModal } from "./AssignCostModeltoAddedBoughtOutModal";
import { API } from "../../../api-services";
import { debounce } from "lodash";
import Swal from "sweetalert2";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { AssignCostModeltoAddedBOPModal } from "./AssignCostModeltoAddedBOPModal";
import { FetchfromBOMModal } from "./FetchfromBOMModal";
import { EditScequenceCostItemsMaterial } from "./EditScequenceCostItemsMaterial";
import { EditItemsName } from "./EditItemsName";
import LoadingButton from "@mui/lab/LoadingButton";
import successIcon from "../../../Assets/images/success.png";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface IAddBoughtOutRevisionModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData: any;
  getRMData: any;
  SelectedmulIds: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: '60vw', xl: '60vw' },
  maxWidth: '96vw',
  // width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function AddBoughtOutRevisionModal(
  props: IAddBoughtOutRevisionModalProps
) {
  const { onCloseModal, isOpen, costDetailsData, getRMData, SelectedmulIds } = props;
  const { scenarioId,newUI } = useRouteParams<any>();
  const [age, setAge] = React.useState("");
  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value);
  };
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>("");
  const [BOPPartsData, setBOPPartsData] = React.useState<any>([]);
  const [selectedBOPParts, setSelectedBOPParts] = React.useState<any>([]);
  const [tableDataBOP, setTableDataBOP] = React.useState<any>([]);
  const [selectedBOPPartsLength, setSelectedBOPPartsLength] = React.useState<number>();
  // const [tableDataBOPLoad, setTableDataBOPLoad] = React.useState<any>(false);
  const getCategoryData = () => {
    API.get(
      "/api/db/revision_database/",
      {
        category_type: "boughtoutpart",
        revision_id: costDetailsData?.machine_revision,
      },
      0
    )
      .then((res: any) => {
        setCategoryData(res.data);
      })
      .catch((err: any) => { });
  };

  const getBOPPartsData = (commodity: any) => {
    if(commodity == "Electrical & Electronics Proprietary Parts"){

    API.get(
      "/api/db/bought_out_cost/",
      {
        commodity: commodity,
        revision: costDetailsData?.machine_revision,
        get_integrated_bop: true,
      },
      0
    )
      .then((res: any) => {
        setBOPPartsData(res.data);
      })
      .catch((err: any) => { });
    }
    else{

    API.get(
      "/api/db/bought_out_cost/",
      {
        commodity: commodity,
        revision: costDetailsData?.machine_revision,
      },
      0
    )
      .then((res: any) => {
        setBOPPartsData(res.data);
      })
      .catch((err: any) => { });
    }
  };

  const getTableData = () => {
    API.get(
      "/cost/material/",
      {
        cost_estimation: costDetailsData?.cost_estimation,
        calculator: true,
        bop: true,
      },
      0
    )
      .then((res: any) => {
        setTableDataBOP(res.data);
      })
      .catch((err: any) => { });
  };

  const handleCategoryChange = (ed: any) => {
    const { value } = ed.target;
    setSelectedCategory(value);
    getBOPPartsData(value);
  };

  React.useEffect(() => {
    getCategoryData();
  }, [costDetailsData]);

  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.post(
        `/cost/material/`,
        {
          purchasing_items: value,
          cost_estimation: costDetailsData?.cost_estimation,
          new_ui: newUI === "true" ? true : undefined,
        },
        {},
        0
      )
        .then((res: any) => {
          getTableData();
          // getRMData();
          // getRMDataList();
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                               <br />
                               <p style="color:"red">${data[0]}</p>   
                                </div>`,
          });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  const AssignCostModalBOP = useBaseParamsModal();
  const AssignCostModalBoughtOutHandler = () => {
    // getRMData();
    Swal.fire({
      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Materials added successfully
</p>   
</div>`,
    });
    AssignCostModalBOP.open(costDetailsData);
  };
  const handleSelectChangBOP = (event: any, newSystemValue: any) => {
    if(selectedBOPPartsLength !== undefined && newSystemValue.length < selectedBOPPartsLength){

    
    setSelectedBOPParts(newSystemValue);
    setSelectedBOPPartsLength(newSystemValue.length)
    let newSelectedSystem = newSystemValue.map(
      (item: any) => item.purchase_item
    );
    }
    else{
      setSelectedBOPParts(newSystemValue);
    setSelectedBOPPartsLength(newSystemValue.length)
    let newSelectedSystem = newSystemValue.map(
      (item: any) => item.purchase_item
    );
    let currentSelectedSystem = newSelectedSystem;
    currentSelectedSystem = currentSelectedSystem.pop();
    ExactDebounce([currentSelectedSystem]);
    }
  };
  const getOptionDisabled = (option: any) => {
    return selectedBOPParts.some(
      (selectedOption: any) =>
        selectedOption.purchase_item === option.purchase_item
    );
  };

  const FetchBOMTable = useBaseParamsModal();
  const FetchBOMTableHandler = () => {
    FetchBOMTable.open();
  };

  return (
    <div>
      {AssignCostModalBOP.isOpen && (
        <AssignCostModeltoAddedBOPModal
          isOpen={AssignCostModalBOP.open}
          onCloseModal={AssignCostModalBOP.close}
          costDetailsData={AssignCostModalBOP.propsId}
          parentClose={onCloseModal}
          getRMData={getRMData}
          SelectedmulIds={SelectedmulIds}
          Commodity={selectedCategory}
          RevisionId={costDetailsData?.machine_revision}

        />
      )}

      {FetchBOMTable.isOpen && (
        <FetchfromBOMModal
          isOpen={FetchBOMTable.open}
          onCloseModal={FetchBOMTable.close}
          costDetailsData={costDetailsData}
          getTableData={getTableData}
        />
      )}

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Bought-Out items
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                API.post(
                  "/cost/cost_calculator/",
                  {
                    bop: true,
                    cost_estimation: costDetailsData?.cost_estimation,
                    scenario: scenarioId ? scenarioId : undefined
                  },
                  {},
                  0
                )
                  .then((res: any) => { })
                  .catch((err: any) => { });
                onCloseModal();
                getRMData();
              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                columnGap: "1rem",
                margin: '4rem 0',
                width: '100%',
              }}
            >
              <FormControl fullWidth>
                <InputLabel shrink
                  sx={{ marginLeft: "-1rem", marginBottom: "-0.5rem", color: 'primary.main' }}
                >
                  Select Bought-Out Category
                </InputLabel>
                <Select
                  variant="standard"
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategory}
                  label="Select Bought-Out Category"
                  onChange={handleCategoryChange}
                  sx={{
                    width: "100%",
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                    {
                      fontSize: "1rem",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {categoryData &&
                    categoryData?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ fontSize: "1rem" }}
                          value={item?.category}
                        >
                          {item?.category}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              {BOPPartsData && (
                <FormControl fullWidth>
                  <InputLabel shrink
                    sx={{ marginLeft: "-1rem", marginTop: '-0.75rem', color: 'primary.main' }}
                  >
                    Select Bought-out Material
                  </InputLabel>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    limitTags={3}
                    disableClearable
                    disableCloseOnSelect
                    sx={{
                      ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                      ".MuiButtonBase-root.MuiChip-root": {
                        backgroundColor: "primary.light",
                        height: "26px",
                      },
                    }}
                    options={BOPPartsData && BOPPartsData}
                    getOptionLabel={(option: any) => option?.purchase_name}
                    value={selectedBOPParts}
                    onChange={(event: any, newSystemValue: any) => {
                      handleSelectChangBOP(event, newSystemValue);
                    }}
                    getOptionDisabled={getOptionDisabled}
                    renderOption={(props, option) => (
                      <li {...props} style={{ fontSize: '1rem' }}>
                        {option?.purchase_name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        // label="Select Bought-out Material"
                        // placeholder="Select Bought-out Material"
                        sx={{
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "1rem !important",
                          },
                          ".MuiSvgIcon-root": { color: "primary.main" },
                        }}
                      />
                    )}
                  />
                </FormControl>
              )}
              {/* <Typography sx={{ fontSize: "1rem" }}>OR</Typography>
              <Box sx={{ width: { lg: '36rem', xl: '33rem' } }}>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => FetchBOMTableHandler()}
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  Fetch from BOM Table
                </Button>
              </Box> */}
            </Box>
            {tableDataBOP && tableDataBOP?.length > 0 && (
              <Box>
                <Box sx={{ margin: "0.5rem" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    Selected Bought-out Material
                  </Typography>
                </Box>
                <Box className={styles.selectMaterial}>
                  <Box
                    sx={{ width: "93vw", marginBottom: "0.5rem", height: { lg: '56vh', xl: '62vh' } }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Sl.No
                          </TableCell>

                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Code
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Component Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Description
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Place
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              textAlign: "right",
                            }}
                          >
                            Quantity
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                              textAlign: 'right',
                            }}
                          >
                            Unit Cost
                          </TableCell>
                          {/* <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              backgroundColor: "primary.light",
                            }}
                          >
                            Action
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {tableDataBOP &&
                          tableDataBOP?.map((item: any) => {
                            return (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    padding: "0rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {newUI === "true"?item?.sequence:<EditScequenceCostItemsMaterial
                                    value={item}
                                    getRMData={getRMData}
                                    idd={item?.id}
                                    getRMDataList={getTableData}
                                    type="bop"
                                  />}
                                </TableCell>

                                <TableCell
                                  sx={{
                                    padding: "0rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {newUI === "true"?item?.alias:<EditItemsName
                                    value={item}
                                    getRMData={getRMData}
                                    idd={item?.id}
                                    getRMDataList={getTableData}
                                  />}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.purchasing_item?.code}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.purchasing_item?.category}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.purchasing_item?.type}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.purchasing_item?.description}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  {item?.purchasing_item?.place}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    textAlign: "right",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    textAlign: "right",
                                  }}
                                >
                                  {item?.purchasing_item?.unit_cost}
                                </TableCell>
                                {/* <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <DeleteIcon />
                                </TableCell> */}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "right",
                      margin: "0.5rem 0",
                      paddingRight: "0.5rem",
                    }}
                  >
                    {newUI !== "true" && <LoadingButton
                      size="small"
                      variant="contained"
                      onClick={() => {
                        AssignCostModalBoughtOutHandler()}}
                      sx={{
                        ".MuiCircularProgress-root": {
                          color: "primary.main",
                        },
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      Add Bought-Out Item
                    </LoadingButton>}
                    {newUI === "true" && <LoadingButton
                      size="small"
                      variant="contained"
                      onClick={() => {
                        API.post(
                          "/cost/cost_calculator/",
                          {
                            bop: true,
                            cost_estimation: costDetailsData?.cost_estimation,
                            scenario: scenarioId ? scenarioId : undefined
                          },
                          {},
                          0
                        )
                          .then((res: any) => { })
                          .catch((err: any) => { });
                        onCloseModal();
                        getRMData();
                      }}
                      sx={{
                        ".MuiCircularProgress-root": {
                          color: "primary.main",
                        },
                        "&:hover": {
                          transform: "Scale(1.05)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                    >
                      Add Bought-Out Item
                    </LoadingButton>}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
