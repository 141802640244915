import { TableContainer, TableHead, TableCell,Table, TableRow, TableBody } from '@mui/material';

import React from 'react'

function ScenarioDeltaTable({HierarchyAllData, type}:any) {
  return (
    <>
   
   
     <TableContainer sx={{ backgroundColor: "white", borderRadius: "8px" }}>
   <Table size="small" sx={{ minWidth: 400 }}>
     <TableHead sx={{ backgroundColor: "#e3f2fd" }}>
       <TableRow>
         <TableCell align="center" sx={{ color: "#007FFF", fontWeight: "bold" }}>
           Scenarios
         </TableCell>
         <TableCell align="center" sx={{ color: "#007FFF", fontWeight: "bold" }}>
           Delta from Main Costing {`(${HierarchyAllData?.currency})`}
         </TableCell>
       </TableRow>
     </TableHead>
     <TableBody>
       {HierarchyAllData?.scenario_data?.map((item: any, index: any) => {
        const stdResult = parseFloat(type == "calculator" ? HierarchyAllData?.std_result : HierarchyAllData?.cost);
        const scenarioCost = parseFloat(item?.scenario_cost);
        const data = 
         [ {
            type: "sub_cal_cat",
            cost:30.5555,
            scenario_data:[
              {
                name:"scenario 1",
                scenario_cost:20.34
              },
              {
                name:"scenario 2",
                scenario_cost:"-"
              },
              {
                name:"scenario 3",
                scenario_cost:null
              },
              {
                name:"scenario 3",
                scenario_cost:5.123
              },
            ]
          
        }]

        const delta =
          !isNaN(stdResult) && !isNaN(scenarioCost)
            ? (stdResult - scenarioCost).toFixed(2)
            : "-";

        return (
          <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f0f7ff" } }}>
            <TableCell align="center" sx={{ color: "#007FFF" }}>
              {item?.name || "-"}
            </TableCell>
            <TableCell align="center" sx={{ color: "#007FFF" }}>
              {delta}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  </Table>
</TableContainer>
</>
  )
}

export default ScenarioDeltaTable