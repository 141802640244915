import {
  Box,
  Popover,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import styles from "../../ComponentUI/CostingCreateModule/CostingCreateSystemInsideBodyRow.module.scss";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

function ScenarioCostingListViewRowItem({
  itemIndex,
  costingTableData,
  hoveredIndex,
  setHoveredIndex,
  sessionGetInto,
  costingTableDataParent,
  handleOpenHierarchy,
  loaderRecursiveId,
  decimalPoints,
  firstItem,
  toggleHighlight,
  setHierarchyLevel,
  hierarchyLevel
}: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [highlightedCellIndex, setHighlightedCellIndex] = useState<
    number | null
  >(null);
  const [indexMax, setIndexMax] = useState<
    number | null
  >(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const {
    vault,
    topVault,
    projectId,
    projectname,
    projectName,
    productId,
    abbrebation,
    cardtype,
    tableCounter,
    condition,
    calctype,
    scenarioId,
    costEstimationId,
    treeabbr,
    newUI,
    scenarioType,
  } = useRouteParams<any>();

 


// Normalize and filter data
const normalizeAndFilter = (data: any) => {
  const validItems = data
    .map((item:any) => ({
      ...item,
      cost:
        typeof item?.cost === "string" && !isNaN(Number(item?.cost))
          ? Number(item?.cost) // Convert valid strings to numbers
          : typeof item?.cost === "number" && !isNaN(item?.cost)
          ? item?.cost // Keep valid numbers
          : null, // Replace invalid or non-convertible values with null
    }))
    .filter((item:any) => item?.cost !== null); // Remove invalid entries

  return validItems;
};

 // Find max values
 const getMaxValues = (data: []) => {
  const costs = data?.map((item:any) => item?.cost as number);
  const maxCost = Math.max(...costs);

  // Check if all valid values are equal
  const allEqual = costs?.length > 1 && costs?.every((cost) => cost === maxCost);
  return { maxCost, allEqual };
};

const normalizedArr = normalizeAndFilter(costingTableData?.scenario_details);
  const { maxCost, allEqual } = getMaxValues(normalizedArr);

console.log(costingTableData?.name, maxCost, allEqual, costingTableData?.scenario_details);
console.log(sessionGetInto, costingTableDataParent?.id ,sessionGetInto?.includes(costingTableDataParent?.id), costingTableData?.name, costingTableData?.ancestors, "marginLeft:"
);



let indexval;
let lengthUpToId;

if (sessionGetInto?.includes(costingTableDataParent?.id)) {
  // Find the index of the first occurrence of id
 indexval = sessionGetInto?.findIndex((item:any) => item === costingTableDataParent?.id);

 // Calculate the length up to and including id
 lengthUpToId = indexval !== -1 ? indexval + 1 : 0;
}

console.log(lengthUpToId * 2 - 2, costingTableData?.ancestors?.length * 2 - 2, "marginLeft");




  return (
    <>
      <Box
        sx={
          hoveredIndex == costingTableData?.id
            ? {
              backgroundColor: "#f7e1f4",
              zIndex: 1,
              position: "relative",
              display: "flex",
              width: "100%",
              borderBottom: "0.1px solid #f0f2f0",
            }
            : {
              display: "flex",
              width: "100%",
              borderBottom: "0.1px solid #f0f2f0",
            }
        }
      // onMouseEnter={() => setHoveredIndex(costingTableData?.id)}
      // onMouseLeave={() => setHoveredIndex(null)}
      >
        <Box
          className={styles?.CheckboxTable}
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            width: "22rem",
            minWidth: '15rem',
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={costingTableData?.part_no}>
            <span
              style={
                (sessionGetInto?.includes(costingTableDataParent?.id))
                  ? {
                    marginLeft:`${costingTableData?.ancestors?.length * 2 - 2
                      }rem`,
                    display: "flex",
                    alignItems: "center",
                  }
                  : { display: "flex", alignItems: "center" }
              }
            >
              {!costingTableData?.is_leaf &&
                (!sessionGetInto?.includes(costingTableData?.id) ? (
                  <AddCircleOutlineIcon
                    sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      //  console.log(costingTableData?.id, "listdata");
                      setHierarchyLevel(hierarchyLevel+1);
                      handleOpenHierarchy(
                        costingTableData?.id,
                        costingTableData?.abbreviation,
                        costingTableData?.object_id
                      );
                    }}
                  />
                ) : (
                  <RemoveCircleOutlineIcon
                    sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      // console.log(costingTableData?.id, "listdata");
                      setHierarchyLevel(hierarchyLevel-1);
                      handleOpenHierarchy(
                        costingTableData?.id,
                        costingTableData?.abbreviation,
                        costingTableData?.object_id
                      );
                    }}
                  />
                ))}

              {costingTableData?.part_no}
            </span>
          </Tooltip>
        </Box>

        <Box
          sx={{
            padding: "0.5rem",
            borderBottomColor: "primary.light",
            width: "15rem",
            minWidth: '15rem',
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            cursor: "pointer",
            textDecoration: itemIndex == 0 ? "inherit" : "underline",
          }}
          onClick={() => {
            if (itemIndex !== 0) {
              window.open(
                `/#/costingscenario/${projectId}/${projectName}/${topVault}/${costingTableData?.id}/${scenarioType}/${treeabbr}/${scenarioId}`
              );
            }

          }}
        >
          <Tooltip title={costingTableData?.name.toLowerCase()}>
            <span style={{ textTransform: "capitalize" }}>
              {costingTableData?.name.toLowerCase()}
            </span>
          </Tooltip>
        </Box>

        <Box sx={{ display: "flex", flex: 1, }}>
          {costingTableData?.scenario_details?.map((item: any, index: any) => (
            <Tooltip
              title={
                item?.id == costingTableData?.scenario_details?.[0]?.id
                  ? ""
                  : item?.cost
              }
            >

              <Box
                onMouseEnter={(e) => {
                  if (item?.id == costingTableData?.scenario_details?.[0]?.id && costingTableData?.scenario_details > 1) {
                    handlePopoverOpen(e);
                  }
                }}
                onMouseLeave={(e) => {
                  if (item?.id == costingTableData?.scenario_details?.[0]?.id && costingTableData?.scenario_details > 1) {
                    handlePopoverClose();
                  }
                }}
                sx={{
                  // background:
                  //   (highlightedCellIndex === index && toggleHighlight) ? "#ffb2b2" : "inherit",
                  // color:
                  //   (highlightedCellIndex === index && toggleHighlight) ? "#fff" : "inherit",
                  display: "inline-block", // Ensures it behaves as a single line
                  overflow: "hidden", // Clips content that overflows the box
                  whiteSpace: "nowrap", // Prevents text from wrapping to the next line
                  textOverflow: "ellipsis", // Adds ellipsis to clipped text
                  padding: "0.5rem",
                  minWidth: "8rem",
                  width: '100%', // Use a measurable unit or a responsive percentage
                  textAlign:'right',
                  borderLeft:'1px solid',
                  borderColor:'primary.light',
                }}
              >


                {typeof item?.cost == "string" || item?.cost == null
                  ? ((!allEqual && item?.cost === maxCost && toggleHighlight) ?(
                    <>
                    {/* <span style={{color:"red", marginRight:"0.15rem"}}>[ </span> */}
                    <span style={{borderBottom:"0.2px solid red"}}> {item?.cost}</span> 
                      {/* <span style={{color:"red", marginLeft:"0.15rem"}}>] </span> */}
                    </>
                  ) : item?.cost )
                  : ((!allEqual && item?.cost === maxCost && toggleHighlight) ?(
                    <>
                   {/* <span style={{color:"red", marginRight:"0.15rem"}}>[ </span> */}
                   <span style={{borderBottom:"0.2px solid red"}}> {item?.cost?.toFixed(decimalPoints ? decimalPoints : 3)}</span>
                      {/* <span style={{color:"red", marginLeft:"0.15rem"}}>] </span> */}
                    </>
                  ) : item?.cost?.toFixed(decimalPoints ? decimalPoints : 3) )
                  
                  }

              </Box>
            </Tooltip>
          ))}
        </Box>

        <Popover
          id="mouse-over-popover"
          sx={{ pointerEvents: "none" }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <TableContainer sx={{ backgroundColor: "white", borderRadius: "8px" }}>
            <Table size="small" sx={{ minWidth: 400 }}>
              <TableHead sx={{ backgroundColor: "#e3f2fd" }}>
                <TableRow>
                  <TableCell align="center" sx={{ color: "#007FFF", fontWeight: "bold" }}>
                    Scenarios
                  </TableCell>
                  <TableCell align="center" sx={{ color: "#007FFF", fontWeight: "bold" }}>
                    Delta from Main Costing
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {costingTableData?.scenario_details?.slice(1)?.map((item: any, index: any) => {
                  const stdResult = parseFloat(costingTableData?.scenario_details?.[0]?.cost);
                  const scenarioCost = parseFloat(item?.cost);

                  const delta =
                    !isNaN(stdResult) && !isNaN(scenarioCost)
                      ? (stdResult - scenarioCost).toFixed(2)
                      : "-";

                  return (
                    <TableRow key={index} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f0f7ff" } }}>
                      <TableCell align="center" sx={{ color: "#007FFF" }}>
                        {item?.name || "-"}
                      </TableCell>
                      <TableCell align="center" sx={{ color: "#007FFF" }}>
                        {delta}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Popover>

        {/* <Box
          sx={{
            flex: "1",
            display: "flex",

           

             marginRight: { lg: "0.65rem", xl: "0.6rem" },
          }}
        >
          {costingTableData?.scenario_details?.map((item: any, index: any) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "0.2rem",
                  width: "100%",
                  borderLeft: "1px solid",
                }}
              >
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {item?.cost}
                </TableCell>
              </Box>
            );
          })}
        </Box> */}
      </Box>

      {loaderRecursiveId == costingTableData?.id && (
        <>

          <TableRow sx={{ display: "flex", width: "100%" }}>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
            <Box
              className={styles?.CheckboxTable}
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                width: "10%",
              }}
            >
              <Skeleton variant="text" />
            </Box>
          </TableRow>
        </>
      )}

      {costingTableData?.subsystem?.map((item: any, index: number) => {
        return (
          sessionGetInto?.includes(costingTableData?.id) && (
            <ScenarioCostingListViewRowItem
              costingTableData={item}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              sessionGetInto={sessionGetInto}
              costingTableDataParent={costingTableData}
              handleOpenHierarchy={handleOpenHierarchy}
              loaderRecursiveId={loaderRecursiveId}
              decimalPoints={decimalPoints}
              firstItem={firstItem}
              toggleHighlight={toggleHighlight}
              setHierarchyLevel={setHierarchyLevel}
              hierarchyLevel={hierarchyLevel}
            />
          )
        );
      })}
    </>
  );
}

export default ScenarioCostingListViewRowItem;
