import { Box, Divider, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { zIndex } from 'material-ui/styles';
import React, { useEffect, useState } from 'react'
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from '../../../../../../../api-services';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
    width: "70vw",
   
   
   
	bgcolor: '#fff',
	borderRadius: '20px',
	p: 1,
    zIndex:"2000"
};

function FormulalistModal({isOpen, onClose, machine_id, mhr_details, labour_id, lhr_details, revisionId}:any) {

    const [Loader, setLoader] = useState(false);
    const [calculationList, setCalculationList] = useState([]);

    const endPoint =  mhr_details ? "api/db/machine_cost/" : "api/db/labour_cost/"

    const getCalculationList = () => {
        setLoader(true);
        API.get(
         endPoint,
          {
            revision_id:revisionId,
            machine_id:machine_id ?machine_id : null,
            labour_id:labour_id ?labour_id : null,
            mhr_details:mhr_details ? true : null,
            lhr_details:lhr_details ? true : null
          },
          0
        )
          .then((res: any) => {
           
    
            setCalculationList(res.data);
          })
          .catch((err: any) => {
            
          }).finally(()=> {
            setLoader(false);
          });
      };


      useEffect(() => {
        getCalculationList();
      
      }, [machine_id, mhr_details, labour_id, lhr_details, revisionId]);

    
  // Define columns for the main data grid
  const columns: GridColDef[] = [
    { field: "id", headerName: "Sl No.", width: 60 },
    {
      field: "Variable",
      headerName: "Variable",
      width: 120,
      valueGetter: (params) => params.row?.Variable || "-",
    },
    {
      field: "Parameter",
      headerName: "Parameter",
      width: 230,
      valueGetter: (params) => params.row?.Parameter || "-",
    },
    {
      field: "Value",
      headerName: "Value",
      width: 110,
      valueGetter: (params) => params.row?.Value || "-",
    },
    {
      field: "Unit",
      headerName: "Unit",
      width: 120,
      valueGetter: (params) => params.row?.Unit || "-",
    },
    {
      field: "Description",
      headerName: "Description",
      width: 400,
      renderCell: (params) => (
       <>
       {params.row?.Description || "-"}
       </>
      ),
    },
  ];

// const columns: GridColDef[] = [
//     { field: "id", headerName: "Sl No.", width: 30 },
//     { field: "calc_name", headerName: "Cost Model Name", width: 300 },
//     { field: "field_name", headerName: "Cost Model Field Name", width: 300 },
//     { field: "address", headerName: "Source", width: 330 },
//     { field: "formula", headerName: "Cost Model Formula", width: 400 },
// ];

// const rows = calculationList.map((item: any, index: number) => ({
//     id: index + 1, // Generate sequential IDs
//     calc_name: item.calc_name || "N/A",
//     field_name: item.field_name || "N/A",
//     address: item.address || "N/A",
//     formula: item.formula || "N/A",
// }));




  // Map data to rows for the data grid
  const rows = calculationList
    ? calculationList.map((item: any, index: any) => ({
        id: index + 1,
        ID: index,
        ...item
      }))
    : [];





  console.log(rows, "calculationlist");
  return (
    <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
        
    <div style={style}>
        <Box sx={{background:"#fff", height:"100%", width:"100%", borderRadius:"0.75rem", borderTopLeftRadius:"10px",
                    borderTopRightRadius:"10px"}}>
        {/* <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            sx={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            {mhr_details ? "MHR Calculations" : "LHR Calculations"}
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onClose}
          />
        </Box>  */}
        {/* <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        /> */}

<Box
          sx={{  width: "100%", borderTopLeftRadius:"10px",
            borderTopRightRadius:"10px" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
          
        >
            {/* <DataGrid
                rows={rows}
                columns={columns}
                // pageSize={7}
                // rowsPerPageOptions={[7]}
                // disableSelectionOnClick
              /> */}

<Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: 0,
                    zIndex: 10,
                    borderTopLeftRadius:"10px",
                    borderTopRightRadius:"10px"
                  }}
                >
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: mhr_details ? "0" :"0.5rem 0.75rem",
                        borderBottomColor: "primary.light",
                        width: "10rem",
                        paddingLeft:"0.5rem",
                        borderTopLeftRadius:"10px",
                    
                      }}
                    >
                      Variable
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: mhr_details ? "0" : "0.35rem 0.5rem",
                        borderBottomColor: "primary.light",
                        paddingLeft:"0.25rem",
                        width: "20rem",
                      }}
                    >
                      Parameter
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: mhr_details ? "0" : "0.35rem 0.5rem",
                        borderBottomColor: "primary.light",
                        paddingLeft:"0.25rem",
                        width: "10rem",
                        textAlign:"right"
                      }}
                    >
                      Value
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: mhr_details ? "0" : "0.35rem 0.5rem",
                        borderBottomColor: "primary.light",
                        paddingLeft:"0.25rem",
                        width: "10rem",
                      }}
                    >
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: mhr_details ? "0" : "0.35rem 0.5rem",
                        borderBottomColor: "primary.light",
                        paddingLeft:"0.25rem",
                        width: "30rem",
                      }}
                    >
                      Description
                    </TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>
                    {calculationList.map((item: any, index: any) => {
                        return (
                            <TableRow sx={{color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : "inherit", background: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#0f7520" : "inherit"}}>
                            <TableCell
                              sx={{
                                //fontWeight:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? 600 : "inherit",
                                padding: mhr_details ? "0" : "0.35rem 0.75rem",
                                borderBottomColor: "primary.light",
                                paddingLeft:"0.5rem",
                                
                                color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : "inherit",
                                borderBottomLeftRadius:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "10px" : "inherit",
                                
                              }}
                            >
                              {item?.Variable}
                            </TableCell>
                            <TableCell
                              sx={{
                                //fontWeight:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? 600 : "inherit",
                                padding: mhr_details ? "0" : "0.35rem 0.75rem",
                                borderBottomColor: "primary.light",
                                paddingLeft:"0.25rem",
                                color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : "inherit",
                                
                              }}
                            >
                               {item?.Parameter}
                            </TableCell>
                            <TableCell
                              sx={{
                                textAlign:"right",
                                padding: mhr_details ? "0" : "0.35rem 0.75rem",
                                borderBottomColor: "primary.light",
                                paddingLeft:"0.25rem",
                                background: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#0f7520" : item?.formula ? "#dbd8d7" : "#fac396",
                              //  color: item?.formula ? "#f57322" : "inherit",
                              fontWeight:
                              //(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? 600 : 
                              item?.formula ? 600 : "inherit",
                                color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : item?.formula ? "#f57322" : "inherit"
                              }}
                            >
                              {typeof item?.Value === "string"
    ? item?.Value // Render as-is if it's a string
    : typeof item?.Value === "number"
    ? (item?.Value % 1 === 0
      ? item?.Value // Render as-is if it's a whole number
      : item?.Value.toFixed(2)) // Format to 2 decimal places if it's a float
    : "-"}
                            </TableCell>
                            <TableCell
                              sx={{
                               // fontWeight:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? 600 : "inherit",
                                padding: mhr_details ? "0" : "0.35rem 0.75rem",
                                borderBottomColor: "primary.light",
                                paddingLeft:"0.25rem",
                                color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : "inherit",
                                
                              }}
                            >
                               {item?.Unit}
                            </TableCell>
                            <TableCell
                              sx={{
                                //fontWeight:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? 600 : "inherit",
                                padding: mhr_details ? "0" : "0.35rem 0.75rem",
                                borderBottomColor: "primary.light",
                                paddingLeft:"0.25rem",
                                color: (item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "#fff" : "inherit",
                              
                                borderBottomRightRadius:(item?.Parameter == "Labour Hourly Rate" || item?.Parameter == "Machine Hourly Rate") ? "10px" : "inherit",
                              }}
                            >
                               {item?.Description}
                            </TableCell>
                          </TableRow>
                        )
                    })}
                </TableBody>
              </Table>
            </Box>
        
        
        </Box>
    </div>
    </Modal>
  )
}

export default FormulalistModal











