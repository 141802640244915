import React from 'react';
import { AUTH } from '../Redux/Services/auth.service';
import { ADMIN } from '../Redux/Services/admin.service';

interface Auth {
    isSuperuser?: boolean; // Optional property to handle when AUTH is not defined
}

interface BlockDevToolsProps {
    // AUTH?: Auth;
}

const BlockDevTools: React.FC<BlockDevToolsProps> = (props:any) => {
    React.useEffect(() => {
        if (AUTH?.isSuperUser) {
            // Allow DevTools if the user is a superuser
            return;
        }

        const blockDevToolsKeys = (e: KeyboardEvent) => {
            if (
                (e.ctrlKey && e.shiftKey && e.key === 'I') || // Ctrl + Shift + I
                (e.ctrlKey && e.shiftKey && e.key === 'J') || // Ctrl + Shift + J
                (e.ctrlKey && e.key === 'U') || // Ctrl + U (View Source)
                e.key === 'F12' // F12
            ) {
                e.preventDefault();
                ADMIN.toast.info('this action is not allowed');
            }
        };

        const disableRightClick = (e: MouseEvent) => {
            e.preventDefault();
            ADMIN.toast.info('this action is not allowed');
        };

        document.addEventListener('keydown', blockDevToolsKeys);
        document.addEventListener('contextmenu', disableRightClick);

        return () => {
            document.removeEventListener('keydown', blockDevToolsKeys);
            document.removeEventListener('contextmenu', disableRightClick);
        };
    }, [AUTH]);

    return null; // This component doesn't render anything
};

export default BlockDevTools;
