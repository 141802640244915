import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import { Box, Typography, Skeleton } from "@mui/material";
import { useParams, useRouteMatch } from "react-router-dom";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../../Components/ViewModule/ViewComponents.module.scss";
// web.cjs is required for IE11 support

import { useHistory } from "react-router-dom";
// import MenuItem from "@mui/material/MenuItem";

import axios from "axios";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });
  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  childRef?: any;
  locatorCount?:any
  setLocatorCount?:any;
  scenarioType?:any;
  isOpenn?:any
}

const CostingScenarioTree = forwardRef((props: TreeViewComponentsProps) => {
  const history = useHistory();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { projectId, topVault, vault, treeabbr, scenarioId,scenarioType } =
    useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const { HierarchyData, onSelectCallback, childRef,locatorCount,setLocatorCount,isOpenn } = props;

  const [titleString, setTitleString] = React.useState<any>("");
  const imageIframeRef = useRef<any>(null);
  const [activeHoverId, setActiveHoverId] = useState<any>("");
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [expanded, setExpanded] = useState<any>([topVault]);
  const [selected, setSelected] = useState<any>([topVault]);
  const [systemloader, setSystemLoader] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [activeId, setActiveId] = useState<any>(topVault);
  const [isProduc, setIsProduct] = useState<boolean>(false);
  const [HierarchyAllData, setHierarchyAllData] = useState<any>(HierarchyData);
  const [menuItems, setMenuItems] = React.useState<any>();
  const [validatedModules, setValidatedModules] = React.useState<any>([]);
  const [contentRef, setContentRef] = React.useState<any>();
  const [ModalData, setModalData] = useState<any>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const getPayload = (abbreviation: string) => {
    if(scenarioType == 2 && abbreviation !== "PRODUCT"){
      return {top_vault: topVault};
    }else{
      return {};
    }
  }

  const appendChildData = (data: any, nodeId: any, newChildData: any): any => {

    // Base case: if this nodeCostingScenario's id matches nodeId, append newChildData
    if (data.id == nodeId) {
      return {
        ...data,
        children: [...(data.children || []), ...newChildData], // Append new data to the existing children
      };
    }

    // If the nodeCostingScenario has children, recursively search in the children
    if (data.children) {
      return {
        ...data,
        children: data.children.map((child: any) =>
          appendChildData(child, nodeId, newChildData)
        ),
      };
    }

    // If no match and no children, return the nodeCostingScenario as is
    return data;
  };

  useEffect(() => {
    return () => source.cancel("Operation canceled by the user.");
  }, [activeHoverId]);

  const getVault = (treeabbr: any, idd: any) => {
    if (treeabbr == "product") {
      return { top_vault: idd };
    } else {
      return { vault: idd };
    }
  };











  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
    // setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
    // setOpenContext(true);
    // getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };

  const handleChange = async (e: any, nodeId: any, abbreviation: any) => {
    let a: any = sessionStorage.getItem("nodeCostingScenario");
    let val: any = JSON.parse(a) || [];

    // Only proceed to fetch child data if the nodeCostingScenario is not a leaf
    if (!HierarchyAllData.is_leaf) {
      if (!val.includes(nodeId)) {
        val.push(nodeId);
        sessionStorage.setItem("nodeCostingScenario", JSON.stringify(val));
        fetchChildData(nodeId, abbreviation); // Fetch child data when a nodeCostingScenario is expanded
      } else {
        let noDuplicate: any = val?.filter((item: any) => {
          return item != nodeId;
        });
        if (noDuplicate?.includes(topVault)) {
        } else {
          noDuplicate.unshift(topVault);
        }
      }
    }

    const newExpanded = expanded.includes(nodeId)
      ? expanded.filter((id: any) => id !== nodeId)
      : [...expanded, nodeId];

    setExpanded(newExpanded);
    setSelected([nodeId]); // Select the nodeCostingScenario

    setActiveId(nodeId);
    onSelectCallback(
      nodeId,
      abbreviation,
      HierarchyAllData,
      HierarchyAllData.id
    );
  };

  useEffect(() => {
    // Retrieve previously expanded nodes on component mount
    const savedExpandedNodes = sessionStorage.getItem("nodeCostingScenario");
    if (savedExpandedNodes) {
      setExpanded(JSON.parse(savedExpandedNodes));
    }
  }, [topVault]);

  // Fetch child data dynamically when expanding a nodeCostingScenario, passing abbreviation as well
  const fetchChildData = async (nodeId: any, abbreviation: string) => {
    setLoading(true);
    // if (url.includes("scenario") && treeabbr !== "product") {
      // API.get("/customer/costing_scenario_hierarchy/", { vault_id: vault }, 0).then((res: any) => {
      //   setHierarchyData(res.data)
      //   setHierarchyAllData(res.data.data);
      //   if(childRef.current){
      //     if(vault != 0){
      //   fetchSequentially();
      //     }else{
      //   fetchFirstLevelData();
      //     }
      //   }
      // })
      try {
        const response = await API.get(
          "/cost/scenario_hierachy/",
        {
          [(scenarioType == 1 ||abbreviation==="PRODUCT" )?"id":"category_id"]: nodeId,
          abbreviation: abbreviation,
          type: scenarioType == 1 ? null : 2,
          ...getPayload(abbreviation)
        },
        0
        );
  
        const ids = new Set(response.data.map((item: any) => item.id));

  
        // Append the new child data to the nodeCostingScenario with the given nodeId
        const updatedData = appendChildData(
          {
            ...HierarchyAllData,
            children: HierarchyAllData?.children?.filter(
              (item: any) => !ids.has(item?.id)
            ),
          },
          nodeId,
          response.data
        );
  
        // Update the state with the modified hierarchy
        setHierarchyAllData(updatedData);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Failed to fetch child data:", error);
      }
      
    // }
   
  };

  useImperativeHandle(childRef, () => ({
    fetchChildData,
  }));

  useEffect(() => {
    document.getElementById(vault)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, [vault]);

  return (
    <>
      {contentRef && (
        <>
          <Box className={styles.centeredContent}>
            <img
              ref={imageIframeRef}
              src={contentRef}
              height={200}
              width={200}
            />
          </Box>
        </>
      )}

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        className={styles.treetemp}
        // Control the icon and expand behavior based on is_leaf
        defaultParentIcon={<FolderIcon fill="primary" />}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && <FolderIcon color="primary" />
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon color="primary" />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={(e: any) => {
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation);
        }}
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == parseInt(vault)
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
      
        {loading ? (
          // Skeleton Loader Placeholder
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
            onMouseEnter={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
        
              setActiveHoverId(HierarchyAllData.id);

              setActiveHoverId(HierarchyAllData.id);
            }}
            onMouseLeave={() => {
              if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
              }
              setActiveHoverId(undefined);
            }}
            title={titleString && url.includes("costing") && titleString}
            label={
              <>
                <Skeleton variant="text" width={200} />
                <Skeleton variant="text" width={100} />
              </>
            }
          />
        ) : (
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
           
            title={titleString && url.includes("costing") && titleString}
            label={
              <Typography className={styles.treeViewData}>
                <Typography
                  style={{ fontSize: "12px" }}
                  className={styles.treeViewText}
                  onContextMenu={(event: any) =>
                    handleClick(
                      event,
                      HierarchyAllData.id,
                      HierarchyAllData.is_leaf,
                      HierarchyAllData
                    )
                  }
                >
                  {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                  <br /> {HierarchyAllData?.name}
                </Typography>
              </Typography>
            }
          >
            {HierarchyAllData?.children?.map((child: any) => {
              return (
                <CostingScenarioTree
                  key={child.id}
                  ref={childRef} // Each child gets its own ref
                  HierarchyData={child}
                  onSelectCallback={onSelectCallback}
                />
              );
            })}
          </StyledTreeItem>
        )}
      </TreeView>
 
    </>
  );
});

export default CostingScenarioTree;
