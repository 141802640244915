import React, { Fragment, Suspense } from "react";
import "./AppRouter.css";
import { Provider, useDispatch } from "react-redux";
import { HashRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { storeConfig } from "./Components/Redux/Store/storeConfigurations";
import Landing from "./Components/ComponentUI/Landing/Landing";
import Routes, { RoutesProps } from "./Components/ui-reusable-component/Routes";
import { Header } from "./Components/ComponentUI/Header/Header";
import { CompareHome } from "./Components/Compare/CompareHome";
import ViewComponents from "./Components/ViewModule/ViewComponents";
import SearchComponent from "./Components/ComponentUI/SearchPage/SearchComponent";
import BomViewComponents from "./Components/ViewModule/BomView/BomViewComponents";
import FeatureLandingPage from "./Components/ViewModule/FeatureLandingPage/FeatureLandingPage";
import IdeaLandingPage from "./Components/ComponentUI/IdeaModule/IdeaLandingPage";
import IdeaEntryInnerPage from "./Components/ComponentUI/IdeaModule/IdeaEntryInnerPage/IdeaEntryInnerPage";
import IdeaDashboard from "./Components/ComponentUI/IdeaModule/IdeaDashboard/IdeaDashboard";
import CostingViewComponents from "./Components/ViewModule/CostingView/CostingViewComponents";
import ReportsDirectory from "./Components/ComponentUI/ReportsBank/ReportsDirectory";
import ActivityLogRouter from "./Components/ComponentUI/ActivityLogs/ActivityLogRouter";
import ScenarioHome from "./Components/ViewModule/CostingView/Scenario/ScenarioHome";
import IdeaPrioritisationMatrix from "./Components/ComponentUI/IdeaModule/IdeaDashboard/IdeaPrioritisationMatrix";
import AnalysisLandingPage from "./Components/ComponentUI/AnalysisModule/AnalysisLandingPage";
import AnalysisWeightLandingPage from "./Components/ComponentUI/AnalysisModule/AnalysisWeightLandingPage";
import AnalysisMaterialLandingPage from "./Components/ComponentUI/AnalysisModule/AnalysisMaterialLandingPage";
import ComparedMainPage from "./Components/ViewModule/Comparefolder/ComparedMainPage";
import AnalysisCostingLandingPage from "./Components/ComponentUI/AnalysisModule/AnalysisCostingLandingPage";
import AnalysisCostInnerPage from "./Components/ComponentUI/AnalysisModule/AnalysisCostInnerPage";
import AnalysiscategoryWisePartCost from "./Components/ComponentUI/AnalysisModule/AnalysisCategoryWisePartCost";
import { VideoGuide } from "./Components/ComponentUI/VideoGuide/VideoGuide";
import { BannerPage } from "./Components/ComponentUI/BannerPage/BannerPage";
import MinutesofMeeting from "./Components/ComponentUI/MinutesofMeeting/MoMLanding";
import SentMail from "./Components/ComponentUI/MinutesofMeeting/SentMail";
import RecycleBinLandingPage from "./Components/ComponentUI/RecycleBin/RecycleBinLandingPage";
import { AUTH } from "./Components/Redux/Services/auth.service";
import InternalLandingMainPage from "./Components/ComponentUI/InternalLanding/InternalLandingMainPage";
import ProjectDirectoryLandingPage from "./Components/ComponentUI/ProjectDirectory/ProjectDirectoryLandingPage";
import { UserManagementLanding } from "./Components/ComponentUI/Usermanagement/UserManagementLanding";
import AnalysisCostingTable from "./Components/ComponentUI/AnalysisModule/AnalysisCostingTable";
import DashboardLandingPage from "./Components/ComponentUI/Dashboard/DashboardLandingPage";
import MilestonePage from "./Components/ComponentUI/MilestoneModule/MilestonePage";
import InwardLandingPage from "./Components/ComponentUI/InwardModule/InwardLandingPage";
import CompareCombination from "./Components/ComponentUI/QuickCompare/CompareCombination";
import GanttChart from "./Components/ComponentUI/MilestoneModule/Gantt Chart/GanttChart";
import ProjectDeliverymailLandingPage from "./Components/ComponentUI/ProjectMailModule/ProjectDeliverymailLandingPage";
import ProjectOthermailLandingPage from "./Components/ComponentUI/ProjectMailModule/ProjectOthermailLandingPage";
import { MailLogs } from "./Components/ComponentUI/ProjectMailModule/MailLogs";
import ProjectScopeLanding from "./Components/ComponentUI/MilestoneModule/ProjectScope/ProjectScopeLanding";
import GanttMailLandingPage from "./Components/ComponentUI/MilestoneModule/GanttMailLandingPage/GanttMailLandingPage";
import InwardEmail from "./Components/ComponentUI/InwardModule/InwardEmail";
import { IdeaConfigurationLanding } from "./Components/Configuration/Idea/IdeaConfigurationLanding";
import ProjectDirectorylist from "./Components/ComponentUI/ProjectDirectory/ProjectDirectorylist";
import CostingConfiguarationCostModels from "./Components/Configuration/Costing/CostingConfiguarationCostModels";
import ConfigurationMainLanding from "./Components/Configuration/ConfigurationMainLanding";
import { FeatureConfigurationLanding } from "./Components/Configuration/Feature/FeatureConfigurationLanding";
import CostingConfiguarationCommodityandProcess from "./Components/Configuration/Costing/CostingConfigurationCommodityandProcess";
import CostingConfigurationGuidelines from "./Components/Configuration/Costing/CostingConfigurationGuidelines";
import CostingConfigurationAssumptionCard from "./Components/Configuration/Costing/CostingConfigurationAssumptioncard";
import CalibrationLandingPage from "./Components/Calibration/CalibrationLandingPage";
import CompanionSessionLogs from "./Components/CompanionConfig/CompanionSessionLogs";
import CompanionMainLanding from "./Components/Configuration/Companion/CompaionMainLanding";
import CalibrationRecyclebinPage from "./Components/Calibration/CalibrationRecyclebinPage";
import DataBaseRouter from "./Components/DataBaseModule/DataBaseRouter";
import { BomConfigurationLanding } from "./Components/Configuration/BOM/BomConfigurationLanding";
import DispatchLandingPage from "./Components/Dispatch/DispatchLandingPage";
import DispatchEmail from "./Components/Dispatch/DispatchEmail";
import { BOMCustomValidationLandingPage } from "./Components/Configuration/BOM/BOMCustomValidationLandingPage";
import { EIRandWorkshopLanding } from "./Components/ComponentUI/EIRandWorkshop/EIRandWorkshopLanding";
import { WorkStationsPage } from "./Components/Configuration/BOM/WorkStationsPage";
import { CreateFeaturePage } from "./Components/ComponentUI/CreateFeature/CreateFeaturePage";
import { MediaCreateProjectSelection } from "./Components/ComponentUI/MediaCreate/MediaCreateProjectSelection";
import { MediaCreateLanding } from "./Components/ComponentUI/MediaCreate/MediaCreateLanding";
import { ArchitectureCreateLanding } from "./Components/ComponentUI/ArchitectureCreate/ArchitectureCreateLanding";
import ArchitectureRecycleBin from "./Components/ComponentUI/ArchitectureCreate/ArchitectureRecycleBin";
import MediaRecycleBin from "./Components/ComponentUI/MediaCreate/MediaRecycleBin";
import FeatureRecyclebin from "./Components/ComponentUI/CreateFeature/FeatureRecyclebin";
import TriangulationLanding from "./Components/ComponentUI/CostingCreateModule/CostingTriangulation/TriangulationLanding";
import InputConfiguration from "./Components/ComponentUI/CostingCreateModule/CostingTriangulation/InputConfiguration";
import CostingCreateSystemLandingPage from "./Components/ComponentUI/CostingCreateModule/CostingCreateSystemLandingPage";
import CostingCreateScannerPage from "./Components/ComponentUI/CostingCreateModule/CostingCreateScannerPage";
import OEMRecyclebin from "./Components/ComponentUI/ProjectDirectory/OEMRecyclebin";
import { CreateCostingPartLanding } from "./Components/ComponentUI/CostingCreateModule/CostingEstimation/CreateCostingPartLanding";
import CostingCreateSystemInside from "./Components/ComponentUI/CostingCreateModule/CostingCreateSystemInside";
import CostingCreateLPPLanding from "./Components/ComponentUI/CostingCreateModule/CostingLPP/CostingCreateLPPLanding";
// import { CostingScenarioLanding } from "./Components/ComponentUI/CostingScenarios/CostingScenarioLanding";
import CostingScenarioCreateSystemLandingPage from "./Components/ComponentUI/CostingCreateModule/CostingScenario/CostingScenarioCreateSystemLandingPage";
import CostingScenerioSystemInsideLanding from "./Components/ComponentUI/CostingCreateModule/CostingScenario/CostingScenerioTableHierarchy/CostingScenerioSystemInsideLanding";
import BOMCreateLandingPage from "./Components/ComponentUI/BOMCreate/BOMCreateLandingPage";
import BOMEntryLandingPage from "./Components/ComponentUI/BOMCreate/BOMEntry/BOMEntryLandingPage";
import BOMCreateScannerPage from "./Components/ComponentUI/BOMCreate/BOMCreateScannerPage";
import BOMEntryQRScannerPage from "./Components/ComponentUI/BOMCreate/BOMEntry/BOMEntryQRScannerPage";
import CostingGuideLines from "./Components/ComponentUI/CostingCreateModule/CostingEstimation/CostingGuideLines";
import BOMImageMeasurement from "./Components/ComponentUI/BOMCreate/BOMEntry/BOMImagesandVideos/BOMImageMeasurement";
import IdeaCreateLandingpage from "./Components/ComponentUI/IdeaCreate/IdeaCreateLandingpage";
import IdeaAutoGenerate from "./Components/ComponentUI/IdeaCreate/IdeaAutoGenerate/IdeaAutoGenerate";
import IdeaAutoGenerateDetailsPage from "./Components/ComponentUI/IdeaCreate/IdeaAutoGenerate/IdeaAutoGenerateDetailsPage";
import IdeaGenerationViewLanding from "./Components/ComponentUI/IdeaCreate/IdeaView/IdeaGenerationViewLanding";
import AnalysisCategoryCostingTable from "./Components/ComponentUI/AnalysisModule/AnalysisCategoryCostingTable";
import AnalysisCategoryBOMTable from "./Components/ComponentUI/AnalysisModule/AnalysisCategoryBOMTable";
import AnalysisMaterialBOMTable from "./Components/ComponentUI/AnalysisModule/AnalysisMaterialBOMTable";
import CostingCreateCommodiyWiseAnalysis from "./Components/ComponentUI/CostingCreateModule/CostingLPP/CostingCreateCommodiyWiseAnalysis";
import CostingAnalysisSummary from "./Components/ComponentUI/CostingCreateModule/CostingLPP/CommodityWiseAnalysis/CostingAnalysisSummary";
import APIConsoleMonitorLandingPage from "./Components/DataBaseModule/APIConsoleMonitorLandingPage";
import { APIConsoleMainPage } from "./Components/ViewModule/APIConsoleMainPage";
import { CostingTemplateConfig } from "./Components/Configuration/Costing/CostingTemplateConfig";
import { LocakFlow } from "./Components/ComponentUI/CostingCreateModule/CostingEstimation/LockFlow";
import PCBTable from "./Components/Configuration/Costing/PCBTable";
import { ScenarioCostingLanding } from "./Components/ComponentUI/NewScenariosCostingCreate/ScenarioCostingLanding";
import CostingCreateCommodiyWiseAnalysisCreateCosting from "./Components/ComponentUI/CostingCreateModule/CostingLPP/CostingCreateCommodiyWiseAnalysisCreateCosting";


const ArchitectureRepositoryRouter = React.lazy(
  () =>
    import(
      "./Components/ComponentUI/ArchitectureModule/ArchitectureRepositoryRouter"
    )
);
const MediaRepositoryRouter = React.lazy(
  () => import("./Components/ComponentUI/MediaModule/MediaRepositoryRouter")
);
const Authentication = React.lazy(
  () => import("./Components/ui-reusable-component/Authentication")
);
const DriveDirectory = React.lazy(
  () => import("./Components/ComponentUI/Drive/DriveDirectory")
);
const FeedbackRouter = React.lazy(
  () => import("./Components/FeedBack/FeedbackRouter")
);
const ProfileLanding = React.lazy(
  () => import("./Components/ComponentUI/ProfilePage/ProfileLanding")
);
const Login = React.lazy(
  () => import("./Components/ComponentUI/Authentication/Login/Login")
);

// store configuration setup
export const store = storeConfig();
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

function AppRouter() {
  
  
  return (
    <React.Fragment>
      <Provider store={store}>
        <HashRouter>
          <Suspense

          // fallback={<BouncingLoader />}
          >
            <ToastContainer className="unselectable" />
            <Switch>
              <Route path="/" component={Login} exact={true} />
              <Route
                path="/verification/:token"
                component={Login}
                exact={true}
              />
              <Route path="/sign-up/:type" component={Login} exact={true} />
              {AUTH.user_category !== "Internal" ? (
                <Route
                  path="/internallanding"
                  render={(props) => (
                    <Fragment>
                      <InternalLandingMainPage />
                    </Fragment>
                  )}
                  exact={true}
                />
              ) : (
                <Route
                  path="/landing"
                  render={(props) => (
                    <Fragment>
                      <Landing />
                    </Fragment>
                  )}
                  exact={true}
                />
              )}
              <Route
                path="/search"
                render={(props) => (
                  <>
                    <Header />
                    <SearchComponent />
                  </>
                )}
                exact={true}
              />
              <Route
                path="/ProfilePage/:projectId/:accessType/:productId"
                render={(props) => (
                  <Fragment>
                    <Header />

                    {/* Now name of Profile is changed to My Account now*/}

                    <ProfileLanding />
                  </Fragment>
                )}
                exact={true}
              />
              <NestedSwitch />
              <Redirect to="/" />
            </Switch>
          </Suspense>
        </HashRouter>
      </Provider>
    </React.Fragment>
  );
}

const NestedSwitch = () => {
  const routes: RoutesProps["routes"] = [
    {
      path: "/dashboard/:projectId/:topVaultId",
      component: DashboardLandingPage,
      exact: true,
    },
    {
      path: "/customValidation/:projectId/:projectname/:topVaultId/:categoryId/:parameterId",
      component: BOMCustomValidationLandingPage,
      exact: true,
    },
    {
      path: "/calibrationRecyclebin",
      component: CalibrationRecyclebinPage,
      exact: true,
    },
    {
      path: "/companionConfig/:projectId/:projectname",
      component: CompanionMainLanding,
      exact: true,
    },
    {
      path: "/companionSessionLogs/:projectId/:projectname",
      component: CompanionSessionLogs,
      exact: true,
    },
    {
      path: "/calibration",
      component: CalibrationLandingPage,
      exact: false,
    },
    {
      path: "/project-scope/:projectId/:dept",
      component: ProjectScopeLanding,
      exact: false,
    },
    {
      path: "/ganttchart-mail/:projectId/",
      component: GanttMailLandingPage,
      exact: false,
    },
    {
      path: "/videoguide",
      component: VideoGuide,
      exact: true,
    },
    {
      path: "/user-management",
      component: UserManagementLanding,
      exact: true,
    },
    {
      path: "/user-permissions/:projectId/:moduleId/:filterOPtion/:routeType",
      component: UserManagementLanding,
      exact: true,
    },

    {
      path: "/project_directory",
      component: ProjectDirectoryLandingPage,
      exact: true,
    },

    {
      path: "/recyclebin/:projectId",
      component: RecycleBinLandingPage,
      exact: true,
    },
    {
      path: "/banner/:projectId/:projectname/:abbreviation/:fileIndex/:pageCount",
      component: BannerPage,
      exact: false,
    },
    {
      path: "/view/:projectId/:topVault/:vault/:treeabbr/false",
      component: ViewComponents,
      exact: true,
    },
    {
      path: "/view/:projectId/:topVault/:vault/:treeabbr/costing",
      component: CostingViewComponents,
      exact: true,
    },
    {
      path: "/view/:projectId/:topVault/:vault/:treeabbr/costing/scenario",
      component: ScenarioHome,
      exact: true,
    },
    {
      path: "/view/:projectId/:topVault/:vault/:treeabbr/costing/scenario/:scenarioId",
      component: CostingViewComponents,
      exact: true,
    },
    {
      path: "/view/:projectId/:topVault/:vault/:treeabbr/true",
      component: BomViewComponents,
      exact: true,
    },

    {
      path: "/drive/:projectId/:type/:groupId",
      component: DriveDirectory,
      exact: true,
    },

    {
      path: "/reports/:projectId/:type/:moduleId",
      component: ReportsDirectory,
      exact: true,
    },
    {
      path: "/feedback/:feedbackId/:type",
      component: FeedbackRouter,
      exact: false,
    },
    {
      path: "/compare/:projectId/:module/:level/:comparedId",
      component: CompareHome,
      exact: false,
    },
    {
      path: "/media-repository",
      component: MediaRepositoryRouter,
      exact: false,
    },
    {
      path: "/architecture",
      component: ArchitectureRepositoryRouter,
      exact: false,
    },
    {
      path: "/idea_dashboard/:projectId/:topVaultId",
      component: IdeaDashboard,
      exact: true,
    },
    {
      path: "/idea_dashboard/prioritisationMatrix/:projectId/:topVaultId",
      component: IdeaPrioritisationMatrix,
      exact: true,
    },

    {
      path: "/newcompare/:projectId/:moduleType/:topVaultId/:abbr/:NodeId/:Partname/:Count/:TopId/:Nodeleaf/:depthNode/:pathname",
      component: ComparedMainPage,
      exact: true,
    },

    {
      path: "/idea/:projectId/:topVaultId/:abbreviation/:vaultId",
      component: IdeaLandingPage,
      exact: true,
    },
    {
      path: "/idea/:projectId/:topVaultId/:abbreviation/:vaultId/:ideaId",
      component: IdeaEntryInnerPage,
      exact: true,
    },

    {
      path: "/featurelandingPage/:projectId/:topVault/:vault/:abbr/:FeatureType/:FeatureId/:GroupId/:FeatureName",
      component: FeatureLandingPage,
      exact: false,
    },
    {
      path: "/activity-logs",
      component: ActivityLogRouter,
      exact: false,
    },
    {
      path: "/analysis/:projectId/:topVault",
      component: AnalysisLandingPage,
      exact: true,
    },
    {
      path: "/analysis/weight/:projectId/:topVault",
      component: AnalysisWeightLandingPage,
      exact: true,
    },
    {
      path: "/analysis/cost/:projectId/:topVault",
      component: AnalysisWeightLandingPage,
      exact: true,
    },
    {
      path: "/analysis/part/:projectId/:topVault",
      component: AnalysisWeightLandingPage,
      exact: true,
    },
    {
      path: "/analysis/material/:projectId/:topVault",
      component: AnalysisMaterialLandingPage,
      exact: true,
    },
    {
      path: "/analysis/category/:projectId/:topVault",
      component: AnalysisMaterialLandingPage,
      exact: true,
    },
    {
      path: "/analysis_costing/:projectId/:topVault",
      component: AnalysisCostingLandingPage,
      exact: true,
    },
    {
      path: "/analysis_costing_table/:projectId/:topVault",
      component: AnalysisCostingTable,
      exact: true,
    },
    {
      path: "/analysis_costing_category/:projectId/:topVault",
      component: AnalysisCategoryCostingTable,
      exact: true,
    },
    {
      path: "/analysis_bom_category/:projectId/:topVault",
      component: AnalysisCategoryBOMTable,
      exact: true,
    },
    {
      path: "/analysis_bom_material/:projectId/:topVault",
      component: AnalysisMaterialBOMTable,
      exact: true,
    },
    {
      path: "/analysis_costing/manufacturing/:projectId/:topVault",
      component: AnalysisCostInnerPage,
      exact: true,
    },
    {
      path: "/analysis_costing/kgCost/:projectId/:topVault",
      component: AnalysisCostInnerPage,
      exact: true,
    },
    {
      path: "/analysis_costing/bopdc/:projectId/:topVault",
      component: AnalysisCostInnerPage,
      exact: true,
    },
    {
      path: "/analysis_costing/CategoryWise/:projectId/:topVault",
      component: AnalysiscategoryWisePartCost,
      exact: true,
    },
    {
      path: "/MinutesofMeeting/:projectId/:groupId/:pointsId",
      component: MinutesofMeeting,
      exact: true,
    },
    {
      path: "/MinutesofMeeting/:projectId/:groupId/:pointsId/sentmail",
      component: SentMail,
      exact: true,
    },
    {
      path: "/Milestone/:projectId",
      component: MilestonePage,
      exact: false,
    },
    {
      path: "/GanttChart/:projectId",
      component: GanttChart,
      exact: false,
    },
    {
      path: "/inward/:projectId/:projectname/:productId",
      component: InwardLandingPage,
      exact: true,
    },
    {
      path: "/CompareCombination/:combinationId",
      component: CompareCombination,
      exact: true,
    },
    {
      path: "/user-management",
      component: UserManagementLanding,
      exact: true,
    },
    {
      path: "/mail-module/Delivery/:projectname/:projectId/:productIds/:status/:ccIds/:date",
      component: ProjectDeliverymailLandingPage,
      exact: true,
    },
    {
      path: "/mail-module/Other/:projectname/:projectId/:productIds/:status/:ccIds/:date",
      component: ProjectOthermailLandingPage,
      exact: true,
    },
    {
      path: "/user-permissions/:projectId/:moduleId/:filterOPtion/:routeType",
      component: UserManagementLanding,
      exact: true,
    },
    {
      path: "/Project-mail-logs/:projectId/:logtype/:logsname",
      component: MailLogs,
      exact: true,
    },
    {
      path: "/Inward-mail/:projectname/:projectId/:productIds/:status/:InwardId",
      component: InwardEmail,
      exact: true,
    },
    {
      path: "/configurationmain/:projectId/:projectname",
      component: ConfigurationMainLanding,
      exact: true,
    },
    {
      path: "/costingconfiguration/:projectId/:projectname/assumptioncard/:productId/:tab/:costMain/:costSenario",
      component: CostingConfigurationAssumptionCard,
      exact: true,
    },
    {
      path: "/ideaconfiguration/:projectId/:projectname/:datatype",
      component: IdeaConfigurationLanding,
      exact: true,
    },
    {
      path: "/feature-configuration/:projectId/:projectname/:mainId/:categoryId/:categoryname/:datatype/:paramType",
      component: FeatureConfigurationLanding,
      exact: true,
    },
    {
      path: "/bomconfiguration/:projectId/:projectname/:productId/:categoryId/:categoryname/:validationId/:datatype",
      component: BomConfigurationLanding,
      exact: true,
    },
    {
      path: "/work-stations/:projectId/:projectname/:productId/",
      component: WorkStationsPage,
      exact: true,
    },
    {
      path: "/costingconfiguration/:projectId/:projectname/:selectedIdTree/:dataType",
      component: CostingConfiguarationCostModels,
      exact: true,
    },
    {
      path: "/costingconfiguration/:projectId/:projectname/:selectedIdTree/:dataType/:library/",
      component: CostingConfiguarationCommodityandProcess,
      exact: true,
    },
    {
      path: "/costingconfiguration/:projectId/:projectname/guidelines",
      component: CostingConfigurationGuidelines,
      exact: true,
    },

    {
      path: "/Project-Directory-list/:type/:projectId",
      component: ProjectDirectorylist,
      exact: true,
    },
    {
      path: "/data-base",
      component: DataBaseRouter,
      exact: false,
    },
    {
      path: "/dispatch/:projectId/:projectname/:productId",
      component: DispatchLandingPage,
      exact: false,
    },
    {
      path: "/dispatchemail/:projectId/:projectname/:status/:productIds",
      component: DispatchEmail,
      exact: false,
    },

    {
      path: "/eirandworkshop/:projectId/:projectname/:abbreviation/:fileIndex/:pageCount",
      component: EIRandWorkshopLanding,
      exact: false,
    },
    {
      path: "/costing/:module/costing-triangulation/:projectID/:topVaultID/:topVaultName",
      component: TriangulationLanding,
      exact: true,
      name: "Triangulation",
    },
    {
      path: "/costing/:module/costing-triangulation/:projectID/:topVaultID/:topVaultName/:calculatorId",
      component: InputConfiguration,
      exact: true,
      name: "Input Configuration",
    },

    {
      path: "/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber",
      component: CreateFeaturePage,
      exact: false,
    },
    {
      path: "/createfeature/recyclebin/:projectId",
      component: FeatureRecyclebin,
      exact: false,
    },
    {
      path: "/mediacreateselect/:projectId/:projectName",
      component: MediaCreateProjectSelection,
      exact: false,
    },
    {
      path: "/mediacreateinternal/:projectId/:projectName/:mediaType/:abbreviation/:category/:groupId/:productList/:pageNumber/:apiCall",
      component: MediaCreateLanding,
      exact: false,
    },
    {
      path: "/mediacreateinternal/recycleBin/:projectId",
      component: MediaRecycleBin,
      exact: false,
    },
    {
      path: "/oem/OEMrecycleBin",
      component: OEMRecyclebin,
      exact: false,
    },
    {
      path: "/architecturecreateinternal/:projectId/:projectname/:abbr/:topvoult/:subSystem/:groupId/:collapestatus/:pageNumber/:productpage/:groupName/:diagramsCount",
      component: ArchitectureCreateLanding,
      exact: false,
    },
    {
      path: "/architecturecreate/recycleBinRrchitecturecreate/:projectId",
      component: ArchitectureRecycleBin,
      exact: false,
    },
    {
      path: "/createcostingpart/:projectId/:projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId/:scenarioId/:conditionPopup/:newUI",
      component: CreateCostingPartLanding,
      exact: false,
    },
    {
      path: "/costingsystemlanding/:projectId/:projectName/:initialTopVault/:topVaultIds/:expandTable",
      component: CostingCreateSystemLandingPage,
      exact: false,
    },

    {
      path: "/costingscenariosystemlanding/:projectId/:projectName/:topVaultId/:initialScenarioId/:scenarioIds/:expandTable",
      component: CostingScenarioCreateSystemLandingPage,
      exact: false,
    },   
    
    {
      path: "/costingscanner/:projectId/:projectName",
      component: CostingCreateScannerPage,
      exact: false,
    },
    {
      path: "/costingscenarioabbrlanding/:projectId/:projectName/:top_vault/:initialScenarioId/:scenarioIds/:expandTable/:inside_scenario_id/:scenarioName/:abbr/:vault_id",
      component: CostingScenerioSystemInsideLanding,
      exact: false,
    },
    {
      path: "/costingabbrlanding/:projectId/:projectName/:initialTopVault/:topVaultIds/:expandTable/:top_vault/:top_vaultName/:abbr/:vault_id",
      component: CostingCreateSystemInside,
      exact: false,
    },
    {
      path: "/costingqualitycheck/:projectId/:projectName/:initialTopVault/:topVaultIds/:expandTable/:abbr/:partId/:depth/:vault_id",
      component: CostingCreateLPPLanding,
      exact: false,
    },
    // {
    //   path: "/costingscenario/:projectId/:projectName/:topVault/:vault/:scenarioType/:treeabbr/:scenarioId",
    //   component: CostingScenarioLanding,
    //   exact: false,
    // },
    {
      path: "/costingscenario/:projectId/:projectName/:topVault/:vault/:scenarioType/:treeabbr/:scenarioId",
      component: ScenarioCostingLanding,
      exact: false,
    },    
    {
      path: "/BOMCreate/:projectId/:projectName/:initialTopVault/:topVaultIds/:expandTable",
      component: BOMCreateLandingPage,
      exact: false,
    },
    {
      path: "/bomentry/:projectId/:projectName/:topVault/:vault/:treeabbr/:workstation/:isLeaf/:copyStatus",
      component: BOMEntryLandingPage,
      exact: false,
    },
    {
      path: "/bom-image-measurement/:projectId/:projectName/:topVault/:vault/:image_id",
      component: BOMImageMeasurement,
      exact: false,
    },
    {
      path: "/bomscanner/:projectId/:projectName",
      component: BOMCreateScannerPage,
      exact: true,
    },
    {
      path: "/bomqrscanner/:projectId/:projectName/:topVault",
      component: BOMEntryQRScannerPage,
      exact: false,
    },
    {
      path:"/costingguidelines/:projectId/:projectName",
      component: CostingGuideLines,
      exact: false,
    },
    {
      path: "/idea-generation/:projectId/:projectName/:topVault/:system/:ideaId/:ideaIdName",
      component: IdeaCreateLandingpage,
      exact: false,      
    },
    {
      path: "/idea-auto-generation/:projectId/:projectName/:topVault/:subSystem/:abbrevID/:originID/:ideaId/:ideaIdName",
      component: IdeaAutoGenerate,
      exact: false,
    },
    {
      path: "/idea-auto-generation-details/:projectId/:projectName/:topVault/:subSystem/:abbrevID/:originID/:combinationsId/:ideaId/:ideaIdName",
      component: IdeaAutoGenerateDetailsPage,
      exact: false,
    },
    {
      path: "/idea-generation-view/:projectId/:projectName/:topVault/:subSystem/:subSystemId/:expandTable/:abbr/:partId/:depth/:vault_id/:ideaId/:ideaIdName",
      
      component: IdeaGenerationViewLanding,
      exact: false,
    },
    {
      path: "/CostingCreateCommodiyWiseAnalysis/:projectId/:projectName/:activeCombinationId/:initialTopVault/:topVaultIds/:expandTable/:abbr/:partId/:depth/:vault_id/:projectIdOld/:projectNameOld",
      component: CostingCreateCommodiyWiseAnalysis,
      exact: false,
    },
    {
      path: "/CostingCreateCommodiyWiseAnalysisCosting/:projectId/:projectName/:activeCombinationId/:initialTopVault/:topVaultIds/:expandTable/:abbr/:partId/:depth/:vault_id/:projectIdOld/:projectNameOld/:abbrParent/:CID/:costing_type",
      component: CostingCreateCommodiyWiseAnalysisCreateCosting,
      exact: false,
    },
    {
      path:'/CostingAnalysisSummary/:projectId/:projectName',
      component: CostingAnalysisSummary,
      exact: false,
    },
    {
      path:'/API-console/:projectId/:projectName/:productId/:productName/:BOMCategory/:GrpId',
      component: APIConsoleMonitorLandingPage,
      exact: true,
    },
    {
      path: '/API-console/:projectId/:projectName/:productId/:productName/:BOMCategory/:GrpId/landing/:requestId/:treeId',
      component: APIConsoleMainPage,
      exact: true,
    },
    {
      path: "/costingconfiguration-template/:projectId/:projectname/:categoryId/:categoryGroup/:configMirror/:calcId/:calcSequence/:config_id/:costingType/:costingSubType",
      component: CostingTemplateConfig,
      exact: false,
    },
    {
      path: "/costingconfiguration-lock-flow/:projectId/:projectname/:topVault/:vault/:selectedIdTree/:dataType",
      component: LocakFlow,
      exact: false,
    },
    {
      path: "/pcbtable/",
      component: PCBTable,
      exact: false,
    },
  ];
  const location = useLocation();
  const excludeHeader = location.pathname === '/landing';
  const excludeHeaderI = location.pathname === '/internallanding';

  return (
    <>
      <Authentication type="error">
        <span className="root">
        {!excludeHeader && !excludeHeaderI && <Header />}
          <span>
            <main className="app-main">
              <Suspense>
                {AUTH.user_category == "Internal" ? (
                  <>
                    <Route
                      path="/internallanding"
                      render={(props) => (
                        <Fragment>
                          <InternalLandingMainPage {...props} />
                        </Fragment>
                      )}
                      exact={true}
                    />
                    <Routes routes={routes} redirectTo="/internallanding" />
                  </>
                ) : (
                  <>
                    <Route
                      path="/landing"
                      render={(props) => (
                        <Fragment>                          
                          <Landing {...props} />
                        </Fragment>
                      )}
                      exact={true}
                    />
                    <Routes routes={routes} redirectTo="/landing" />
                  </>
                )}
              </Suspense>
            </main>
          </span>        
        </span>
      </Authentication>
    </>
  );
};

export default AppRouter;
