import React, { useEffect, useMemo, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";

// src/Components/Assets/images/svgs/openfolderblue.svg
// openfolderblue.svg
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import calculator from "../../Assets/images/calculator.svg";

// import { API } from "../../api-services";
import { Box, CircularProgress, Typography } from "@mui/material";
import { ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import AddProcessModal from "./AddProcessModal";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import InfoTreeModal from "./InfoTreeModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { CalculatorPasteModal } from "./CalculatorPasteModal";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Swal from "sweetalert2";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {AUTH} from  "../../Redux/Services/auth.service"
import { Download, Upload } from "@mui/icons-material";
import UploadIcon from '@mui/icons-material/Upload';
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CostingTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  useraction?: any;
}
const CostingConfigurationTreeViewcomponents = (
  props: CostingTreeViewcomponentsProps
) => {
  const {
    HierarchyData,
    onSelectCallback,
    setSelectedTreeData,
    setCounterApiCall,
    useraction,
  } = props;
  const userProfile = useSelector(userSelectors.selectAll);
  
  const { selectedIdTree, projectname, projectId } = useRouteParams<any>();
  const infoModal = useBaseModal();
  const costModelPaste = useBaseParamsModal();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>(null);
  const [rightClickedId, setRightClickedId] = React.useState<any>("");
  const [anchorPoint, setAnchorPoint] = useState<any>({ x: 0, y: 0 });
  const inputElRef = React.useRef<any>();
  const [uploadCalcListLoader, setUploadCalcListLoader] = React.useState<any>(
    false
  );
  

  
  const open = Boolean(anchorEl);

  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([
    parseInt(selectedIdTree),
  ]);
  const [selected, setSelected] = React.useState<any>(selectedIdTree);

  const addProcess = useBaseParamsModal();

  const getAutoExpandedNodes = (data: any, resultArr: any = []) => {
    const nodes = Array.isArray(data) ? data : [data]; // Wrap in array if not already an array

    nodes.forEach((node: any) => {
      // Check if node has exactly one child in its data array
      if (node?.data?.length === 1) {
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Recursively continue with its single child
        getAutoExpandedNodes(node?.data, resultArr);
      } else if (node?.data?.length > 1) {
        // If node has multiple children in data array
        resultArr.push(node?.sequence_id); // Push current node's sequence_id
        // Push sequence_id of each immediate child
        //node?.data.forEach((child:any) => resultArr.push(child?.sequence_id));
      }
    });

    return resultArr;
  };

  const handleChange = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    onSelectCallback(nodeId, id, HierarchyAllData, itemId);
    if (HierarchyAllData && HierarchyAllData?.type === "calculator") {
      setSelectedTreeData(HierarchyAllData);
      sessionStorage.setItem(
        "calculator_type",

        HierarchyAllData?.calculator_type
      );
    } else {
      setSelectedTreeData({});
      sessionStorage.removeItem("calculator_type");
    }
    let a: any = sessionStorage.getItem("Costingconfigurationcalcnode");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(id)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != id;
      });
      let b: any = sessionStorage.setItem(
        "Costingconfigurationcalcnode",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(id);
      let b: any = sessionStorage.setItem(
        "Costingconfigurationcalcnode",
        JSON.stringify(val)
      );
    }



    const expandedNodes = getAutoExpandedNodes(HierarchyAllData);
     
     const index = expanded.indexOf(id);
    if (index === -1) {
     
      expandedNodes?.forEach((item: any) => {
        if (!val?.includes(item)) {
          val.push(item); // Add to arr2 if not present
        }
      });
    } else {
      

      expandedNodes?.forEach((item: any) => {
        const indexx = val?.indexOf(item);
        if (indexx !== -1) {
          val.splice(indexx, 1); // Add to arr2 if not present
        }
      });
    }

    sessionStorage.setItem(
      "Costingconfigurationcalcnode",
      JSON.stringify(val)
    );

    setActiveId(id);

   

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(id);
    } else {
      copyExpanded?.splice(index, 1);
    }

    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  const renderData = useMemo(()=>{
    return HierarchyAllData &&
      HierarchyAllData?.data?.map((subsystemData: any) => {
        return (
          <>
            <CostingConfigurationTreeViewcomponents
              HierarchyData={subsystemData}
              key={subsystemData?.sequence_id}
              useraction={useraction && useraction}
              setSelectedTreeData={setSelectedTreeData}
              onSelectCallback={onSelectCallback}
              setCounterApiCall={setCounterApiCall}
            />
          </>
        );
      })
  },[HierarchyAllData])

  const handleDownload = (e:any, id:any) => {
    // console.log(id, "e.target")
    API.post(`/cost/export_calculator/`, {
      
    },
    {
      calculator_id: id,
    }, 0)
    .then((res: any) => {
      window.open(res.data, "_blank", " noopener");
    })
    .catch((err: any) => { 
      Swal.fire({
        title: "Error!",
        text: "Error in downloading calculator list",
        icon: "error",
        confirmButtonText: "Ok",
      });
    });
  }

  useEffect(() => {
    let a: any = sessionStorage.getItem("Costingconfigurationcalcnode");
    let val: any = JSON.parse(a) || [];
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [selectedIdTree, sessionStorage.getItem("Costingconfigurationcalcnode")]);
  //@ts-ignore
  // const menuItemClassName = ({ hover }) =>
  // 	hover ? 'my-menuitem-hover' : 'my-menuitem';
  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  const uploadCalc = (file: any) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("commodity", HierarchyAllData?.commodity_id);
    formData.append("process", HierarchyAllData?.id);

    // commodity & process

    setUploadCalcListLoader(true)
    API.post("/cost/upload_calculator_data/", formData , {},  0)
      .then((res: any) => {
        setUploadCalcListLoader(false)
        Swal.fire({
          title: "Success!",
          text: "Calculator uploaded successfully",
          icon: "success",
          confirmButtonText: "Ok",
        });
      })
      .catch((err: any) => {
        setUploadCalcListLoader(false)
        Swal.fire({
          title: "Error!",
          text: "Error in uploading calculator",
          icon: "error",
          confirmButtonText: "Ok",
        });
      });
  }

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    //convert into formdata
    

    uploadCalc(file);
    // setDisable( false );
  };

  const browseFiles = () => {
    
    return inputElRef.current?.click?.();
  };
  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };
  const [contextMenu, setContextMenu] = React.useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  const handleContextMenu = (event: React.MouseEvent, id: any) => {
    event?.stopPropagation();
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
        // Other native context menus might behave different.
        // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
        null
    );
    setRightClickedId(id);
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  return (
    <>
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        sx={{
          ".MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper": {
            boxShadow: 1,
          },
        }}
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
      >
        {useraction &&
          useraction?.includes("C") &&
          HierarchyAllData?.type === "commodity" ? (
          <MenuItem
            value={"Process"}
            // className={menuItemClassName}
            disabled={useraction && useraction?.includes("C") ? false : true}
            onClick={(e: any) => {
              setSelectedCommodity(HierarchyAllData.id);
              addProcess.open(HierarchyAllData.id);
            }}
          >
            <DataSaverOnIcon
              sx={{ cursor: "pointer", marginRight: "0.5rem" }}
            />
            Add Process
          </MenuItem>
        ) : (
          " "
        )}
        {useraction &&
          useraction?.includes("C") &&
          (userProfile[0].is_project_admin || AUTH.isSuperUser) &&
          HierarchyAllData?.type === "process" ? (
            <>
            
            <MenuItem
            value={"Process"}
            // className={menuItemClassName}
            disabled={useraction && useraction?.includes("C") && !uploadCalcListLoader ? false : true}
            onClick={() => browseFiles()}
            
          >
            {
              uploadCalcListLoader ? <CircularProgress size={16} /> : (<><UploadIcon
              sx={{
                cursor: "pointer",
                marginRight: "0.5rem",
                fontSize: "1.5rem",
              }}
              
              />
            Upload Cost Model</>)
            }
            
            
          </MenuItem>
            </>
          
        ) : (
          ""
        )}
        {useraction &&
          useraction?.includes("C") &&
          HierarchyAllData?.type === "sub_calculator_cat" ? (
          <MenuItem
            value={"Process"}
            // className={menuItemClassName}
            disabled={useraction && useraction?.includes("C") ? false : true}
            onClick={() => {
              API.post(
                "/cost/calculator/",
                {
                  name: "calculator1",
                  process: HierarchyAllData?.process,
                  commodity_mapping: HierarchyAllData?.commodity,
                  result: "",
                  category: HierarchyAllData.id,
                },
                {},
                0
              )
                .then((res: any) => {
                  history.push(
                    `/costingconfiguration/${projectId}/${projectname}/${res.data.id
                    }/${"calculator"}`
                  );
                  setCounterApiCall((prev: any) => prev + 1);
                })
                .catch((err: any) => { });
            }}
          >
            <img
              src={calculator}
              style={{
                width: "13px",
                color: "#007fff",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            />
            Add Cost model
          </MenuItem>
        ) : (
          ""
        )}
        {HierarchyAllData?.type === "calculator" ? (
          <>
            {useraction &&
              useraction?.includes("D") &&
              !HierarchyAllData?.mapped_status && (
                <MenuItem
                  style={{ fontSize: "1rem" }}
                  value={"calculator"}
                  disabled={
                    useraction && useraction?.includes("D") ? false : true
                  }
                  // className={menuItemClassName}
                  onClick={() => {
                    API.delete(`/cost/calculator/${rightClickedId}/`, {}, 0)
                      .then((res: any) => {
                        setCounterApiCall((prev: any) => prev + 1);
                        history.push(
                          `/costingconfiguration/${projectId}/${projectname}/0/0`
                        );
                      })
                      .catch((err: any) => { });
                  }}
                >
                  <DeleteIcon
                    sx={{
                      cursor: "pointer",
                      marginRight: "0.5rem",
                      fontSize: "1.5rem",
                    }}
                  />
                  Delete
                </MenuItem>
              )}
            {useraction && useraction?.includes("C") && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                // className={menuItemClassName}
                // disabled={useraction && useraction?.includes("C")?false:true }
                onClick={() => {
                  // costModelPaste.open(HierarchyAllData);
                  API.post(
                    "/cost/copy_calculator/",
                    {
                      id: HierarchyAllData.id,
                      name: "copy_calc1",
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      setCounterApiCall((prev: any) => prev + 1);
                      Swal.fire({
                        icon: "success",
                        title: "Copied Successfully",
                        showConfirmButton: true,
                      });
                      history.push(
                        `/costingconfiguration/${projectId}/${projectname}/${res.data.id
                        }/${"calculator"}`
                      );
                    })
                    .catch((err: any) => { });
                }}
              >
                <FileCopyIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Copy here
              </MenuItem>
            )}
            {useraction && useraction?.includes("C") && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                // className={menuItemClassName}
                // disabled={useraction && useraction?.includes("C")?false:true }
                onClick={() => {
                  costModelPaste.open(HierarchyAllData);
                }}
              >
                <CopyAllIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Copy
              </MenuItem>
            )}
            {HierarchyAllData?.mapped_status && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                // className={menuItemClassName}
                onClick={() => infoModal.open()}
              >
                <InfoIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Usage Info
              </MenuItem>
            )}
            {
              (userProfile[0].is_project_admin || AUTH.isSuperUser)  && (
                <MenuItem
                  style={{ fontSize: "1rem" }}
                  value={"calculator"}
                  // className={menuItemClassName}
                  onClick={(e:any) => handleDownload(e, HierarchyAllData?.id)}
                  >
                    <Download
                      sx={{
                        cursor: "pointer",
                        marginRight: "0.5rem",
                        fontSize: "1.5rem",
                      }}
                    />
                    Download CostModel
                  </MenuItem>
              )
            }

          </>
        ) : (
          ""
        )}
      </Menu>

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.sequence_id}
        id={HierarchyAllData?.sequence_id}
        defaultParentIcon={<FolderIcon fill="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.type !== "calculator" && (
            // HierarchyAllData?.sequence_id == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
          // )
        }
        defaultExpandIcon={<FolderIcon color="primary" />}
        defaultEndIcon={
          HierarchyAllData?.type === "calculator" ? (
            <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={() =>
          handleChange(
            HierarchyAllData?.name,
            HierarchyAllData?.sequence_id,
            HierarchyAllData,
            HierarchyAllData?.id
          )
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == selectedIdTree
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
        onContextMenu={(event: any) =>
          handleContextMenu(event, HierarchyAllData?.id)
        }
        style={{ cursor: "context-menu" }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.sequence_id}
          nodeId={HierarchyAllData?.sequence_id}
          label={
            <Typography className={styles.treeViewData}>
              <div
                className={styles.treeViewText}
                style={
                  HierarchyAllData?.type === "calculator"
                    ? {
                      color: HierarchyAllData?.mapped_status
                        ? "green"
                        : "red",
                    }
                    : { color: "" }
                }
              // style={{position:'relative',zIndex:1}}
              // onContextMenu={(e: any) => {
              //   setRightClickedId(HierarchyAllData.id);
              //   if (HierarchyAllData?.type === "commodity") {
              //     e.preventDefault();
              //     e.stopPropagation();
              //     setAnchorPoint({ x: e.clientX + 10, y: e.clientY + 10 });
              //     setOpen(true);
              //   } else if (HierarchyAllData?.type === "sub_calculator_cat") {
              //     e.preventDefault();
              //     e.stopPropagation();
              //     setAnchorPoint({ x: e.clientX, y: e.clientY });
              //     setOpen(true);
              //   } else if (HierarchyAllData?.type === "calculator") {
              //     e.preventDefault();
              //     e.stopPropagation();
              //     setAnchorPoint({ x: e.clientX, y: e.clientY });
              //     setOpen(true);
              //   }
              // }}
              >
                {HierarchyAllData?.name}{" "}
                {HierarchyAllData?.calc_count ||
                  HierarchyAllData?.calc_count === 0
                  ? `(${HierarchyAllData?.calc_count})`
                  : ""}
              </div>
            </Typography>
          }
        >
          {renderData}
          {/* {HierarchyAllData &&
            HierarchyAllData?.data?.map((subsystemData: any) => {
              return (
                <>
                  <CostingConfigurationTreeViewcomponents
                    HierarchyData={subsystemData}
                    key={subsystemData?.sequence_id}
                    useraction={useraction && useraction}
                    setSelectedTreeData={setSelectedTreeData}
                    onSelectCallback={onSelectCallback}
                    setCounterApiCall={setCounterApiCall}
                  />
                </>
              );
            })} */}
        </StyledTreeItem>
      </TreeView>
      <input
            type="file"
            style={{ display: "none" }}
            // required
            accept=".enc"
            id="file"
            name="file"
            ref={inputElRef}
            onChange={handleChangeFile}
            onClick={handleInputClick}
            capture="user"
          />
      {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}
      {infoModal.isOpen && (
        <InfoTreeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={rightClickedId && rightClickedId}
        />
      )}

      {costModelPaste.isOpen && (
        <CalculatorPasteModal
          isOpen={costModelPaste.isOpen}
          onCloseModal={costModelPaste.close}
          HierarchyAllData={costModelPaste.propsId}
          setCounterApiCall={setCounterApiCall}
        />
      )}
    </>
  );
};

export default CostingConfigurationTreeViewcomponents;
