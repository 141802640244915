import * as React from 'react';
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, IconButton, Skeleton, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import FlagIcon from '@mui/icons-material/Flag';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LaunchIcon from '@mui/icons-material/Launch';
import { useHistory } from 'react-router-dom';
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags';
import EditIcon from '@mui/icons-material/Edit';
import { useEditOperationRevision } from './useEditOperationRevision';
import EditOperationRevisionTableModel from './EditOperationRevisionTableModel';
import { useShareWithDb } from '../../useShareWithDb';
import { useBaseModal } from '../../../../ComponentUI/SearchPage/useBaseModal';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import useWindowDimensions from '../../../../ui-reusable-component/useWindowDimensions';
import { API } from '../../../../api-services';
import FetchItemFromDBPopUp from '../../FetchItemFromDBPopUp';
import OperationMovePopUp from './OperationMovePopUp';
import AddIcon from '@mui/icons-material/Add';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import move from "../../../../Assets/images/right move.svg";
import { useBomCategoryUse } from '../../../BOM Categories/useBomCategoryUse';
import ToolsRevisionUseList from '../../ToolsRevisionUseList';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../../Redux/Reducers/user.reducer';
import LockIcon from '@mui/icons-material/Lock';
import { useToolAddEditModel } from '../../useToolAddEditModel';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockStatusInfoModal from '../../LockStatusInfoModal';
import AddOperationDatabaseModel from '../OperationDatabase/AddOperationDatabaseModel';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import OperationActionComponents from '../../ActionComponents/OperationActionComponents';
import Popover from '@mui/material/Popover';



type Anchor = "top" | "left" | "bottom" | "right";

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Database Operation Revision (Costing-DB) In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '100%', xl: '100%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.6rem',
  }}>
    <span>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion
//     disableGutters
//     elevation={0}
//     square
//     {...props}
//   />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: "1px solid rgba(0, 0, 0, .125)",
// }));


export interface IOperationRevisionTableProps {
  setRecyclebinCounter: any;
}


export function OperationRevisionTable(props: IOperationRevisionTableProps) {
  const { setRecyclebinCounter } = props;
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [accordianData, setAccordianData] = useState<any>();
  const [accordianInsideData, setAccordianInsideData] = useState<any>();
  const [accordianInsideDataLoader, setAccordianInsideDataLoader] = useState<boolean>(true);
  const [apiCallAccordianInsideData, setApiCallAccordianInsideData] = useState<boolean>(true);
  const [apiCallAccordianData, setApiCallAccordianData] = useState<boolean>(true);
  const [accordianLoader, setAccordianLoader] = useState<boolean>(false);
  const listOperationRevisionUse = useBomCategoryUse();
  const operationShare = useShareWithDb();
  const operationFetchFromDb = useBaseModal();
  const operationEdit = useEditOperationRevision();
  const [CurrencyData, setCurrencyData] = useState<any>(null)
  const [useraction, setuseraction] = useState<any>(null)
  const { width } = useWindowDimensions();
  const history = useHistory();
  const lockmodal = useBaseModal();
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile = useSelector(userSelectors.selectAll);
  const [selectedTab, setSelectedTab] = React.useState('');
  const operationAddFetchFromDb = useToolAddEditModel();
  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  useEffect(() => {
    setAccordianLoader(true);
    API.get('/api/db/revision_database/', { revision_id: revisionId, category_type: "operationdatabase" }, 0).then((res: any) => {
      setAccordianData(res?.data);
      setAccordianLoader(false);
      if (categoryName == 0) {
        setSelectedTab(res?.data[0]?.category);
        history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${res?.data[0]?.category}/${status}/${search}/${lockCounter}`)
      }
    })
  }, [typeId, apiCallAccordianData])

  React.useEffect(() => {
    if (categoryName !== '0') {
      setSelectedTab(categoryName);
      // getCategoryList();
    }
  }, [categoryName, lockCounter]);

  // useEffect(() => {
  //   API.get('/api/db/revision_database/', { revision_id: revisionId, category_type: "operationdatabase" }, 0).then((res: any) => {
  //     setAccordianData(res?.data);
  //   })
  // }, [typeId, apiCallAccordianData])

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`)
  //   }
  // };
  const handleChange = (categoryName: any) => {
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}/${lockCounter}`)
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);

  //for initial load
  // React.useEffect(() => {
  // 	if (accordianData && accordianData.length > 0) {
  // 	  // Set the selected tab to the first item in categoryList when the component loads
  // 	  setSelectedTab(accordianData[0].category);
  // 	  history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${accordianData[0].category}/${status}/${search}`);
  // 	//   getMaterialData(categoryList[0].category);
  // 	}
  //   }, [accordianData]);
  useEffect(() => {
    if (categoryName != 0) {
      setAccordianInsideDataLoader(true)
      API.get('/api/db/stroke_rate_revision/', { revision: revisionId, category: categoryName, fetch: true }, 0).then((res: any) => {
        setAccordianInsideData(res?.data);
        setAccordianInsideDataLoader(false);
      });
    }
  }, [categoryName, apiCallAccordianInsideData, lockCounter])


  const handleShareWith: any = (idGet: any, shareItemCodeGet: any, listCommodityGet: any, shareItemNameGet: any) => {
    operationShare.open(idGet, shareItemCodeGet, listCommodityGet, shareItemNameGet)
    setAnchorEl(null);
  }

  const fetchDataFromDb: any = (e: any) => {
    e.stopPropagation();
    operationFetchFromDb.open();
  }

  const handleEditOperation: any = ( currency_code: any, unit: any, idGet: any, rateGet: any, flagGet: any, unitGet: any, currency_id?:any,) => {
    setCurrencyData({
      currency_code: currency_code,
      unit: unit,
      currency_id:currency_id
    });

    operationEdit.open(idGet, rateGet, flagGet, unitGet);
  }

  const addAndFetchDataFromDb: any = (e: any) => {
    e.stopPropagation();
    operationAddFetchFromDb.open(-1, "add");

  }

  const handleListToolRevisionUse = (id: any) => {
    listOperationRevisionUse.open(id)
  }
  const LockHandler = (item: any, type: any) => {

    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {

        API.put(`/api/db/stroke_rate_revision/${item?.id}/`, {
          lock: type, revision: revisionId,
          commodity: categoryName,

        }).then((res: any) => {
          setAccordianInsideDataLoader(true)
          API.get('/api/db/stroke_rate_revision/', { revision: revisionId, category: categoryName }, 0).then((res: any) => {
            setAccordianInsideData(res?.data);
            setAccordianInsideDataLoader(false);
          });
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    } if (type == true) {
      API.put(`/api/db/stroke_rate_revision/${item?.id}/`, {
        lock: type, revision: revisionId,
        commodity: categoryName,

      }).then((res: any) => {
        setAccordianInsideDataLoader(true)
        API.get('/api/db/stroke_rate_revision/', { revision: revisionId, category: categoryName }, 0).then((res: any) => {
          setAccordianInsideData(res?.data);
          setAccordianInsideDataLoader(false);
        });
      }).catch((err: any) => {
        console.log("Server error")
      })
    }



  }
  const handleDelete = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Operations Code`,
          value: `deleteOperationCode`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteOperationCode":
          API.delete("/api/db/stroke_rate_revision/" + id + "/")
            .then((res: any) => {
              setRecyclebinCounter((prev: any) => prev + 1);
              setApiCallAccordianInsideData(!apiCallAccordianInsideData);
              setApiCallAccordianData(!apiCallAccordianData);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.


  let columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1390 ? 130 : width < 1200 ? 120 : 120,
      renderCell: (cellValues: any) => {
        return (<>
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
            <Box>
              {cellValues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                <LockIcon />
              </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                <LockOpenIcon />
              </IconButton>}
            </Box>
            <Box>
              {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <IconButton title='Edit' sx={{ color: '#007fff', padding: '0rem' }} 
              onClick={() => {
                
                
                 handleEditOperation(cellValues?.row?.currency_code, cellValues?.row?.unit, cellValues?.row?.id, cellValues?.row?.rate, cellValues?.row?.flag, cellValues?.row?.unit, cellValues?.row?.currency_id)
              }}
              ><EditIcon /></IconButton>}
            </Box>
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellValues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: cellValues?.row?.flag ? 'green' : 'primary.main' }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              sx={{
                '.MuiPaper-root.MuiPopover-paper': {
                  boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                }
              }}
            >
              <OperationActionComponents
                cellValues={cellValues.row}
                rowId={rowId}
                handleListToolRevisionUse={handleListToolRevisionUse}
                handleDelete={handleDelete}
                handleShareWith={handleShareWith}
                LockHandler={LockHandler}
                history={history}
                tab={tab}
                year={year}
                month={month}
                revisionId={revisionId}
                typeId={typeId}
                categoryName={categoryName}
                status={status}
                search={search}
                useraction={useraction}
              />
            </Popover>
          </Box>
        </>)
      }
    },
    { field: "sno", headerName: "SNo.", width: width > 1390 ? 50 : width < 900 ? 50 : 50, },
    { field: "operationCode", headerName: "Operation Code", width: width > 1390 ? 300 : 150, flex: 1 },
    {
      field: "description",
      headerName: "Description",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1390 ? 100 : width < 900 ? 200 : 200,
      flex: 1,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: width > 1390 ? 270 : 180,
      flex: 1,
    },
    {
      field: "rate", headerName: "Rate/Unit (Curr)", width: width > 1390 ? 140 : 120, align: 'right', cellClassName: "super-app-theme--cell",
      renderCell: (cellValues: any) => {
        return (
          <Box title={`Rate/Unit (Curr) :${cellValues?.row?.rate} `} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.rate ? cellValues?.row?.rate : "--"} `}
          </Box>
        );
      },
    },
    // {
    //   field: "operation_unit",
    //   headerName: "Operation Unit",
    //   minWidth: width > 1390 ? 120 : 80,
    // },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },

  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = accordianInsideData && accordianInsideData?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: ideaItem?.id,
      sno: index + 1,
      operationCode: ideaItem?.["operation"]?.code,
      description: ideaItem?.["operation"]?.description,
      remark: ideaItem?.["operation"]?.remark,
      operation_unit: ideaItem?.operation_unit,
      rate: ideaItem?.rate,
      flag: ideaItem?.flag,
      category: ideaItem?.["operation"]?.category,
      idGetInner: ideaItem?.["operation"]?.id,
      mapped_status: ideaItem?.mapped_status,
      lock_status: ideaItem?.lock_status,
      locked_on: ideaItem?.locked_on,
      currency_id: ideaItem?.currency_id ? ideaItem?.currency_id : ideaItem?.currency,
      currency_code: ideaItem?.currency_code,
      unit: ideaItem?.unit,

      locked_user: ideaItem?.locked_user,

      locked_user_email
        :
        ideaItem?.locked_user_email,
      locked_user_name
        :
        ideaItem?.locked_user_name,
      locked_user_phone
        :
        ideaItem?.locked_user_phone,
    };
    return objShared
  });


  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  
  console.log(rows, "typee");
  

  return (
    <Box sx={{ width: "100%", }}>
      {operationFetchFromDb.isOpen &&
        <FetchItemFromDBPopUp
          isOpen={operationFetchFromDb.open}
          onCloseModal={operationFetchFromDb.close}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
          apiCallAccordianData={apiCallAccordianData}
          setApiCallAccordianData={setApiCallAccordianData}
        />
      }
      {operationEdit.isOpen &&
        <EditOperationRevisionTableModel
          isOpen={operationEdit.open}
          operationEdit={operationEdit}
          currency_code={CurrencyData?.currency_code}
          unit={CurrencyData?.unit}
          currency_id={CurrencyData?.currency_id}
          onCloseModal={operationEdit.close}
          id={operationEdit.id}
          valueEdit={operationEdit.rate}
          flagEdit={operationEdit.flag}
          unitGet={operationEdit.unit}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
        />
      }
      {
        operationAddFetchFromDb.isOpen &&
        <AddOperationDatabaseModel
          isOpen={operationAddFetchFromDb.open}
          onCloseModal={operationAddFetchFromDb.close}
          id={operationAddFetchFromDb.id}
          actionSend={operationAddFetchFromDb.actionSend}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
          apiCallAccordianData={apiCallAccordianData}
          setApiCallAccordianData={setApiCallAccordianData}

        />
      }
      {operationShare.isOpen &&
        <OperationMovePopUp
          isOpen={operationShare.open}
          onCloseModal={operationShare.close}
          idIncoming={operationShare.id}
          shareItemCode={operationShare.shareItemCode}
          shareItemCategory={operationShare.shareItemCategory}
          apiCallAccordianInsideData={apiCallAccordianInsideData}
          setApiCallAccordianInsideData={setApiCallAccordianInsideData}
          apiCallAccordianData={apiCallAccordianData}
          setApiCallAccordianData={setApiCallAccordianData}
        />
      }
      {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}

      {listOperationRevisionUse.isOpen &&
        <ToolsRevisionUseList
          isOpen={listOperationRevisionUse.isOpen}
          onCloseModal={listOperationRevisionUse.close}
          id={listOperationRevisionUse.id}
        />}

      {accordianLoader ?
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
        : <>
          {/* <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '0.5rem',
              width: '100%',
              borderBottom: '1px solid',
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.2">
          {accordianData && accordianData?.map((item: any, index: number) => {
            return <>
            <Box
										key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
													item?.category == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
														item?.category == selectedTab ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
                          whiteSpace: 'nowrap',                          
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => handleChange(item?.category)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>            
              </>
            })}
          </Box> */}
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'flex-start',
            margin: "0.3rem",
            columnGap: "0.5rem",
          }}>
            {useraction && useraction?.includes("C") && <> <Button size='small' sx={{
              cursor: 'pointer', width: "13rem", zIndex: 1, '&:hover': {
                backgroundColor: "#007fff", color: "#fff",
              }
            }} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />} onClick={(e: any) => fetchDataFromDb(e)}>Fetch Operations</Button>
              <Button size='small' sx={{
                cursor: 'pointer', width: "18rem", zIndex: 1, '&:hover': {
                  backgroundColor: "#007fff", color: "#fff",
                }
              }} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />} onClick={(e: any) => addAndFetchDataFromDb(e)}>Add & Fetch Operations</Button>
            </>
            }
          </Box>
          <Box sx={{ height: { lg: '82vh', xl: '85vh' }, width: "100%" }}>
            {accordianInsideData && accordianInsideData?.length > 0 ? <DataGrid
              headerHeight={width > 1370 ? 48 : 40}
              rowHeight={36}
              loading={accordianInsideDataLoader}
              {...dataStructureDataGrid}
              disableSelectionOnClick={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: 'none',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                },
              }}
              pageSize={100}
              rowsPerPageOptions={[100]}
            /> : <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
            }
          </Box>
        </>}


    </Box>
  );
}